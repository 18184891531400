
import angular from 'angular';

import template from './userImage.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myUserImageNew', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            user: '=',
            size: '='
        },
        controller($scope) {
            $scope.onClickInt = ($event) => {
                $scope.onClick({ $event });
            };
        }
    };
});
