import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('stationService', (httpService) => {
    return {
        async getMyStations() {
            return await httpService.get('/station/my');
        },
        async searchStations({ query, suggestAllTags, page, itemsPerPage }) {
            return await httpService.post('/station/search', {
                query,
                suggestAllTags,
                page,
                itemsPerPage
            });
        },
        async getTopStations() {
            return await httpService.get('/station/top');
        },
        async getUserTotalStations({ userId, exclude }) {
            return await httpService.post(`/station/userCount`, {
                userId,
                exclude
            });
        }
    }
});
