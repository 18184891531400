/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('beatsService', function($q, $http, helper, $timeout, $rootScope, httpService, storage, userService){

        // Private variables

        var _isReadyDefer = $q.defer();

        var _balance;
        var _packages;
        var _features;

        // Private functions
        var _allLoaded = function(){
            return !helper.isUndefined(_balance) && !helper.isUndefined(_packages) && !helper.isUndefined(_features);
        };

        var _checkIsServiceReady = function(){
            if(_allLoaded()){
                _isReadyDefer.resolve();
            }
        };

        const updateBalance = (data) => {
            const prevBalance = _balance;
            _balance = data;

            if(prevBalance !== _balance){
                $rootScope.$broadcast('userBeats:updated', data);
                storage.setItem('totalBeats', data);
            }
        };

        var _loadBalance = function(){
            var d = $q.defer();
            $timeout(function(){
                if(!helper.isUserLoggedIn()) {
                    _balance = 0;
                    return d.resolve(0);
                }
                $http.get('/beats/balance').success(function(data){
                    updateBalance(data);
                    d.resolve();
                }).error(d.reject);
            });
            return d.promise;
        };

        var _loadPackages = function(){
            var d = $q.defer();
            $http.get('/beats/packages').success(function(data){
                _packages = data;
                d.resolve();
            }).error(d.reject);
            return d.promise;
        };

        var _loadFeatures = function(){
            var d = $q.defer();
            $http.get('/beats/features').success(function(data){
                _features = data;
                d.resolve();
            }).error(d.reject);
            return d.promise;
        };

        var _loadAll = function(){
            return $q.all([
                _loadBalance(),
                _loadPackages(),
                _loadFeatures()
            ]);
        };

        return {
            promise: _isReadyDefer.promise,
            keys: MyGlobal.project.beats.keys,
            init: function(){

                $rootScope.$on('window:storage', (evt, event) => {
                    const { key }  = event?.originalEvent || {};

                    if (key === 'totalBeats') {
                        updateBalance(storage.getItem('totalBeats'));
                    }
                });

                return _loadAll().then(function(){
                    _checkIsServiceReady();
                });
            },
            getBalance: function(){
                return _balance;
            },
            getPurchasePackages: function(){
                return _packages;
            },
            getFeatures: function(forFeaturesList){
                if(!_features || !_features.length) return;
                if(forFeaturesList){
                    return _features.filter(function (feature) {
                        return !feature.hideInFeaturesList;
                    });
                }
                return _features;
            },
            async purchaseHasBeenMade(key){

                const feature = this.getFeatureByKey(key);
                if (userService.hasSubscription() && feature?.freeOnSubscription) {
                    this.addFeatureUserFeatures(feature);
                    return;
                }

                $rootScope.$broadcast('beats:purchaseHasBeenMade', key);
                this.addFeatureUserFeatures(feature);
                return _loadBalance();
            },
            addFeatureUserFeatures(feature) {
                if (!feature || !feature.enableFeatures?.length) return;

                for (const userFeatureKey of feature.enableFeatures) {
                    userService.addFeature(userFeatureKey);
                }
            },
            async rewardHasBeenGiven(key){
                $rootScope.$broadcast('beats:rewardHasBeenGiven', key);
                return _loadBalance();
            },
            async refresh(){
                return _loadBalance();
            },
            getFeatureByKey: function(key){
                var features = _features && _features.filter(function(feature){
                    return feature.key === key;
                });

                if(features && features.length) return features[0];
            },
            getFeaturePrice: function(key){
                const feature = this.getFeatureByKey(key);
                if (userService.hasSubscription() && feature?.freeOnSubscription) return 0;
                return feature?.cost || 0;
            },
            userCanSpend(amount = 0) {
                return this.getBalance() - amount >= 0;
            },
            userCanBuy: function(key, factor){
                var feature = this.getFeatureByKey(key);
                if(!feature) return false;
                return feature.cost + (factor || 0) <= this.getBalance();
            },
            generatePaymentToken(pkg){
                return httpService.post('/beats/generatePaymentToken', {
                    id: pkg._id,
                    origin: location.pathname
                });
            },
            getMinBeatsForDonation(){
                return MyGlobal?.project?.beats?.donation?.minBeats;
            },
            getDonationAmount(){
                return MyGlobal?.project?.beats?.donation?.amount;
            },
            async donate({ userIds, amount, ...args }){
                if(!userIds?.length || !amount) return;
                return httpService.post('/beats/donate', { userIds, amount, ...args });
            },
            async buyFeature(key){
                if(!key) return;
                return httpService.post('/beats/feature/buy', { key });
            },
            getSubscribeToBeatCasePrice(numOfSongs) {
                if(numOfSongs <= 100) {
                    return 10;
                }

                if(numOfSongs > 100 && numOfSongs <= 300) {
                    return 20;
                }

                if(numOfSongs > 300 && numOfSongs <= 1000) {
                    return 30;
                }

                return 50;
            }
        };
    });

}(angular));
