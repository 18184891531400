import angular from 'angular';
import './contributionPopup.less';
import './contribution.service';
import {CONTRIBUTION_SETTINGS, PAYMENT_TYPES} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyContributionPopupCtrl', ($scope, $stateParams, $uibModalInstance, $timeout,
                                           contributionService, helper, beatsService, popupService,
                                           userService, analyticsService, $rootScope) => {

    const { res } = $stateParams;

    $scope.purchaseResult = res;

    $scope.contributionPrice = CONTRIBUTION_SETTINGS.PURCHASE_PRICE;
    $scope.contributionBeatsPrice = CONTRIBUTION_SETTINGS.BEATS_PRICE;

    $scope.contribute = async (type) => {

        try {

            if ($scope.loading) return;

            $scope.loading = true;

            switch(type) {
                case 'payment':
                    popupService.openPaymentPopup({
                        type: PAYMENT_TYPES.CONTRIBUTION
                    });
                    break;

                case 'beats':

                    if (!beatsService.userCanSpend($scope.contributionBeatsPrice)) {
                        return helper.gotoState('beatsInsufficient', {
                            reason: 'contributionWithBeats'
                        });
                    }

                    await analyticsService.trackContributionEventP({
                        eventAction: 'monthlyContribution',
                        eventLabel: 'Beats'
                    });

                    await contributionService.contributeBeats();
                    $scope.purchaseResult = 'succeed';
                    await beatsService.refresh();
                    userService.setUserLastContribution(new Date());
                    $scope.close();
                    $rootScope.showNotification(`Much appreciated ${$rootScope.user.userName}!`);
                    break;

            }
        } catch(e) {
            helper.error(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }

    }

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    $timeout(function () {
        if($scope.purchaseResult === 'succeed'){
            analyticsService.trackContributionEvent({
                eventAction: 'monthlyContribution',
                eventLabel: 'Payment'
            });
            $rootScope.showNotification(`Much appreciated ${$rootScope.user.userName}!`);
            $scope.close();
        }
    }, 600);

    analyticsService.trackActionEvent({
        eventLabel: 'ContributePopupOpened'
    });
});
