
import './pollViewer.less';

import angular from 'angular';

import template from './pollViewer.html';

import '../poll.service';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myPollViewer', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            poll: '=',
            feedDropId: '='
        },
        controller($scope, pollService, helper, $rootScope) {
            $scope.random = helper.getRandomString();
            $scope.answer = {};

            const enhancePoll = ()=> {
                const totalVotes = $scope.poll.options.reduce((acc, item) => {
                    acc += item.votes || 0;
                    return acc;
                }, 0);

                for (const option of $scope.poll.options) {
                    option.ratio = totalVotes ? (option.votes || 0) / totalVotes : 0;

                    if (option.selected) {
                        $scope.answer.value = option._id;
                    }
                }
            };

            $scope.onChange = async (option)=> {
                try {

                    if (!helper.isUserLoggedIn()) {
                        helper.gotoState('join');
                        $scope.answer = {};
                        return;
                    }

                    const { options } = await pollService.vote({
                        pollId: $scope.poll._id,
                        optionId: option._id,
                        feedDropId: $scope.feedDropId
                    });

                    const arr = [];
                    for (let tmpOption of $scope.poll.options) {
                        tmpOption.ratio = 0;
                        tmpOption.selected = tmpOption._id === option._id;

                        const upToDateItem = options.find(item => item._id === tmpOption._id);

                        if (upToDateItem) {
                            arr.push({
                                ...tmpOption,
                                ...upToDateItem
                            });
                        }
                    }

                    $scope.poll.options = arr;

                    enhancePoll();

                } catch(e) {
                    if (e.status === 409) {
                        $rootScope.showNotification(e.message, { digest: true });
                    } else {
                        helper.error(e);
                    }
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            enhancePoll();
        }
    };
});
