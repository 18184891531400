
import './songOpinion.less';

import angular from 'angular';

import template from './songOpinion.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('mySongOpinion', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            song: '=',
            prefix: '='
        },
        controller($scope, songService) {
            $scope.opinion = songService.calculateOpinion($scope.song.score, $scope.song.plays);
        }
    };
});
