import angular from 'angular';
import template from './feedDropItemRoom.html';
import './feedDropItemRoom.less';

const app = angular.module(MyGlobal.page.ngAppName);

if (MyGlobal.project.isMobile) {
    import('./feedDropItemRoom_mobile.less');
}

app.directive('myFeedDropItemRoom', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            data: '='
        },
        controller($scope) {
            $scope.room = $scope.data;
        }
    };
});
