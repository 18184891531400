/**
 * Created by Shlomi on 28/12/2014.
 */

import {PLATFORM_TYPES} from "../../../shared/consts/general";
import sharedHelper from "../../../shared/sharedHelper";

(function (angular) {
    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('RootCtrl', ($scope, $rootScope, helper, storage, pageLoadManager, $state,
                                $window, $http, $timeout, $interval, beatsService,
                                themeService, config, mobileAppService, popupService,
                                userService, userAgentService, songService,
                                $uibModalStack, mySocket, userAlertService, analyticsService,
                                webNotificationService, sdkService, musicMatchService, roomService) => {

        // Save data into rootScope
        $scope.user = $rootScope.user = userService.getUser();
        $rootScope.itsChristmasTime = !MyGlobal.page.innerPlayer && true;

        $scope.noCookiesApproval = MyGlobal.page.noCookiesApproval;
        $scope.cookiesApproved = storage.getItem('cookiesApproved');

        $scope.$on('cookies:approved', () => {
            storage.setForeverItem('cookiesApproved', true);
            $scope.cookiesApproved = true;
        });

        $rootScope.projectVersion = MyGlobal.project.version;
        $rootScope.projectUrl = MyGlobal.project.host;
        $rootScope.helper = helper;
        $rootScope.isRoomPage = !!(MyGlobal.page.room);
        $rootScope.isWikiPage = songService.isWikiURL(location.href);
        $rootScope.isHomePage = MyGlobal.page.isHomePage;
        $rootScope.isStations = MyGlobal.project.platformType === PLATFORM_TYPES.STATIONS;
        $rootScope.origTitle = $('title').text();
        $rootScope.isMobileOrTablet = userAgentService.isMobile() || userAgentService.isTablet();
        $rootScope.isMobileApp = mobileAppService.isMobileApp() || helper.getUrlParamBoolean('isMobileApp');
        $rootScope.ref = helper.getUrlParam('ref');
        $rootScope.isWithinIframe = helper.getUrlParamBoolean('iframe');
        $rootScope.roomKindGlobally = roomService.getRoomKindGlobally();
        //$rootScope.stationsComingSoon = true;

        $rootScope.isIpad = userAgentService.isIpad();
        $rootScope.isWebViewApp = !!MyGlobal.project.isWebViewApp;

        var _hideSomeElementsBeforePageTopReached = 480;

        config.setData(MyGlobal);
        storage.init();

        const user = userService.getUser();

        let data;
        if (user) {
            data = {
                userId: user.id || undefined,
                userName: user.userName,
                beats: user.beats,
                score: user.score,
                hasSubscription: userService.hasSubscription(user)
            }
        }

        analyticsService.init({
            ...(data || {}),
            keepAlive: !!$rootScope.isRoomPage
        });

        // For responsive shits..
        var onWindowResized = function () {
            helper.onWindowResize();
        };
        onWindowResized(); // Run first time
        $(window).resize(onWindowResized);

        // To use for technical issues and send with the user feedback
        $scope.technicalData = $rootScope.technicalData = window.___technicalData = {
            projectVersion: MyGlobal.project.version,
            userAgent: navigator.userAgent,
            sio: MyGlobal.project.sio,
            user: userService.getUser()
        };

        $rootScope.addTechnicalData = function (key, val) {
            window.___technicalData[key] = val;
            $scope.technicalData = $rootScope.technicalData = window.___technicalData;
        };

        $rootScope.addSongToBeatBox = function($event, song, options = {}){

            if(!MyGlobal.user) {
                return helper.gotoState('join', {
                    message: 'Join first, to save songs to your BeatBox'
                });
            }

            $rootScope.$broadcast('beatBox:add:song', song, {
                ...options,
                openerOffset: $($event.currentTarget).offset(),
                openerHeight: $($event.currentTarget).outerHeight()
            });

            $event.stopPropagation();
        };

        $rootScope.isAdmin = userService.isAdmin();
        $rootScope.isUserAdmin = userService.isUserAdmin();
        $rootScope.isImpersonated = userService.isImpersonated();
        $rootScope.isLoggedIn = helper.isUserLoggedIn();

        // Save user data into scope
        $scope.isUserLoggedIn = !!MyGlobal.user;
        $scope.projectName = $rootScope.projectName = MyGlobal.project.name;
        $scope.isMobile = $rootScope.isMobile = MyGlobal.project.isMobile;
        $scope.isTablet = $rootScope.isTablet = MyGlobal.project.isTablet;
        $scope.isProd = $rootScope.isProd = helper.isProd();
        $scope.fbPageIndicator = $rootScope.fbPageIndicator = MyGlobal.page.fbPageIndicator;

        $scope.$on('user:updated', function (evt, data) {
            $scope.user = MyGlobal.user = {
                ...$scope.user,
                ...MyGlobal.user,
                ...data
            };
        });

        $scope.$on('window:scroll', function () {
            if (!helper.isUserLoggedIn() && !$rootScope.isRoomPage) {
                if ($(window).scrollTop() <= _hideSomeElementsBeforePageTopReached) {
                    $('.hideBeforeSomeScrolls').fadeIn();
                }
            }
        });

        $rootScope.$on('userBeats:updated', function ($evt, balance) {
            $rootScope.userTotalBeats = balance;
        });

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (!MyGlobal.temp.isHelperTransition) {
                helper.gotoState(toState, toParams);
                event.preventDefault();

            }
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            MyGlobal.temp.isHelperTransition = false;
        });

        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams) {
            MyGlobal.temp.isHelperTransition = false;
        });

        $scope.$on(pageLoadManager.events.GOTO_STATE, function (evt, data) {
            helper.gotoState(data.state);
        });

        // Fix to not show SocketIO disconnection message when user refresh/redirect from page
        $window.onbeforeunload = function (event) {
            $scope.disconnectedInPurpose = true;
            $rootScope.$broadcast('window:onbeforeunload', event);
        };

        let prevScrollX = 0;
        $window.onscroll = function (evt) {
            $rootScope.$broadcast('window:scroll', evt);

            const docHeight = document.body.scrollHeight;
            const viewPortHeight = window.innerHeight ;
            const scrollX = $(window).scrollTop();

            if ((prevScrollX <= scrollX) && (viewPortHeight + scrollX) >= docHeight - 250) {
                helper.debug('Arrived to page bottom..');
                prevScrollX = scrollX;
                $rootScope.$broadcast('window:scroll:bottom', evt);
            }
        };

        $(window).bind('storage', (...args) => {
            $rootScope.$broadcast('window:storage', ...args);
        });

        $(document).keydown(function (e) {
            if ((e.keyCode === 10 || e.keyCode === 13) && e.ctrlKey) {
                $rootScope.$broadcast('document:ctrl_enter', e);
            }

            $rootScope.$broadcast('document:keydown', e);
        });

        $(document).keypress(function (e) {
            if (e.which === 13) {
                $rootScope.$broadcast('document:keypress:enter');
            }

            $rootScope.$broadcast('document:keypress', e);
        });

        $(document).keyup(function (e) {
            if (e.which === 27) {
                $rootScope.$broadcast('document:keyup:esc', e);
            }

            $rootScope.$broadcast('document:keyup', e);
        });

        $(document).click(function (e) {
            $rootScope.$broadcast('document:click', e);
        });

        $(document).mouseenter(function (e) {
            $rootScope.$broadcast('document:mouseenter', e);
        });

        $(window).on('message', function (e) {
            $rootScope.$broadcast('window:message', e);
        });

        $(window).on('focus', function (e) {
            $rootScope.$broadcast('window:focus', e);
        });

        $(window).on('blur', function (e) {
            $rootScope.$broadcast('window:blur', e);
        });

        // Check what to do
        pageLoadManager.performCurrAction($rootScope);

        $scope.onAuthError = function (err) {
            helper.debug('User is not authorized');

            return helper.gotoState('login', {
                message: 'Login first'
            });
        };

        $rootScope.onRoomClicked = ($event, room) => {
            if (helper.isAnotherPlatform(room)) {
                $event.preventDefault();

                const type = $rootScope.isStations ? PLATFORM_TYPES.BEATSENSE : PLATFORM_TYPES.STATIONS;

                helper.gotoState( 'redirectToPlatform', {
                    type,
                    room
                });
            }
        };

        /**
         * Christmas show feature - hidden till next year..
         * @param src
         */

        let _christmasModeInitialized = false;

        $rootScope.onChristmasModeClicked = function ($event, resume) {
            if ($event) $event.preventDefault();

            $scope.christmasModeEnabled = !$scope.christmasModeEnabled;

            storage.setItem('christmasModeEnabled', $scope.christmasModeEnabled);

            if (!resume) {
                helper.trackGaUIEvent('ChristmasMode' + ($scope.christmasModeEnabled ? 'ON' : 'OFF'));
            }

            if (_christmasModeInitialized) return;
            _christmasModeInitialized = true;

            helper.debug('Init christmas mode..');

            //canvas init 
            var canvas = document.getElementById("christmasModeCanvas");
            var ctx = canvas.getContext("2d");

            //canvas dimensions
            var W = window.innerWidth;
            var H = window.innerHeight;
            canvas.width = W;
            canvas.height = H;

            //snowflake particles
            var mp = 25; //max particles
            var particles = [];
            for (var i = 0; i < mp; i++) {
                particles.push({
                    x: Math.random() * W, //x-coordinate
                    y: Math.random() * H, //y-coordinate
                    r: Math.random() * 4 + 1, //radius
                    d: Math.random() * mp //density
                })
            }

            //Lets draw the flakes
            function draw() {
                ctx.clearRect(0, 0, W, H);

                ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
                ctx.beginPath();
                for (var i = 0; i < mp; i++) {
                    var p = particles[i];
                    ctx.moveTo(p.x, p.y);
                    ctx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
                }
                ctx.fill();
                update();
            }

            //Function to move the snowflakes
            //angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
            var angle = 0;

            function update() {
                angle += 0.01;
                for (var i = 0; i < mp; i++) {
                    var p = particles[i];
                    //Updating X and Y coordinates
                    //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
                    //Every particle has its own density which can be used to make the downward movement different for each flake
                    //Lets make it more random by adding in the radius
                    p.y += Math.cos(angle + p.d) + 1 + p.r / 2;
                    p.x += Math.sin(angle) * 2;

                    //Sending flakes back from the top when it exits
                    //Lets make it a bit more organic and let flakes enter from the left and right also.
                    if (p.x > W + 5 || p.x < -5 || p.y > H) {
                        if (i % 3 > 0) //66.67% of the flakes
                        {
                            particles[i] = {x: Math.random() * W, y: -10, r: p.r, d: p.d};
                        } else {
                            //If the flake is exitting from the right
                            if (Math.sin(angle) > 0) {
                                //Enter from the left
                                particles[i] = {x: -5, y: Math.random() * H, r: p.r, d: p.d};
                            } else {
                                //Enter from the right
                                particles[i] = {x: W + 5, y: Math.random() * H, r: p.r, d: p.d};
                            }
                        }
                    }
                }
            }

            //animation loop
            setInterval(draw, 33);
        };
        //END OF CHRISTMAS MODE

        // TODO: looks like a shitty code, check it out sometime..
        //to prevent SF modal close when mouse up outside of it
        $rootScope.$watch(function () {
            return document.querySelectorAll('.modal').length;
        }, function (val) {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = document.querySelectorAll('.modal')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var modal = _step.value;

                    if ($uibModalStack.getTop()?.value?.backdrop !== 'static') {
                        // Testing if the modal is supposed to be static before attaching the event
                        modal.addEventListener('mousedown', function (e) {
                            if (e.which === 1) {
                                const top = $uibModalStack.getTop();
                                if(top){
                                    top.key.dismiss();
                                }
                            }
                        });
                        modal.querySelector('.modal-content')?.addEventListener('mousedown', function (e) {
                            e.stopPropagation();
                        });
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            }

            if (_didIteratorError) {
                throw _iteratorError;
            }

            if (val > 0) {
                $uibModalStack.getTop().value.backdrop = 'static';
            }
        });

        $rootScope.trackGaUIEvent = function (name, data) {
            helper.trackGaUIEvent(name, data);
        };

        $rootScope.openRanksModal = (score) =>{
            helper.gotoState('ranks', { score });
        };

        $rootScope.toggleDebug = () => {
            sdkService.toggleDebug();
        };

        $timeout(() => {

            if (!$rootScope.isRoomPage) {
                $('.hideBeforeSomeScrolls').hide();
            }

           if($rootScope.itsChristmasTime && storage.getItem('christmasModeEnabled') !== false){
              $scope.onChristmasModeClicked(null, true);
           }
        });

        if (storage.getItemAndRemove('invalidSocialEmail')) {
            helper.gotoState('update-invalid-email');
        }

        // reload the user data - dont remove this part!
        userService.init().then(function () {
            MyGlobal.user = $scope.user = $rootScope.user = userService.getUser();
        }).catch(function (err) {
            helper.debug('Failed load current user data');
            helper.error(err);
        });

        // If the user redirected from social login process - send GA event for success
        var socialLoginCookie = helper.getCookie('socialRegistration');
        if (socialLoginCookie) {
            helper.trackGaUIEvent('UserSuccessfullyJoined', {method: socialLoginCookie});
        }

        // Familiar guest case
        if (!helper.isUserLoggedIn()) {

            var firstVisit = storage.getItem('firstVisit');

            if (!firstVisit) {
                storage.setForeverItem('firstVisit', Date.now());
            } else if (!storage.getItem('isSawPardon') &&
                storage.getItem('isSawTutorialModal') &&
                new Date(parseInt(firstVisit)).getDate() !== new Date(Date.now()).getDate()) {
                helper.gotoState('join', {state: 'pardon'});
                storage.setForeverItem('isSawPardon', true);
            }

            $rootScope.$broadcast('page:ready');
        } else {
            (async () => {

                // In room page case the room's socket also connected to the user's channel
                if (!$rootScope.isRoomPage) {

                    $scope.$on('socket:reconnected', async () => {
                        await mySocket.join();
                    });

                    await mySocket.init();
                    await mySocket.join();
                    mySocket.bindListeners();
                    userAlertService.init();
                    beatsService.init();

                    analyticsService.trackPage({
                        page: analyticsService.getRoomUrl()
                    });

                    musicMatchService.loadNewMatches().catch(e => {
                        helper.error(e);
                    });
                }

                // Check System Alerts first time and every 15 minutes..
                await userService.checkSystemAlerts();

                $interval(async ()=> {
                    try {
                        await userService.checkSystemAlerts();
                    } catch(e) {
                        helper.error(e);
                    }
                }, helper.minutesToMs(15));

                $scope.$on('system:message', async ($evt, data) => {
                    try {
                        if(!data || !data.message) return;

                        const { message, adminMessage, action } = data;

                        $rootScope.showNotification(message, {
                            systemMessage: true,
                            adminMessage: adminMessage
                        });

                        if(action) {
                            switch(action) {
                                case 'refreshBeats':
                                    await beatsService.refresh();
                                    break;
                            }
                        }
                    } catch (e) {
                        helper.error(e);
                    }
                });

                webNotificationService.init().catch(e => {
                    helper.error(e);
                });

                if (!userService.tosApproved() && location.pathname !== '/tos') {
                    popupService.openApproveTosPopup();
                }

                $rootScope.$broadcast('page:ready');

            })();
        }

        if ($rootScope.isMobileApp) {
            (async() => {
                try {
                    mobileAppService.init();
                    await mobileAppService.sendAppReady(helper.isUserLoggedIn() ? { userId: $rootScope.user.id } : {} );
                } catch(e) {
                    helper.error(e);
                }
            })();
        }

        sdkService.init();

        $timeout(() => {
            $('.hideUntilPageLoad').removeClass('hideUntilPageLoad');
        });

        $timeout(() => {
            if ($rootScope.isHomePage) {
                try {

                    const cookie = helper.getCookie('redirectReason', { jsonDecode: true });

                    let message;
                    if (cookie) {
                        switch (cookie.reason) {
                            case 'pd':

                                if (!MyGlobal.user && cookie.room) {
                                    helper.gotoState('join', {
                                        message: 'Please login to join <div>' + cookie.room.name + '</div>',
                                        postLoginUrl: cookie.postUrl ? cookie.postUrl : undefined
                                    });
                                } else {
                                    message = 'You have to be invited to join that room';
                                }
                                break;
                            case 'revoked':
                                message = `Your access to the BeatRoom has been ${roomService.getRoomKindGlobally()}`;
                                break;
                            case 'banned':
                                message = `You are not welcome in this ${sharedHelper.getRoomKind(cookie.room)}.`;
                                break;
                            case 'unsubscribed':
                                message = `You've been unsubscribed successfully`;
                                break;
                            case 'roomDeletedSuccessfully':
                                message = `Room deleted successfully`;
                                break;
                            case 'roomDeleted':
                                message = `Room has been deleted`;
                                break;
                            default:
                                return cookie.reason ? helper.gotoState(cookie.reason) : undefined;

                        }

                        if (message) {
                            if (!cookie.type || cookie.type === 'error') {
                                $rootScope.showError(message);
                            } else {
                                $rootScope.showNotification(message);
                            }
                        }
                    }

                } catch (e) {
                    helper.error(e);
                }
            }
        }, 1000);
    });
}(angular));


