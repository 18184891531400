/**
 * Created by Shlomi on 06/02/2016.
 */

(function(angular) {
    var app = angular.module(MyGlobal.page.ngAppName);

    app.config(function ($stateProvider) {

        // Allow those modals just for logged in users
        if (MyGlobal.user){
            $stateProvider.state('myBeats', {
                url: '/my-beats',
                onEnter: function ($stateParams, $state, $uibModal, helper) {

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/beats/myBeats.html',
                        controller: 'MyBeatCtrl',
                        windowClass: 'my-beats-modal'
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('beatsExplanation', {
                url: '/beats/explanation',
                onEnter: function ($stateParams, $state, $uibModal, helper) {

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/beats/explanationModal.html',
                        controller: 'BeatsExplanationModalCtrl',
                        windowClass: 'beatsExplanation-modal',
                        resolve: {
                            beatsServiceReady: function(beatsService){
                                return beatsService.promise;
                            }
                        }
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('beatsPurchase', {
                url: '/beats/purchase',
                onEnter: function ($stateParams, $state, $uibModal, helper) {

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/beats/purchaseModal.html',
                        controller: 'BeatsPurchaseModalCtrl',
                        windowClass: 'beatsPurchase-modal',
                        resolve: {
                            beatsServiceReady: function(beatsService){
                                return beatsService.promise;
                            }
                        }
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('beatsPostPurchaseSuccess', {
                url: '/beats/purchase/:package/success/:transactionId/:amount',
                onEnter: function ($stateParams, $state, $uibModal, helper) {

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/beats/postPurchaseSuccessModal.html',
                        controller: 'BeatsPostPurchaseSuccessModalCtrl',
                        windowClass: 'beatsPostPurchaseSuccess-modal',
                        resolve: {
                            beatsServiceReady(beatsService){
                                return beatsService.promise;
                            },
                            purchaseInfo(){
                                return {
                                    package: $stateParams.package,
                                    amount: $stateParams.amount
                                }
                            }
                        }
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('beatsInsufficient', {
                url: '/beats/insufficient',
                params: { reason: null },
                onEnter: function ($stateParams, $state, $uibModal, helper, $uibModalStack) {

                    $uibModalStack.dismissAll();

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/beats/insufficientModal.html',
                        controller: 'BeatsInsufficientModalCtrl',
                        windowClass: 'beatsInsufficient-modal',
                        resolve: {
                            beatsServiceReady: function(beatsService){
                                return beatsService.promise;
                            }
                        }
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: false});
                    });
                }
            }).state('beatsRewardVisitDaysInARow', {
                url: '/beats/reward/visitDaysInARow/a/:amount/d/:daysToBonus',
                onEnter: function ($stateParams, $state, $uibModal, helper, $uibModalStack) {

                    $uibModalStack.dismissAll();

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/beats/rewards/visitDaysInARow.html',
                        controller: 'BeatsRewardVisitDaysInARowModalCtrl',
                        windowClass: 'beatRewardVisitDaysInARow-modal',
                        resolve: {
                            reward: {
                                amount: $stateParams.amount,
                                daysToBonus: $stateParams.daysToBonus
                            }
                        }
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            });
        }

        $stateProvider.state('beatsPostPurchaseFailure', {
            url: '/beats/purchase/:package/failure/:reason',
            onEnter: function ($stateParams, $state, $uibModal, helper) {

                $uibModal.open({
                    templateUrl: '/static/templates/partials/beats/postPurchaseFailureModal.html',
                    controller: 'BeatsPostPurchaseFailureModalCtrl',
                    windowClass: 'beatsPostPurchaseFailure-modal',
                    resolve: {
                        beatsServiceReady: function(beatsService){
                            return beatsService.promise;
                        },
                        purchaseInfo: function(){
                            return {
                                package: $stateParams.package,
                                reason: $stateParams.reason
                            }
                        }
                    }
                }).result.then(function (result) {
                    helper.navigateOnModalClose(result);
                }, function () {
                    helper.navigateOnModalClose({ignoreStack: true});
                });
            }
        });
    });
})(angular);

