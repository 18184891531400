import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);


app.service('mobileLayerService', (helper, $rootScope) => {

    return {
        subViews: {
            songSearch: 'songSearch'
        },
        open(subView) {
            $rootScope.mobileLayer = {
                show: true,
                subView
            };
        },
        close() {
            delete $rootScope.mobileLayer;
        },
        isOpen(subView) {
            return $rootScope.mobileLayer?.subView === subView;
        }
    };

});
