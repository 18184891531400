import sharedHelper from '../../../shared/sharedHelper';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('songService', ($http, $rootScope, helper, httpService, analyticsService) => {

    let moods;
    const recently = helper.hoursToMinutes(3); // minutes

    return {
        async getByYouTubeId({ youtubeId, roomId }){

            if (!youtubeId) {
                throw new Error('Invalid params');
            }

            let url = `/song/youtube/${youtubeId}`;

            if (roomId) {
                url += `/${roomId}`;
            }

            return await httpService.get(url);
        },
        fromYoutubeSong(ytSong) {
            if (!ytSong?.id || !ytSong?.name ) throw new Error('YouTube Song cannot be converted to our song, invalid params');
            return { youtubeId: ytSong.id, name: ytSong.name };
        },
        toYouTubeSong(song) {
            if (!song?.youtubeId || song?.duration < 0) {
                throw new Error('Song cannot be converted to YouTube song, invalid params');
            }
            return { id: song.youtubeId, duration: song.duration, name: song.name, sqThumbnail: song.ytSqThumbnail };
        },
        getRecently() {
            return recently;
        },
        songPlayedRecently(song, prop = '_id') {
            if($rootScope.history?.length){
                const item = $rootScope.history.find(item => item.song?.[prop] === song[prop]);

                if(item){
                    const now = Date.now();
                    const startedAt = (new Date(item.startedAt)).getTime();
                    return !isNaN(startedAt) && helper.msToMinutes(now - startedAt) <= this.getRecently()
                }
            }
        },
        async search(searchType, index, params) {

            return await httpService.post(`/song/search/${searchType}?i=${index}`, params).then((res) => {
                return {
                    res,
                    index
                };
            })
        },
        async aiSearch({ question }) {
            return await httpService.post(`/song/aiSearch`, { question });
        },
        calculateOpinion ( score, plays ){
            const opinion = {};
            if( score < 0 ){
                if( score >= -2 ){
                    opinion.level = 'Ambivalent';
                    opinion.class = 'doNotPlay';
                }else if ( score <= -3 && score >= -5 ){
                    opinion.level = 'Might be a receipt for disaster';
                    opinion.class = 'doNotPlay';
                }else{
                    opinion.level = 'Do not play this one!';
                    opinion.class = 'doNotPlay';
                }
            }else if( score === 0 ){
                if( plays <= 100 ){
                    opinion.level = 'Neutral';
                    opinion.class = 'neutral';
                }else{
                    opinion.level = 'Meh... you better re-think adding this one';
                    opinion.class = 'meh';
                }
            }else{
                if( plays <= 100 ){
                    if( score < 3 ){
                        opinion.level = 'Nothing exciting about this one';
                        opinion.class = 'nothingExiting';
                    }else if( score < 10 ) {
                        opinion.level = 'A safe bet';
                        opinion.class = 'safeBet';
                    }else if( score < 30 ) {
                        opinion.level = 'Pretty good, actually';
                        opinion.class = 'pettyGood';
                    }else{
                        opinion.level = 'Awesome!!!';
                        opinion.class = 'fuckingAwesome';
                    }
                }else{
                    if( score/plays < 0.03 ){
                        opinion.level = 'Nothing exciting about this one';
                        opinion.class = 'nothingExiting';
                    }else if( score/plays < 0.1 ){
                        opinion.level = 'A safe bet';
                        opinion.class = 'safeBet';
                    }
                    else if( score/plays >= 0.1 && score/plays < 0.6 ) {
                        opinion.level = 'Pretty good, actually';
                        opinion.class = 'pettyGood';
                    }else {
                        opinion.level = 'Fucking awesome!!!';
                        opinion.class = 'fuckingAwesome';
                    }
                }
            }
            return opinion;
        },
        getSongName(song) {
            return sharedHelper.getSongName(song);
        },
        isWikiURL(text) {
            return /beatsense\.com(.+)?\/wiki\/.+?youtube=.+/.test(text);
        },
        async getAllMoods() {
            if (!moods) {
                moods = httpService.get('/song/moods');
            }

            return moods;
        }
    };
});
