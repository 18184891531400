import angular from 'angular';
import template from './wikiOpener.html';
import './wikiOpener.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myWikiOpener', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            song: '=',
            target: '=',
            tooltip: '=?',
            showOnFireIndicator: '='
        },
        controller($scope) {

            $scope.isOnFire = ()=> {
                //return $scope.showOnFireIndicator && $scope?.song?.plays >= 10 && $scope?.song?.score/$scope?.song?.plays >= 0.6;
            };

            $scope.hasCoolInfo = ()=> {
                let isCool = $scope?.song?.metaData?.info;
                if (isCool) $scope.tooltip = 'We got some cool info about this tune! click to check it out';
                return isCool;
            };
        }
    };
});
