import angular from 'angular';
import {EMPTY_USER_IMAGE, NOTIFICATION_TYPES } from '../../../../shared/consts';
const app = angular.module(MyGlobal.page.ngAppName);

app.service('userAlertService', (helper, httpService, $rootScope, config, webNotificationService,
                                 buddyService, roomService, userService, analyticsService,
                                 beatsService) => {

    let localAlerts = [];
    let alerts = [];

    const clearLocalAlert = (alert) => {
        helper.removeItemFromArray(localAlerts, (item) => {
            return item._id === alert._id;
        });
    };

    const createActions = (actions) => {
        for(const action of actions) {
            const { do: origDo } = action || {};

            action.do = async (alert, $event)=> {
                try {

                    if ($event) {
                        $event.preventDefault();
                    }

                    // The deletion has to be before the action, to avoid case of page redirect that wont complete the deletion

                    if (action.deleteOnCTA && !action.deleteAfterCTA) {
                        await publicObj.delete(alert);
                    }

                    if (typeof origDo === 'function') {
                        const promise = origDo(alert);

                        if (promise) {
                            await promise;
                        }
                    }

                    if (action.deleteAfterCTA) {
                        await publicObj.delete(alert);
                    }

                    await publicObj.trackAlert(alert);

                    if ($rootScope.isMobile) {
                        $rootScope.$broadcast('userAlerts:close');
                    }

                    const { url, target } = action?.link || {};

                    if (url) {
                        helper.openWindow(url, target || '_self');
                    }

                } catch (e) {
                    helper.error(e);
                }
            };
        }

        return actions;
    };

    const publicObj = {
        async load({ page, itemsPerPage, append }) {
            const { models, pagination } = await httpService.post(`/userAlert/list/${page}`, {
                itemsPerPage
            }) || [];

            if (!append) {
                alerts = models;
            } else {
                alerts = (alerts || []).concat(models);
            }

            return { alerts, pagination };
        },
        init() {
            $rootScope.$on('userAlert:received', (evt, alert) => {
                // Prevent duplicates
                if(localAlerts.find(item => item._id === alert._id)) return;

                alert.ttl = parseInt(helper.getUrlParam('userAlertLocalInterval')) || 20000;

                localAlerts.unshift(alert);
                $rootScope.$broadcast('userAlert:added');

                if (webNotificationService.isAllowed(webNotificationService.sections.userAlert)) {
                    webNotificationService.systemNotification(alert.text, webNotificationService.sections.userAlert).catch(e => helper.error(e));
                }
            });
        },
        getLocalAlerts() {
            return localAlerts;
        },
        getAll() {
            return alerts;
        },
        async delete(alert, options = {}) {

            if (!options.local) {
                await httpService.delete(`/userAlert/${alert._id}`);
                helper.removeItemFromArray(alerts, (item) => {
                    return item._id === alert._id;
                });
            }

            clearLocalAlert(alert);

            $rootScope.$broadcast('userAlert:deleted', alert, options);
        },
        async deleteAll() {

            await httpService.delete(`/userAlert/all`);
            alerts = [];
            localAlerts = [];

            $rootScope.$broadcast('userAlerts:reset');
        },
        async getInfo() {
            return httpService.get(`/userAlert/info`);
        },
        async markAllAsRead() {
            await httpService.post(`/userAlert/markAsRead`);
            //$rootScope.$broadcast('userAlerts:reset');
        },
        async sendNewOnlineBuddiesAlert(buddies) {

            if (!buddies || !buddies.length) return;

            buddies = buddies.filter(item => {
                return item.room?.id !== roomService.getRoomId();
            });

            // Maybe some buddies has filtered..
            if (!buddies || !buddies.length) return;

            return httpService.post(`/userAlert/send/onlineBuddy`, { buddies });
        },
        async trackAlert(alert) {
            return await analyticsService.trackUserAlertEventP({
                eventAction: alert.type
            });
        },
        // Keep it last to easy find later
        getAlertSettings(alert) {

            if(!alert) return;

            let iconClass;
            let image;
            let actions;

            switch (alert.type) {
                case NOTIFICATION_TYPES?.BUDDIED?.key:
                    iconClass = 'fas fa-user-alt';
                    actions = !alert?.data?.alreadyBuddy && createActions([{
                        text: 'Add them back',
                        deleteOnCTA: true,
                        async do(alert) {
                            if (alert?.data?.user?._id) {
                                const buddy = await buddyService.create(alert.data.user._id);
                                $rootScope.showNotification(`${buddy.userName} has been added`);
                            }
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.ONLINE_BUDDY?.key:
                    iconClass = 'fas fa-user';
                    actions = createActions([{
                        text: 'Invite here',
                        deleteAfterCTA: true,
                        active: roomService.isRoomPage(),
                        async do(alert) {
                            if (alert?.data?.userName) {
                                await roomService.inviteUsers({
                                    _id: roomService.getRoomId()
                                }, [alert.data.userName]);
                                $rootScope.showNotification(`An invitation was sent to ${alert.data.userName}`);
                            }
                        }
                    }, {
                        text: 'Join there',
                        deleteAfterCTA: true,
                        active: !!alert?.data?.room,
                        link: {
                            url: !!alert?.data?.room ? helper.getRoomUrl(alert.data.room) : undefined
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.GOT_DONATION?.key:
                    iconClass = 'fas fa-bolt';
                    beatsService.refresh().catch(e => {
                        helper.error(e);
                    })
                    break;
                case NOTIFICATION_TYPES?.ROOM_INVITATION?.key:
                    iconClass = 'fas fa-glass-cheers';
                    actions = alert?.data?.room?._id !== roomService.getRoomId() && createActions([{
                        text: 'Go there!',
                        link: {
                            url: helper.getRoomUrl(alert.data.room)
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.ROOM_ADMIN_INVITATION?.key:

                    const sameRoom = alert?.data?.room?._id === roomService.getRoomId();

                    iconClass = 'fas fa-user-shield';
                    actions = createActions([{
                        text: sameRoom ? 'Refresh' : 'Go there!',
                        link: !sameRoom && {
                            url: helper.getRoomUrl(alert.data.room)
                        },
                        do() {
                            helper.reloadPage();
                        }
                    }]);

                    if (alert?.data?.room?._id && roomService.getRoomId() === alert.data.room._id) {
                        $rootScope.$broadcast('user:promotedToRoomAdmin', {
                            room: alert.data.room
                        });
                    }

                    break;
                case NOTIFICATION_TYPES?.USER_LEVELED_UP?.key:
                    iconClass = 'fas fa-trophy';
                    actions = createActions([{
                        text: 'My Profile',
                        do(alert) {
                            userService.openProfile();
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.NEW_MUSIC_MATCH?.key:
                    iconClass = 'fas fa-heart';
                    actions = createActions([{
                        text: 'Show me',
                        do() {
                            helper.gotoState('musicMatches');
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.SYSTEM_ALERT?.key:
                    iconClass = 'fas fa-bullhorn';
                    actions = alert?.data?.url && createActions([{
                        text: alert?.data?.label || 'Show me',
                        do() {
                            if (alert.data.url.startsWith('#')) {
                                helper.redirect(alert.data.url);
                            } else {
                                helper.openWindow(alert.data.url);
                            }
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.USER_FOLLOWED?.key:
                    iconClass = 'fas fa-glass-cheers';
                    actions = createActions([{
                        text: `Who is it?`,
                        do() {
                            if (!alert?.data?.user?.userName) return;
                            helper.gotoState('user-modal', { userName: alert.data.user.userName });
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.TRACKED_USER_IS_ONLINE?.key:
                    iconClass = 'far fa-smile';
                    break;
                case NOTIFICATION_TYPES?.USER_START_JAMMING?.key:
                    iconClass = 'fas fa-compact-disc';
                    actions = alert?.data?.room?._id !== roomService.getRoomId() && createActions([{
                        text: `Jam with ${alert?.data?.user.userName}!`,
                        link: {
                            url: helper.getRoomUrl(alert.data.room)
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.ADDED_YOUR_SONG_TO_BEATBOX?.key:
                    iconClass = 'fas fa-heartbeat';
                    actions = createActions([{
                        text: `Song Wiki`,
                        link: {
                            url: helper.getSongWikiUrl(alert.data.song),
                            target: '_blank'
                        },
                        do() {
                            if (!alert?.data?.song?._id) return;
                            helper.redirectToWiki(alert.data.song, { newPage: true });
                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.FEED_DROP_LIKED?.key:
                case NOTIFICATION_TYPES?.FEED_DROP_REDROPED?.key:
                    iconClass = 'fas fa-bell';
                    actions = createActions([{
                        text: `View Drop`,
                        do() {
                            if (!alert?.data?.feedDrop?._id) return;

                            helper.gotoState('feedDrop', {
                                id: alert.data.feedDrop._id
                            });

                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.FEED_DROP_REPLIED?.key:
                    iconClass = 'fas fa-comment';
                    actions = createActions([{
                        text: `View Drop`,
                        do() {
                            if (!alert?.data?.feedDrop?._id) return;

                            helper.gotoState('feedDrop', {
                                id: alert.data.feedDrop._id
                            });

                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.FEED_DROP_USER_MENTIONED?.key:
                    iconClass = 'fas fa-at';
                    actions = createActions([{
                        text: `View Drop`,
                        do() {
                            if (!alert?.data?.feedDrop?._id) return;

                            helper.gotoState('feedDrop', {
                                id: alert.data.feedDrop._id
                            });

                        }
                    }]);
                    break;
                case NOTIFICATION_TYPES?.USER_START_BROADCASTING?.key: {
                    let url = helper.getRoomUrl(alert.data.room);

                    if (alert.data.asAdmin) {
                        url = `${url}?ghost`;
                    }

                    iconClass = 'fas fa-video';
                    actions = alert?.data?.room?._id !== roomService.getRoomId() && createActions([{
                        text: `Watch`,
                        link: { url }
                    }]);
                    break;
                }
                case NOTIFICATION_TYPES?.ROOM_ACCESS_REQUEST?.key: {
                    iconClass = 'fas fa-key';
                    actions = createActions([{
                        text: `Approve & Receive ${alert.data.room.entranceFee} Beats`,
                        deleteAfterCTA: true,
                        async do() {
                            try {
                                await roomService.approveAccessRequest({
                                    id: alert.data._id
                                });

                                $rootScope.showNotification(`Invitations sent. ${alert.data.room.entranceFee} beats earned!`);
                                await beatsService.refresh();

                                analyticsService.trackUIEvent({
                                    eventAction: 'userRequestToJoinARoomApproved',
                                    eventLabel: alert.data.room.entranceFee
                                });

                            } catch (e) {
                                $rootScope.showError(e.message);

                                if (e.message !== 'Request expired.') {
                                    throw e;
                                }
                            }
                        }
                    }]);
                    break;
                }
                case NOTIFICATION_TYPES?.FEED_DROP_POLL_VOTED?.key: {
                        iconClass = 'fas fa-poll';
                        actions = createActions([{
                            text: `View`,
                            do() {
                                if (!alert?.data?.feedDrop?._id) return;
                                helper.gotoState('feedDrop', { id: alert.data.feedDrop._id });
                            }
                        }]);
                        break;
                }
                case NOTIFICATION_TYPES?.ROOM_OPENED_BY_USER?.key: {
                    iconClass = 'fa fa-plus';
                    actions = createActions([{
                        text: `Jam together!`,
                        do() {
                            const { room } = alert.data || {};
                            roomService.gotoRightPlatform(room);
                        }
                    }]);
                    break;
                }
                case NOTIFICATION_TYPES?.ROOM_JAMMING?.key: {
                    iconClass = 'fas fa-music';
                    actions = createActions([{
                        text: `Jam!`,
                        link: {
                            url: helper.getRoomUrl(alert.data.room)
                        }
                    }]);
                    break;
                }
                case NOTIFICATION_TYPES?.PROFILE_WATCH?.key: {
                    if (alert.data?.user) {
                        image = alert.data.user.image || EMPTY_USER_IMAGE;
                    } else {
                        iconClass = 'fas fa-mask';
                    }
                    actions = createActions([{
                        text: userService.hasSubscription() ? 'Who else?' : 'Who is it?',
                        link: {
                            url: `#profileWatches`
                        }
                    }]);
                    break;
                }
                case NOTIFICATION_TYPES?.FEED_DROP_POSTED?.key: {
                    iconClass = 'fas fa-microphone-alt';
                    actions = createActions([{
                        text: `View`,
                        do() {
                            if (!alert?.data?.feedDrop?._id) return;
                            helper.gotoState('feedDrop', { id: alert.data.feedDrop._id });
                        }
                    }]);
                    break;
                }
                case NOTIFICATION_TYPES?.ROOM_FAVORITED?.key: {
                    iconClass = 'fas fa-headphones';
                    actions = createActions([{
                        text: 'Go there',
                        do() {

                            if (roomService.getRoomId() !== alert.data?.room?._id) {
                                const { room } = alert.data || {};
                                roomService.gotoRightPlatform(room);
                            } else {
                                $rootScope.showNotification(`You're already here ;)`);
                            }
                        }
                    }]);
                    break;
                }
                case NOTIFICATION_TYPES?.AMBASSADOR_REFERRAL_JOINED?.key: {
                    iconClass = 'fab fa-black-tie';
                    actions = createActions([{
                        text: 'Ambassador status',
                        link: {
                            url: '#ambassador'
                        }
                    }, {
                        text: 'Who?',
                        do() {
                            if (!alert?.data?.user?.userName) return;
                            helper.gotoState('user-modal', { userName: alert.data.user.userName });
                        }
                    }]);
                    break;
                }
                default:
                    iconClass = 'fas fa-bell';
            }

            return { iconClass, image, actions };
        },
        resetLocally() {
            $rootScope.$broadcast('userAlerts:reset');
            $rootScope.$broadcast('userAlerts:close');
        }
    };

    return publicObj;
});
