/**
 * Created by Shlomi on 20/09/2014.
 */

import {EMPTY_USER_IMAGE, BEATSENSE_USER_IMAGE} from '../../../shared/consts';
import sharedHelper from '../../../shared/sharedHelper';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.filter('secondsToTime', function(helper) {
        return function(seconds, donotConvertLongerThanHour, forceHours) {
            return helper.timeToString(helper.secondsToTime(seconds), !donotConvertLongerThanHour, forceHours);
        };
    });

    app.filter('safeHtml', function($sce) {
        return function(val) {
            return $sce.trustAsHtml(val);
        };
    });

    app.filter('trustUrl', ['$sce', function ($sce) {
        return function (url) {
            return $sce.trustAsResourceUrl(url);
        };
    }]);

    app.filter('reverse', function() {
        return (arr) => {
            if(arr) {
                return arr.slice().reverse();
            }
        };
    });

    app.filter('userImage', (helper) => {
        return (user, fallbackToProject) => {
            if(user){
                if(user.image && !helper.isLink(user.image) && !user.image.includes('/static/images')){
                    return '/static/uploads/userImages/'+user.image;
                }else if(user.image){
                    return helper.stripLinkProtocol(user.image);
                }else if(typeof user === 'string'){
                    return user;
                }
            } else if (fallbackToProject) {
                return BEATSENSE_USER_IMAGE;
            }
            return EMPTY_USER_IMAGE;
        };
    });

    app.filter('userName', (helper) => {
        return (user) => {
            return helper.getUserName(user);
        };
    });

    app.filter('linkUserName', (helper) => {
        return (text) => {
            return helper.replaceUserNamesInText(text);
        };
    });

    app.filter('moodImage', () => {
        return function(mood) {
            if(mood){
                return mood.icon ? '/static/images/moods/'+mood.icon+'.png' : '/static/images/moods/okay.png';
            }
        };
    });

    app.filter('roomUniqueUrl', function(helper) {
        return function(room, debug) {
            if(room){
                if (debug) debugger;
                return helper.getRoomUrl(room);
            }
        };
    });

    app.filter('linkableContent', function(helper) {
        return function(content, target) {
            return helper.replaceLinksInText(content, { target });
        };
    });

    app.filter('emojiableContent', function(helper) {
        return function(content) {
            return helper.replaceEmoji(content);
        };
    });

    app.filter('replaceNewLines', function(helper) {
        return function(content) {
            return helper.replaceNewLines(content)
        };
    });

    app.filter('wikiUrl', (helper, songService) => {
        return (song, convertFromYoutube) => {

            if (convertFromYoutube) {
                song = songService.fromYoutubeSong(song);
            }

            return helper.getSongWikiUrl(song);
        };
    });

    app.filter('stripLinkProtocol', function(helper) {
        return function(content) {
            return helper.stripLinkProtocol(content);
        };
    });

    app.filter('joinArray', function() {
        return function(arr, objField) {
            if(!arr || !arr.length) return;

            if(objField){
                arr = arr.map(function (elem) {
                    return elem && typeof elem === 'object' ? elem[objField] : elem;
                });
            }

            return arr.join(', ');

        };
    });

    app.filter('maxLength', function(helper) {
        return function(str, maxLength, suffix, donotCutWords) {
            return helper.cutStrToMaxLength(str, maxLength, {
                suffix: suffix,
                donotCutWords: donotCutWords
            });
        };
    });

    app.filter('staticBaseUrl', function(config) {
        return function(path) {
            return `${config.getStaticBaseUrl()}${path}`;
        };
    });

    app.filter('privateRoom', function(roomService) {
        return function(room) {
            return roomService.isPrivate(room);
        };
    });

    app.filter('publicRoom', function(roomService) {
        return function(room) {
            return roomService.isPublic(room);
        };
    });

    app.filter('percentage', ($filter) => {
        return (input, decimals = 0) => {
            return $filter('number')(input * 100, decimals) + '%';
        };
    });

    app.filter('properUserName', (userService) => {
        return (user) => {
            return userService.getProperUserName(user);
        };
    });

    app.filter('fromYoutubeSong', (songService) => {
        return (ytSong) => {
            return songService.fromYoutubeSong(ytSong);
        };
    });

    app.filter('isProRoom', (roomService) => {
        return (room) => {
            return roomService.isProRoom(room);
        };
    });

    app.filter('isProPlusRoom', (roomService) => {
        return (room) => {
            return roomService.isProPlusRoom(room);
        };
    });

    app.filter('isPremiumRoom', (roomService) => {
        return (room) => {
            return roomService.isPremiumRoom(room);
        };
    });

    app.filter('isUpgradedLevel', (roomService) => {
        return (room) => {
            return roomService.isUpgradedLevel(room);
        };
    });

    app.filter('isGreaterEqualToLevel', () => {
        return (room, level) => {
            return room && room.level >= level;
        };
    });

    app.filter('boolean', () => {
        return (value) => {
            return !!value ? 'true' : 'false';
        };
    });

    app.filter('hashtagable', () => {
        return (value) => {
            return sharedHelper.replaceHashtagsInString(value);
        };
    });

    app.filter('songName', (songService) => {
        return (song) => {
            try {
                return songService.getSongName(song);
            } catch(e) {
                return song;
            }
        };
    });

    app.filter('roomKind', (roomService) => {
        return (room) => {
            if (room) {
                return sharedHelper.getRoomKind(room);
            }

            return roomService.getRoomKindGlobally();
        };
    });

    app.filter('isAnotherPlatform', (helper) => {
        return (room) => {
            return helper.isAnotherPlatform(room);
        };
    });

    app.filter('abbreviateNumber', () => {
        return (number) => {
            if (isNaN(number)) return number;

            if (number >= 1e9) {
                return (number / 1e9).toFixed(1) + 'B';
            } else if (number >= 1e6) {
                return (number / 1e6).toFixed(1) + 'M';
            } else if (number >= 1e3) {
                return (number / 1e3).toFixed(1) + 'K';
            } else {
                return number.toString();
            }
        };
    });
}(angular));
