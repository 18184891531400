import angular from 'angular';

import './slidingWindow.less';
import template from './slidingWindow.html';

if (MyGlobal.project.isMobile) {
    import('./slidingWindow_mobile.less');
}

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('mySlidingWindow', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            params: '='
        },
        controller($scope, helper, slidingWindowService) {
            $scope.slidingWindowService = slidingWindowService;

            $scope.feedFiltersVisibility = {
                group: false
            };

            for (const [key, val] of Object.entries($scope.params)) {
                $scope[key] = val;
            }

            $scope.preventClick = ($event) => {
                $event.stopImmediatePropagation();
            };

            $scope.$on('document:keyup:esc', () => {
                $scope.close();
            });

            // $scope.$on('document:click', ($evt, jqEvt) => {
            //     if ($(jqEvt.target).hasClass('btn-close')) return;
            //     //$scope.close();
            // });

            $scope.back = () => {
                slidingWindowService.back();
            };

            $scope.close = () => {
                slidingWindowService.close();
            };

            (async ()=> {
                try {
                    if ($scope.resolves?.length) {
                        await Promise.all($scope.resolves);
                    }
                    $scope.ready = true;
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            })();
        }
    };
});
