import angular from 'angular';
import './chatMessageRowHoverOptions.less';
import template from './chatMessageRowHoverOptions.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myChatMessageRowHoverOptions', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            message: '=',
            hideEmojis: '=',
            onReplyMessageClicked: '&',
            onToggleEmojis: '&'
        },
        controller($scope) {
            $scope.toggleEmojisInt = ($event) => {
                $scope.onToggleEmojis({ $event, message: $scope.message });
            };
        }
    };
});
