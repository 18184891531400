/**
 * Created by Shlomi on 26/10/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('UpdateInvalidEmailCtrl', function ($scope, $uibModalInstance, userService,
                                                       $http, $timeout, helper, $rootScope) {

        $scope.user = {
            userName: $rootScope.user.userName
        };

        $scope.isAlreadyExist = {};

        $scope.checkIsExist = function(evt, what, value){

            value = value || evt.currentTarget.value;
            if(what && value){
                $http.get('/auth/isExist?w='+what+'&v='+value).success(function(data){
                    $scope.isAlreadyExist[what] = data.exist || data.preserved;
                }).error(function(response){
                    $scope.error = response.error;
                });
            }
        };

        $scope.updateEmail = async ()=>{

            if($scope.isAlreadyExist.email) return;

            try {

                if(!helper.isValidEmail($scope.user.email)) {
                    throw new Error('Invalid Email');
                }

                const params = {
                    userName: $scope.user.userName,
                    email: $scope.user.email
                };

                $scope.loading = true;
                await userService.update(params);

                $scope.error = null;
                $scope.notification = 'You email been updated successfully!';

                $timeout(() => {
                    helper.reloadPage(true);
                }, 1000);
            } catch(e) {
                helper.error(e);
                $scope.error = response.error;
            } finally {
                delete $scope.loading;
                $scope.notification = null;
                try { $scope.$digest(); } catch(e) {}
            }
        };

        $scope.close = function(){
            $uibModalInstance.dismiss();
        };

        if(!$rootScope.user){
            $scope.close();
        }
    });

}(angular));

