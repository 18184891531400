import angular from 'angular';
import template from './feedDropItemGif.html';
import './feedDropItemGif.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myFeedDropItemGif', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            gif: '='
        }
    };
});
