
import angular from 'angular';

import './emojis.less';
import template from './emojis.html';

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('myEmojis', (helper) => {
    return {
        restrict: 'E',
        template,
        scope: {
            hide: '=',
            element: '=',
            zIndex: '=',
            onEmojiSelected: '&',
            onClose: '&'
        },
        link($scope) {

            $scope.helper = helper;

            const close = () => {
                $scope.onClose();
            };

            (async ()=> {
                try {
                    const { Picker } = await import('emoji-picker-element');

                    const container = $('<div>');
                    const id = helper.getRandomFloat(1, 1000);
                    container.attr('data-unique-id', id);

                    const picker = new Picker();

                    container.append(picker);
                    $('body').append(container);

                    const pickerShadowElem = picker.shadowRoot.querySelector('#search');

                    let { top, left } = $scope.element.offset();

                    const position = helper.getPositionNextToElementWithinScreen({
                        elemTop: top,
                        elemLeft: left,
                        elemHeight: $scope.element.outerHeight(),
                        holderHeight: $(picker).outerHeight()
                    });

                    container.css({
                        ...position,
                        position: 'absolute',
                        'z-index': $scope.zIndex || 999
                    });

                    picker.addEventListener('emoji-click', (event) => {
                        $scope.onEmojiSelected({
                            emoji: event?.detail
                        });
                    });

                    picker.addEventListener('click', (evt) => {
                        evt.stopImmediatePropagation();
                    });

                    // Make sure we're not propagating typing of the emojis search to avoid trigger of BeatSense shortcuts
                    pickerShadowElem.addEventListener('keyup', (evt) => {
                        evt.stopImmediatePropagation();

                        if ([27, 13].includes(evt.which)) {
                            close();
                        }
                    });

                    pickerShadowElem.addEventListener('click', (evt) => {
                        evt.stopImmediatePropagation();
                    });

                    $scope.$on('$destroy', () => {
                        container.remove();
                    });

                    $scope.$watch('hide', (val) => {
                        if (val) {
                            container.hide();
                        } else {
                            container.show();
                            $(pickerShadowElem).focus();
                        }
                    });

                    $scope.$on('document:click', () => {
                        close();
                    });

                    $scope.$on('document:keyup:esc', () => {
                        close();
                    });

                    $scope.$on('document:keypress:enter', () => {
                        close();
                    });

                } catch(e) {
                    helper.error(e);
                }
            })();
        }
    };
});
