import angular from 'angular';
import { GiphyFetch } from '@giphy/js-fetch-api';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('subscriptionService', (config, httpService, helper) => {

    let subscriptions;

    return {
        async getSubscriptions() {
            if (!subscriptions) {
                subscriptions = await httpService.get('/subscription/all');
            }
            return subscriptions;
        },
        async getSubscription(id) {
            const subscriptions = await this.getSubscriptions();
            return subscriptions && subscriptions.find(item => item.id === id);
        },
        async generatePaymentToken(subscriptionId){
            return httpService.post('/subscription/generatePaymentToken', {
                id: subscriptionId,
                origin: location.pathname
            });
        },
        /**
         * @param subscription
         * @returns left and total in MS
         */
        getInfo(subscription) {
            if (!subscription) return;

            const now = new Date();
            const start = new Date(subscription.start);
            const end = new Date(subscription.end);

            const total = end.getTime() - start.getTime();
            const left = end.getTime() - now.getTime();
            const ratio = Math.round((left / total) * 100);
            const leftDays = left ? Math.round(helper.msToDays(left)) : 0;

            return {
                left,
                total,
                ratio,
                leftDays
            }
        }
    };
});
