import angular from 'angular';
import template from './copyPasteInput.html';
import './copyPasteInput.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myCopyPasteInput', ($timeout, helper) => {
    return {
        restrict: 'E',
        template,
        scope: {
            text: '=',
            autoCheck: '='
        },
        link($scope, $element) {
            const elem = $element.find('.copyPasteInput input');

            $scope.prepareCopyPasteLinkFn = function($event){
                if($event) $event.preventDefault();
                elem.focus().select();
            };

            $scope.onCopyClicked = async ($event)=> {
                try {
                    if($event) $event.stopImmediatePropagation();
                    await helper.copyToClipboard({
                        text: $scope.text
                    });

                    $scope.copied = true;
                    $timeout(()=>{
                        delete $scope.copied;
                    }, 1500);

                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            if ($scope.autoCheck) {
                $timeout(() => {
                    $scope.prepareCopyPasteLinkFn();
                });
            }
        }
    };
});
