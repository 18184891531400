import angular from 'angular';
import template from './feed.html';
import './feed.less';

import './feed.service';
import './feedDropInput/feedDropInput';
import './feedDropItem/feedDropItem';
import '../popups/feedReDropPopup/feedReDropPopup';
import '../popups/feedDropReplyPopup/feedDropReplyPopup';
import {FEED_GROUP_FILTER_KEYS, MAX_SEARCH_HASHTAGS} from '../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myFeed', (helper) => {
    return {
        restrict: 'E',
        template,
        scope: {
            userId: '=',
            isMainFeed: '=',
            isSystemFeed: '=',
            hideInput: '=',
            filters: '=?',
            filtersVisibility: '=?',
            visible: '=',
            onLoaded: '&'
        },
        controller($scope, feedService, $interval, storage, userService, $document, $timeout) {

            const storageKey = 'feedFilters';

            let currScrollX = 0;
            let lastNewFeedDropsCheck;
            $scope.currPage = 1;

            $scope.filters = $scope.filters || {};

            $scope.filtersVisibility = {
                group: typeof $scope.filtersVisibility?.group === 'undefined' ? true : $scope.filtersVisibility?.group,
                hashtags: true,
            };

            $scope.groupOptions = [{
                name: 'Trending',
                value: FEED_GROUP_FILTER_KEYS.TRENDING
            }, {
                name: 'Following',
                value: FEED_GROUP_FILTER_KEYS.FOLLOWING
            }, {
                name: 'Latest',
                value: FEED_GROUP_FILTER_KEYS.LATEST
            }];

            const setDefaultGroupFilter = () => {
                const stored = storage.getItem(storageKey);
                $scope.filters.group = stored?.group || FEED_GROUP_FILTER_KEYS.TRENDING;
            };

            const changeCounterToFeedDrop = ({ feedDrop, field, sign }) => {
                for (const tmpFeedDrop of $scope.feedDrops) {
                    if (tmpFeedDrop._id === feedDrop._id) {
                        tmpFeedDrop[field] = (tmpFeedDrop[field] || 0) + sign;
                    }
                }
            };

            $scope.loadFeedDrops = async ({ append } = {}) => {
                try {
                    $scope.feedLoading = !append;

                    const { models, pagination } = await feedService.loadFeed({
                        userId: $scope.userId,
                        isSystemFeed: $scope.isSystemFeed,
                        page: $scope.currPage,
                        filters: $scope.filters
                    });

                    if (!append) {
                        lastNewFeedDropsCheck = new Date();
                    }

                    $scope.feedPagination = pagination;

                    if (append) {
                        $scope.feedDrops = ($scope.feedDrops || []).concat(models);
                    } else {
                        $scope.feedDrops = models;
                    }

                } finally {
                    $scope.feedLoading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.$on('document:click', () => {
                delete $scope.showDropsFilterMenu;
            });

            $scope.$on('feedDrop:redroped', ($evt, feedDrop) => {
                changeCounterToFeedDrop({
                    feedDrop: feedDrop ,
                    field: 'redrops',
                    sign: 1
                });
            });

            $scope.$on('feedDrop:replied', ($evt, feedDrop) => {
                changeCounterToFeedDrop({
                    feedDrop: feedDrop ,
                    field: 'replies',
                    sign: 1
                });
            });

            $scope.$on('feedDrop:created', ($evt, feedDrop) => {
                if ($scope.userId && $scope.userId !== feedDrop?.user?._id) return;
                $scope.onFeedDropCreated(feedDrop);
            });

            $scope.onFeedDropCreated = (feedDrop) => {
                $scope.feedDrops = $scope.feedDrops || [];
                $scope.feedDrops.unshift(feedDrop);
            };

            $scope.onDropsFilterOpenerClicked = ($event) => {
                $event.stopImmediatePropagation();
                $scope.showDropsFilterMenu = !$scope.showDropsFilterMenu;
            };

            $scope.loadNewestDrops = async ($event) => {
                $event.stopImmediatePropagation();

                $scope.currPage = 1;
                await $scope.loadFeedDrops();
                delete $scope.hasNewFeedDrops;
            };

            $scope.onDropsFilterClicked = ($event) => {
                $event.stopImmediatePropagation();
            };

            $scope.onFilterChanged = async () => {
                try {

                    $scope.showDropsFilterMenu = false;

                    if($scope.filters.group === FEED_GROUP_FILTER_KEYS.FOLLOWING && !userService.isLoggedIn()){
                        setDefaultGroupFilter();
                        return helper.gotoState( 'join', {
                            message: `Join first ;)`
                        });
                    }

                    $scope.currPage = 1;
                    await $scope.loadFeedDrops();

                    if ($scope.feedDrops?.length) {
                        storage.setItem(
                            storageKey,
                            $scope.filters,
                            helper.daysToMs(7)
                        );
                    } else {
                        storage.removeItem(storageKey);
                    }

                } catch(e) {
                    helper.error(e);
                }
            };

            $scope.scrollFeedIfNeeded = async () => {

                if ($scope.currPage >= $scope.feedPagination?.totalPages) {
                    return;
                }

                $scope.currPage = ($scope.currPage || 0) + 1;
                return await $scope.loadFeedDrops({
                    append: true
                });
            };

            $scope.removeFeedDrop = (feedDrop, options = {}) => {
                const removed = helper.removeItemFromArray($scope.feedDrops, item => {
                    return item._id === feedDrop._id;
                });

                if (!options.fake && removed && feedDrop?.originalFeedDrop) {
                    changeCounterToFeedDrop({
                        feedDrop: feedDrop.originalFeedDrop ,
                        field: 'redrops',
                        sign: -1
                    });
                }
            };

            $scope.onHashTagSelected = async (hashtag) => {
                if (!$scope.filters.hashtags?.find(item => item === hashtag)) {

                    if ($scope.filters.hashtags?.length === MAX_SEARCH_HASHTAGS) {
                        $scope.filters.hashtags.shift();
                    }

                    $scope.filters.hashtags = $scope.filters.hashtags || [];
                    $scope.filters.hashtags.push(hashtag);

                    await $scope.loadFeedDrops();
                }
            };

            $scope.removeHashtag = async (hashtag) => {
                helper.removeItemFromArray($scope.filters.hashtags, item => item === hashtag);

                if (!$scope.filters.hashtags.length) {
                    delete $scope.filters.hashtags;
                }

                await $scope.loadFeedDrops();
            };

            $scope.$watch('userId', async (newVal, oldVal)=> {
                try {
                    if (newVal === oldVal) return;
                    $scope.currPage = 1;
                    await $scope.loadFeedDrops();
                } catch (e) {
                    helper.error(e);
                }
            });

            $scope.$watch('visible', async (newVal, oldVal)=> {
                try {
                    if (!!newVal && newVal !== oldVal) {
                        if (currScrollX > 0) {
                            $timeout(() => {
                                $document.scrollTop(currScrollX);
                            });
                        }
                    }
                } catch (e) {
                    helper.error(e);
                }
            });

            $scope.$on('window:scroll:bottom', async () => {
                try {
                    await $scope.scrollFeedIfNeeded();
                } catch(e) {
                    helper.error(e);
                }
            });

            $scope.$on('feed:filtersUpdated', async (evt, { filters }) => {
                $scope.filters = {
                    ...$scope.filters,
                    ...filters
                };
                await $scope.loadFeedDrops();
            });

            if ($scope.filtersVisibility?.group) {
                setDefaultGroupFilter();
            }

            (async () => {
                try {
                    await $scope.loadFeedDrops();

                    $scope.onLoaded({ totalItems: $scope.feedDrops?.length || 0 });

                    $interval(async ()=> {
                        try {

                            // If we already know we have new items, dont check it again..
                            if ($scope.hasNewFeedDrops) return;

                            const { hasNew } = await feedService.hasNewFeedDrops(lastNewFeedDropsCheck);
                            $scope.hasNewFeedDrops = hasNew;
                        } catch(e) {
                            helper.error(e);
                        }
                    }, helper.minutesToMs(5));

                    // Track and save user scroll
                    $scope.$on('window:scroll', () => {
                        if ($scope.visible) {
                            currScrollX = $(window).scrollTop();
                        }
                    });

                } catch(e) {
                    helper.error(e);
                }
            })();
        }
    };
});
