import angular from 'angular';
const app = angular.module(MyGlobal.page.ngAppName);

app.service('slidingWindowService', (helper, $rootScope, $timeout) => {

    let stack = [];

    const applySlidingInfoData = (data) => {
        $rootScope.slidingWindowInfo = null;

        $timeout(() => {
            $rootScope.slidingWindowInfo = data;
        });

        try { $rootScope.$digest(); } catch(e) {}
    };

    return {
        types: {
            USER_PROFILE: 'USER_PROFILE',
            FEED: 'FEED',
            FEED_DROP: 'FEED_DROP'
        },
        open({ type, resolves, data, ignoreStack }) {

            if (!ignoreStack && $rootScope.slidingWindowInfo) {
                this.pushStack($rootScope.slidingWindowInfo);
            }

            applySlidingInfoData({
                type,
                resolves,
                data
            });
        },
        pushStack({ type, resolves, data }) {
            stack.push({ type, resolves, data });
        },
        hasStack() {
            return stack?.length;
        },
        back() {
            if (this.hasStack()) {
                this.open({
                    ...stack.pop(),
                    ignoreStack: true
                });
                helper.gotoState('default');
            } else {
                this.close();
            }
        },
        close() {
            stack = [];
            delete $rootScope.slidingWindowInfo;
            helper.gotoState('default');
        }
    };
});
