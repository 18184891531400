import angular from 'angular';
import './redirectToPlatform.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyRedirectToPlatformPopupCtrl', ($scope, $stateParams, $rootScope, $uibModalInstance, $timeout) => {

    const { type, room } = $stateParams;

    $scope.currPlatform = $rootScope.isStations ? 'Stations' : 'BeatSense';
    $scope.room = room;
    $scope.type = type;

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    if (!type || !room) {
        $timeout(() => {
            $scope.close();
        });
    }
});
