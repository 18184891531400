import angular from 'angular';
import './moodsOpener.less';
import template from './moodsOpener.html';
import '../moods.service'

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myMoodsOpener', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, $rootScope, popupService) {
            $scope.show = false;
            $scope.open = () => {
                popupService.openMoodsPopup();
            };
        }
    };
});
