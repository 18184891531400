
import './chat.less';
import angular from 'angular';
import template from './chat.html';
import './chatMessageRow/chatMessageRow';
import './chatInput/chatInput';

import { CHAT_MESSAGE_TYPES } from '../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myChat', ($timeout, helper, chatService, $rootScope, userService) => {
    return {
        restrict: 'E',
        template,
        scope: {
            chat: '=',
            onClose: '&',
            onMinimize: '&',
            onMaximize: '&',
        },
        controller($scope, roomService, $injector,
                   webNotificationService, storage) {

            let beepsCounter = 0;
            const beepsThreshold = 2;
            let beepResetTimer;

            $scope.CHAT_MESSAGE_TYPES = CHAT_MESSAGE_TYPES;

            $scope.chatMessages = [];
            $scope.autoScroll = true;

            const getLastChatMessageStorageKey = () => {
                return `lastPrivateChatMessage.${$scope.chat.id}`;
            }

            const checkMentions = async (chatMessage) => {
                // Check if need to beep..

                let mainPlayerService;

                if ($rootScope.isRoomPage) {
                    mainPlayerService = $injector.get('mainPlayerService');
                }

                if (userService.isLoggedIn() && mainPlayerService && !mainPlayerService.isPlayerMuted()) {

                    const currUser = userService.getUser();
                    const userNamesInMessage = helper.getAllUserNamesInText(chatMessage.message.content, {toLowerCase: true});
                    const userNameMentioned = userNamesInMessage?.includes(`@${currUser.userName.toLowerCase()}`);
                    const itsNotMe = chatMessage?.message?.user?._id !== currUser.id;

                    if(userNameMentioned && beepsCounter < beepsThreshold){
                        beepsCounter++;

                        // Beep the user
                        $scope.beep().catch(helper.error);

                        // Send user notification about the chat
                        if(itsNotMe && webNotificationService.isAllowed(webNotificationService.sections.chatMentioned)){

                            const text = chatMessage.from.userName ? `${chatMessage.from.userName} mentioned you in chat` : `You've been mentioned in chat`;

                            webNotificationService.systemNotification(text, webNotificationService.sections.chatMentioned, {
                                duration: 9000
                            }).catch(e => {
                                helper.error(e);
                            });
                        }
                    } else if (!userNameMentioned) {
                        beepsCounter = 0;
                    } else {
                        beepResetTimer = $timeout(() => {
                            beepsCounter = 0;
                        }, helper.secsToMs(30));
                    }

                    const yourMessageReplied = chatMessage?.replyTo?.from?._id === userService.getUserId();
                    if(yourMessageReplied && itsNotMe && webNotificationService.isAllowed(webNotificationService.sections.myMessageReplied)){

                        // Beep only once a minute max..
                        const storageKey = `chat.${$scope.chat.id}.repliedMessageIgnoreBeep`;
                        if(!storage.getItem(storageKey)) {
                            $scope.beep().catch(helper.error);
                            storage.setItem(storageKey, true, helper.minutesToMs(1));
                        }

                        await webNotificationService.systemNotification(`${chatMessage.from.userName} replied your private message`, webNotificationService.sections.myMessageReplied, {
                            duration: 9000
                        });
                    }
                }
            };

            $scope.onCloseInt = ($event, userId) => {
                $event.stopImmediatePropagation();
                $scope.onClose({ userId });
            };

            $scope.load = async () => {
                const { models, blocks } = await chatService.loadMessages({
                    userId: $scope.chat.user._id
                });

                $scope.blocks = blocks;
                $scope.chatMessages = models.reverse();

                const lastMessage = $scope.chatMessages[$scope.chatMessages.length - 1];

                for (let chatMessage of $scope.chatMessages) {
                    chatMessage = chatService.enhanceChatMessage(chatMessage, {
                        prevMessage: lastMessage?.message
                    });
                }
            };

            $scope.onBlockClicked = async ($event) => {
                try {
                    $event.stopImmediatePropagation();
                    const { state } = await chatService.toggleBlock($scope.chat.user._id);

                    $scope.blocks = $scope.blocks || {};
                    $scope.blocks.yours = state;
                } catch (e) {
                    helper.error(e);
                }
            };

            const addMessage = (chatMessage, { apply } = {}) => {

                chatMessage = chatService.enhanceChatMessage(chatMessage, {
                    prevMessage: $scope.chatMessages?.[$scope.chatMessages?.length - 1]?.message
                });

                $scope.chatMessages.push(chatMessage);

                if ($scope.autoScroll) {
                    $scope.scrollToBottom({ apply }).catch(e => {
                        helper.error(e);
                    });
                } else {
                    $scope.showUnreadIndicator = true;
                }

                return chatMessage;
            };

            const updateMessage = (id, chatMessage, fields) => {
                for (let i=0;i<$scope.chatMessages.length;i++) {
                    if ($scope.chatMessages[i]._id === id) {

                        if (fields) {
                            for (const field of fields) {
                                $scope.chatMessages[i][field] = chatMessage[field];
                            }
                        } else {
                            $scope.chatMessages[i] = chatService.enhanceChatMessage(chatMessage, {
                                prevMessage: $scope.chatMessages?.[i-1]?.message
                            });
                        }
                        break;
                    }
                }
            };

            const sendMessage = async ({ type, content, ...args }) => {
                if(!userService.isLoggedIn()){
                    return helper.gotoState( 'join', {
                        message: `The chat is for Beatsters only, it's time to become one!`
                    });
                }

                const currSong = roomService.getCurrSong();

                let userSong;
                if ($rootScope.isRoomPage) {
                    const userSongService = $injector.get('userSongService');
                    userSong = currSong && userSongService.toChatMessage(currSong);
                }

                const replyTo = chatService.reduceReplyToPrivateMessage($scope.replyTo);

                $scope.autoScroll = true;

                // Add the message **locally** to the current chat (to show it faster and smooter)
                const id = helper.getRandomFloat(1, 1000);
                addMessage({
                    _id: id,
                    from: userService.getUser(),
                    message: {
                        type,
                        content,
                        userSong,
                        ...args
                    },
                    replyTo
                });

                // Send the message to the target user
                let chatMessage;
                switch (type) {
                    case chatService.messageTypes.TEXT:
                        chatMessage = await chatService.sendTextMessage({
                            message: content,
                            to: $scope.chat.user._id,
                            replyTo,
                            userSong,
                            ...args
                        });
                        break;
                    case chatService.messageTypes.GIF:
                        chatMessage = await chatService.sendGifMessage({
                            data: content,
                            to: $scope.chat.user._id,
                            replyTo,
                            userSong,
                            ...args
                        });
                        break;
                }

                if (chatMessage) {
                    chatMessage.sent = true;
                    updateMessage(id, chatMessage);
                    // Send update to other tabs
                    storage.setItem(getLastChatMessageStorageKey(), chatMessage, helper.secsToMs(5));
                }

                $scope.clearReplyMessage();
            };

            $scope.toggleMinimized = async ($event) => {
                try {
                    $event.stopImmediatePropagation();

                    if (!$scope.chat.minimized) {
                        $scope.onMinimize();
                    } else {
                        $scope.onMaximize();

                        $scope.unreads = 0;
                        await chatService.ackAllUserMessages($scope.chat.user._id);

                        $scope.scrollToBottom().catch(e => helper.error(e));
                    }
                } catch (e) {
                    helper.error(e);
                }
            };

            $scope.clearReplyMessage = () => {
                delete $scope.replyTo;
            };

            $scope.setReplyMessage = (chatMessage) => {
                $scope.replyTo = chatMessage;
            };

            $scope.onReplyClicked = (chatMessage) => {
                return $scope.scrollToMessage(`.chatMessageRow[data-id=${chatMessage._id}]`);
            };

            // Send text message
            $scope.onInputSubmit = async ($event, value) => {
                try {
                    await sendMessage({
                        type: chatService.messageTypes.TEXT,
                        content: value
                    });
                } catch (e) {
                    $rootScope.showError(e);
                }
            };

            // Send GIF  message
            $scope.$on(`chat:${$scope.chat.user._id}:send:gif`, async (evt, data) => {
                try {
                    await sendMessage({
                        type: chatService.messageTypes.GIF,
                        content: data
                    });
                } catch (e) {
                    $rootScope.showError(e);
                }
            });

            // Receive message
            $scope.$on('chat:message:private', async (evt, chatMessage) => {
                try {
                    if (chatMessage?.from._id === $scope.chat.user._id) {
                        addMessage(chatMessage, { apply: true });

                        if (chatMessage?.message.type === chatService.messageTypes.TEXT) {
                            checkMentions(chatMessage);
                        }

                        if (!$scope.chat.minimized) {
                            await chatService.ackMessage({ id: chatMessage._id });
                        } else {
                            $scope.unreads = ($scope.unreads  || 0) + 1;
                        }
                    }
                } catch (e) {
                    helper.error(e);
                }
            });

            $scope.$on('chat:message:private:ack', async (evt, chatMessage) => {
                try {
                    if (chatMessage?.to === $scope.chat.user._id) {
                        chatMessage.read = true;
                        updateMessage(chatMessage._id, chatMessage, ['read']);
                    }
                } catch (e) {
                    helper.error(e);
                }
            });

            $scope.$watch('chat.minimized', async (newVal, oldVal) => {
                try {
                    if (!newVal && newVal !== oldVal) {
                        $scope.unreads = 0;
                        await chatService.ackAllUserMessages($scope.chat.user._id);
                    }
                } catch (e) {
                    helper.error(e);
                }
            });

            $scope.$on('inbox:unreads', (evt, { data }) => {
                if ($scope.chat.minimized && data?.length) {
                    for (const piece of data) {
                        const { _id: id, total } = piece;

                        if (id === $scope.chat.id) {
                            $scope.unreads = total;
                            break;
                        }
                    }
                }
            });

            $scope.$on('chat:block:toggle', (evt, { state }) => {
                $scope.blocks.his = state;
            });

            $scope.$on('window:storage', (evt, event) => {
                const { key }  = event?.originalEvent || {};

                const storageKey = getLastChatMessageStorageKey();

                if (key === storageKey) {
                    const lastMessage = storage.getItem(storageKey);

                    if (lastMessage) {
                        addMessage(lastMessage, { apply: true });
                        storage.removeItem(storageKey)
                    }

                }
            });

            $scope.$on('$destroy', () => {
                if (beepResetTimer) {
                    $timeout.cancel(beepResetTimer);
                    beepResetTimer = null;
                }
            });
        },
        link($scope, $element) {

            let times = 0;

            const messagesHolderElement = $($element).find('.chatBody .messagesHolder > .scrollArea');

            $scope.scrollToMessage = async (selector) => {
                return new Promise((resolve, reject) => {
                    $timeout(() => {
                        try {
                            const elemJq = messagesHolderElement;
                            const ngElem = angular.element(elemJq);

                            const lastElement = elemJq.find(selector);
                            if (!(elemJq?.length && lastElement?.length)) {
                                return resolve();
                            }

                            ngElem.scrollToElement(lastElement);
                            $scope.updateScroller().then(resolve).catch(reject);
                        } catch (e) {
                            reject(e);
                        }
                    });
                });
            };

            $scope.scrollToBottom = async ({ apply, $event } = {}) => {

                if ($event) {
                    $event.stopImmediatePropagation();
                }

                if (apply) {
                    try { $scope.$digest(); } catch(e) {}
                }

                return $scope.scrollToMessage('.chatMessageRow:last');
            };

            $scope.updateScroller = async () => {
                return new Promise((resolve, reject) => {
                    $timeout(() => {
                        messagesHolderElement.perfectScrollbar('update');
                        resolve();
                    });
                });
            };

            $scope.deleteAll = async($event) => {
                $event.stopImmediatePropagation();

                if (!confirm('Are you sure?')) return;
                try {
                    await chatService.deleteChat($scope.chat.user._id);
                    //$scope.chatMessages = [];
                    $rootScope.showNotification(`Messages deleted, next time you'll open the chat it'll be empty`);
                } catch(e) {
                    $rootScope.showError(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            }

            const onScrollUp = () => {
                if ($scope.autoScroll && ++times % 3 === 0) {
                    helper.debug(`chat scrolled up, stop auto scrolling..`);
                    $scope.autoScroll = false;
                }
            };

            const onScrollReachEndY = () => {
                $timeout(() => {
                    helper.debug('chat scroll reached end, start auto scrolling..');
                    times = 0;
                    $scope.autoScroll = true;
                    $scope.showUnreadIndicator = false;
                });
            };

            messagesHolderElement.on('ps-scroll-up', onScrollUp);
            messagesHolderElement.on('ps-y-reach-end', onScrollReachEndY);

            // Beep shit..

            const beepElem = $($element).find('.chatBeeper').get(0);
            $scope.beep = async () => {
                beepElem.pause();
                beepElem.currentTime = 0;
                try {
                    await beepElem.play();
                } catch (e) {}
            };

            $scope.$on('$destroy', () => {
                messagesHolderElement.off('ps-scroll-up', onScrollUp);
                messagesHolderElement.off('ps-y-reach-end', onScrollReachEndY);
            });

            // Initial load
            $scope.loading = true;
            (async () => {
                try {

                    if(!userService.isLoggedIn()){
                        return;
                    }

                    $scope.loading = true;
                    await $scope.load();
                    $scope.loading = false;

                    for (const chatMessage of $scope.chatMessages) {
                        chatMessage.sent = true;
                    }

                    if (!$scope.chat.minimized) {
                        await chatService.ackAllUserMessages($scope.chat.user._id);
                    }

                    await $scope.scrollToBottom({ apply: true });

                } catch (e) {
                    helper.error(e);
                } finally {
                    $scope.loading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            })();
        }
    };
});
