/**
 * Created by Shlomi on 06/02/2016.
 */

/**
 * Created by Shlomi on 26/10/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('BeatsPostPurchaseFailureModalCtrl', function ($scope, helper, $uibModalInstance, purchaseInfo) {

        $scope.purchaseInfo = purchaseInfo;

        $scope.closeAndGoToBeatsPurchaseModal = function(){
            helper.trackBeatsEvent('PurchaseModalOpened', 'PostPaymentFailureModal');
            $scope.close();
        };

        $scope.close = function(){
            $uibModalInstance.dismiss();
        };

        // Send GA event
        if($scope.purchaseInfo){

            var action = 'BeatsPackagePurchaseFailed';
            var label = $scope.purchaseInfo.reason;

            if(action && label){
                helper.trackBeatsEvent(action, label);
            }

        }
    });

}(angular));


