import angular from 'angular';

import template from './feedDropItemImage.html';
import './feedDropItemImage.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myFeedDropItemImage', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            data: '='
        },
        controller($scope, helper) {
            $scope.onClick = ($event)=> {
                $event.preventDefault();
                helper.openWindow($scope.data.url, '_blank');
            }
        }
    };
});
