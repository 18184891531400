
import angular from 'angular';
import './debugInfo.less';
import template from './debugInfo.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myDebugInfo', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, $rootScope, sdkService, $injector) {

            $scope.streamingInfo = $injector.has('streamingService') ? $injector.get('streamingService').getDebugInfo() : undefined;
            $scope.close = () => {
                sdkService.toggleDebug();
            };
        }
    };
});
