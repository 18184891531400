import angular from 'angular';
import moment from 'moment';
import './deleteAccountPopup.less';
import {USER_STAYING_BONUS_AMOUNT} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyDeleteAccountPopupCtrl', ($scope, $uibModalInstance, userService, beatsService,
                                            helper, $rootScope, analyticsService, storage) => {

    $scope.USER_STAYING_BONUS_AMOUNT = USER_STAYING_BONUS_AMOUNT;
    $scope.beats = beatsService.getBalance();
    $scope.score = userService.getGlobalScore();

    $scope.RETENTION_SCENARIOS = {
        A: 'a',
        B: 'b',
        C: 'c'
    };

    let retentionScenario;
    switch(true) {
        case $scope.score > 10 && $scope.beats > 5:
            retentionScenario = $scope.RETENTION_SCENARIOS.A;
            break;
        case $scope.score < 10 && $scope.beats > 10:
            retentionScenario = $scope.RETENTION_SCENARIOS.B;
            break;
        case $scope.score > 50 && $scope.beats < 5:
            retentionScenario = $scope.RETENTION_SCENARIOS.C;
            break;
    }

    $scope.retentionScenario = retentionScenario;

    $scope.onClaimStayingBonusClicked = async()=> {
        try {
            $scope.loading = true;
            await userService.claimStayingBonus();

            $rootScope.showNotification(`Good to have you with us! Enjoy the extra ${USER_STAYING_BONUS_AMOUNT} Beats.`);

            await Promise.all([
                beatsService.refresh(),
                analyticsService.trackRetentionEventP({
                    eventAction: 'UserClaimedStayingBonus',
                    eventLabel: `${$scope.score}_${$scope.beats}`
                })
            ]);

            $scope.close();
        } catch(e) {
            helper.error(e);
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.deleteAccount = async ($event) => {
        $event.preventDefault();

        if (!confirm('Are you sure? Your account will be deleted next.')) return;

        try {

            $scope.loading = true;

            const data = await userService.deleteAccount();

            await analyticsService.trackRetentionEventP({
                eventAction: 'UserDeletedAccount',
                eventLabel: `${$scope.score}_${$scope.beats}`
            });

            storage.setItem('postDeletionData', data, moment.duration(5, 'minutes'));
            await userService.logout({ redirect: '/?postAccountDeletion' });
        } catch(e) {
            helper.error(e);
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = function () {
        $uibModalInstance.dismiss();
    };

    (async () => {
        try {
            $scope.loading = true;
            $scope.eligibleForStayingBonus = await userService.isUserEligibleForStayingBonus();
        } catch(e) {
            helper.error(e);
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    })();
});
