import angular from 'angular';
import './moodsPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyMoodsPopupCtrl', ($scope, $uibModalInstance, helper, moodsService, $timeout, userService,
                                    analyticsService) => {

    $scope.updateUserMood = async (mood) => {
        try {
            await userService.updateMood({ mood });

            analyticsService.trackUIEvent({
                eventAction: 'UserChangedMood'
            });

            $scope.close();
        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    $timeout(async () => {
        try {
            $scope.loading = true;

            const { moods, groupedMoods } = await moodsService.getAllMoods();
            $scope.moods = moods;
            $scope.groupedMoods = groupedMoods;
        } catch(e) {
            helper.error(e);
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    });
});
