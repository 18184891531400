import angular from 'angular';
import './approveTosPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyApproveTosPopupCtrl', ($scope, $uibModalInstance, helper, userService) => {
    $scope.submit = async($event)=> {
        $event.preventDefault();

        try {
            await userService.approveTos();
        } catch(e) {
            helper.error(e);
        } finally {
            $uibModalInstance.dismiss();
        }
    }
});
