import 'jquery.ellipsis/dist/jquery.ellipsis.js';
import 'components-jqueryui';
import 'angular';
// import 'ng-file-upload/dist/angular-file-upload-shim.js';
import 'ng-file-upload/dist/ng-file-upload.min';
import 'angular-route';
import '@uirouter/angularjs';
import 'angular-ui-bootstrap';
import 'perfect-scrollbar/jquery';
import 'intro.js';
import 'angular-intro.js';
import 'ng-tags-input';
import 'angular-timeago';
import 'angular-scroll/angular-scroll.js';

import '../modules/main.js';
import '../controllers/root.js';
import '../routes/root.js';
import '../routes/beats.js';
import '../filters/all.js';
import '../services/config.js';
import '../services/userAgent.js';
import '../services/pageLoadManager.js';
import '../services/modal.js';
import '../services/buddy.js';
import '../services/beats.service.js';
import '../services/room.service.js';
import '../services/similarSongs.js';
import '../services/webNotification.js';
import '../services/theme.js';
import '../services/user.service.js';
import '../services/http.js';
import '../services/lastfm.js';
import '../services/musicTag.js';
import '../services/analytics.js';
import '../services/userJamming.js';
import '../components/popups/myBeatBox/beatbox.service.js';
import '../services/song.service.js';
import '../services/roomFeatures';
import '../services/sdk';
import '../services/popup.service';
import '../services/userRooms';
import '../services/mobileApp';
import '../factories/helper.js';
import '../factories/storage.js';
import '../factories/musicPlayers/youtubePlayer';
import '../factories/musicPlayers/musicPlayer.js';
import '../factories/mySocket.js';
import '../directives/emoji.js';
import '../directives/jammingPartners.js';
import '../directives/error.js';
import '../directives/notification.js';
import '../directives/footer.js';
import '../directives/buddiesList.js';
import '../directives/perfectScrollbar.js';
import '../directives/userImage.js';
import '../directives/myEllipsis.js';
import '../directives/feedbackWidget.js';
import '../directives/partials/themeThumbnail.js';
import '../directives/roomThemeManagement.js';
import '../directives/partials/nothingButEcho.js';
import '../directives/readModeElement.js';
import '../directives/countLettersElement.js';
import '../directives/topMenu.js';
import '../directives/partials/searchBox.js';
import '../directives/room/partials/addSongToBeatBox.js';
import '../directives/room/partials/addSongToBeatBoxMenu.js';
import '../controllers/partials/updateInvalidEmail.js';
import '../controllers/partials/login.js';
import '../controllers/partials/register.js';
import '../controllers/partials/forgotPassword.js';
import '../controllers/partials/ranks.js';
import '../controllers/partials/stickySongModal.js';
import '../controllers/partials/beats/myBeats.js';
import '../controllers/partials/beats/explanationModal.js';
import '../controllers/partials/beats/purchaseModal.js';
import '../controllers/partials/beats/insufficientModal.js';
import '../controllers/partials/beats/postPurchaseSuccessModal.js';
import '../controllers/partials/beats/postPurchaseFailureModal.js';
import '../controllers/partials/songModal.js';

// New components structure
import '../components/userAlerts/userAlertsOpener/userAlertsOpener';
import '../components/userAlerts/userAlertsHolder/userAlertsHolder';
import '../components/shared/switch/switch';
import '../components/cookiesApproval/cookiesApproval';
import '../components/popups/addLongSongPopup/addLongSongPopup';
import '../components/shared/userLink/userLink';
import '../components/shared/userImage/userImage';
import '../components/shared/musicTagSearch/musicTagSearch';
import '../components/shared/songMoodsSearch/songMoodsSearch';
import '../components/shared/languagesSearch/languagesSearch';
import '../components/shared/myImage/myImage';
import '../components/shared/spinner/spinner';
import '../components/popups/gifsPopup/gifsPopup';
import '../components/privateChats/privateChats';
import '../components/inbox/inboxOpener/inboxOpener';
import '../components/inbox/inboxHolder/inboxHolder';
import '../components/shared/musicMatchTags/musicMatchTags';
import '../components/popups/musicMatchesPopup/musicMatchesPopup';
import '../components/popups/profileWatchesPopup/profileWatchesPopup';
import '../components/popups/usersPopup/usersPopup';
import '../components/popups/buyFeaturePopup/buyFeaturePopup';
import '../components/popups/createRoomPopup/createRoomPopup';
import '../components/shared/roomLevelBadge/roomLevelBadge';
import '../components/popups/roomLevelPopup/roomLevelPopup';
import '../components/popups/subscriptionPopup/subscriptionPopup';
import '../components/popups/subscriptionPurchasePopup/subscriptionPurchasePopup';
import '../components/shared/beatsIcon/beatsIcon';
import '../components/shared/bGPT/bGPT';
import '../components/shared/subscriptionBattery/subscriptionBattery';
import '../components/shared/songsCarousel/songsCarousel';
import '../components/slidingWindow/slidingWindow';
import '../components/debugInfo/debugInfo';
import '../components/shared/contentEditable/contentEditable';
import '../components/slidingWindow/slidingWindow.service';
import '../components/userProfile/userProfile';
import '../components/shared/roomTile/roomTile';
import '../components/shared/feedDrop/feedDrop';
import '../components/shared/emojis/emojis';
import '../components/popups/feedDropPopup/feedDropPopup';
import '../components/feed/feed';
import '../components/shared/feedDropOpener/feedDropOpener';
import '../components/shared/wikiOpener/wikiOpener';
import '../components/popups/roomAccessRequestPopup/roomAccessRequestPopup';
import '../components/popups/contributionPopup/contributionPopup';
import '../components/popups/paymentPopup/paymentPopup';
import '../components/shared/songOpinion/songOpinion';
import '../components/shared/ctrlEnterInput/ctrlEnterInput';
import '../components/shared/copyPasteInput/copyPasteInput';
import '../components/shared/shareButton/shareButton';
import '../components/shared/closeButton/closeButton';
import '../components/popups/sharePopup/sharePopup';
import '../components/shared/shareTargets/shareTargets';
import '../components/popups/approveTosPopup/approveTosPopup';
import '../components/popups/userReportPopup/userReportPopup';
import '../components/header/header';
import '../components/moods/moodsOpener/moodsOpener';
import '../components/popups/moodsPopup/moodsPopup';
import '../components/popups/beatzonePopup/beatzonePopup';
import '../components/popups/deleteAccountPopup/deleteAccountPopup';
import '../components/shared/countryIcon/countryIcon';
import '../components/popups/changeUserCountryPopup/changeUserCountryPopup';
import '../components/popups/redirectToPlatform/redirectToPlatform';
import '../components/shared/ambassadorProgramOpener/ambassadorProgramOpener';
import '../components/popups/ambassadorProgramPopup/ambassadorProgramPopup';

// Temp - remove after legacy migration
import '../components/chat/chat.service'; // move to chat component after migrate room's chat to use it.
import '../components/chat/chatInput/chatMessageInputReply/chatMessageInputReply';
import '../components/chat/chatMessageRow/chatMessageRowReply/chatMessageRowReply';
import '../components/chat/chatMessageRow/chatMessageRowHoverOptions/chatMessageRowHoverOptions';
import '../components/chat/chatInputSuggestedUsers/chatInputSuggestedUsers';
 // ### end temp

if (MyGlobal.project.isMobile) {
    require('../components/mobileLayer/mobileLayer');
    require('../components/mobileFooter/mobileFooter');
    require('../components/mobileRightMenu/mobileRightMenu');
    require('../components/mobileCurrSongControls/mobileCurrSongControls');
}

(async()=>{
    if (MyGlobal.project.sentry.enabled) {
        await import('../libs/sentry');
    }
})();
