import angular from 'angular';
const app = angular.module(MyGlobal.page.ngAppName);

app.service('moodsService', (helper, httpService) => {
    return {
        async getAllMoods() {
            const moods = await httpService.get('/user/moods');

            moods.sort( (a, b)=>{
                return a.happinessValue < b.happinessValue;
            });

            const groupedMoods = [];
            for( let i = 0;  i < moods.length; i++ ){
                if( typeof groupedMoods[moods[i].group] === 'undefined') groupedMoods[moods[i].group] = [];
                groupedMoods[moods[i].group].push(moods[i]);
            }

            return { moods, groupedMoods };
        }
    }
});