import angular from 'angular';
import './roomLevelPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyRoomLevelPopupCtrl', ($scope, $uibModalInstance, beatsService,
                                      roomFeaturesService, helper, roomService, popupParams) => {

    const { level } = popupParams;
    $scope.roomLevel = roomService.getRoomLevel({ level: parseInt(level) });

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    if (!$scope.roomLevel){
        return $scope.close();
    }

    $scope.roomLevelFeatures = roomFeaturesService.getRoomLevelFeatures($scope.roomLevel.value);
});
