import angular from 'angular';

import '../components/popups/togglePrivacyPopup/togglePrivacyPopup';
import '../components/popups/subscribeToUserBeatCasesPopup/subscribeToUserBeatCasesPopup';
import '../components/popups/postFavoritePopup/postFavoritePopup';
import '../components/popups/connectCaseToRoomPopup/connectCaseToRoomPopup';

import gifsPopupTpl from '../components/popups/gifsPopup/gifsPopup.html';
import feedDropReplyPopupTpl from '../components/popups/feedDropReplyPopup/feedDropReplyPopup.html';
import feedReDropPopupTpl from '../components/popups/feedReDropPopup/feedReDropPopup.html';
import feedDropPopupTpl from '../components/popups/feedDropPopup/feedDropPopup.html';
import roomLevelPopupTpl from '../components/popups/roomLevelPopup/roomLevelPopup.html';
import dontLeavePopupTpl from '../components/popups/dontLeavePopup/dontLeavePopup.html';
import userReportPopupTpl from '../components/popups/userReportPopup/userReportPopup.html';
import paymentPopupTpl from '../components/popups/paymentPopup/paymentPopup.html';
import sharePopupTpl from '../components/popups/sharePopup/sharePopup.html';
import approveTosPopupTpl from '../components/popups/approveTosPopup/approveTosPopup.html';
import moodsPopupTpl from '../components/popups/moodsPopup/moodsPopup.html';
import autoPlayBlockedPopupTpl from '../components/popups/autoPlayBlockedPopup/autoPlayBlockedPopup.html';
import changeStatusPopupTpl from '../components/popups/changeStatusPopup/changeStatusPopup.html';
import deleteAccountPopupTpl from '../components/popups/deleteAccountPopup/deleteAccountPopup.html';
import postAccountDeletionPopupTpl from '../components/popups/postAccountDeletionPopup/postAccountDeletionPopup.html';
import changeUserCountryPopupTpl from '../components/popups/changeUserCountryPopup/changeUserCountryPopup.html';
import togglePrivacyPopupTpl from '../components/popups/togglePrivacyPopup/togglePrivacyPopup.html';
import subscribeToUserBeatCasesPopupTpl from '../components/popups/subscribeToUserBeatCasesPopup/subscribeToUserBeatCasesPopup.html';
import postFavoritePopupTpl from '../components/popups/postFavoritePopup/postFavoritePopup.html';
import connectCaseToRoomPopupTpl from '../components/popups/connectCaseToRoomPopup/connectCaseToRoomPopup.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('popupService', ($uibModal, helper, userService, $rootScope) => {

    return {
        openPopup({ $event, template, controller, resolve, ...args }) {

            if ($event) {
                $event.preventDefault();
            }

            return $uibModal.open({
                template,
                controller,
                scope: $rootScope.$new(true),
                resolve,
                ...args
            })
        },
        openGifsPopup({ $event, ...data }) {
            return this.openPopup({
                $event,
                template: gifsPopupTpl,
                controller: 'MyGifsPopupCtrl',
                resolve: {
                    popupParams: data,
                    // Weird case that `ng-annotate-loader` cannot cover, therefore we need to define the Angular's way explicit
                    beatsServiceReady: ['beatsService', (beatsService) => {
                        return userService.isLoggedIn() ? beatsService.promise : Promise.resolve();
                    }]
                }
            });
        },
        openCreateFeedDropPopup({ $event, type, content, autoDrop, extraData }) {
            return this.openPopup({
                $event,
                template: feedDropPopupTpl,
                controller: 'MyFeedDropPopup',
                resolve: {
                    popupParams: { type, content, autoDrop, extraData }
                }
            });
        },
        openFeedDropReplyPopup({ $event, feedDrop }) {
            return this.openPopup({
                $event,
                template: feedDropReplyPopupTpl,
                controller: 'MyFeedDropReplyPopup',
                resolve: {
                    popupParams: { feedDrop }
                }
            });
        },
        openFeedReDropReplyPopup({ $event, feedDrop }) {
            return this.openPopup({
                $event,
                template: feedReDropPopupTpl,
                controller: 'MyFeedReDropPopup',
                resolve: {
                    popupParams: { feedDrop }
                }
            });
        },
        openRoomLevelPopup({ $event, level }) {
            return this.openPopup({
                $event,
                template: roomLevelPopupTpl,
                controller: 'MyRoomLevelPopupCtrl',
                resolve: {
                    popupParams: { level }
                }
            });
        },
        openDontLeavePopup({ $event }) {
            return this.openPopup({
                $event,
                template: dontLeavePopupTpl,
                controller: 'MyDontLeavePopupCtrl'
            });
        },
        openUserReportPopup({ $event, type, data }) {
            return this.openPopup({
                $event,
                template: userReportPopupTpl,
                controller: 'MyUserReportPopupCtrl',
                resolve: {
                    popupParams: { type, data }
                }
            });
        },
        openPaymentPopup({ $event, type, data }) {
            return this.openPopup({
                $event,
                template: paymentPopupTpl,
                controller: 'MyPaymentPopupCtrl',
                resolve: {
                    popupParams: { type, data }
                }
            });
        },
        openSharePopup({ entityType, entityData, title, text }) {
            return this.openPopup({
                template: sharePopupTpl,
                controller: 'MySharePopupCtrl',
                resolve: {
                    popupParams: { entityType, entityData, title, text }
                }
            });
        },
        openApproveTosPopup() {
            return this.openPopup({
                template: approveTosPopupTpl,
                controller: 'MyApproveTosPopupCtrl',
                backdrop: 'static'
            });
        },
        openMoodsPopup() {
            return this.openPopup({
                template: moodsPopupTpl,
                controller: 'MyMoodsPopupCtrl'
            });
        },
        openAutoPlayBlockedPopup() {
            return this.openPopup({
                template: autoPlayBlockedPopupTpl,
                controller: 'AutoPlayBlockedPopupCtrl'
            });
        },
        openChangeStatusPopup() {
            return this.openPopup({
                template: changeStatusPopupTpl,
                controller: 'ChangeStatusPopupCtrl'
            });
        },
        openDeleteAccountPopup() {
            return this.openPopup({
                template: deleteAccountPopupTpl,
                controller: 'MyDeleteAccountPopupCtrl'
            });
        },
        openPostAccountDeletionPopup() {
            return this.openPopup({
                template: postAccountDeletionPopupTpl,
                controller: 'MyPostAccountDeletionPopupCtrl'
            });
        },
        openChangeUserCountryPopup() {
            return this.openPopup({
                template: changeUserCountryPopupTpl,
                controller: 'MyChangeUserCountryPopupCtrl'
            });
        },
        openTogglePrivacyPopup() {
            return this.openPopup({
                template: togglePrivacyPopupTpl,
                controller: 'MyTogglePrivacyPopupCtrl'
            });
        },
        openSubscribeToUserBeatCasesPopup({ user, song, beatCaseId } = {}) {
            return this.openPopup({
                template: subscribeToUserBeatCasesPopupTpl,
                controller: 'MySubscribeToUserBeatCasesPopupCtrl',
                resolve: {
                    popupParams: { user, song, beatCaseId }
                }
            });
        },
        openPostFavoritePopup() {
            return this.openPopup({
                template: postFavoritePopupTpl,
                controller: 'MyPostFavoritePopupCtrl'
            });
        },
        openConnectCaseToRoomPopup({ bCase, room }) {
            return this.openPopup({
                template: connectCaseToRoomPopupTpl,
                controller: 'MyConnectCaseToRoomPopupCtrl',
                resolve: {
                    popupParams: { bCase, room }
                }
            });
        },
    }
});
