/**
 * Created by Shlomi on 19/09/2014.
 */

(function (angular) {

    const app = angular.module(MyGlobal.page.ngAppName);

    app.service('beatBoxService', function($q, $http, helper, httpService, userService, storage, $rootScope,
                                           mySocket){

        const SELECTED_CASE_COOKIE_NAME = 'MyBeatBox.selectedCaseId';

        const _deffered = $q.defer();
        let _cases = [];
        let _state = {};
        let _lastAddedSongId;

        // client-side only, this dictionary holds all songs added after page loaded
        let _addedSongs = {};

        /**
         * Use to indicate if song added on songModal so we'll show message in the beatbox modal later..
         * @type {boolean}
         * @private
         */
        var _songAddedInModal = false;

        return {
            initialized: false,
            promise: _deffered.promise,
            async init(){

                if(!helper.isUserLoggedIn()){
                    _deffered.resolve();
                    return;
                }

                $rootScope.$on('case:subscribed', (event, data) => {

                });

                return this.loadAllCases().then(() => {
                    this.initialized = true;
                    _deffered.resolve();
                });
            },
            loadAllCases: function () {
                var _this = this;
                var localDefer = $q.defer();

                $http.get('/beatbox/cases').success(function(userBeatBoxCases){
                    _cases = userBeatBoxCases;
                    _this.sortCases();
                    localDefer.resolve();
                }).error(localDefer.reject);

                return localDefer.promise;
            },
            findCaseLocally(caseId) {
                return _cases && _cases.find(item => {
                    return item._id === caseId;
                });
            },
            async getCaseById(id){
                return await httpService.get(`/beatbox/case/${id}`);
            },
            sortCases(){
                if(!_cases) return;

                _cases.sort(function(a, b){
                    if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                });

                _cases.sort(function(a){
                    return a.permanent ? -1 : 1;
                });

                _cases.sort(function(a){
                    return a.isDefault ? -1 : 1;
                });

            },
            isSongExists: function(song){
                return _addedSongs && song && _addedSongs[song._id];
            },
            markSongAsExists: function(song){
                if(_addedSongs && song && song._id){
                    _addedSongs[song._id] = true;
                }
            },
            setSongAddedFlag: function(state){
                _songAddedInModal = state;
            },
            getSongAddedFlag: function(){
                return _songAddedInModal;
            },
            getAllCases(){
                return _cases;
            },
            getMyCases(options = {}) {
                return _cases && _cases.filter(item => {
                    return !item.user || (item.user === userService.getUserId()) && (options.ignoreCase !== item._id);
                });
            },
            async saveSongInCase(caseId, songId, params = {}){
                const data = await httpService.post(`/beatbox/case/${(caseId ? caseId : 'default')}/song/${songId}`, params);

                const { action, originCase, currSong, publishToChat } = params;

                if(action === 'move' && originCase) {
                    $rootScope.$broadcast('caseSong:moved', { songId, fromCaseId: originCase, toCaseId: caseId });
                }

                if (currSong && _lastAddedSongId !== currSong._id) {
                    await mySocket.emitP('currSong:addedToCase', {
                        _id: currSong._id,
                        publishToChat,
                    });
                    _lastAddedSongId = currSong._id;
                }

                return data;
            },
            saveCase: function(caseObj){
                var localDefer = $q.defer();

                $http.put('/beatbox/case/'+caseObj._id, caseObj)
                    .success(localDefer.resolve)
                    .error(localDefer.reject);

                return  localDefer.promise;
            },
            deleteCase: function(caseObj){
                var localDefer = $q.defer();

                $http.delete('/beatbox/case/'+caseObj._id)
                    .success(localDefer.resolve)
                    .error(localDefer.reject);

                return  localDefer.promise;
            },
            deleteCaseSong: function(caseSong){
                var localDefer = $q.defer();

                $http.delete('/beatbox/song/'+caseSong._id)
                    .success(localDefer.resolve)
                    .error(localDefer.reject);

                return  localDefer.promise;
            },
            createNewCase: function(caseName){

                var _this = this;

                var localDefer = $q.defer();

                $http.post('/beatbox/case', {name: caseName})
                    .success(function(data){
                        _this.sortCases();
                        localDefer.resolve(data);
                    })
                    .error(localDefer.reject);

                return  localDefer.promise;
            },
            addNewCaseLocally: function(newCase){
                _cases = _cases || [];
                _cases.push(newCase);
                this.sortCases();
            },
            removeCaseLocally(bCase) {
                helper.removeItemFromArray(_cases, item => {
                    return item._id === bCase._id;
                });

                this.sortCases();
            },
            getBeatCaseSongs: function(beatCase, page){

                if(!beatCase || !beatCase._id){
                    return $q.reject('Invalid BeatCase');
                }

                page = page || 0;

                var localDefer = $q.defer();
                $http.get('/beatbox/case/' + beatCase._id + '/p/' + page).success(localDefer.resolve).error(localDefer.reject);
                return  localDefer.promise;
            },
            search: function(query, page, reqOptions){

                reqOptions = reqOptions || {};

                if(!query){
                    return $q.reject('Empty query');
                }

                var localDefer = $q.defer();
                $http.get('/beatbox/song/search?q='+query+'&p='+(page || 1), reqOptions).success(localDefer.resolve).error(localDefer.reject);
                return  localDefer.promise;
            },
            async setCasePermissions({ caseId, data }){
                if(!caseId) return;
                return httpService.post(`/beatbox/case/${caseId}/subscribable`, data);
            },
            async getSubscribableCases({ userName }) {
                if(!userName) return;
                return httpService.get(`/beatbox/cases/${userName}`);
            },
            async subscribeToCase({ caseId, userId }) {
                if(!caseId || !userId) return;
                return httpService.post(`/beatbox/case/${caseId}/subscribe`, { userId });
            },
            async unsubscribeFromCase({ caseId }) {
                if(!caseId) return;
                return httpService.post(`/beatbox/case/${caseId}/unsubscribe`);
            },
            setState: function(state){
                _state = {
                    ..._state,
                    ...state
                };
            },
            getState: function(){
                return _state || {};
            },
            resetState(){
                _state = {};
            },
            setSelectedCase(bCase) {
                storage.setItem(SELECTED_CASE_COOKIE_NAME, bCase._id);
            },
            getSelectedCase() {
                return storage.getItem(SELECTED_CASE_COOKIE_NAME);
            },
            removeSelectedCase() {
                storage.removeItem(SELECTED_CASE_COOKIE_NAME);
            },
            async exploreCases({ page, query }) {
                let url = `/beatbox/explore/${page}`;

                if (query) {
                   url += `?q=${query}`;
                }

                return httpService.get(url);
            },
            async emptyCase(bCase) {
                return await httpService.post('/beatbox/case/empty', { _id: bCase._id});
            },
            async toggleConnectCaseToRoom({ caseId, roomId }) {
                return await httpService.post('/beatbox/case/toggleConnect', { caseId, roomId });
            },
            async getConnectedCases({ roomId }) {
                return await httpService.post('/beatbox/connectedCases', { roomId });
            }
        };
    });
}(angular));
