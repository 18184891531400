import angular from 'angular';

import './bGPT.less';
import template from './bGPT.html';

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('bGPT', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope) {
            $scope.onClick = () => {
                alert('Powered by BeatSenseGPT!');
            }
        }
    };
});
