import angular from 'angular';
import template from './feedDropItemPoll.html';

import '../../../shared/poll/pollViewer/pollViewer';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myFeedDropItemPoll', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            feedDrop: '='
        }
    };
});
