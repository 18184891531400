/**
 * Created by Shlomi on 29/09/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myPerfectScrollbar', function($timeout, $rootScope) {
        return {
            restrict: 'A',
            link: function(scope, elem, attrs){

                if($rootScope.isMobile && !attrs.forceMobile) return;

                $(elem).perfectScrollbar({
                    suppressScrollX: true,
                    wheelSpeed: attrs.wheelSpeed || 0.25
                });

                $timeout(function(){
                    $(elem).perfectScrollbar('update');
                });
            }
        };
    });
}(angular));

