import template from './subscribableCasesList.html';
import './subscribableCasesList.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('mySubscribableCasesList', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            cases: '=',
            showUser: '=',
            onSubscribed: '&',
            onSubscribedClicked: '&'
        },
        controller($scope, beatsService, beatBoxService, userService, helper) {

            $scope.loaders = {};

            $scope.getCaseSubscribePrice = (bCase) => {
                return beatsService.getSubscribeToBeatCasePrice(bCase.totalSongs);
            };

            $scope.subscribedClicked = (bCase) => {
                $scope.onSubscribedClicked({ bCase });
            }

            $scope.subscribeToCase = async (bCase) => {
                try {

                    if(bCase.subscribed || $scope.loaders[bCase._id]) return;

                    const cost = $scope.getCaseSubscribePrice(bCase);

                    if(!beatsService.userCanSpend(cost)){
                        return helper.gotoState('beatsInsufficient', {
                            reason: 'SubscribeToBeatCase'
                        });
                    }

                    $scope.loaders[bCase._id] = true;

                    await beatBoxService.subscribeToCase({ caseId: bCase._id, userId: userService.getUserId() });
                    await beatsService.purchaseHasBeenMade(beatsService.keys.subscribeToBeatCase);

                    for (const item of $scope.cases) {
                        if(item._id === bCase._id) {
                            item.subscribed = true;
                            item.subscribers = (item.subscribers || 0) + 1;
                        }
                    }

                    await beatBoxService.loadAllCases();
                    beatBoxService.resetState();
                    beatBoxService.setSelectedCase(bCase);

                    $scope.onSubscribed({ bCase });
                } catch (e) {
                    helper.error(e);
                }

                delete $scope.loaders[bCase._id];
            };
        }
    };
});
