import './musicMatchesPopup.less';

import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyMusicMatchesPopupCtrl', ($scope, musicMatchService, $uibModalInstance, userService,
                                           helper, userAlertService, analyticsService, $timeout) => {

    const user = userService.getUser();
    $scope.userMusicTags = (user.musicTags && user.musicTags.map(item => item.tag)) || [];
    $scope.maxTags = musicMatchService.getMaxTags();

    const search = async ()=> {
        const { data, lastOpen } = await musicMatchService.search() || {};

        $scope.matchedUsers = data;

        for (const matchedUser of $scope.matchedUsers) {
            let matches = 0;
            let isNew = false;
            for (const userMusicTag of matchedUser.musicTags) {

                const found = $scope.userMusicTags.find(item => {
                    return item._id === userMusicTag.tag._id;
                });

                if (found) {
                    userMusicTag.matched = true;
                    matches++;
                }

                isNew = !lastOpen || new Date(userMusicTag.addedAt).getTime() >= new Date(lastOpen).getTime();
            }

            matchedUser.isNew = matchedUser.isNew || isNew;

            if (matches >= 3) {
                matchedUser.superMatch = true;
            }
        }
    };

    $scope.onUserMatchRowClicked = (user) => {

        analyticsService.trackMusicMatchEvent({
            eventAction: 'MatchOpened'
        });

        helper.gotoState('user-modal', { userName: user.userName });
        $timeout(() => {
            $scope.close();
        });
    };

    $scope.onTagsChanged = async () => {
        try {
            $scope.loading = true;
            await search();
        } catch (e) {
            helper.error(e);
        }

        $scope.loading = false;
    };

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    (async () => {
        try {
            $scope.loading = true;
            await search();
            musicMatchService.clearNewMatches();
            userAlertService.resetLocally();
        } catch (e) {
            helper.error(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    })();

    analyticsService.trackMusicMatchEvent({
        eventAction: 'ModalOpened'
    });
});
