const WIKI_CHANGE_ACTIONS = {
    //removeSongTag: 'removeSongTag',
    updateSongTags: 'updateSongTags',
    updateSongMoods: 'updateSongMoods',
    updateSongLanguages: 'updateSongLanguages',
    updateSongInfo: 'updateSongInfo',
    updateSongLyrics: 'updateSongLyrics',
    updateName: 'updateName',
    updateArtist: 'updateArtist',
    updateAlbumTitle: 'updateAlbumTitle'
};

const WIKI_SONG_VALIDATION = {
    LYRICS_MIN_LENGTH: 100
}

module.exports = {
    WIKI_CHANGE_ACTIONS,
    WIKI_SONG_VALIDATION
};