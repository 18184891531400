
import angular from 'angular';

import './subscriptionBattery.less';
import template from './subscriptionBattery.html';

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('mySubscriptionBattery', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, userService, subscriptionService) {

            if (userService.hasSubscription()) {

                const { ratio, leftDays } = subscriptionService.getInfo(userService.getUser()?.subscription);
                $scope.ratio = ratio;

                switch (true) {
                    case $scope.ratio <= 25:
                        $scope.tooltip = `Shoot! ${leftDays} Days left for your subscription. Click to renew.`;
                        $scope.class = 'fa-battery-quarter';
                        break;
                    case $scope.ratio > 25 && $scope.ratio <= 50:
                        $scope.tooltip = `${leftDays} Days left for your subscription. Click to renew.`;
                        $scope.class = 'fa-battery-half';
                        break;
                    case $scope.ratio > 50 && $scope.ratio <= 75:
                        $scope.tooltip = `${leftDays} left for your subscription`;
                        $scope.class = 'fa-battery-three-quarters';
                        break;
                    case $scope.ratio > 75:
                        $scope.tooltip = `${leftDays} left for your subscription`;
                        $scope.class = 'fa-battery-full';
                        break;
                }
            } else {
                $scope.ratio = 0;
                $scope.tooltip = 'Click for unlimited powers!';
                $scope.class = 'fa-battery-empty';
            }
        }
    };
});
