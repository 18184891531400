import './userAlertsHolder.less';
import template from './userAlertsHolder.html';
import './userAlertRow/userAlertRow';
import moment from 'moment/moment';

if (MyGlobal.project.isMobile) {
    import('./userAlertsHolder_mobile.less');
}

((angular) => {

    const USER_ALERT_CLOSED_BY_USER_STORAGE_KEY = 'uaClosedByUser';
    const app = angular.module(MyGlobal.page.ngAppName);

    app.directive('myUserAlertsHolder', () => {
        return {
            restrict: 'E',
            template,
            scope: {},
            controller($scope, helper, userAlertService, $timeout, $rootScope,
                       storage, analyticsService) {

                const listSelector = '#userAlertsHolder .list';
                let scrollLocked = false;
                let hideTimer;

                $scope.noAlertsText = helper.getRandomFromArray([
                    `You're all caught up.`,
                    `Tumbleweed...`,
                    `Crickets...`,
                    `No alerts.`
                ]);

                $scope.close = () => {
                    $scope.show = false;
                };

                $scope.clearAll = async ($event) => {
                    try {
                        $event.preventDefault();
                        await userAlertService.deleteAll();
                        $scope.close();

                        analyticsService.trackUserAlertEvent({
                            eventAction: 'ClearAll'
                        });
                    } catch (e) {
                        helper.error(e);
                    }
                };

                $scope.$on('userAlerts:close', async () => {
                    $scope.close();
                });

                $scope.$on('userAlerts:reset', () => {
                    updateScroller();
                    $scope.pagination = null;
                });

                $scope.$on('userAlert:deleted', async () => {
                    try {
                        updateScroller();

                        // Edge-case: when user deleted all items but there are more in the server..
                        if (!$scope.alerts.length) {
                            $scope.pagination = null;
                            await load();
                        }
                    } catch (e) {
                        helper.error(e);
                    }
                });

                const updateScroller = () => {
                    $timeout(() => {
                        $(listSelector).perfectScrollbar('update');
                    });
                };

                const load = async ({ append,page } = { page: 1 }) => {

                    const { alerts, pagination } = await userAlertService.load({
                        page,
                        itemsPerPage: $rootScope.isMobile ? 20 : 10,
                        append
                    });

                    $scope.alerts = alerts;
                    $scope.pagination = pagination;
                };

                $scope.loadMore = async ($event)=> {

                    if ($event) {
                        $event.preventDefault();
                    }

                    if ($scope.loadingMore) return;

                    if (!$scope.pagination || $scope.pagination.page >= $scope.pagination.totalPages) {
                        return;
                    }

                    try {
                        $scope.loadingMore = true;
                        await load({
                            append: true,
                            page: $scope.pagination.page + 1
                        });
                    } catch(e) {
                        helper.error(e);
                    } finally {
                        delete $scope.loadingMore;
                        try { $scope.$digest(); } catch(e) {}
                    }
                };

                const stopHideTimer = ()=> {
                    if (hideTimer) {
                        $timeout.cancel(hideTimer);
                    }
                };

                $scope.$watch('alerts', () => {
                    updateScroller();
                    if (!$scope.alerts || !$scope.alerts.length) {
                        hideTimer = $timeout(() => {
                            $scope.close();
                        }, 4000);
                    } else if (hideTimer) {
                        stopHideTimer();
                    }
                });

                // $scope.$on('userAlert:added', () => {
                //     $scope.alerts = userAlertService.getLocalAlerts();
                //     $scope.show = !storage.getItem(USER_ALERT_CLOSED_BY_USER_STORAGE_KEY);
                // });

                $(listSelector).on('ps-y-reach-end', async (evt) => {

                    if(scrollLocked) return;
                    scrollLocked = true;

                    $timeout(() => {
                        scrollLocked = false;
                    }, 500);

                    try {
                        await $scope.loadMore();
                    } catch (e) {
                        helper.error(e);
                    }
                });

                $scope.$on('userAlerts:toggle', async (evt, { state } = {}) => {
                    try {

                        $scope.show = state || !$scope.show;

                        if ($scope.show) {
                            $scope.loading = true;
                            await load();
                            await userAlertService.markAllAsRead();
                            storage.removeItem(USER_ALERT_CLOSED_BY_USER_STORAGE_KEY);
                        } else {
                            storage.setForeverItem(USER_ALERT_CLOSED_BY_USER_STORAGE_KEY, true);
                            stopHideTimer();
                        }
                    } catch(e) {
                        helper.error(e);
                    } finally {
                        delete $scope.loading;
                        try { $scope.$digest(); } catch(e) {}
                    }
                });

                $scope.onClick = ($event) => {
                    $event.stopImmediatePropagation();
                };

                $scope.$on('mobileRightMenu:toggled', (evt, { state }) => {
                    if (!state) {
                        $scope.close();
                    }
                });

                $scope.$watch('show', () => {
                    $rootScope.$broadcast('userAlerts:toggled', { state: $scope.show });
                });
            }
        };
    });
})(angular);
