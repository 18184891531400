
import './chatInput.less';
import angular from 'angular';
import template from './chatInput.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myChatInput', (chatService, popupService, helper, $rootScope) => {
    return {
        restrict: 'E',
        template,
        scope: {
            chat: '=',
            showUnreadIndicator: '=',
            blocked: '=',
            replyTo: '=',
            placeholder: '=?',
            myTooltip: '=',
            myTooltipPlacement: '=',
            onSubmit: '&',
            onUnreadIndicatorClicked: '&',
            onClearReplyTo: '&'
        },
        link($scope, $element) {

            $scope.placeholder = $scope.placeholder || ($scope.blocked ? 'Chat is blocked' : 'Write right here');
            const inputElement = $($element).find('.chatInputHolder input');
            $scope.emojisElement = inputElement;

            const onInputBlur = () => {
                $scope.placeholder = chatService.getPlaceholder();
            };

            const appendToValue = (text) => {
                $scope.value = $scope.value ? `${$scope.value}${text}` : text;
            };

            $scope.$watch('replyTo', () => {
                if ($scope.replyTo) {
                    $scope.focus();
                }
            });

            $scope.focus = () => {
                inputElement.focus();
            };

            $scope.openGifs = ($event) => {
                const chatId = $scope.chat?.id
                popupService.openGifsPopup({
                    $event,
                    source: chatId ? 'privateChat' : 'chat',
                    id: chatId,
                    text: 'Chat it'
                });
            };

            $scope.mentionUserInChat = ({userName}) => {

                if (!$scope.value || !$scope.value.endsWith('@')) {
                    userName = `@${userName}`;

                    if ($scope.value) {
                        userName = ` ${userName}`;
                    }
                }

                appendToValue(userName + ' ');
                $scope.focus();
            };

            const key = $scope.chat?.id ? `chat:${$scope.chat.id}:mentionUser` : 'chat:mentionUser';

            $scope.$on(key, (evt, data) => {
                $scope.mentionUserInChat(data);
            });

            $scope.onKeyPress = async ($event) => {
                try {
                    if($event.keyCode !== 13) return;

                    const value = $scope.value;
                    if(!value) return;

                    $scope.onSubmit({ $event, value });
                    $scope.value = '';
                } catch (e) {
                    helper.error(e);
                }
            };

            $scope.onKeyUp = ($event) => {
                const { value } = $scope;
                $scope.showSuggestedUsers = !!( value === '@' || value?.endsWith(' @'));
            };

            $scope.closeSuggestedUsers = () => {
                delete $scope.showSuggestedUsers;
            };

            $scope.toggleEmojis = ($event)=> {
                $event.preventDefault();
                $event.stopImmediatePropagation();

                $scope.initEmojis = true;
                $scope.showEmojis = !$scope.showEmojis;
            };

            $scope.onEmojiSelected = (emoji)=> {
                appendToValue(emoji.unicode);
                $scope.focus();
                try { $scope.$digest(); } catch(e) {}
            };

            $scope.closeEmojis = ()=> {
                $scope.showEmojis = false;
                try { $scope.$digest(); } catch(e) {}
            };

            $scope.$on('$destroy', () => {
                inputElement.off('blur', onInputBlur);
            });

            inputElement.on('blur', onInputBlur);

            if (!$rootScope.isMobile) {
                $scope.focus();
            }
        }
    };
});
