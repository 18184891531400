import './profileWatchesPopup.less';

import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyProfileWatchesPopupCtrl', ($scope, $uibModalInstance, userService, helper, $timeout) => {

    $scope.subscribed = userService.hasSubscription();

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    (async () => {
        try {
            $scope.loading = true;

            if ($scope.subscribed) {
                $scope.watches = await userService.getProfileWatches();
            } else {
                const { count } = await userService.getProfileWatchesCount();
                $scope.watchesCount = count;
            }

        } catch (e) {
            helper.error(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    })();
});
