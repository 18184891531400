/**
 * Created by Shlomi on 29/09/2014.
 */

import template from './userAlertRow.html';

((angular) => {

    const app = angular.module(MyGlobal.page.ngAppName);

    app.directive('myUserAlertRow', () => {
        return {
            restrict: 'E',
            template,
            scope: {
                alert: '=',
                temporary: '='
            },
            controller($scope, $rootScope, helper, userAlertService, $timeout) {

                let timer;
                const { iconClass, image, actions } = userAlertService.getAlertSettings($scope.alert);

                $scope.userAlertService = userAlertService;
                $scope.iconClass = iconClass;
                $scope.image = image;
                $scope.actions = actions;

                $scope.delete = async (options = {}) => {
                    try {
                        $scope.loading = true;
                        await userAlertService.delete($scope.alert, options);
                    } catch (e) {
                        $rootScope.showError(e);
                    }

                    $scope.loading = false;
                };

                if ($scope.alert.ttl) {

                    $scope.$on('$destroy', function() {
                        $timeout.cancel(timer);
                    });

                    timer = $timeout(async () => {
                        try {
                            await $scope.delete({ local: true });
                        } catch (e) {
                            helper.error(e);
                        }
                    }, $scope.alert.ttl);
                }
            }
        };
    });
})(angular);
