import './subscriptionPopup.less';
import './subscription.service';

import angular from 'angular';
import {PAYMENT_TYPES} from '../../../../../shared/consts';

if (MyGlobal.project.isMobile) {
    import('./subscriptionPopup_mobile.less');
}

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MySubscriptionPopupCtrl', ($scope, $uibModalInstance, $stateParams, userService, analyticsService,
                                           subscriptionService, helper, beatsService, popupService) => {

    $scope.userHasActiveSubscription = userService.hasSubscription();

    if ($scope.userHasActiveSubscription) {
        const { leftDays } = subscriptionService.getInfo(userService.getUser()?.subscription);
        $scope.subscriptionLeftDays = leftDays;
    }

    $scope.onSubscriptionSelected = (subscription) => {

        popupService.openPaymentPopup({
            type: PAYMENT_TYPES.SUBSCRIPTION,
            data: {
                subscription
            }
        });

        if ($stateParams.ref === 'contribution'){
            analyticsService.trackContributionEvent({
                eventAction: 'monthlyContribution',
                eventLabel: 'Subscription'
            });
        }
    };

    $scope.close = ($event) => {
        if ($event) $event.stopImmediatePropagation();
        $uibModalInstance.dismiss();
    };

    (async () => {
        try {
            $scope.loading = true;

            $scope.features = beatsService.getFeatures()?.filter(item => {
                return item.freeOnSubscription;
            });
            $scope.subscriptions = (await subscriptionService.getSubscriptions()).reverse();
        } catch(e) {
            helper.error(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    })();

    analyticsService.trackBeatsEvent({
        eventAction: 'SubscriptionModalOpened'
    });

});
