import angular from 'angular';
import { load } from 'recaptcha-v3';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('recaptchaService', (config) => {
    return {
        ACTIONS: {
            REGISTRATION_CODE: 'registrationCode',
            REGISTER: 'register',
            CLAIM_BEATS_REWARD: 'claimBeatsReward'
        },
        async generateToken({ action }) {
            const recaptcha = await load(config.getRecaptchaSiteKey(), {
                useRecaptchaNet: true
            });

            if (!recaptcha) {
                throw new Error('Missing recaptcha by sitekey');
            }

            return await recaptcha.execute(action);
        }
    }
});