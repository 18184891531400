const LanguageList = require('language-list');

// The package has it inside their GitHub repo but they didn't publish a new version, adding mossing languages here.
const oldGetData = LanguageList.prototype.getData;
LanguageList.prototype.getData = () => {
    const data = oldGetData.call(LanguageList.prototype);
    return [{
        language:'Ukrainian',
        code:'uk'
    }, ...data].sort((a, b) => {
        return a.code > b.code ? 1 : -1;
    });
};

module.exports = LanguageList;