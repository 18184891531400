import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('musicMatchService', (httpService, config, storage, helper, $rootScope) => {

    const storageKey = 'musicMatches';

    return {
        async loadNewMatches() {
            if(!storage.has(storageKey)) {
                const total = await httpService.get('/user/musicMatch/new');
                $rootScope.hasNewMatches = !!total;
                storage.setItem(storageKey, total, helper.hoursToMs(3));
            } else {
                $rootScope.hasNewMatches = storage.getItem(storageKey);
            }
        },
        clearNewMatches() {
            $rootScope.hasNewMatches = false;
            storage.setItem(storageKey, 0);
        },
        getMaxTags() {
            return config.getMusicMatchSettings()?.maxTags;
        },
        async updateMyMusicTags(tags) {
            return httpService.post('/user/tags', tags);
        },
        async search() {
            return httpService.get('/user/musicMatch');
        }
    };
});
