/**
 * Created by Shlomi on 28/12/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('pageLoadManager', function(helper, storage){

        // Private variables
        var currAction;
        var cookieKey = 'pla';
        var rootScopeEventPrefix = 'onPageLoad';

        return {
            events: {
                GOTO_STATE: rootScopeEventPrefix + ':gotoState'
            },
            setCurrAction: function(params){
                if(typeof params === 'string') params = {name: params};
                currAction = params;
            },
            activateCurrAction: function(){
                currAction && storage.setItem(cookieKey, currAction, helper.hoursToMs(1));
            },
            getCurrAction: function(){
                return storage.getItem(cookieKey, {jsonDecode: true});
            },
            deleteCurrAction: function(){
                currAction = null;
                storage.removeItem(cookieKey);
            },
            performCurrAction: function($rootScope){
                var currAction = this.getCurrAction();
                if(currAction && currAction.name){
                    var actionName = currAction.name;
                    delete currAction.name;
                    $rootScope.$broadcast(actionName, currAction);

                    // Clear all
                    this.deleteCurrAction();
                }
            }
        };
    });

}(angular));
