
import './switch.less';

import angular from 'angular';

import template from './switch.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('mySwitch', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            inline: '=',
            label: '=',
            value: '=?',
            rightText: '=?',
            rightLabel: '=?',
            rightValue: '=?',
            leftText: '=?',
            leftLabel: '=?',
            leftValue: '=?',
            disabled: '=',
            onChange: '&'
        },
        controller($scope) {

            if (typeof $scope.rightText === 'undefined') {
                $scope.rightText = 'ON';
            }

            if (typeof $scope.rightValue === 'undefined') {
                $scope.rightValue = true;
            }

            if (typeof $scope.leftText === 'undefined') {
                $scope.leftText = 'OFF';
            }

            if (typeof $scope.leftValue === 'undefined') {
                $scope.leftValue = false;
            }

            if (typeof $scope.value === 'undefined') {
                $scope.value = false;
            }

            $scope.onChangeInt = (newValue) => {
                if ($scope.disabled) return;
                if ($scope.value !== newValue) {
                    $scope.onChange({ newValue });
                }
            };
        }
    };
});
