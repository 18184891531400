import angular from 'angular';
import { GiphyFetch } from '@giphy/js-fetch-api';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('gifsService', (config) => {

    const giphy = new GiphyFetch(config.getGiphyApiKey());

    return {
        async trending(options) {
            return giphy.trending({ ...options, rating: 'g' })
        },
        async search(term, params) {
            return giphy.search(term, params);
        }
    };
});
