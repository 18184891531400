import angular from 'angular';
import './connectCaseToRoomPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyConnectCaseToRoomPopupCtrl', ($scope, $stateParams, $rootScope, $uibModalInstance, popupParams,
                                                beatBoxService, $timeout, helper) => {

    const { bCase, room } = popupParams;

    $scope.bCase = bCase;
    $scope.room = room;

    $scope.connect = async () => {

        if ($scope.loading) return;

        try {
            $scope.loading = true;
            await beatBoxService.toggleConnectCaseToRoom({
                caseId: bCase._id,
                roomId: room._id
            });
            $rootScope.$broadcast('case:connectedToRoom', bCase._id);
            $rootScope.showNotification(`${bCase.name} is now connected with that Station!`);
            $scope.close();
        } catch(e) {
            helper.error(e);
            $rootScope.showError(e);
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    }

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    $timeout(() => {
        if (!bCase?._id || !room?._id) {
            $scope.close();
        }
    })
});
