import angular from 'angular';
import './changeUserCountryPopup.less';
import sharedHelper from '../../../../../shared/sharedHelper';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyChangeUserCountryPopupCtrl', ($scope, $uibModalInstance, userService, $timeout, beatsService,
                                                helper) => {

    const countriesList = sharedHelper.getCountryNames();
    const userCountryCode = userService.getCountryCode();
    const userOrigCountryCode = userService.getOrigCountryCode();

    $scope.beatsService = beatsService;
    $scope.userCurrCountry = userCountryCode && {
        code: userCountryCode,
        name: sharedHelper.getCountryNameByCode(userCountryCode)
    };

    $scope.search = {};

    $scope.onSearch = ($event) => {
        if ($event.keyCode === 13) return;

        if($scope.search?.val) {
            $scope.countries = countriesList.filter(item => {
                return item.name.toLowerCase().startsWith($scope.search.val.toLowerCase());
            });
        } else {
            $scope.countries = [];
        }
    };

    $scope.onCountrySelected = (country) => {
        // re-render UI kombina
        delete $scope.selectedCountry;
        $timeout(() => {
            $scope.selectedCountry = country;
            $scope.freeChange = $scope.selectedCountry.code === userOrigCountryCode;
        });
    };

    $scope.changeCountry = async ($event, country) => {
        $event.preventDefault();
        if ($scope.loading) return;

        if(!beatsService.userCanBuy(beatsService.keys.changeUserCountry)){
            return helper.gotoState('beatsInsufficient', {
                reason: 'ChangeCountry'
            });
        }

        try {
            $scope.loading = true;
            await userService.changeCountry(country.code);

            if (!$scope.freeChange) {
                await beatsService.purchaseHasBeenMade(beatsService.keys.changeUserCountry);
            }

            $scope.close();
        } catch(e) {
            helper.error(e);
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    }

    $scope.close = function () {
        $uibModalInstance.dismiss();
    };

    $timeout(() => {
        $('#changeUserCountryPopup .searchCountry').focus();
    })
});
