const STREAMING_EVENTS = {
    WATCHER: 'streaming:watcher',
    ANSWER: 'streaming:answer',
    CANDIDATE: 'streaming:candidate',
    DISCONNECT_PEER: 'streaming:disconnectPeer',
    OFFER: 'streaming:offer',
    BROADCASTER: 'streaming:broadcaster',
    STOP_BROADCAST: 'streaming:broadcast:stop',
    // Custom
    WATCHERS_CHANGED: 'streaming:watchers:changed',
    BROADCASTER_MUTE_TOGGLE: 'streaming:broadcaster:mute:toggle',
    WATCHER_ERROR: 'streaming:watcher:error',
    CANDIDATE_CONNECTED: 'streaming:candidate:connected'
};

module.exports = {
    STREAMING_EVENTS
};