import 'bootstrap/dist/css/bootstrap.css';
import 'perfect-scrollbar/dist/css/perfect-scrollbar.css';
import 'intro.js/introjs.css';
import 'components-jqueryui/themes/smoothness/jquery-ui.css';
import 'ng-tags-input/build/ng-tags-input.css';

import '../../less/fonts.less';
import '../../less/animations.less';
import '../../less/general.less';
import '../../less/updateInvalidEmail.less';
import '../../less/buddiesList.less';
import '../../less/stickySongModal.less';
import '../../less/roomThemeManagement.less';
import '../../less/ranks.less';
import '../../less/topMenu.less';
import '../../less/searchBox.less';
import '../../less/emoji.less';
import '../../less/addSongToBeatBoxMenu.less';

if(window?.MyGlobal?.project?.isMobile){
    import('../../less/mobile.less');
}
