/**
 * Created by Shlomi on 06/02/2016.
 */

/**
 * Created by Shlomi on 26/10/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('BeatsExplanationModalCtrl', function ($scope, helper, $uibModalInstance, beatsService) {

        $scope.features = beatsService.getFeatures(true);

        $scope.closeAndGoToBeatsPurchaseModal = function(){
            helper.trackBeatsEvent('PurchaseModalOpened', 'ExplanationModal');
            $scope.close();
        };

        $scope.close = function(){
            $uibModalInstance.dismiss();
        };
    });

}(angular));


