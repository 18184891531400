import angular from 'angular';
import './shareButton.less';
import template from './shareButton.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myShareButton', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            entityType: '=',
            entityData: '=',
            text: '=',
            title: '=',
            tooltip: '='
        },
        controller($scope, shareService, popupService, $rootScope) {
            $scope.onClick = ($event) => {

                if ($event) {
                    $event.stopImmediatePropagation();
                }

                popupService.openSharePopup({
                    entityType: $scope.entityType,
                    entityData: $scope.entityData,
                    text: $scope.text,
                    title: $scope.title || `Join me @ ${$rootScope.projectName}`
                });
            };
        }
    };
});
