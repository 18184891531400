
import './musicMatchTags.less';

import angular from 'angular';

import './musicMatchTags.service';
import template from './musicMatchTags.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myMusicMatchTags', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            model: '=',
            placeholder: '=?',
            searchAll: '=',
            maxTags: '=?',
            onChange: '&'
        },
        controller($scope, $rootScope, helper, musicMatchService, userService, analyticsService) {

            $scope.maxTags = $scope.maxTags || musicMatchService.getMaxTags();
            $scope.placeholder = $scope.placeholder || `Select up to ${$scope.maxTags} favorite genres`;
            $scope.tags = [];

            const save = async () => {
                const tags = await musicMatchService.updateMyMusicTags($scope.tags.map(item => item._id));
                userService.updateMusicTags(tags);
            };

            $scope.updateTagsList = async (tags) => {
                try {
                    $scope.tags = tags;
                    await save();
                    $scope.onChange({ tags });

                    analyticsService.trackMusicMatchEvent({
                        eventAction: 'TagsUpdated'
                    });

                } catch (e) {
                    helper.error(e);
                }
            };

            $scope.validateLength = () => {
                return $scope.tags.length + 1 <= $scope.maxTags;
            };
        }
    };
});
