/**
 * Created by Shlomi on 29/09/2014.
 */

import template from '../../templates/partials/notification.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myNotification', function() {
        return {
            restrict: 'E',
            template,
            scope: {},
            controller: function($scope, $rootScope, $timeout){
                var notificationTimerId;

                var _defaultHideDuration = 10000; // in ms
                let action;
                
                $scope.showNotification = $rootScope.showNotification = function(message, { permanent, onClick, button, digest } = {}){

                    if(notificationTimerId){
                        $timeout.cancel(notificationTimerId);
                    }

                    $rootScope.hideError();
                    $scope.hideNotification();

                    action = onClick;
                    $scope.notificationMessage = message;
                    $scope.showNotificationAlert = true;
                    $scope.button = button;
                    $scope.permanent = permanent;

                    if(permanent) return;
                    
                    notificationTimerId = $timeout(function(){
                        $scope.hideNotification(true);
                    }, _defaultHideDuration);

                    if (digest) {
                        try { $rootScope.$digest(); } catch(e) {}
                    }
                };

                $scope.onClick = () => {

                    if($scope.permanent) return;

                    if (typeof action === 'function') {
                        action();
                    }
                    $scope.hideNotification();
                };

                $scope.hideNotification = $rootScope.hideNotification = function(applyAfter){
                    $scope.showNotificationAlert = false;
                    $scope.notificationMessage = '';
                };

                $scope.$on('showNotification', $scope.showNotification);
            }
        };
    });
}(angular));
