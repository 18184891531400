/**
 * Created by Shlomi on 06/02/2016.
 */

/**
 * Created by Shlomi on 26/10/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('BeatsPostPurchaseSuccessModalCtrl', function ($scope, helper, $uibModalInstance,
                                                                  purchaseInfo, beatsService, analyticsService) {

        $scope.balance = beatsService.getBalance();
        $scope.purchaseInfo = purchaseInfo;

        $scope.close = function(){
            $uibModalInstance.dismiss();
        };

        // Send GA event
        if($scope.purchaseInfo){

            const action = 'BeatsPackagePurchasedSuccessfully';
            const { package: label, amount } = $scope.purchaseInfo;

            if(action && label){
                analyticsService.trackBeatsEvent({
                    eventAction: action,
                    eventLabel: label,
                    eventValue: parseFloat(amount)
                });
            }

        }
    });

}(angular));


