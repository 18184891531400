import angular from 'angular';
import './chatMessageInputReply.less';
import template from './chatMessageInputReply.html';
import {CHAT_MESSAGE_TYPES} from '../../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myChatMessageInputReply', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            user: '=',
            type: '=',
            content: '=',
            onClose: '&'
        },
        controller($scope) {
            $scope.CHAT_MESSAGE_TYPES = CHAT_MESSAGE_TYPES;
        }
    };
});
