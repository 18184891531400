import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('contributionService', (config, httpService) => {

    return {
        async generatePaymentToken(){
            return await httpService.post('/contribution/generatePaymentToken', {
                origin: location.pathname
            });
        },
        async contributeBeats() {
            return await httpService.post('/contribution/beats');
        }
    };
});
