import angular from 'angular';
import './mobileCurrSongControls.less';
import template from './mobileCurrSongControls.html';

import {SHARE_ENTITY_TYPES} from '../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myMobileCurrSongControls', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, $rootScope, userSongService, helper) {
            $scope.userSongService = userSongService;
            $scope.SHARE_ENTITY_TYPES = SHARE_ENTITY_TYPES;
            $scope.skipDisabledMessage = `Skipping others is allowed for BeatSters with score of at least ${userSongService.getMinScoreToSkip()}`;
            $scope.pausedDisabledMessage = `Pausing is restricted to room owner`;

            $scope.onRoomPauseClicked = function ($evt) {
                $evt.preventDefault();

                if (!$rootScope.isRoomModerator && !$rootScope.isAdmin) {
                    $rootScope.showError($scope.pausedDisabledMessage);
                    return;
                }

                if($rootScope.isRoomModerator || $rootScope.isAdmin){
                    helper.gotoState('pause');
                } else if($rootScope.room.station) {
                    helper.gotoState('create-room');
                }
            };

            $scope.skipCurrSong = ()=> {

                if (!userSongService.isSkipEnabled()) {
                    $rootScope.showError($scope.skipDisabledMessage);
                    return;
                }

                $rootScope.skipCurrSong();
            }
        }
    };
});
