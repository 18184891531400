/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('musicTagService', function($q, $http, httpService){

        return {
            getItems: function(page, query){
                var defer = $q.defer();

                var url = '/musicTag/all?p='+(page || 1)+'&admin=1';

                if(query){
                    url += '&q='+query;
                }

                $http.get(url).success(defer.resolve).error(defer.reject);
                return defer.promise;
            },
            getFew: function(ids){

                if(!ids || !ids.length) return;

                var defer = $q.defer();

                var url = '/musicTag/few';

                $http.post(url, {ids: ids}).success(defer.resolve).error(defer.reject);
                return defer.promise;
            },
            async searchItems(query, { ignore, all } = {}){

                let url = `/musicTag/search?q=${query}`;

                if (ignore) {
                    url += `&ignore=${ignore}`;
                }

                if (all) {
                    url += `&all=1`;
                }

                return httpService.get(url);
            },
            deleteItem: function (id) {
                var defer = $q.defer();
                $http.delete('/musicTag/'+id).success(defer.resolve).error(defer.reject);
                return defer.promise;
            },
            addItem: function (params) {
                var defer = $q.defer();
                $http.post('/musicTag', params).success(defer.resolve).error(defer.reject);
                return defer.promise;
            },
            updateItem: function (params) {
                var defer = $q.defer();
                $http.put('/musicTag', params).success(defer.resolve).error(defer.reject);
                return defer.promise;
            }
        };
    });

}(angular));
