/**
 * Created by Shlomi on 26/10/2014.
 */

import {USE_INNER_PLAYER} from "../../../../shared/consts";

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('StickySongModalCtrl', function ($scope, $rootScope, $uibModalInstance, $http,
                                                    helper, MyMusicPlayer, YoutubePlayer, $state, songData,
                                                    songService, userService, $timeout) {

        var youtubePlayer;

        $rootScope.loadInitialRoomPlayerCued = true;

        $scope.close = function(){
            if(youtubePlayer) youtubePlayer.dispose();
            $uibModalInstance.close();
        };

        if(!songData.id){
            helper.log('Song id does not provided');
            return $scope.close();
        }

        $scope.$on('document:keypress:enter', function(){
            $scope.close();
        });

        $scope.$on('currSong:updated', function(evt, currSong){
            $scope.currSong = currSong;
        });

        const loadSong = async () => {

            const data = await songService.getByYouTubeId({ youtubeId: songData.id, roomId: $rootScope.room?._id });

            $scope.song = data.song;

            // Show the player
            if (USE_INNER_PLAYER) {
                youtubePlayer = new MyMusicPlayer();
            } else {
                youtubePlayer = new YoutubePlayer();
            }

            await youtubePlayer.init({
                playerId: 'song-preview-player',
                width: '100%',
                height: 329
            });

            $rootScope.$broadcast('player:mute:artificially', true);

            youtubePlayer.addEventListener('onStateChange', function(event){

                const { states, data } = event;

                switch (data) {
                    case states.ENDED:
                        helper.debug('Sticky modal player has ended, closing');
                        $scope.close();
                        break;
                    default:
                    // NO-OP
                }
            });

            await youtubePlayer.loadVideoById($scope.song.youtubeId);
            await youtubePlayer.unMute();
        };

        helper.trackGaUIEvent('StickySongModalOpened', {
            id: songData.id
        });

        $timeout(async () => {
            try{
                $scope.loadingSong = true;
                await loadSong();
            }catch(e){
                $scope.error = e?.message || 'Unknown error';
            } finally {
                $scope.loadingSong = false;
                try { $scope.$digest(); } catch(e) {}
            }
        })
    });

}(angular));

