/**
 * Created by Oren on 26/04/2020.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('RanksCtrl', function ($scope, $uibModalInstance, helper, data) {
        $scope.ranks = helper.getRank();
        $scope.userRank = helper.getRank( parseInt(data.score) );

        helper.trackGaUIEvent("RanksModalOpened");

        $scope.close = function(){
            $uibModalInstance.close();
        };
    });

}(angular));

