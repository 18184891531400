
import '../../components/shared/loginOptions/loginOptions';
import { REGISTRATION_CODE_LENGTH, REGISTRATION_CODE_TTL } from '../../../../shared/consts';

import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('RegisterCtrl', function ($scope, $rootScope, $uibModalInstance, $http, params, config,
                                         helper, $state, pageLoadManager, $timeout, analyticsService,
                                         userService) {

    // TODO: ui-router not allowed to move params without use the URL, this is workaround meantime. (UPDATE: it is, refactor this code)
    $scope.message = MyGlobal.temp.popupParams && MyGlobal.temp.popupParams.message ? MyGlobal.temp.popupParams.message : '';
    $scope.isInvalid = {};
    $scope.loader = {};
    $scope.params = params;
    $scope.registerParams = {};
    $scope.tosApproved = {};

    let codeTimer;

    const stopCodeTimer = ()=> {
        if (codeTimer) {
            $timeout.cancel(codeTimer);
            codeTimer = null;
        }
    };

    const resetCodeVars = ()=> {
        $scope.error = '';
        $scope.codeExpired = false;
    }

    const focusCodeInput = ()=> {
        $timeout(() => {
            $('#txtCode').focus();
        });
    };

    $scope.isFormValid = ()=> {
        return !Object.values($scope.isInvalid).find(item => !!item);
    };

    $scope.sendRegistrationCode = async ({ email, resend }, $event)=> {

        if (!$scope.isFormValid()) {
            return;
        }

        if ($event) {
            $event.preventDefault();
        }

        if (!$scope.tosApproved.checked) {
            $scope.error = 'Our terms of use should be accepted first';
            return;
        }

        const loaderType = resend ? 'register' : 'code';

        try {
            stopCodeTimer();

            resetCodeVars();
            $scope.loader[loaderType] = true;

            await userService.sendRegistrationCode({ email });
            $scope.codeRequested = true;
            $scope.codeResent = resend;

            codeTimer = $timeout( ()=> {
                $scope.codeExpired = true;
                $scope.error = '';
            }, REGISTRATION_CODE_TTL*1000);

            focusCodeInput();

        } catch(e) {
            $scope.error = e.message;
            helper.error(e);
        } finally {
            $scope.loader[loaderType] = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.onCodeKeyup = async ()=> {
        try {
            $scope.error = '';
            if ($scope.registerParams.code && `${$scope.registerParams.code}`.length === REGISTRATION_CODE_LENGTH) {
                await register();
            }
        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.resetErrors = (field)=> {
        $scope.error = '';

        if (field) {
            $scope.isInvalid[field] = '';
        }
    };

    $scope.cancelCode = ($event)=> {
        if ($event) {
            $event.preventDefault();
        }

        $scope.codeRequested = false;
        resetCodeVars();
        stopCodeTimer();
    };

    $scope.onSocialLoginError = (error) => {
        $scope.error = error.message;
        $scope.showRegisterForm = false;
    };

    const register = async () => {
        try {
            const { userName } = $scope.registerParams;

            if (!$scope.tosApproved.checked) {
                $scope.error = 'Our terms of use should be accepted first';
                return;
            }

            if(!helper.isUsernameLongEnough(userName)){
                return $scope.error = helper.userNameValidation.messages.min;
            }

            if(!helper.isUsernameShortEnough(userName)){
                return $scope.error = helper.userNameValidation.messages.max
            }

            if(!helper.isValidUsername(userName)){
                return $scope.error = helper.userNameValidation.messages.invalid;
            }

            if(!helper.isValidEmail($scope.registerParams.email)){
                return $scope.error = helper.emailValidation.messages.invalid;
            }

            if(!helper.isValidPassword($scope.registerParams.password)){
                return $scope.error = helper.passwordValidation.messages.min;
            }

            const params = $scope.registerParams;

            // In case the page is invitation page and the user need to login - so pass it to login and automatically approve the invitation after the user will log in successfully on server side.
            if(MyGlobal.page.t && MyGlobal.page.h){
                params.t = MyGlobal.page.t;
                params.h = MyGlobal.page.h;
            }

            $scope.loader.register = true;
            const regRes = await userService.register(params);

            await analyticsService.trackUIEventP({
                eventAction: 'UserSuccessfullyJoined',
                eventLabel: {method: 'regular'}
            });

            $scope.loader.register = false;

            // Activate action may settled by pageLoadManager
            pageLoadManager.activateCurrAction();

            helper.postLogin({
                isNewUser: true,
                popupParams: MyGlobal.temp.popupParams,
                ...regRes
            });
        } catch(e) {

            $scope.loader.register = false;
            $scope.isInvalid = {};

            if(e.message === 'banned'){
                return helper.reloadPage();
            }

            switch(e.message) {
                case 'Wrong verification code':
                    focusCodeInput();
                    break;
                case 'Code has expired':
                    $timeout(()=>{
                        $scope.codeRequested = false;
                        resetCodeVars();
                    }, 2000);
                    break;
            }

            $scope.error = e.message;
        }
    };

    $scope.validateEmailOnBlur = async (email)=> {
        try {
            if (!email) return;

            const what = 'email';

            if(!helper.isValidEmail(email)){
                $scope.isInvalid[what] = helper.emailValidation.messages.invalid;
                return;
            }

            await $scope.onInputNeedCheckIfExist(what, email);
        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.onInputNeedCheckIfExist = async (what, value) => {
        try {

            if(what && value){
                const res = await userService.checkIfExists(what, value);
                $scope.isInvalid[what] = res.exists ? res.message : false;
            }

        } catch(e) {
            $scope.error = e.message;
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.showLoginPopup = function(){
        $scope.close('login');
    };

    $scope.close = function(what){
        $uibModalInstance.close(what);
    };

    $scope.showRegisterFormFn = () => {
        $scope.showRegisterForm = !$scope.showRegisterForm

        if ($scope.showRegisterForm) {
            $timeout(() => {
                $('#txtUsername').focus();
            });
        }
    };

    helper.trackGaUIEvent('JoinModalOpened');
});

