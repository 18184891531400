import './roomAccessRequestPopup.less';

import angular from 'angular';

import { ROOM_ACCESS_REQUEST_MIN_BEATS } from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyRoomAccessRequestPopupCtrl', ($scope, $uibModalInstance, popupParams, roomService,
                                                $rootScope, helper, beatsService, analyticsService) => {

    const { room } = popupParams;
    $scope.room = room;
    $scope.minBeatsToRequest = ROOM_ACCESS_REQUEST_MIN_BEATS;

    beatsService.promise.then(()=> {
        $scope.balance = beatsService.getBalance();
        $scope.notEnoughBeats = beatsService.getBalance() < $scope.minBeatsToRequest;
    });

    $scope.submit = async ()=> {
        try {

            if ($scope.loading) return;

            if(!helper.isUserLoggedIn()){
                helper.gotoState('join', {
                    message: 'Sign up to join the room'
                });
                return;
            }

            if (!beatsService.userCanSpend($scope.room.entranceFee)) {
                return helper.gotoState('beatsInsufficient', {
                    reason: 'requestRoomAccess'
                });
            }

            $scope.loading = true;

            await roomService.requestAccess({ roomId: $scope.room._id });

            $scope.close();
            $rootScope.showNotification('Your request sent. You will receive a notification once approved.');

            analyticsService.trackUIEvent({
                eventAction: 'userRequestToJoinARoom',
                eventLabel: $scope.room.entranceFee
            });

        } catch(e) {
            $rootScope.showError(e.message);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };
});
