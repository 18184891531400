
import angular from 'angular';

import './songsCarousel.less';
import template from './songsCarousel.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('mySongsCarousel', (helper) => {
    return {
        restrict: 'E',
        template,
        scope: {
            songs: '=',
            visibleElements: '=',
            openWiki: '='
        },
        controller($scope, songService) {
            $scope.onSongClicked = ($event, song) => {
                $event.preventDefault();

                if ($scope.openWiki) {
                    helper.redirectToWiki(songService.fromYoutubeSong(song), { newPage: true });
                } else {
                    helper.gotoState('song-modal', {
                        id: song.id,
                        type: 'ytap',
                        name: songService.getSongName(song)
                    });
                }
            }
        },
        link($scope, $element){

            let currScroll = 1;

            const getTotalScrolls = () => {
                return Math.ceil($scope.songs?.length / $scope.visibleElements);
            };

            // if( getTotalScrolls() ){ //if there is a scroll, do not center the elements
            //     $timeout(() => {
            //         $($element).find( '.results' ).removeClass( "centered" );
            //     },1000);
            // }

            $scope.resetScroll = function(){
                $($element).find('.scroller').css('left', 0);
            };

            $scope.showNextButton = function(){
                return currScroll < getTotalScrolls();
            };

            $scope.showPrevButton = function(){
                return currScroll > 1;
            };

            $scope.onScroll = function($event, direction){
                $event.preventDefault();

                if ($scope.scrollLocked) return;

                if(direction === 'next'){
                    currScroll++;
                }else if(direction === 'prev'){
                    currScroll--;
                }

                if (currScroll < 1) {
                    currScroll = 1;
                    return;
                }

                if (currScroll > getTotalScrolls()) {
                    currScroll = getTotalScrolls();
                    return;
                }

                const scroller = $($element).find('.results');
                const resultElementWidth = scroller.find('.result').outerWidth(true);
                const currLeft = scroller.position().left;
                const scrollAreaWidth = resultElementWidth*$scope.visibleElements;
                const isOneScrollBeforeTheLast = currScroll  === getTotalScrolls();
                const bunchOfSongsWidth = getTotalScrolls() > 2 || !isOneScrollBeforeTheLast ? scrollAreaWidth : (scrollAreaWidth / $scope.visibleElements) * (parseInt($scope.songs.length)%4);

                let newLeft;

                if(direction === 'next'){
                    newLeft = currLeft - bunchOfSongsWidth;
                }else if(direction === 'prev'){
                    const isLastScrollOutOfTwo = getTotalScrolls() === 2 && currScroll + 1  === getTotalScrolls(); //fix to edge case
                    newLeft = isLastScrollOutOfTwo ? 0 : currLeft + bunchOfSongsWidth;
                }

                const nextThreshold = (resultElementWidth*$scope.songs.length-1);

                // Check limits
                if(Math.abs(newLeft) > nextThreshold || newLeft > 0) return;

                // Animate to new position
                if(!isNaN(newLeft)){
                    $scope.scrollLocked = true;
                    scroller.animate({
                        left: newLeft
                    }, {
                        complete: function(){
                            $scope.scrollLocked = false;
                            helper.trackGaUIEvent('LoadSimilarSongs_ClickedNext');
                        }
                    });
                }
            };

        }
    };
});
