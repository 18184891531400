/**
 * Created by Shlomi on 22/04/2015.
 */

import musicMatchesPopupTpl from '../components/popups/musicMatchesPopup/musicMatchesPopup.html';
import createRoomPopupTpl from '../components/popups/createRoomPopup/createRoomPopup.html';
import editRoomPopupTpl from '../components/popups/editRoomPopup/editRoomPopup.html';
import subscriptionPopupTpl from '../components/popups/subscriptionPopup/subscriptionPopup.html';
import subscriptionPurchasePopupTpl from '../components/popups/subscriptionPurchasePopup/subscriptionPurchasePopup.html';
import roomsPopupTpl from '../components/popups/roomsPopup/roomsPopup.html';
import contributionPopupTpl from '../components/popups/contributionPopup/contributionPopup.html';
import feedDropPopupTpl from '../components/popups/feedDropPopup/feedDropPopup.html';
import beatzonePopupTpl from '../components/popups/beatzonePopup/beatzonePopup.html';
import profileWatchesPopupTpl from '../components/popups/profileWatchesPopup/profileWatchesPopup.html';
import usersPopupTpl from '../components/popups/usersPopup/usersPopup.html';
import stationsPopupTpl from '../components/popups/stationsPopup/stationsPopup.html';
import redirectToPlatformPopupTpl from '../components/popups/redirectToPlatform/redirectToPlatform.html';
import ambassadorProgramPopup from '../components/popups/ambassadorProgramPopup/ambassadorProgramPopup.html';

(function(angular) {
    var app = angular.module(MyGlobal.page.ngAppName);

    app.config(function ($stateProvider) {

        $stateProvider.state('default', {
            url: '/'
        }).state('user-modal', {
            url: '/user/:userName',
            params: { ignoreStack: null },
            onEnter: ($stateParams, helper, $rootScope, roomService, slidingWindowService) => {
                const { userName, ignoreStack } = $stateParams;

                if ($rootScope.isHomePage && !$rootScope.isStations) {
                    $rootScope.$broadcast('currUserProfile:changed', { userName, ignoreStack });
                } else {
                    slidingWindowService.open({
                        type: slidingWindowService.types.USER_PROFILE,
                        resolves: [
                            $rootScope.isRoomPage ? roomService.promise : Promise.resolve()
                        ],
                        data: {
                            userName
                        }
                    });
                }
            }
        })
        .state('songModalOld', {
            url: '/song/:id/:type/:name',
            onEnter: function($stateParams, $state, $uibModal, $uibModalStack, $rootScope, $q, helper) {
                helper.gotoState('song-modal', {
                    ...$stateParams
                });
            }
        })
        .state('song-modal', {
            url: '/song/:id/:type',
            params: { name: null, duration: null, userBeatBoxCase: null },
            onEnter: function($stateParams, $state, $uibModal, $uibModalStack, $rootScope, $q, helper) {

                $uibModalStack.dismissAll();

                var params;
                var stickySongMode = $stateParams.type === 'sticky';

                if(!stickySongMode){
                    params = {
                        templateUrl: '/static/templates/partials/songModal.html',
                        controller: 'SongModalCtrl',
                        windowClass: 'song-modal light',
                        resolve: {
                            songData: function(){
                                return {
                                    id: $stateParams.id,
                                    type: $stateParams.type,
                                    name: $stateParams.name,
                                    duration: $stateParams.duration,
                                    userBeatBoxCase: $stateParams.userBeatBoxCase
                                };
                            },
                            // Weird case that `ng-annotate-loader` cannot cover, therefore we need to define the Angular's way explicit
                            roomServiceReady: ['roomService', (roomService) => {
                                return $rootScope.isRoomPage ? roomService.promise : $q.resolve();
                            }]
                        }
                    };

                }else{

                    params = {
                        templateUrl: '/static/templates/partials/stickySongModal.html',
                        controller: 'StickySongModalCtrl',
                        windowClass: 'sticky-song-modal light our-light',
                        resolve: {
                            songData: function(){
                                return {
                                    id: $stateParams.id,
                                    name: $stateParams.name
                                };
                            },
                            // Weird case that `ng-annotate-loader` cannot cover, therefore we need to define the Angular's way explicit
                            roomServiceReady: ['roomService', (roomService) => {
                                return $rootScope.isRoomPage ? roomService.promise : $q.resolve();
                            }]

                        }
                    };
                }

                $uibModal.open(params).result.then(function(result){

                    if(stickySongMode){
                        if($rootScope.isRoomPage){
                            $rootScope.showUserTutorialModal();
                            $rootScope.$broadcast('player:mute:artificially', false);
                        }
                    }

                    helper.navigateOnModalClose(result);
                }, function(){

                    if(stickySongMode){
                        if($rootScope.isRoomPage){
                            $rootScope.showUserTutorialModal();
                            $rootScope.$broadcast('player:mute:artificially', false);
                        }
                    }

                    if($rootScope.isRoomPage){
                        helper.navigateOnModalClose({ignoreStack: true});
                    }
                });
            }
        });

        if (MyGlobal.user) {
            $stateProvider.state('beatzone', {
                url: '/beatzone',
                onEnter($stateParams, $state, $uibModal, helper) {
                    $uibModal.open({
                        template: beatzonePopupTpl,
                        controller: 'MyBeatzonePopupCtrl',
                        resolve: {
                            beatsServiceReady(beatsService){
                                return beatsService.promise;
                            }
                        }
                    }).result.then((result) => {
                        helper.navigateOnModalClose(result);
                    }, () => {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('update-invalid-email', {
                url: '/update-email',
                onEnter: function ($stateParams, $state, $uibModal, helper) {

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/updateInvalidEmail.html',
                        controller: 'UpdateInvalidEmailCtrl',
                        windowClass: 'update-invalid-email-modal'
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('musicMatches', {
                url: '/musicMatches',
                onEnter: ($uibModal, $stateParams, helper) => {
                    $uibModal.open({
                        template: musicMatchesPopupTpl,
                        controller: 'MyMusicMatchesPopupCtrl'
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('profileWatches', {
                url: '/profileWatches',
                onEnter: ($uibModal, $stateParams, helper) => {
                    $uibModal.open({
                        template: profileWatchesPopupTpl,
                        controller: 'MyProfileWatchesPopupCtrl'
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            });
        }else{
            $stateProvider.state('login', {
                url: '/login',
                onEnter: function ($stateParams, $state, $uibModal, pageLoadManager, helper) {

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/login.html',
                        controller: 'LoginCtrl',
                        windowClass: 'login-modal',
                        size: 'md'
                    }).result.then(function (result) {

                        // Clean stuff just if not going to join
                        if (result !== 'join') {
                            MyGlobal.temp.popupParams = null;
                            pageLoadManager.deleteCurrAction();
                        }

                        helper.navigateOnModalClose(result);
                    }, function () {
                        // Clean stuff
                        MyGlobal.temp.popupParams = null;
                        pageLoadManager.deleteCurrAction();

                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('join', {
                url: '/join/:state',
                onEnter: function ($stateParams, $state, $uibModal, pageLoadManager, helper) {
                    $uibModal.open({
                        templateUrl: '/static/templates/partials/register.html',
                        controller: 'RegisterCtrl',
                        windowClass: 'register-modal',
                        size: 'md',
                        resolve: {
                            params: function () {
                                return {
                                    state: $stateParams.state
                                };
                            }
                        }
                    }).result.then(function (result) {
                        // Clean stuff just if not going to login
                        if (result !== 'login') {
                            MyGlobal.temp.popupParams = null;
                            pageLoadManager.deleteCurrAction();
                        }

                        helper.navigateOnModalClose(result);
                    }, function () {
                        // Clean stuff
                        MyGlobal.temp.popupParams = null;
                        pageLoadManager.deleteCurrAction();

                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            }).state('forgot-pw', {
                url: '/forgot-password',
                onEnter: function ($stateParams, $state, $uibModal, helper) {

                    $uibModal.open({
                        templateUrl: '/static/templates/partials/forgotPassword.html',
                        controller: 'ForgotPasswordCtrl'
                    }).result.then(function (result) {
                        helper.navigateOnModalClose(result);
                    }, function () {
                        helper.navigateOnModalClose({ignoreStack: true});
                    });
                }
            })
        }

        var onCreateOrEditRoomEnter = function ($stateParams, $state, $uibModal, helper, pageLoadManager, themeService, url) {


        };

        var openJoinAndRememberAction = function ($state, pageLoadManager, helper, originState, message) {

            message = message || 'Quickly join to proceed:';

            // Tell the browser to set event to perform after page will load by login/register
            pageLoadManager.setCurrAction({
                name: pageLoadManager.events.GOTO_STATE,
                state: originState
            });

            helper.gotoState('join', {
                message: message
            });
        };

        $stateProvider.state('create-room', {
            url: '/create-room/:state?',
            params: { subTitle: null },
            onEnter($stateParams, $state, $uibModal, helper, pageLoadManager, themeService) {

                if (!MyGlobal.user) {
                    return openJoinAndRememberAction($state, pageLoadManager, helper, 'create-room');
                }

                $uibModal.open({
                    template: createRoomPopupTpl,
                    controller: 'MyCreateRoomPopupCtrl',
                    size: 'md',
                    resolve: {
                        beatsServiceReady(beatsService){
                            return beatsService.promise;
                        }
                    }
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                    themeService.restoreBodyTheme().catch(e => helper.error(e));
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: true});
                    themeService.restoreBodyTheme().catch(e => helper.error(e));
                });
            }
        }).state('edit-room', {
            url: '/edit-room?tab?outlineSection?source',
            onEnter($stateParams, $state, $uibModal, helper, pageLoadManager, themeService) {
                $uibModal.open({
                    template: editRoomPopupTpl,
                    controller: 'MyEditRoomPopupCtrl',
                    size: 'md',
                    resolve: {
                        data: function () {
                            return {
                                tab: $stateParams.tab,
                                outlineSection: {
                                    name: $stateParams.outlineSection
                                },
                                source: $stateParams.source
                            };
                        },
                        beatsServiceReady(beatsService){
                            return beatsService.promise;
                        },
                        roomServiceReady(roomService){
                            return roomService.promise;
                        }
                    }
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                    themeService.restoreBodyTheme().catch(e => helper.error(e));
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: true});
                    themeService.restoreBodyTheme().catch(e => helper.error(e));
                });
            }
        }).state('ranks', {
            url: '/rank/:score',
            onEnter: ($uibModal, $stateParams, helper) => {
                $uibModal.open({
                    templateUrl: '/static/templates/partials/ranks.html',
                    controller: 'RanksCtrl',
                    windowClass: 'ranks-modal',
                    resolve: {
                        data: function () {
                            return {
                                score: $stateParams.score
                            };
                        }
                    }
                }).result.then(function (result) {
                    helper.navigateOnModalClose(result);
                }, function () {
                    helper.navigateOnModalClose({ignoreStack: true});
                });
            }
        }).state('url-command', {
            url: 'url-command/:commandJSON',
            controller: function ($scope, $stateParams) {
                $scope.showNotification($stateParams.commandJSON);
            }
        }).state('subscription', {
            url: '/subscription',
            params: { ref: null },
            onEnter($stateParams, $state, $uibModal, helper) {
                $uibModal.open({
                    template: subscriptionPopupTpl,
                    controller: 'MySubscriptionPopupCtrl',
                    resolve: {
                        serviceReady(beatsService){
                            return beatsService.promise;
                        }
                    }
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        }).state('subscriptionPurchase', {
            url: '/subscription/purchase?id?token?res?reason?amount',
            onEnter($stateParams, $state, $uibModal, helper) {
                $uibModal.open({
                    template: subscriptionPurchasePopupTpl,
                    controller: 'MySubscriptionPurchasePopupCtrl'
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        }).state('rooms', {
            url: '/rooms',
            params: { filters: null },
            onEnter($stateParams, $state, $uibModal, helper) {
                $uibModal.open({
                    template: roomsPopupTpl,
                    controller: 'MyRoomsPopupCtrl',
                    windowClass: 'roomsModal',
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        }).state('stations', {
            url: '/stations',
            params: { tags: null },
            onEnter($stateParams, $state, $uibModal, helper) {
                $uibModal.open({
                    template: stationsPopupTpl,
                    controller: 'MyStationsPopupCtrl',
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        }).state('users', {
            url: '/users',
            onEnter($stateParams, $state, $uibModal, helper) {
                $uibModal.open({
                    template: usersPopupTpl,
                    controller: 'MyUsersPopupCtrl',
                    windowClass: 'usersModal',
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        }).state('feedDrop', {
            url: '/feedDrop/:id',
            params: { feedDrop: null, ignoreStack: null },
            onEnter: ($rootScope, $stateParams, slidingWindowService) => {
                let data = {};
                ({ id: data.id, feedDrop: data.feedDrop, ignoreStack: data.ignoreStack } = $stateParams);

                if ($rootScope.isHomePage) {
                    $rootScope.$broadcast('feedDrop:changed', data);
                } else {
                    slidingWindowService.open({
                        type: slidingWindowService.types.FEED_DROP,
                        data
                    });
                }
            }
        }).state('contribution', {
            url: '/contribution?token?res?reason?amount',
            onEnter($stateParams, $state, $uibModal, helper) {
                $uibModal.open({
                    template: contributionPopupTpl,
                    controller: 'MyContributionPopupCtrl'
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        }).state('createFeedDrop', {
            url: '/createFeedDrop?text?url',
            onEnter($stateParams, $state, $uibModal, helper) {
                $uibModal.open({
                    template: feedDropPopupTpl,
                    controller: 'MyFeedDropPopup',
                    resolve: {
                        popupParams: {
                            content: `${$stateParams.text}, ${$stateParams.url}`
                        }
                    }
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        }).state('redirectToPlatform', {
            url: '/redirectToPlatform',
            params: { type: null, room: null },
            onEnter($stateParams, $state, $uibModal, helper) {
                $uibModal.open({
                    template: redirectToPlatformPopupTpl,
                    controller: 'MyRedirectToPlatformPopupCtrl'
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        }).state('ambassadorProgram', {
            url: '/ambassador',
            onEnter($stateParams, $state, $uibModal, helper, $rootScope) {
                $uibModal.open({
                    template: ambassadorProgramPopup,
                    controller: 'MyAmbassadorProgramPopupCtrl',
                    resolve: {
                        roomServiceReady: ['roomService', '$q', (roomService, $q) => {
                            return $rootScope.isRoomPage ? roomService.promise : $q.resolve();
                        }]
                    }
                }).result.then((result) => {
                    helper.navigateOnModalClose(result);
                }, () => {
                    helper.navigateOnModalClose({ignoreStack: false});
                });
            }
        });
    });
})(angular);
