import angular from 'angular';
const app = angular.module(MyGlobal.page.ngAppName);

app.service('sdkService', ($rootScope, helper, $injector) => {

    return window.BeatSenseSDK = {
        init() {
            $rootScope.showDebugInfo = helper.getUrlParam('debug');
        },
        toggleDebug() {
            $rootScope.showDebugInfo = !$rootScope.showDebugInfo;
        },
        async addSong(youtubeId, options = {}) {
            return $injector.get('userSongService').addSong({
                youtubeId,
                purchase: {
                    type: 'longSong'
                },
                ...options
            });
        },
        async skipCurrSong() {

            if (!$rootScope.currSong) return;

            return $injector.get('userSongService').removeSong($rootScope.currSong, {
                buy: true
            });
        }
    };
});
