import angular from 'angular';
import moment from 'moment';
import './userReportPopup.less';
import {USER_REPORT_TYPES} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyUserReportPopupCtrl', ($scope, $uibModalInstance, popupParams, userService,
                                             $timeout, helper, feedService, $rootScope, storage) => {

    const { type, data } = popupParams;

    let storageKey;
    switch(type) {
        case USER_REPORT_TYPES.FEED_DROP:
            $scope.type = 'drop';
            storageKey = `userReport.feedDrop.${data._id}`;
            break;
        case USER_REPORT_TYPES.USER:
            $scope.type = 'user';
            storageKey = `userReport.user.${data._id}`;
            break;
    }

    $scope.reportText = '';

    $scope.submit = async ()=> {
        try {
            $scope.error = '';
            $scope.loading = true;

            switch(type) {
                case USER_REPORT_TYPES.FEED_DROP:
                    await feedService.reportFeedDrop({
                        feedDropId: data._id,
                        text: $scope.reportText
                    });
                    break;
                case USER_REPORT_TYPES.USER:
                    await userService.reportUser({
                        _id: data._id,
                        text: $scope.reportText
                    });
                    break;
            }

            storage.setItem(storageKey, true, moment.duration(1, 'week').asMilliseconds());
            $scope.close();
            $rootScope.showNotification('Thanks!');
        } catch(e) {
            $scope.error = e.message;
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    }

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    $timeout(() => {
        if (data._id) {

            if (storage.has(storageKey)) {
                $rootScope.showNotification(`You already reported this ${$scope.type}`);
                $scope.close();
                return;
            }

            $scope.show = true;

            $timeout(()=>{
                $('#userReportPopup textarea').focus();
            });
        } else {
            $timeout(() => {
                helper.error(new Error('Invalid feedDropId'));
                $scope.close();
            });
        }
    });
});
