import angular from 'angular';
import './ambassadorProgramPopup.less';
import {SHARE_ENTITY_TYPES, AMBASSADOR_PROGRAM_INFO, USER_REF_TYPES} from "../../../../../shared/consts";

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyAmbassadorProgramPopupCtrl', ($scope, $uibModalInstance, helper, config, userService,
                                                analyticsService, $rootScope, $timeout) => {
    $scope.AMBASSADOR_PROGRAM_INFO = AMBASSADOR_PROGRAM_INFO;
    $scope.SHARE_ENTITY_TYPES = SHARE_ENTITY_TYPES;
    $scope.contactUsEmail = config.getContactUsEmail();
    $scope.isAmbassador = userService.isAmbassador();
    $scope.ambassadorLink = userService.getAffiliateLink({
        room: $rootScope.room,
        ref: USER_REF_TYPES.AMBASSADOR
    });

    $scope.submit = async () => {

        if($scope.loading) return;

        try {

            $scope.loading = true;

            if(!userService.isLoggedIn()){
                return helper.gotoState( 'join', {
                    message: `To be ambassador, please join first`
                });
            }

            await userService.toggleAmbassador(true);

            $scope.isAmbassador = true;

            analyticsService.trackAmbassadorEvent({
                eventAction: 'newAmbassador'
            });
        } catch(e) {
            helper.error(e);
        } finally {
            delete $scope.loading;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = () => {
        $uibModalInstance.dismiss();
    };

    (async() => {
        try {
            const { count } = await userService.getAmbassadorData();
            $scope.totalSignUps = count || 0;
            $scope.signUpsToGo = $scope.totalSignUps ? Math.ceil($scope.totalSignUps/$scope.AMBASSADOR_PROGRAM_INFO.REWARD_USERS)*$scope.AMBASSADOR_PROGRAM_INFO.REWARD_USERS : $scope.AMBASSADOR_PROGRAM_INFO.REWARD_USERS;
        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    })();
});
