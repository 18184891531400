import './buyFeaturePopup.less';

import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyBuyFeaturePopupCtrl', ($scope, $uibModalInstance, $stateParams, beatsService, $rootScope, helper) => {

    const { key } = $stateParams;

    $scope.submit = async () => {
        try {
            if(!beatsService.userCanBuy($scope.feature.key)){
                return helper.gotoState('beatsInsufficient', {
                    reason: $scope.feature.key
                });
            }

            $scope.loading = true;
            await beatsService.buyFeature($scope.feature.key);
            helper.reloadPage(true);
        } catch (e) {
            $rootScope.showError(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    $scope.feature = beatsService.getFeatureByKey(key);
});
