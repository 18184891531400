import angular from 'angular';

import { FILE_UPLOAD_TYPES } from '../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('uploadService', (mySocket, Upload) => {

    return {
        FILE_UPLOAD_TYPES,
        maxSize: {
            userImage: '2MB',
            feedDrop: '8MB'
        },
        accept: {
            userImage: 'image/*',
            feedDrop: 'image/*'
        },
        async upload({ type, file, objId }) {
            try {

                const res = await Upload.upload({
                    url: `/upload/${type}`,
                    data: {
                        file,
                        objId
                    }
                });

                return res?.data;
            } catch(e) {
                throw new Error(e?.data?.error || 'Unknown upload error');
            }
        },
        async getFileBase64(file) {
            return await Upload.dataUrl(file);
        }
    }
});