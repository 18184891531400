/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('userAgentService', function($rootScope){

        var _userAgentInfo = MyGlobal.project.userAgentInfo;

        return {
            isMobile: function () {
                return $rootScope.isMobile;
            },
            isTablet: function () {
                return $rootScope.isTablet;
            },
            isIphone: function () {
                return _userAgentInfo &&
                    _userAgentInfo.device &&
                    _userAgentInfo.device.family &&
                    typeof _userAgentInfo.device.family === 'string' &&
                    _userAgentInfo.device.family.toLowerCase() === 'iphone';
            },
            isIpad: function () {
                return _userAgentInfo &&
                    _userAgentInfo.device &&
                    _userAgentInfo.device.family &&
                    typeof _userAgentInfo.device.family === 'string' &&
                    _userAgentInfo.device.family.toLowerCase() === 'ipad';
            }
        }

    });

}(angular));
