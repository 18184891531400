/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('buddyService', function($q, $http, httpService, helper, $timeout){

        var _list = [];

        var _sortList = function(){
            if(!_list) return;

            _list.sort(function(a, b){

                // If both online - dont consider online flag as valuable..
                if(!a.online || !b.online){
                    if(a.online) return -1;
                    if(b.online) return 1;
                }

                // When both online or offline - sort by name..
                if(a.userName.toLowerCase() < b.userName.toLowerCase()) return -1;
                if(a.userName.toLowerCase() > b.userName.toLowerCase()) return 1;

                return 0;
            });
        };

        return {
            async load(){
                const data = await httpService.get('/buddy/list');

                _list = data;
                _sortList();

                return data;
            },
            getBuddyByUser({ userName }) {
                return _list?.find(buddy => buddy.userName === userName);
            },
            isUserBuddy(userName){
                return !!this.getBuddyByUser({ userName });
            },
            async create(userId){
                const buddy = await httpService.post('/buddy', {userId: userId});

                _list.push(buddy);
                _sortList();

                return buddy;
            },
            async delete(buddyId){
                const res = await httpService.delete(`/buddy/${buddyId}`);
                helper.removeItemFromArray(_list, item => item._id === buddyId);
                return res;
            },
            getList(){
                return _list;
            },
            getOnlineBuddies() {
                const list = this.getList();

                return list && list.length && list.filter(item => {
                    return !!item.online;
                });
            },
            getTotalOnline(){
                const list = this.getOnlineBuddies();
                return list?.length || 0;
            },
            getNewOnlineBuddies(prev, curr) {
                if (!curr?.length) return;
                if (!prev?.length) return curr;

                const diff = [];

                for (const item of curr) {

                    const found = prev.find(item2 => item2._id === item._id);

                    if (!found) {
                        diff.push(item);
                    }
                }

                return diff;
            }
        };
    });

}(angular));
