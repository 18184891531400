/**
 * Created by Shlomi on 29/09/2014.
 */

import template from '../../../../templates/room/partials/addSongToBeatBoxMenu.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myAddSongToBeatBoxMenu', function(helper) {
        return {
            restrict: 'E',
            template,
            scope: {},
            controller: function($scope, beatBoxService, $q, $timeout, $rootScope, storage, analyticsService){

                const storageKey = 'beatbox.publishToChat';
                $scope.publishToChat = storage.getItem(storageKey);

                const holder = $('#add-song-to-beat-box-menu-holder');
                const newCaseJqInputSelector = '#add-song-to-beat-box-menu-holder .txtNewCaseName';

                $scope.init = async (song, options = {}) => {
                    $scope.song = song;

                    if (!beatBoxService.initialized) {
                        await beatBoxService.init();
                    }

                    $scope.cases = beatBoxService.getMyCases(options);
                };

                $scope.$watch('publishToChat', (newValue, oldValue) => {
                    if (oldValue === newValue) return;
                    if(newValue) {
                        storage.setForeverItem(storageKey, newValue);

                        analyticsService.trackBeatBoxEvent({
                            eventAction: 'EnabledPublishToChat'
                        });

                    } else {
                        storage.removeItem(storageKey);

                        analyticsService.trackBeatBoxEvent({
                            eventAction: 'DisabledPublishToChat'
                        });
                    }
                });

                $scope.createNewCaseFn = function($event){
                    $scope.createNewCase = true;

                    $timeout(function(){
                        $(newCaseJqInputSelector).focus();
                    }, 100);

                    $event.preventDefault();
                    $event.stopPropagation();
                };

                $scope.onCreateNewCaseKeypress = function($event){
                    if($event && $event.keyCode === 13){
                        $scope.createNewCaseFromInput();
                        $event.preventDefault();
                        $event.stopPropagation();
                    }
                };

                $scope.createNewCaseFromInput = function(){

                    var elem = $(newCaseJqInputSelector);

                    var value = elem.val();
                    if(!value) return;

                    $scope.saveToNewCase(value);
                    $(elem).val('');
                };

                $scope.getRandomStr = function() {
                    var strings = [
                        'Swoosh! Right into',
                        'Done. Saved to',
                        'You got it! We\'ve put it in',
                        'Forever yours. Find it in',
                        'One small song to your BeatBox, one giant leap to BeatCase',
                        'Safely stashed into',
                        'Another gem into your treasury. Added to',
                        'Cool find! We\'ve saved it into',
                        'Collecting gems eh? Stashed into',
                        'Thanks for feeding me! said'
                    ];
                    return helper.getRandomFromArray(strings);
                };

                const showSuccessMessage = (caseName, params = {}) => {
                    let message;

                    const { action } = params;

                    if (action === 'move') {
                        message = `Moved to "${caseName}"`
                    } else if (action === 'duplicate') {
                        message = `Copied to "${caseName}"`
                    } else {
                        message = `${$scope.getRandomStr()} "${caseName}"`;
                    }

                    $rootScope.showNotification(message);
                };

                $scope.addToCase = function($event, caseObj){

                    const params = $scope.saveParams ? { ...$scope.saveParams } : undefined;

                    beatBoxService.saveSongInCase(caseObj._id, $scope.getCurrSongId(), params).then(() => {
                        showSuccessMessage(caseObj.name, params);
                    }).catch(err => {
                        $rootScope.showError(err);
                    });

                    $scope.createNewCase = false;
                    $scope.hide();

                    $event.preventDefault();
                    $event.stopPropagation();
                };

                $scope.saveToNewCase = function(caseName){

                    const params = $scope.saveParams ? { ...$scope.saveParams } : undefined;

                    beatBoxService.saveSongInCase(caseName, $scope.getCurrSongId(), params).then(function(response){
                        if(response){
                            showSuccessMessage(caseName, params);

                            if(response.isNewCase && response.userBeatBoxCase){
                                beatBoxService.addNewCaseLocally(response.userBeatBoxCase);
                            }
                        }
                    }).catch(err => {
                        $rootScope.showError(err);
                    });

                    $scope.createNewCase = false;
                    $scope.hide();
                };

                $scope.saveToDefaultCaseOnMenuOpen = function(){
                    if(!beatBoxService.isSongExists($scope.song)){
                        $scope.saveToDefaultCase();
                    }
                };

                $scope.saveToDefaultCase = function(){

                    const params = $scope.saveParams ? { ...$scope.saveParams } : undefined;

                    beatBoxService.saveSongInCase(null, $scope.getCurrSongId(), {
                        ...params,
                        publishToChat: $scope.publishToChat
                    }).then(function(data){

                        beatBoxService.markSongAsExists($scope.song);

                        if(data && data.isNewCase) beatBoxService.init().then(function(){
                            $scope.cases = beatBoxService.getAllCases();
                        });


                    }).catch(err => {
                        $rootScope.showError(err);
                    });
                    $scope.createNewCase = false;
                };

                $scope.show = () => {
                    $scope.opened = true;
                    holder.show();
                };

                $scope.hide = function($event){

                    $scope.opened = false;

                    holder.hide();
                    $scope.clearScrolledItems();

                    if($event){
                        $event.stopPropagation();
                        $event.preventDefault();
                    }
                };

                $scope.clearScrolledItems = () => {
                    $('#add-song-to-beat-box-menu-holder .cases .case').removeClass('scrolled');
                };

                $scope.getCurrSongId = function(){
                    return $scope.song ? ($scope.song._id || $scope.song.youtubeId) : undefined;
                };
            },
            link: function($scope, $element){

                const casesListSelector = '#add-song-to-beat-box-menu-holder .cases';
                const holder = $($element.get(0)).find('#add-song-to-beat-box-menu-holder');

                const _scrollToCase = (bCase) => {
                    if(!bCase) return;

                    const elem = $('[data-case-id="'+bCase._id+'"]');
                    if(elem.length) {
                        $scope.clearScrolledItems();
                        elem.addClass('scrolled');

                        $(casesListSelector).scrollToElementAnimated(elem, 0);
                        $(casesListSelector).perfectScrollbar('update');
                    }
                };

                $scope.$on('document:click', function(evt, jqEvt){
                    if(!$(jqEvt.target).hasClass('add-song-to-beat-box-holder') && !$(jqEvt.target).parents('#add-song-to-beat-box-menu-holder').length){
                        $scope.hide();
                    }
                });

                $scope.$on('document:keyup', (evt, jqEvt = {}) => {
                    if(!$scope.opened || !$scope.cases || !$scope.cases.length) return;
                    const { key } = jqEvt;

                    if(key){
                        const bCase = $scope.cases.find(item => {
                            try{
                                return !item.isDefault && !item.permanent && item.name.toLowerCase().startsWith(key.toLowerCase());
                            }catch(e) {}
                        });

                        if(bCase){
                            _scrollToCase(bCase);
                        }

                    }
                });

                $scope.$on('beatBoxMenu:hide', () => {
                    $scope.hide();
                });

                $scope.$on('beatBox:add:song', function(evt, song, options = {}){

                    $scope.init(song, options).then(function(){

                        const { openerOffset, openerHeight, saveParams, skipDefaultCase } = options;

                        if(!openerOffset || typeof openerOffset.top === 'undefined' || typeof openerOffset.left === 'undefined') {
                            throw new Error('Must sent openerOffset');
                        }

                        if(!openerHeight) throw new Error('Must sent openerHeight');

                        const isMobile = MyGlobal.project.isMobile;

                        const openerTop = openerOffset.top;
                        const openerLeft = openerOffset.left;

                        const holderCss = {
                            top: !isMobile ? openerTop + openerHeight : undefined,
                            left: !isMobile ? openerLeft : undefined
                        };

                        // Must be here
                        const windowHeightBeforeShowHolder = $(window).outerHeight();
                        const windowScrollTop = $(window).scrollTop();

                        // Must be here
                        $scope.show();

                        // Make sure the menu doenst goes out of the window
                        const holderHeight = holder.outerHeight();
                        const threshold = (holderHeight + (holderCss.top - windowScrollTop));

                        if(threshold > windowHeightBeforeShowHolder){
                            holderCss.top = openerTop - holderHeight;
                        }

                        // Edge case
                        if(holderCss.top < 0){
                            holderCss.top = openerTop + openerHeight;
                        }

                        holder.css(holderCss);

                        $scope.saveParams = saveParams;
                        $scope.skipDefaultCase = skipDefaultCase;
                        $scope.action = saveParams?.action;

                        $('#add-song-to-beat-box-menu-holder .cases').perfectScrollbar('update');

                        if (!skipDefaultCase) {
                            $scope.saveToDefaultCaseOnMenuOpen();
                        }
                    });
                });

                $scope.$on('document:keyup:esc', function(){
                    $scope.hide();
                });
            }
        };
    });
}(angular));
