/**
 * Created by Shlomi on 24/01/2015.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myEllipsis', function($timeout, $interval, $rootScope, helper) {
        return {
            restrict: 'A',
            link: function($scope, element, attrs){

                var debugMode = !!attrs?.debugMode;

                // add this shit because its need to enter this condition also for mobile. I dont understand why do we need to code after this return
                if(1 || !$rootScope.isMobile) {
                    if(attrs.myEllipsisCol){
                        element.addClass('my-ellipsis-col');
                    }
                    return element.addClass('my-ellipsis');
                }

                var _watchTimerId;

                var _cancelWatch = function () {
                    if(_watchTimerId){
                        $interval.cancel(_watchTimerId);
                        _watchTimerId = null;
                    }
                };

                element.addClass('my-ellipsis-mobile');

                var doEllipsis = function(origValue){
                    element.text(origValue);
                    element.ellipsis();
                    // Workaround to make sure fonts are loaded the the ellipsis component calculating the shit fine..
                    $timeout(function(){

                        element.text(origValue);
                        element.ellipsis();
                    }, 100);
                };

                if(attrs.myEllipsis && typeof $scope[attrs.myEllipsis] !== 'undefined'){
                    // Listen to changes
                    $scope.$watch(attrs.myEllipsis, function(value) {
                        if(value) doEllipsis(value);
                    });
                }

                if(attrs.myEllipsisTriggerEvent){
                    $scope.$on(attrs.myEllipsisTriggerEvent, function(){
                        doEllipsis(element.text());
                    });
                }

                _cancelWatch();

                var _prevValue;
                if(attrs.myEllipsisWatch){
                    _watchTimerId = $interval(function(){

                        if(debugMode) helper.debug('watching ellipsis changes');

                        var value = attrs.myEllipsis.trim();

                        // Keep watching..
                        if(!value || value === _prevValue) return;

                        if(debugMode) helper.debug('stop watch ellipsis changes, value found: '+value);

                        //_cancelWatch();
                        _prevValue = value;
                        doEllipsis(value);
                    }, 500);
                }else{
                    $timeout(function(){
                        doEllipsis(element.text());
                    }, attrs.myEllipsisDuration || 0);
                }
            }
        };
    });
}(angular));
