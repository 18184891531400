/**
 * Created by Shlomi.
 */

import {STREAMING_EVENTS} from '../../../shared/consts';

((angular) => {

    const app = angular.module(MyGlobal.page.ngAppName);

    app.factory('mySocket', (helper, $q, $rootScope, $injector) => {

        let initialized = false;
        let mySocket;

        return {
            async init(options = {}) {

                const host = location.hostname;
                const port = location.port ? `:${location.port}` : '';

                let path = `//${host}${port}`;
                if (MyGlobal.project.namespace) {
                    path += '/' + MyGlobal.project.namespace;
                }

                return new Promise((resolve, reject) => {

                    const reconnectionDelayStart = 5000;
                    const reconnectionDelayEnd = 15000;

                    const reconnectionDelay = helper.getRandomInt(reconnectionDelayStart, reconnectionDelayEnd);
                    const reconnectionDelayMax = reconnectionDelay*2;

                    helper.debug(`Starting a SIO client with reconnectionDelay=${reconnectionDelay} and reconnectionDelayMax=${reconnectionDelayMax}`);

                    const token = helper.getCookie('jwt', { skipPrefix: true });
                    const query = token ? `token=${token}` : undefined;

                    mySocket = io(path, {
                        query,
                        reconnection: true,
                        reconnectionDelay,
                        reconnectionAttempts: 10,
                        randomizationFactor: 0.5,
                        reconnectionDelayMax,
                        closeOnBeforeunload: false,
                        transports: ['websocket', 'polling']
                    });

                    mySocket.io.on('ping',  () => {
                        helper.debug('SIO ping');
                    });

                    mySocket.on('connect', () => {
                        helper.debug('SIO connected');
                    });

                    mySocket.on('connect_error', async (error) => {
                        try {
                            if (error?.message === 'invalid signature') {
                                await $injector.get('userService').logout();
                                return;
                            }

                            reject(error);
                        } catch(e) {
                            reject(e);
                        }
                    });

                    mySocket.on('socket:ready', function () {
                        helper.debug(`Got socket event: socket:ready`);
                        helper.debug('SIO socket:ready received, resolving..');

                        if (!initialized) {
                            resolve();
                            initialized = true;
                        } else{
                            $rootScope.$broadcast('socket:reconnected');
                        }
                    });
                });
            },
            connected() {
                return mySocket && mySocket.connected;
            },
            reconnect() {
                if(!mySocket) throw new Error('mySocket is not defined, please init first');
                return mySocket.connect();
            },
            disconnect() {
                if(!mySocket) throw new Error('mySocket is not defined, please init first');
                return mySocket.disconnect();
            },
            async join(room, options = {}) {
                if(!mySocket) throw new Error('mySocket is not defined, please init first');

                if (room && room._id) {
                    return new Promise((resolve, reject) => {
                        mySocket.emit('socket:connectToRoom', {
                            roomId: room._id,
                            ghost: !!helper.getUrlParamBoolean('ghost') || MyGlobal.page.ghost,
                            isMobile: $rootScope.isMobile,
                            isTablet: $rootScope.isTablet,
                            isReconnect: options.isReconnect
                        }, (data) => {
                            if(data?.error) return reject(data?.error);
                            resolve(data);
                        });
                    });
                } else {
                    return new Promise((resolve, reject) => {
                        mySocket.emit('socket:singleConnect', {
                            ghost: !!helper.getUrlParamBoolean('ghost') || MyGlobal.page.ghost,
                            isMobile: $rootScope.isMobile,
                            isTablet: $rootScope.isTablet,
                            isReconnect: options.isReconnect
                        }, (data) => {
                            if(data?.error) return reject(data?.error);
                            resolve(data);
                        });
                    });
                }
            },
            on(...args) {
                if(!mySocket) {
                    throw new Error('mySocket is not defined, please init first');
                }

                mySocket.on(...args);
            },
            off(...args) {
                if(!mySocket) {
                    throw new Error('mySocket is not defined, please init first');
                }

                mySocket.off(...args);
            },
            emit(event, params, cb, ...args) {
                if(!mySocket) {
                    throw new Error('mySocket is not defined, please init first');
                }

                cb = typeof params === 'function' ? params : cb;

                // Socketio server return NULL when nothing send back in the callback, we have to convert it into undefined
                if (typeof cb === 'function') {
                    const oldCb = cb;

                    cb = (data, ...args) => {
                        if (data === null) {
                            data = undefined;
                        }

                        return oldCb(data, ...args);
                    };
                }

                mySocket.emit(event, params, cb, ...args)
            },
            async emitP(event, params, ...args) {
                return new Promise((resolve, reject) => {
                    this.emit(event, params, (res) => {
                        if (res?.error?.message) {
                            return reject(new Error(res.error.message));
                        }

                        resolve(res);
                    }, ...args);
                });
            },
            bindListeners() {

                const events = [{
                    eventName: 'disconnect'
                }, {
                    eventName: 'socket:ready'
                }, {
                    eventName: 'playlist:updated'
                }, {
                    eventName: 'playlist:empty'
                }, {
                    eventName: 'room:reloadStats'
                }, {
                    eventName: 'song:similarSongs'
                }, {
                    eventName: 'song:similarSongs'
                }, {
                    eventName: 'song:voted'
                }, {
                    eventName: 'sockets:total:updated'
                }, {
                    eventName: 'user:focusChanged'
                }, {
                    eventName: 'user:muteChanged'
                }, {
                    eventName: 'user:kicked'
                }, {
                    eventName: 'page:reload'
                }, {
                    eventName: 'playlist:nextSong'
                }, {
                    eventName: 'song:aboutToBeDraftOut'
                }, {
                    eventName: 'song:cancelDraftingOut'
                }, {
                    eventName: 'user:moodChanged'
                }, {
                    eventName: 'room:moodChanged'
                }, {
                    eventName: 'room:updated'
                }, {
                    eventName: 'room:waitingForFirstUserToAddSongs'
                }, {
                    eventName: 'song:waitingForRandomSong'
                }, {
                    eventName: 'socket:error'
                }, {
                    eventName: 'socket:notification'
                }, {
                    eventName: 'general:notification'
                }, {
                    eventName: 'user:score:updated'
                }, {
                    eventName: 'chat:message'
                }, {
                    eventName: 'chat:message:private'
                }, {
                    eventName: 'chat:message:private:ack'
                },  {
                    eventName: 'chat:block:toggle'
                }, {
                    eventName: 'song:vote:feedback'
                }, {
                    eventName: 'song:oneLiner:updated'
                }, {
                    eventName: 'system:message'
                }, {
                    eventName: 'room:theme:tempChanged'
                }, {
                    eventName: 'room:theme:restoreDefault'
                }, {
                    eventName: 'room:suggestingTag:changed'
                }, {
                    eventName: 'room:pause:toggle'
                }, {
                    eventName: 'userAlert:received'
                }, {
                    eventName: 'room:effect:started'
                }, {
                    eventName: 'chat:cleared'
                }, {
                    eventName: 'user:selfStatus:changed'
                }, {
                    eventName: 'room:deleted'
                }, {
                    eventName: 'chat:message:updated'
                }, {
                    eventName: STREAMING_EVENTS.BROADCASTER
                }, {
                    eventName: STREAMING_EVENTS.STOP_BROADCAST
                }, {
                    eventName: STREAMING_EVENTS.BROADCASTER_MUTE_TOGGLE
                }];

                const managerEvents = [{
                    eventName: 'reconnect_failed'
                }, {
                    eventName: 'reconnect'
                }];

                for (const { eventName, callback } of events) {
                    mySocket.on(eventName, data => {

                        helper.debug(`Got socket event: ${eventName}`);

                        if (typeof callback === 'function') {
                            return callback(data);
                        }

                        $rootScope.$broadcast(eventName, data);
                    })
                }

                for (const { eventName, callback } of managerEvents) {
                    mySocket.io.on(eventName, data => {

                        helper.debug(`Got socket IO event: ${eventName}`);

                        if (typeof callback === 'function') {
                            return callback(data);
                        }

                        $rootScope.$broadcast(eventName, data);
                    })
                }
            }
        }
    });
})(angular);
