/**
 * Created by Shlomi on 29/09/2014.
 */

import template from '../../templates/partials/error.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myError', function() {
        return {
            restrict: 'E',
            template,
            controller: function($scope, $rootScope, $timeout, helper){
                // Error view
                var errorTimerId;

                var _defaultHideDuration = 9000; // in ms

                $scope.showError = $rootScope.showError = (err, alwaysShow, options = {}) => {
                    err = err || {
                        message: 'Unknown Error'
                    };

                    // Fix for angular $http response structure
                    if(err?.data?.error){
                        err = new Error(err.data.error);
                    }

                    if(err.status === 401 && !$rootScope.user) {
                        return $scope.onAuthError(err);
                    }

                    if (err.message === 'InsufficientBeats') {
                        return helper.gotoState('beatsInsufficient', {
                            reason: 'unknown'
                        });
                    }

                    if(errorTimerId){
                        $timeout.cancel(errorTimerId);
                    }

                    $scope.hideError();
                    $scope.hideNotification();

                    $scope.errorMessage = (typeof err === 'string' ? err : false) || err.error || err.message;
                    $scope.showErrorAlert = true;

                    if(!alwaysShow){
                        return new Promise((resolve) => {
                            errorTimerId = $timeout(function(){
                                $scope.hideError(true);
                                resolve();
                            }, options.ttl || _defaultHideDuration);
                        });
                    }

                };

                $scope.hideError = $rootScope.hideError = function(){
                    $scope.showErrorAlert = false;
                    $scope.errorMessage = '';
                };
            }
        };
    });
}(angular));
