const {MADE_MY_DAY_PRICE_INCREMENT, FREE_MADE_MY_DAYS, ROOM_ENTITY_NAMES} = require('./consts/general');
const moment = require('moment/moment');
const {YOUTUBE_LINK_REGEX} = require('../shared/consts');
const {WIKI_CHANGE_ACTIONS, WIKI_SONG_VALIDATION} = require('./consts');
const hashTagsRegexp = /(#[a-zA-Z\d-_]+)/gi;
const languagesModule = require('./languagesHelper.js');
const countryList = require('country-list');

const languages = languagesModule().getData();
const excludedCountries = ['PS', 'IR'];

const castLanguage = (lang) => {
    return {
        _id: lang.code,
        name: lang.language
    }
};

module.exports = {
    replaceHashtagsInString(str) {
        return str && str.replace(hashTagsRegexp, '<span class=\'hashtag\'>$1</span>');
    },
    getHashtagsFromString(str) {
        return str && str.match(hashTagsRegexp);
    },
    removeArrayDuplicates(arr) {
        return [...new Set(arr)];
    },
    strToHashtag(str){
        return str && str.replace(/ /g, '_');
    },
    getSongName(song) {
        if (!song || !song.name) {
            throw new Error('Invalid song');
        }
        const { artist, name }  = song?.metaData || {};
        return artist && name ? `${artist} - ${name}` : song.name;
    },
    calculateNextMadeMyDayCost(madeMyDay) {

        if (!madeMyDay) return;
        const { count, first } = madeMyDay;

        return moment(first).isSame(new Date(), 'day') && count && count + 1 > FREE_MADE_MY_DAYS ?
            count * MADE_MY_DAY_PRICE_INCREMENT : 0;
    },
    isYouTubeLink(text) {
        return YOUTUBE_LINK_REGEX.test(text)
    },
    validateWikiContribution({ action, value, params }) {
        switch (action) {
            case WIKI_CHANGE_ACTIONS.updateSongLyrics: {
                // Some day we'll have only `value` - for now this function takes two as a legacy problem
                value = value || params && params.metaData && params.metaData.lyrics;
                if (value && value.length < WIKI_SONG_VALIDATION.LYRICS_MIN_LENGTH) {
                    throw new Error('Invalid lyrics');
                }
            }
        }
    },
    getLanguages() {
        return languages.map(castLanguage);
    },
    getLanguageByCode(code) {
        return castLanguage(languages.find( item => item.code === code));
    },
    escapeRegex(str) {
        return str && str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    },
    getCountryNames() {
        return countryList.getData().filter(item => {
            return !excludedCountries.includes(item.code);
        })
    },
    getCountryNameByCode(code) {
        if (excludedCountries.includes(code)) return;
        return countryList.getName(code);
    },
    getCountryCodes() {
        return countryList.getCodes().filter(item => {
            return !excludedCountries.includes(item);
        });
    },
    removeItemFromArray(arr, resolver) {
        let removed;
        for (let i=0;i<arr.length;i++) {
            if (resolver(arr[i], i)) {
                arr.splice(i--, 1);
                removed = true;
            }
        }

        return removed;
    },
    getRoomKind(room) {
        return room?.station ? ROOM_ENTITY_NAMES.STATION : ROOM_ENTITY_NAMES.BEATROOM;
    },
    appendEmojiToEmojis({ emoji, user, existingEmojis }) {
        existingEmojis = existingEmojis || {};

        const key = `${emoji.unicode}#${emoji.skinTone}`;

        existingEmojis[key] = existingEmojis[key] || {...emoji};
        existingEmojis[key].users = existingEmojis[key].users || [];

        for (const tmpUser of existingEmojis[key].users) {
            if (tmpUser._id === user._id) {
                throw new Error('You cannot emoji twice!');
            }
        }

        existingEmojis[key].users.push(user);

        return existingEmojis;
    },
    removeEmojiFromEmojis({ emoji, user, existingEmojis }) {
        const key = `${emoji.unicode}#${emoji.skinTone}`;

        if (existingEmojis[key]?.users) {
            this.removeItemFromArray(existingEmojis[key].users, item => item._id === user._id);

            if (!existingEmojis[key].users.length) {
                delete existingEmojis[key];
            }
        }

        return existingEmojis;
    },
    getRoomUniqueId(room){
        return room ? (room.uniqueName || room.id || room._id) : null;
    },
    isFe() {
        return typeof window !== 'undefined';
    },
    getPlatformBaseUrl(isStations) {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const port = window.location.port;
        const isIpAddress = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(hostname);

        let baseHost = hostname;
        let subdomain = '';
        if (!isIpAddress) {
            // Not an IP address, split by '.' and use the last two parts
            const parts = hostname.split('.');
            baseHost = parts.slice(-2).join('.');

            subdomain = isStations ? 'stations.' : 'www.';
        }

        return port ? `${protocol}//${subdomain}${baseHost}:${port}` : `${protocol}//${subdomain}${baseHost}`;
    },
    getRoomUrl(room) {
        const roomUniqueId = this.getRoomUniqueId(room);

        if (!roomUniqueId) {
            throw new Error('Invalid room identifier');
        }

        if (!this.isFe()) {
            return `/${roomUniqueId}`
        }

        const baseDomain = this.getPlatformBaseUrl(room.station);
        return `${baseDomain}/${roomUniqueId}`;
    }
}