import angular from 'angular';
import template from './feedDropItemSong.html';
import './feedDropItemSong.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myFeedDropItemSong', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            data: '=',
            onClick: '&'
        },
        controller($scope, $rootScope) {

            const { song, room } = $scope.data;

            // backward compatibility
            $scope.song = song || $scope.data;
            $scope.room = room;

            $scope.onSongClicked = ($event) => {
                if ($rootScope.isMobile) {
                    $event.preventDefault();
                    $rootScope.$broadcast('homePage:mobile:songChanged', $scope.song);
                }
            };
        }
    };
});
