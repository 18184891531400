import {PAYMENT_TYPES, USER_CONTRIBUTION_GAINS} from '../../../../../shared/consts';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('BeatsPurchaseModalCtrl', function ($scope, config, helper, $rootScope,
                                                       $uibModalInstance, beatsService,
                                                       $timeout, popupService, userService) {

        $scope.maxBeatsForReward = config.getBeatsTimerReward().minBeats;
        $scope.USER_CONTRIBUTION_GAINS = USER_CONTRIBUTION_GAINS;
        $scope.balance = beatsService.getBalance();
        $scope.packages = beatsService.getPurchasePackages();
        $scope.packages.sort(function(a, b){
            return a.price > b.price ? 1 : -1;
        });

        $scope.affiliateLink = userService.getAffiliateLink({
            room: $rootScope.room
        });

        $scope.packageSelected = function($event, pkg){
            $event.preventDefault();

            popupService.openPaymentPopup({
                type: PAYMENT_TYPES.BEATS_PACKAGE,
                data: {
                    package: pkg
                }
            });

            helper.trackBeatsEvent('BeatsPackageSelected', pkg.name);
        };

        $scope.affiliateLinkClicked = function($event){
            $scope.affiliateLinkSelected = true;

            $timeout(function(){
                $('#beatsPurchaseModal input.affiliateLinkInput').focus().select();
            });

            $event.preventDefault();
        };

        $scope.affiliateLinkBlur = function(){
            $scope.affiliateLinkSelected = false;
        };

        $scope.close = function(){
            $uibModalInstance.dismiss();
        };
    });

}(angular));


