import angular from 'angular';
const app = angular.module(MyGlobal.page.ngAppName);

app.service('userRoomsService', (httpService, roomService, $rootScope) => {

    let favoritesPromise;
    let privatePromise;

    const favoriteRooms = {};
    const privateRooms = {};

    const loadFavorites = async ()=> {
        favoritesPromise = httpService.get('/room/userFavorites');
        const roomIds = await favoritesPromise;

        for(let i=0;i<roomIds.length;i++){
            favoriteRooms[roomIds[i]] = true;
        }
    };

    const loadPrivates = async ()=> {
        privatePromise = httpService.get('/room/userPrivateRooms');
        const roomIds = await privatePromise;

        for(let i=0;i<roomIds.length;i++){
            privateRooms[roomIds[i]] = true;
        }
    };

    return {
        async inUserFavorites(roomId) {
            if (!favoritesPromise) {
                await loadFavorites();
            } else {
                await favoritesPromise;
            }

            return favoriteRooms[roomId];
        },
        async inUserPrivateRooms(roomId) {
            if (!privatePromise) {
                await loadPrivates();
            } else {
                await privatePromise;
            }

            return privateRooms[roomId];
        },
        async toggleFavorite({ roomId }) {
            const { added } = await httpService.post('/room/favorite/toggle', { roomId });

            if (added) {
                favoriteRooms[roomId] = true;
            } else {
                delete favoriteRooms[roomId];

                if ($rootScope.isRoomPage) {
                    roomService.setRoomSubscription(false);
                }
            }

            return { added };
        }
    }
});
