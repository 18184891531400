/**
 * Created by Shlomi on 29/09/2014.
 */

import template from '../../templates/partials/buddiesList.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myBuddiesList', function($timeout, $interval, helper) {
        return {
            restrict: 'E',
            template,
            scope: {},
            controller: function($scope, $rootScope, helper, buddyService, $q, userAlertService, storage,
                                 analyticsService){

                var _updateTimer;
                var _updateTimerInternalWhenOpen = helper.minutesToMs(2);
                var _updateTimerInternalWhenClosed = parseInt(helper.getUrlParam('buddiesIntervalClose')) || helper.minutesToMs(5);

                $scope.showBuddiesList = storage.getItem('buddiesOpened');
                $scope.loading = false;
                $scope.buddies = null;

                var _load = function(options = {}){

                    if(!helper.isUserLoggedIn()) return $q.resolve(false);

                    helper.debug('Loading buddies..');

                    const { silent, initial } = options;

                    const prevOnlineBuddies = buddyService.getOnlineBuddies();
                    const prevTotalOnline = $scope.totalOnline;

                    // Load buddies list first time
                    $scope.loading = !silent;
                    return buddyService.load().then(function(){
                        $scope.buddies = buddyService.getList();
                        $scope.totalOnline = buddyService.getTotalOnline();

                        // Check online buddies diff, only when list is closed, to send user's alerts about it.
                        if (!initial && !$scope.showBuddiesList && $scope.totalOnline !== prevTotalOnline) {
                            const newBuddies = buddyService.getNewOnlineBuddies(prevOnlineBuddies, buddyService.getOnlineBuddies());

                            if (newBuddies && newBuddies.length) {
                                userAlertService.sendNewOnlineBuddiesAlert(newBuddies).catch(e => helper.error(e));
                            }
                        }

                        $scope.loading = false;
                        $scope.updateScroller();
                    }).catch(function(err){
                        $rootScope.showError(err);
                        $scope.loading = false;
                    });
                };

                var _startUpdateTimer = function(interval){

                    interval = interval || _updateTimerInternalWhenClosed;

                    _stopUpdateTimer();
                    helper.debug('Starting buddies timer with '+interval+' duration');

                    _updateTimer = $interval(function(){
                        helper.debug('Updating buddies list');
                        _load({silent: true});
                    }, interval);
                };

                var _stopUpdateTimer = function(){
                    if(_updateTimer){
                        helper.debug('Stopping updating buddies timer');
                        $interval.cancel(_updateTimer);
                        _updateTimer = null;
                    }
                };

                $scope.toggleBuddies = function(){
                    $scope.showBuddiesList = !$scope.showBuddiesList;

                    if($scope.showBuddiesList){
                        _load().then(function () {
                            $scope.setWindowHeight();
                            _startUpdateTimer(_updateTimerInternalWhenOpen);
                            storage.setForeverItem('buddiesOpened', true);
                        });

                        analyticsService.trackUIEvent({
                            eventAction: 'BuddiesList:opened'
                        });

                    }else{
                        _startUpdateTimer(_updateTimerInternalWhenClosed);
                        storage.removeItem('buddiesOpened');

                        analyticsService.trackUIEvent({
                            eventAction: 'BuddiesList:closed'
                        });
                    }
                };

                $scope.removeBuddy = async(buddy) => {
                    try {
                        await buddyService.delete(buddy._id);
                        $scope.buddies = buddyService.getList();
                        $scope.updateScroller();
                    } catch(e) {
                        helper.error(e);
                    }
                };

                // Load buddies list first time
                _load({ initial: true }).then(function (startTimer) {
                    if(startTimer === false) return;
                    _startUpdateTimer(_updateTimerInternalWhenClosed);
                });
            },
            link: function($scope, $element){
                $scope.setWindowHeight = function(){
                    $($element).find('.list').css('maxHeight',$(window).height());
                };

                $scope.updateScroller = function(){
                    if (!$scope.buddies?.length) {
                        $timeout(() => {
                            $scope.showBuddiesList = false;
                        }, helper.secsToMs(10));
                    } else {
                        $timeout(function () {
                            $($element).find('.list').perfectScrollbar('update');
                        });
                    }
                };
            }
        };
    });
}(angular));
