/**
 * Created by Shlomi on 29/09/2014.
 */

import template from '../../../../templates/room/partials/addSongToBeatBox.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myAddSongToBeatBox', function() {
        return {
            restrict: 'E',
            template,
            scope: {
                song: '=',
                currSong: '=',
                gaSource: '@'
            },
            controller: function($scope, $rootScope, beatBoxService, userService, analyticsService, helper){

                $scope.added = beatBoxService.isSongExists($scope.song);

                $scope.addSongToBeatBox = function(...args){

                    if(!userService.isLoggedIn()){
                        return helper.gotoState( 'join', {
                            message: `Be a Beatsters first to be able adding songs your your BeatBox`
                        });
                    }

                    if (!userService.canPerformPrivilegedFeatures()) {
                        helper.gotoState('contribution');
                        return;
                    }

                    $rootScope.addSongToBeatBox(...args);
                    $scope.added = true;

                    if( $scope.gaSource === 'wikiPage'){
                        analyticsService.trackWikiEvent({
                            eventAction: 'AddToBeatboxClicked'
                        });
                    }
                };
            }
        };
    });
}(angular));
