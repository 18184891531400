import angular from 'angular';
import template from './feedDrop.html';
import './feedDrop.less';

if (MyGlobal.project.isMobile) {
    import('./feedDrop_mobile.less');
}

import '../../feed/feed.service';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myFeedDrop', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            feedDropId: '=?',
            feedDrop: '=?',
            replyType: '=?',
            onFeedDropLoaded: '&',
            onClose: '&'
        },
        controller($scope, feedService, helper, slidingWindowService) {

            $scope.currPage = 1;

            const updateField = (feedDropId, field, sign) => {
                let feedDrop;
                if (feedDropId === getFeedDropId()) {
                    feedDrop = $scope.feedDrop;
                } else if($scope.feedDropReplies?.length) {
                    feedDrop = $scope.feedDropReplies.find(item => item._id === feedDropId);
                }

                if (feedDrop) {
                    feedDrop[field] = (feedDrop[field] || 0) + sign;
                }
            };

            const getFeedDropId = () => {
                return $scope.feedDrop?._id || $scope.feedDropId;
            };

            const loadReplies = async ({ append } = {})=> {
                const { models, pagination } = await feedService.getFeedDropReplies({
                    id: getFeedDropId(),
                    page: $scope.currPage
                });

                if (models?.length) {
                    for (const model of models) {
                        model.parentFeedDrop = $scope.feedDrop;
                    }
                }

                if (!append) {
                    $scope.feedDropReplies = models;
                } else {
                    $scope.feedDropReplies = ($scope.feedDropReplies || []).concat(models);
                }

                $scope.repliesPagination = pagination;
            };

            $scope.scrollFeedIfNeeded = async () => {

                if ($scope.currPage >= $scope.repliesPagination?.totalPages) {
                    return;
                }

                $scope.currPage = ($scope.currPage || 0) + 1;
                return await loadReplies({
                    append: true
                });
            };

            $scope.$on(`feedDrop:redroped`, ($evt, { _id }) => {
                updateField(_id, 'redrops', 1);
            });

            $scope.$on(`feedDrop:replied`, async ($evt, { _id }) => {
                try {
                    updateField(_id, 'replies', 1);

                    if (_id === getFeedDropId()) {
                        await loadReplies();
                    }
                } catch(e) {
                    helper.error(e);
                }
            });

            $scope.onDeleted = ()=> {
                $scope.onClose();
            };

            $scope.onReplyDeleted = (feedDropReply)=> {
                helper.removeItemFromArray($scope.feedDropReplies, item => item._id === feedDropReply._id);
                updateField(getFeedDropId(), 'replies', -1);
            };

            $scope.onReplyDropCreated = (feedDrop) => {
                $scope.feedDropReplies = $scope.feedDropReplies || [];
                $scope.feedDropReplies.unshift(feedDrop);
            };

            (async ()=> {
                try {

                    if (!$scope.feedDrop && $scope.feedDropId) {
                        $scope.loading = true;
                        $scope.feedDrop = await feedService.getFeedDrop($scope.feedDropId);
                        $scope.onFeedDropLoaded({ feedDrop: $scope.feedDrop });
                        $scope.loading = false;
                        try { $scope.$digest(); } catch(e) {}

                        if (!slidingWindowService.hasStack() && $scope.feedDrop.parentFeedDrop?._id) {
                            slidingWindowService.pushStack({
                                type: slidingWindowService.types.FEED_DROP,
                                data: {
                                    id: $scope.feedDrop.parentFeedDrop._id
                                }
                            })
                        }
                    }

                    $scope.repliesLoading = true;
                    await loadReplies();

                    $scope.$on('window:scroll:bottom', async () => {
                        try {
                            await $scope.scrollFeedIfNeeded();
                        } catch(e) {
                            helper.error(e);
                        }
                    });

                    $scope.$watch('feedDropId', async (newVal, oldVal)=> {
                        if (newVal === oldVal) return;
                        try {

                            if (!$scope.feedDrop || $scope.feedDrop?._id !== newVal) {
                                $scope.loading = true;
                                $scope.feedDrop = await feedService.getFeedDrop($scope.feedDropId);
                                $scope.onFeedDropLoaded({ feedDrop: $scope.feedDrop });
                                $scope.loading = false;
                                try { $scope.$digest(); } catch(e) {}
                            }

                        } catch(e) {
                            helper.error(e);
                        } finally {
                            delete $scope.repliesLoading;
                            try {
                                try { $scope.$digest(); } catch(e) {}
                            } catch(e) {}
                        }
                    });

                    $scope.$watch('feedDrop', async (newVal, oldVal)=> {
                        if (newVal?._id === oldVal?._id) return;

                        try {

                            $scope.repliesLoading = true;
                            await loadReplies();

                        } catch(e) {
                            helper.error(e);
                        } finally {
                            delete $scope.repliesLoading;
                            try {
                                try { $scope.$digest(); } catch(e) {}
                            } catch(e) {}
                        }
                    });

                    $scope.$on(`feedDrop:${$scope.feedDrop._id}:reply:added`, ($evt, feedDrop) => {
                        $scope.feedDropReplies = $scope.feedDropReplies || [];
                        $scope.feedDropReplies.unshift(feedDrop);
                    });

                } catch(e) {

                    if (e.status === 404) {
                        $scope.onClose();
                    } else {
                        helper.error(e);
                    }
                } finally {
                    delete $scope.loading;
                    delete $scope.repliesLoading;
                    try { $scope.$digest(); } catch(e) {}
                }
            })();
        }
    };
});
