import angular from "angular";
import {IFRAME_CONNECTION_TYPES} from "../../../../shared/consts/general";

import '../iframeConnector';

const app = angular.module(MyGlobal.page.ngAppName);
app.factory('MyMusicPlayer', (config, IFrameConnector, helper) => {
    class MusicPlayer {

        #elem = null;
        #listeners = {};
        #ready;
        async init({ playerId, width, height, playerVars}) {

            this.#elem = $(`#${playerId}`);

            const params = {
                outerPlayerId: playerId,
                width,
                height
            };

            if (playerVars) {
                params.playerVars = JSON.stringify(playerVars);
            }

            const urlParams = new URLSearchParams(params);
            const domain = `https://${config.getPlayerDomain()}${location.port ? `:${location.port}` : ''}`;

            this.iframeConnector = new IFrameConnector({
                id: playerId,
                mode: IFRAME_CONNECTION_TYPES.PARENT,
                data: {
                    url: `${domain}/innerMusicPlayer/?${urlParams.toString()}`,
                    width,
                    height,
                    parentElem: this.#elem
                }
            });

            await this.iframeConnector.init();
            this.#ready = true;
        }

        getElem() {
            return this.#elem?.length ? this.#elem : null;
        }

        async #callInnerPlayer({ method, args }) {
            return await this.iframeConnector.emitP('invoke', {
                method,
                args
            });
        }

        // Methods
        isReady() {
            return this.#ready;
        }

        async addEventListener(eventName, callback) {
            const callbackStr = `cb.${eventName}.${helper.getRandomString()}`;
            const func = (...args) => {
                callback(...args);
            };

            this.iframeConnector.on(callbackStr, func);

            if (!this.#listeners[eventName]) {
                this.#listeners[eventName] = [];
            }

            this.#listeners[eventName].push({
                callbackStr,
                origCallback: callback,
                wrapperCallback: func
            });

            await this.#callInnerPlayer({
                method: 'addEventListener',
                args: [eventName, callbackStr]
            });
        }
        async removeEventListener(eventName, callback) {
            if (this.#listeners[eventName]) {
                for (let i=0;i<this.#listeners[eventName];i++) {
                    const { callbackStr, origCallback, wrapperCallback } = this.#listeners[eventName][i];

                    if (origCallback === callback) {

                        await this.#callInnerPlayer({
                            method: 'removeEventListener',
                            args: [eventName, callbackStr]
                        });

                        this.iframeConnector.off(callbackStr, wrapperCallback);
                        this.#listeners[eventName].splice(i--, 1);
                    }
                }
            }
        }

        async removeEventListeners() {
            this.#listeners = {};
            return await this.#callInnerPlayer({
                method: 'removeEventListeners'
            });
        }

        async isPlaying() {
            return await this.#callInnerPlayer({
                method: 'isPlaying'
            });
        }

        async mute() {
            return await this.#callInnerPlayer({
                method: 'mute'
            });
        }

        async unMute() {
            return await this.#callInnerPlayer({
                method: 'unMute'
            });
        }

        async isMuted() {
            return await this.#callInnerPlayer({
                method: 'isMuted'
            });
        }

        async loadVideoById(...args) {
            return await this.#callInnerPlayer({
                method: 'loadVideoById',
                args: [...args]
            });
        }

        async cueVideoById(...args) {
            return await this.#callInnerPlayer({
                method: 'cueVideoById',
                args: [...args]
            });
        }

        async seekTo(...args) {
            return await this.#callInnerPlayer({
                method: 'seekTo',
                args: [...args]
            });
        }

        async getCurrentTime() {
            return await this.#callInnerPlayer({
                method: 'getCurrentTime'
            });
        }

        async getDuration() {
            return await this.#callInnerPlayer({
                method: 'getDuration'
            });
        }

        async playVideo(...args) {
            return await this.#callInnerPlayer({
                method: 'playVideo',
                args: [...args]
            });
        }

        async stopVideo() {
            return await this.#callInnerPlayer({
                method: 'stopVideo'
            });
        }

        async getVolume() {
            return await this.#callInnerPlayer({
                method: 'getVolume'
            });
        }

        async getVideoData() {
            return await this.#callInnerPlayer({
                method: 'getVideoData'
            });
        }

        async pauseVideo() {
            return await this.#callInnerPlayer({
                method: 'pauseVideo'
            });
        }

        async setVolume(value) {
            return await this.#callInnerPlayer({
                method: 'setVolume',
                args: [value]
            });
        }

        async setSize(width, height) {

            this.iframeConnector.setSize(width, height);

            return await this.#callInnerPlayer({
                method: 'setSize',
                args: [width, height]
            });
        }

        async dispose() {
            this.iframeConnector.dispose();
            this.#ready = false;
            this.iframeConnector = null;
        }
    }

    return MusicPlayer;
});