import angular from 'angular';
import './songMoodsSearch.less';
import template from './songMoodsSearch.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('mySongMoodsSearch', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            model: '=',
            disabled: '=',
            placeholder: '=',
            autoFocus: '=',
            searchAll: '=',
            maxTags: '=',
            onEnter: '&?',
            onAdding: '&?',
            onChanges: '&'
        },
        controller($scope, helper, songService) {

            let moods;
            $scope.state = $scope.model || [];

            $scope.onAddingInt = () => {
                if (typeof $scope.onAdding === 'function') {
                    return $scope.onAdding();
                }

                if (!!$scope.maxTags && $scope.state?.length) {
                    return $scope.state.length < $scope.maxTags;
                }
            };

            $scope.onAdded = () => {
                $scope.onChanges({ state: $scope.state });
            };

            $scope.onRemoved = () => {
                $scope.onChanges({ state: $scope.state });
            };

            $scope.loadMoods = async (query) => {
                try {

                    $scope.loading = true;
                    moods = await songService.getAllMoods();
                    return helper.autoCompleteSearch(query, moods);

                } catch (e) {
                    helper.error(e);
                    throw e;
                } finally {
                    $scope.loading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            };
        },
        link($scope, $element) {
            if ($scope.autoFocus) {
                $($element).find('input').focus();

                $($element).find('input').on('keypress', (e) => {
                    if (e.which === 13) {
                        $scope.onEnter({
                            value: $($element).find('input').val()
                        });
                        return false;
                    }
                });
            }
        }
    };
});
