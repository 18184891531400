import '../../less/jammingPartners.less';
import template from '../../templates/partials/jammingPartners.html';

((angular) => {
    const app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myJammingPartners', ($timeout) => {
        return {
            restrict: 'E',
            template,
            scope: {},
            controller($scope, helper, userJammingService, $interval) {

                let timer;

                $scope.page = 1;

                $scope.itemsPerPage = 10;
                $scope.pagination = {};

                const load = async ({ avoidLoading } = {}) => {

                    try {

                        if (!avoidLoading) {
                            $scope.loading = true;
                        }

                        const { models, pagination } = await userJammingService.load({ page: $scope.page }) || {};

                        $scope.models = models;
                        $scope.pagination = pagination;
                    } catch (e) {
                        helper.error(e);
                    }

                    if (!avoidLoading) {
                        $scope.loading = false;
                    }

                    try { $scope.$digest(); } catch(e) {}
                };

                $scope.getTags = (model) => {
                    const tags = model?.room?.musicTags.map(item => item.name);
                    return tags && tags.join(', ') || 'Everything goes!';
                };

                $scope.gotoRoom = (room) => {
                    helper.redirect(helper.getRoomUrl(room));
                };

                let isCrtlClicked = false;

                $scope.$on('document:keydown', ($evt, $event) => {
                    if($event?.which === 17) {
                        isCrtlClicked = true;
                    }
                });

                $scope.$on('document:keyup', ($evt, $event) => {
                    if($event?.which === 17) {
                        isCrtlClicked = false;
                    }
                });

                $scope.onPartnerClick = ($event) => {

                    $event.preventDefault();

                    const url = $event.currentTarget.href;

                    if (!url) return;

                    helper.trackGaUIEvent('JammingPartnerClicked', null, null, {
                        hitCallback() {
                            if (!isCrtlClicked) {
                                helper.redirect(url);
                            } else{
                                helper.openWindow(url,'_blank');
                            }
                        }
                    });
                };

                $scope.onUserNameClicked = ($event, user) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    helper.gotoState('user-modal', { userName: user.userName });
                };

                $scope.paginate = async (sign) => {
                    $scope.page += sign;
                    if ($scope.page < 1 && $scope.page > $scope.pagination.totalPages) return;
                    await load();
                };

                $scope.$on('$destroy', function() {
                    if (timer) {
                        $timeout.cancel(timer);
                        timer = null;
                    }
                });

                $scope.loading = true;

                (async () => {
                    try {
                        await load();

                        timer = $interval(async () => {
                            try {
                                if ($scope.page === 1) {
                                    await load({ avoidLoading: true });
                                }
                            } catch (e) {
                                helper.error(e);
                            }
                        }, helper.minutesToMs(1));

                    } catch (e) {
                        helper.error(e);
                    }
                })();
            }
        };
    });
})(angular);
