import angular from 'angular';
import {PAYMENT_TYPES} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('googlePayService', () => {

    const baseRequest = {
        apiVersion: 2,
        apiVersionMinor: 0
    };

    const merchantInfo = {
        merchantName: 'Example Merchant',
        merchantId: '12345678901234567890'
    };

    const tokenizationSpecification = {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            'gateway': 'example',
            'gatewayMerchantId': 'exampleGatewayMerchantId'
        }
    };

    const allowedCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "MIR", "VISA"];
    const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

    const baseCardPaymentMethod = {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: allowedCardAuthMethods,
            allowedCardNetworks: allowedCardNetworks
        }
    };

    const cardPaymentMethod = Object.assign(
        { tokenizationSpecification },
        baseCardPaymentMethod
    );


    let paymentsClient;
    let button;

    return {
        async getButton({ paymentType, onClick }) {

            if (button) {
                return button;
            }

            return await new Promise((resolve, reject) => {
                try {
                    const script = document.createElement('script');
                    script.type = "text/javascript";
                    script.async = true;
                    $('body').append(script);
                    script.addEventListener("load", async () => {
                        try {

                            paymentsClient =
                                new google.payments.api.PaymentsClient({environment: 'TEST'});

                            const isReadyToPayRequest = Object.assign({}, baseRequest);
                            isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];

                            const response = await paymentsClient.isReadyToPay(isReadyToPayRequest);

                            if (!response.result) {
                                throw new Error('Something went wrong with GooglePay');
                            }

                            let buttonType;
                            switch (paymentType) {
                                case PAYMENT_TYPES.BEATS_PACKAGE:
                                case PAYMENT_TYPES.CONTRIBUTION:
                                    buttonType = 'pay';
                                    break;
                                case PAYMENT_TYPES.SUBSCRIPTION:
                                    buttonType = 'subscribe';
                                    break;
                                default:
                                    throw new Error('Invalid payment type');
                            }

                            button = paymentsClient.createButton({
                                buttonType,
                                buttonColor: 'white',
                                buttonSizeMode: 'fill',
                                onClick,
                                allowedPaymentMethods: [cardPaymentMethod],
                            });
                            resolve(button);
                        } catch(e) {
                            reject(e);
                        }
                    });
                    script.src = 'https://pay.google.com/gp/p/js/pay.js';

                } catch(e) {
                    reject(e);
                }
            });
        },
        async makePayment({ price, countryCode }) {
            const paymentDataRequest = Object.assign({}, baseRequest);
            paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];

            paymentDataRequest.transactionInfo = {
                totalPriceStatus: 'FINAL',
                totalPrice: price,
                currencyCode: 'USD',
                countryCode
            };

            paymentDataRequest.merchantInfo = merchantInfo;

            const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);

            // if using gateway tokenization, pass this token without modification
            return paymentData.paymentMethodData.tokenizationData.token;

        }
    }
});