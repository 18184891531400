import angular from 'angular';
import './languagesSearch.less';
import template from './languagesSearch.html';
import sharedHelper from '../../../../../shared/sharedHelper';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myLanguagesSearch', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            model: '=',
            disabled: '=',
            placeholder: '=',
            autoFocus: '=',
            searchAll: '=',
            maxTags: '=',
            onAdding: '&?',
            onChanges: '&'
        },
        controller($scope, helper) {

            const languages = sharedHelper.getLanguages();

            $scope.state = $scope.model || [];

            $scope.onAddingInt = () => {
                if (typeof $scope.onAdding === 'function') {
                    return $scope.onAdding();
                }

                if (!!$scope.maxTags && $scope.state?.length) {
                    return $scope.state.length < $scope.maxTags;
                }
            }

            $scope.onAdded = () => {
                $scope.onChanges({ state: $scope.state });
            };

            $scope.onRemoved = () => {
                $scope.onChanges({ state: $scope.state });
            };

            $scope.loadData = async (query) => {
                return helper.autoCompleteSearch(query, languages);
            };
        },
        link($scope, $element) {
            if ($scope.autoFocus) {
                $($element).find('input').focus();
            }
        }
    };
});
