import angular from 'angular';
import {NOTIFICATION_TYPES} from '../../../shared/consts';
const app = angular.module(MyGlobal.page.ngAppName);

app.service('pushNotificationService', (userService, roomService, helper) => {

    return {
        onNotification(notification) {
            try {
                const { type, data } = notification;

                switch(type) {
                    case NOTIFICATION_TYPES.USER_FOLLOWED.key:
                    case NOTIFICATION_TYPES.BUDDIED.key:
                    case NOTIFICATION_TYPES.TRACKED_USER_IS_ONLINE.key:
                        userService.openUserDetails(data.user);
                        break;
                    case NOTIFICATION_TYPES.FEED_DROP_LIKED.key:
                    case NOTIFICATION_TYPES.FEED_DROP_REDROPED.key:
                    case NOTIFICATION_TYPES.FEED_DROP_REPLIED.key:
                    case NOTIFICATION_TYPES.FEED_DROP_USER_MENTIONED.key:
                    case NOTIFICATION_TYPES.FEED_DROP_POLL_VOTED.key:
                        helper.gotoState('feedDrop', {
                            id: data.feedDrop._id
                        });
                        break;
                    case NOTIFICATION_TYPES.USER_START_JAMMING.key:
                    case NOTIFICATION_TYPES.ROOM_JAMMING.key:
                    case NOTIFICATION_TYPES.ROOM_INVITATION.key:
                    case NOTIFICATION_TYPES.ROOM_ADMIN_INVITATION.key:
                    case NOTIFICATION_TYPES.USER_START_BROADCASTING.key:
                    case NOTIFICATION_TYPES.ROOM_OPENED_BY_USER.key:
                        roomService.gotoRoom(data.room);
                        break;
                    case NOTIFICATION_TYPES.ADDED_YOUR_SONG_TO_BEATBOX.key:
                        helper.redirectToWiki(data.data.song, { newPage: true });
                        break;
                    case NOTIFICATION_TYPES.ROOM_ACCESS_REQUEST.key:
                        // todo: open the alert so the user will be able to approve it
                        break;
                    case NOTIFICATION_TYPES.PROFILE_WATCH.key:
                        helper.gotoState('profileWatches');
                        break;

                }
            } catch(e) {
                helper.error(e);
            }
        }
    };
});
