import angular from 'angular';
import './togglePrivacyPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyTogglePrivacyPopupCtrl', ($scope, $uibModalInstance, beatsService, $rootScope,
                                      roomFeaturesService, helper, userService, analyticsService) => {

    $scope.beatsService = beatsService;

    $scope.submit =  async ()=> {
        if ($scope.loading) return;
        try {

            if(!beatsService.userCanBuy(beatsService.keys.enablePrivacy)){
                helper.trackBeatsEvent('NotEnoughBeatsModalOpened', beatsService.keys.enablePrivacy);

                return helper.gotoState('beatsInsufficient', null, {
                    prev: {
                        name: 'beatzone'
                    }
                });
            }

            $scope.loading = true;

            const features = await userService.updateFeature(userService.features.ENABLE_PRIVACY, true);
            userService.setFeatures(features);

            await beatsService.purchaseHasBeenMade(beatsService.keys.enablePrivacy);

            analyticsService.trackBeatzoneEvent({
                eventAction: 'PrivacyMode',
                eventLabel: 'on'
            });

            $scope.close();

        } catch (e) {
            $rootScope.showError(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = () => {
        $uibModalInstance.dismiss();
    };
});
