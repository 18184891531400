/**
 * Created by Shlomi on 09/09/2016.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myCountLettersElement', function(helper, $timeout) {
        return {
            restrict: 'A',
            scope: {},
            link: function ($scope, $element, $attributes) {

                var _counterElemClass = '.letterCounter';

                var _jqElem = $($element);

                if(!(_jqElem.is('input') || _jqElem.is('textarea'))){
                    return helper.error('Trying to use CountLettersElement directive on a non `input` or `textarea` element');
                }

                var _jqElemParent = $(_jqElem.parents()[0]);

                var _maxLength = _jqElem.attr('maxlength') || $attributes.myCountLettersElementMaxLength

                var _dispose = function () {
                    _jqElemParent.removeClass('letterCounterHolder');
                    _jqElem.find(_counterElemClass).remove();
                    _jqElem.off('keypress', _onKeyPress);
                };

                var _updateView = function () {

                    var length = _jqElem.val().length;
                    var counterElem = _jqElemParent.find(_counterElemClass);

                    counterElem.text(length+'/'+_maxLength);

                    counterElem[length > _maxLength ? 'addClass' : 'removeClass']('overflow');
                };

                var _getCounterElem = function () {
                    return $('<div class="letterCounter"></div>');
                };

                var _onKeyPress = function () {
                    _updateView();
                };

                // Event delegation for all future buttons
                _jqElem.on('keyup', _onKeyPress);

                _jqElemParent.addClass('letterCounterHolder');

                _jqElemParent.append(_getCounterElem());

                $scope.$on('$destroy', function() {
                    helper.debug('Destroying CountLettersElement');
                    _dispose();
                });

                $timeout(function () {
                    _updateView();
                });
            }
        };
    });
}(angular));
