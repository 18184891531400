/**
 * Created by Shlomi on 29/09/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.directive('myThemeThumbnail', function() {
        return {
            restrict: 'E',
            templateUrl: '/static/templates/partials/themeThumbnail.html',
            scope: {
                theme: '=',
                onThemeClicked: '&'
            }
        };
    });
}(angular));
