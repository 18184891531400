import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myCtrlEnterInput', () => {
    return {
        restrict: 'A',
        scope: {
            ctrlEnterSubmitFunc: '&'
        },
        controller($scope, helper) {
            if (typeof $scope.ctrlEnterSubmitFunc === 'function') {
                $scope.$on('document:ctrl_enter', async () => {
                    try {
                        await $scope.ctrlEnterSubmitFunc();
                    } catch(e) {
                        helper.error(e);
                    } finally {
                        try { $scope.$digest(); } catch(e) {}
                    }
                });
            }
        }
    };
});
