const SHARE_TARGET_TYPES = {
    FACEBOOK: 'facebook',
    MASTODON: 'mastodon',
    MESSENGER: 'messenger',
    PINTEREST: 'pinterest',
    POCKET: 'pocket',
    REDDIT: 'reddit',
    TELEGRAM: 'telegram',
    TWITTER: 'twitter',
    VIBER: 'viber',
    WHATSAPP: 'whatsapp',
    LINE: 'line',
    EMAIL: 'email',
    //   BEATSENSE: 'beatsense',
    LINK: 'link'
};

const SHARE_ENTITY_TYPES = {
    SONG: 'song',
    ROOM: 'room',
    USER: 'user',
    FEED_DROP: 'feedDrop',
    AMBASSADOR: 'ambassador'
};

const USER_REF_TYPES = {
    BEATSTER: 'beatster',
    AMBASSADOR: 'ambassador'
};

const EXTERNAL_REF_TYPES = {
    INFLUENCER: 'influencer'
};

module.exports = {
    SHARE_TARGET_TYPES,
    SHARE_ENTITY_TYPES,
    USER_REF_TYPES,
    EXTERNAL_REF_TYPES
};