import angular from 'angular';
import './addLongSongPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyLongSongPopupCtrl', ($scope, $uibModalInstance, $stateParams, beatsService,
                                       helper, analyticsService, userSongService, $rootScope) => {

    const {
        id,
        duration,
        playItNext,
        launchToTop,
        userBeatBoxCase
    } = $stateParams;

    $scope.beatsService = beatsService;
    $scope.addLongDurationSongCost = beatsService.getFeaturePrice(beatsService.keys.addLongDurationSong);

    $scope.ytSong = {
        id,
        duration
    };

    const options = {
        playItNext,
        launchToTop
    };

    $scope.addSong = async () => {
        try {
            // In case the user not have enough beats to buy this feature
            if(!beatsService.userCanBuy(beatsService.keys.addLongDurationSong)){
                helper.trackBeatsEvent('NotEnoughBeatsModalOpened', 'AddNewSongOnAllowLongSong');

                return helper.gotoState('beatsInsufficient', null, {
                    prev: {
                        name: 'addLongSong',
                        params: {
                            id,
                            duration,
                            ...options
                        }
                    }
                });
            }

            analyticsService.trackUIEvent({
                eventAction: 'AddSongClicked',
                eventLabel: 'long'
            });

            $scope.loading = true;

            const data = await userSongService.addSong({
                youtubeId: $scope.ytSong.id,
                beatCaseId: userBeatBoxCase,
                purchase: {
                    type: 'longSong'
                },
                ...options
            });

            analyticsService.trackBeatsEvent({
                eventAction: 'AddLongDurationSong'
            });

            await beatsService.purchaseHasBeenMade(beatsService.keys.addLongDurationSong);

            $rootScope.showNotification(data.message);
            $scope.close();
        } catch (e) {

            if (e.message === 'InsufficientBeats') {
                return helper.gotoState('beatsInsufficient', {
                    reason: 'addLongSong'
                });
            }

            $scope.error = e?.message;
        }
    };

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };
});
