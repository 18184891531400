
import angular from 'angular';

import './chargeForRoomEntrance.less';
import template from './chargeForRoomEntrance.html';

import { ROOM_DEFAULT_ENTRANCE_FEE } from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myChargeForRoomEntrance', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            room: '=',
            defaultValue: '='
        },
        controller($scope) {

            $scope.checked = $scope.defaultValue;
            $scope.room.entranceFee = $scope.room.entranceFee || ROOM_DEFAULT_ENTRANCE_FEE;
            $scope.currFee = $scope.room.entranceFee;

            $scope.onChargeForRoomChanged = (state) => {
                if (state) {
                    $scope.updateFee();
                } else {
                    delete $scope.room.entranceFee;
                }
            };

            $scope.updateFee = ()=> {
                $scope.room.entranceFee = $scope.currFee;
            }
        }
    };
});
