import angular from 'angular';

import './usersPopup.less';
import {
    USERS_SORT_OPTIONS_KEYS,
    USERS_SORT_OPTIONS_STORAGE_KEY,
    USERS_SORT_OPTIONS
} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyUsersPopupCtrl', ($scope, $uibModalInstance, helper, userService, $timeout, storage) => {

    const listHolderSelector = '#usersPopup .listHolder';

    let pagination;
    let scrollLocked;
    let currPage = 1;

    $scope.currSort = storage.getItem(USERS_SORT_OPTIONS_STORAGE_KEY) || USERS_SORT_OPTIONS_KEYS.FOLLOWERS;
    $scope.sortOptions = USERS_SORT_OPTIONS;
    $scope.currQuery = '';

    const updateSelectedSort = (key) => {
        $scope.sortOptions = $scope.sortOptions.map(item => {
            if (key === item.key) {
                item.selected = true;
            } else {
                delete item.selected;
            }

            return item;
        });
    };

    const load = async ({ append, what } = {}) => {
        const { models, pagination: newPagination } = await userService.getUsers({
            what,
            query: $scope.currQuery || undefined,
            page: currPage,
            sort: $scope.currSort
        });

        if (append) {
            $scope.users = ($scope.users || []).concat(models);
        } else {
            $scope.users = models;
        }

        pagination = newPagination;

        const { title, field } = userService.getUserSortStatsTitleAndField($scope.currSort);
        $scope.statsTitle = title;
        $scope.statsField = field;

        if ($scope.users.length) {
            $timeout(() => {
                $(listHolderSelector).perfectScrollbar('update');
            });
        }
    };

    $scope.search = async (query) => {
        try {
            currPage = 1;
            $scope.currQuery = query;

            await load();

        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.sort = async (sort)=> {
        try {
            currPage = 1;
            $scope.currSort = sort?.key;

            await load();

            updateSelectedSort(sort);

        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.clearSearch = async () => {
        try {
            currPage = 1;
            $scope.currQuery = '';

            await load();
        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    (async () => {
        try {

            updateSelectedSort($scope.currSort);
            // Load the data - first time
            $scope.loading = true;
            await load({
                append: false
            });

            $timeout(() => {
                const listHolder = $(listHolderSelector);

                listHolder.on('ps-y-reach-end', async () => {
                    try {
                        if (!pagination || pagination.page >= pagination.totalPages) {
                            return;
                        }

                        if(scrollLocked) return;
                        scrollLocked = true;

                        $timeout(() => {
                            scrollLocked = false;
                        }, 500);

                        currPage++;
                        await load({ append: true });
                    } catch(e) {
                        helper.error(e);
                    }
                });
            })
        } catch(e) {
            helper.error(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    })();

});
