/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('themeService', function($q, $http, helper, $rootScope, userService, roomService, storage, $timeout, config){

        let _data;
        let _items = [];
        const _myItems = {};

        const load = ({ page, search, append, filters } = {}) => {
            const localDefer = $q.defer();

            let url = `/theme/all/${page}`;

            if(search){
                url = `${url}?q=${search}`;
            }

            if(filters){
                for(const [key, value] of Object.entries(filters)){
                    url = `${url}${helper.getUrlSeparator(url)}${key}=${value}`;
                }
            }

            $http.get(url).success((data) => {
                _data = data;

                if(!append){
                    _items = []
                }

                _items[page] = _data?.models;

                localDefer.resolve({ result: _items[page], pages: _data.pages });
            }).error(function(err){
                localDefer.reject(err);
            });

            return localDefer.promise;
        };

        return {
            async loadMy(){
                const localDefer = $q.defer();

                $http.get('/theme/my').success((userThemes) => {
                    for(const userTheme of userThemes){
                        _myItems[userTheme.theme] = true;
                    }
                    localDefer.resolve();
                }).error(function(err){
                    localDefer.reject(err);
                });

                return localDefer.promise;
            },
            async load(params = {}){
                if(!params?.page || params?.page > _data?.pages) return false;

                if(params.append && _items[params.page]){
                    return {
                        result: _items[params.page],
                        pages: _data.pages
                    };
                }

                return load(params);
            },
            getBodyThemes: function () {
                return $('body')[0].className.match(/\b[a-zA-Z0-9\-]+Theme\b/g);
            },
            getVideoElement(){
                const container = $('#playerAndPlaylistContainer');
                const wrapper = container.find('.videoTheme');
                const video = wrapper.find('video');
                const source = video.find('source');

                return { wrapper, video, source };
            },
            removeVideoTheme(){

                const { wrapper, video, source } = this.getVideoElement();

                if(wrapper?.length && video?.length && source?.length){
                    video.off('canplaythrough');
                    source.off('error');
                    wrapper.remove();
                }
            },
            removeImageTheme() {
                const body = $('body');
                const existThemes = this.getBodyThemes();

                if(existThemes && existThemes.length){
                    existThemes.forEach(function (existTheme) {
                        body.removeClass(existTheme);
                    });
                }
            },
            removeTheme() {
                if ($('body').hasClass('videoTheme')) {
                    this.removeVideoTheme();
                } else {
                    this.removeImageTheme();
                }
            },
            async setInitialBodyTheme(){

                const { theme } = $rootScope?.room;

                if(theme){
                    if(theme.type === 'video' && !$rootScope.isMobile){
                        const video = $('#page-preloader-video');
                        const container = $('#playerAndPlaylistContainer');
                        const wrapper = $(`<div class="videoTheme ${theme.key}">`);

                        video.detach();

                        video.appendTo(wrapper);
                        wrapper.appendTo(container);
                        this.playVideoIfNeed({ theme });
                    }else{
                        await this.restoreBodyTheme({ force: true });
                    }
                }
            },
            playVideoIfNeed({ theme }){
                // Check if this room's video theme should started in paused mode

                let shouldPlay = true;

                const key = this.getPausedThemeStorageKey({ theme });
                if(key){
                    shouldPlay = !storage.getItem(key);
                }

                if(shouldPlay){
                    this.resumeVideo();
                }else{
                    this.pauseVideo();
                }
            },
            getCurrentThemeKey(){
                const theme = roomService.getTheme();
                return theme?.key;
            },
            isCurrentVideoTheme(){
                const theme = roomService.getTheme();
                return theme?.type === 'video';
            },
            isCurrentThemeVideoPausedPermanent(){
                const key = this.getPausedThemeStorageKey();
                return !!(key && storage.getItem(key));
            },
            async setBodyTheme (theme, options = {}) {
                if(!theme || !theme.key){
                    theme = { key: 'default' };
                }

                const { songId } = options;
                const { key, type, settings } = theme;

                const keyClass = `${theme.key}Theme`;
                let typeClass = theme.type === 'image' ? 'imageTheme' : 'videoTheme';

                const body = $('body');
                const container = $('#playerAndPlaylistContainer');

                // If the theme is the current one, dont do anything
                if(theme.type === 'image' && body.hasClass(keyClass)) return;
                if(theme.type === 'video' && container.find('.videoTheme').hasClass(keyClass)) return;

                if(type === 'image'){

                    await new Promise((resolve, reject) => {
                        const img = new Image();

                        img.addEventListener('load', () => {
                            resolve();
                        }, false);

                        img.addEventListener('error', (e) => {
                            reject(e);
                        }, false);

                        img.src = `${config.getStaticBaseUrl()}/images/themes/${key}.jpg`;
                    });

                    if(songId){
                        body.attr('data-theme-for-song', songId);
                    }

                    this.removeVideoTheme();

                }else if(type === 'video' && !$rootScope.isMobile){
                    const wrapper = $(`<div class="videoTheme ${keyClass}">`);
                    const video = $('<video>');
                    const source = $('<source>');

                    video.attr('muted', '');
                    video.attr('loop', '');

                    if(settings?.playbackRate){
                        video.get(0).playbackRate = settings?.playbackRate;
                    }

                    await new Promise((resolve, reject) => {

                        let resolved = false;

                        const done = () => {
                            if(resolved) return;
                            resolved = true;
                            $timeout(() => {
                                resolve();
                            }, 500);
                        };

                        video.one('canplay', () => {
                            done();
                        });

                        source.on('error', (e) => {
                            reject(e);
                        });

                        source.attr('src', `${config.getStaticBaseUrl()}/videos/themes/${key}.mp4`);

                        video.append(source);
                        wrapper.append(video);

                        if (source.readyState > 3) {
                            done();
                        }
                    });

                    this.removeVideoTheme();

                    container.prepend(wrapper);
                    body.addClass('videoTheme');

                    if(!options.preview){
                        this.playVideoIfNeed({ theme });
                    }else{
                        this.resumeVideo();
                    }
                }

                // Clean image theme
                this.removeImageTheme();

                if(options.preview){
                    body.addClass('preview');
                }

                body.addClass(keyClass);
                body.addClass(typeClass);
            },
            async restoreBodyTheme(options = {}) {

                const body = $('body');

                if(options.ignoreSongId && body.attr('data-theme-for-song') === options.ignoreSongId){
                    return;
                }

                if(options.force || body.hasClass('preview')){
                    await this.setBodyTheme($rootScope.room ? $rootScope.room.theme : undefined);
                    body.removeClass('preview');
                }
                body.removeAttr('data-theme-for-song');
            },
            getPausedThemeStorageKey({ theme } = {}){
                const roomId = roomService.getRoomId();
                const themeKey = theme?.key || this.getCurrentThemeKey();
                if(!roomId || !themeKey) return;
                return `videoThemePaused.${roomId}.${themeKey}`
            },
            resumeVideo({ changeVisibility, persist } = {}){

                if(changeVisibility && this.isCurrentThemeVideoPausedPermanent()) return;

                const { video } = this.getVideoElement();

                if(video?.length){

                    if(changeVisibility){
                        video.show();
                    }

                    const elem = video[0];
                    if(elem.paused){
                        helper.debug('resuming theme video');
                        elem.play();
                    }

                    if(persist){
                        const key = this.getPausedThemeStorageKey();
                        if(key){
                            storage.removeItem(key);
                        }
                    }
                }
            },
            pauseVideo({ changeVisibility, persist } = {}){

                if(changeVisibility && this.isCurrentThemeVideoPausedPermanent()) return;

                const { video } = this.getVideoElement();

                if(video?.length){

                    if(changeVisibility){
                        video.hide();
                    }

                    const elem = video[0];
                    helper.debug('pausing theme video');
                    elem.pause();

                    if(persist){
                        const key = this.getPausedThemeStorageKey();
                        if(key){
                            storage.setItem(key, true, helper.daysToMs(90));
                        }
                    }
                }
            },
            getItems: function () {
                return _items;
            },
            userHaveThisTheme: function (theme) {
                return !!_myItems[theme._id];
            },
            userNeedToBuy: function (theme, options = {}) {

                if(this.userHaveThisTheme(theme)){
                    return false;
                }

                if(!options.avoidFreeVideoTheme && theme.type === 'video' && !userService.freeVideoThemeUsed()){
                    return false;
                }

                return !theme.free;
            },
            buyThemeForUser: function (theme) {
                if(!theme) return;

                const defer = $q.defer();

                $http.post('/theme/buy', {
                    theme: theme._id
                }).success(function () {
                    _myItems[theme._id] = true;
                    defer.resolve();
                }).error(defer.reject);

                return defer.promise;
            }
        };
    });

}(angular));
