const app = angular.module(MyGlobal.page.ngAppName);

let timer;
const inMemory = {};

const isLocalStorageEnabled = () => {
    try {
        localStorage.getItem('aaaa');
        return true;
    } catch(e) {
        if (e instanceof DOMException) {
            return false;
        }

        // Usually Android case when user disabled localStorage
        if (e instanceof TypeError && e.message === 'Cannot read property \'getItem\' of null') {
            return false;
        }

        throw e;
    }
};

const enabled = isLocalStorageEnabled();

app.factory('storage', ($injector, $interval) => {
    return {
        init() {

            const helper = $injector.get('helper');

            if (!enabled) {
                helper.log('localStorage/cookies are disabled, some functionality (e.g. login) won\'t work, pls enable it and try again');
                return;
            }

            timer = $interval(() => {

                helper.debug('cleaning storage');

                for (let i=0;i<localStorage.length;i++){
                    this.getItem(localStorage.key(i), {
                        ignoreJsonError: true
                    });
                }
            }, helper.minutesToMs(2));
        },
        // ttl in milliseconds
        setItem(key, value, ttl) {

            if (!enabled) return;

            // Default 24 hours
            ttl = ttl || 24 * 60 * 60 * 1000;

            const now = new Date();
            const expiry = ttl > -1 ? now.getTime() + ttl : ttl;

            const data = JSON.stringify({
                value: value,
                expiry
            });

            try {
                //throw new Error('The quota has been exceeded.');
                localStorage.setItem(key, data);
            } catch (e) {
                if (e?.message?.includes('The quota has been exceeded.')) {
                    // Use in mem cache in case something failed with storage set
                    inMemory[key] = data;
                } else {
                    throw e;
                }
            }
        },
        getItem(key, options = {}) {

            if (!enabled) return null;

            let itemStr = typeof inMemory[key] === 'undefined' ? localStorage.getItem(key) : inMemory[key];
            if (!itemStr) return null;

            const helper = $injector.get('helper');

            let item;

            try {
                item = JSON.parse(itemStr);
            } catch (e) {

                if (options.ignoreJsonError) {
                    return itemStr;
                }

                helper.error(e);
                helper.error(`Corrupted storage key: ${key}, value: ${itemStr}`);
            }

            const now = new Date();

            if (item.expiry >= 0 && now.getTime() > item.expiry) {
                this.removeItem(key);
                return null;
            }

            return item.value;
        },
        removeItem(key) {
            if (!enabled) return;
            localStorage.removeItem(key);
            delete inMemory[key];
        },
        getItemAndRemove(key, options) {
            const res = this.getItem(key, options);
            this.removeItem(key);
            return res;
        },
        removeItems(keys) {
            if (!keys?.length) return;
            for (const key of keys) {
                this.removeItem(key);
            }
        },
        setAlmostForeverItem: function(key, value){
            const helper = $injector.get('helper');
            this.setItem(key, value, helper.daysToMs(90));
        },
        setForeverItem: function(key, value){
            this.setItem(key, value, -1);
        },
        getDateItem(key) {
            return new Date(this.getItem(key));
        },
        has(key) {
            const val = this.getItem(key);
            return typeof val !== 'undefined' && val !== null;
        }
    };
});

