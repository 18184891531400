/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('modalService', () => {

        const _prevs = [];

        return {
            push: function(name){
                _prevs.push(name);
            },
            pop: function(){
                return _prevs.pop();
            }
        };
    });
}(angular));
