import angular from 'angular';
import './closeButton.less';
import template from './closeButton.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myCloseButton', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            text: '=',
            tooltip: '=',
            onClick: '&'
        }
    };
});
