import angular from 'angular';
import './inboxItem.less';
import template from './inboxItem.html';

import { CHAT_MESSAGE_TYPES } from '../../../../../../shared/consts';

if (MyGlobal.project.isMobile) {
    import('./inboxItem_mobile.less');
}

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myInboxItem', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            thread: '=',
            onThreadClick: '&',
            onThreadMarkAsReadClicked: '&'
        },
        controller($scope, helper, $rootScope, chatService, userService) {

            $scope.CHAT_MESSAGE_TYPES = CHAT_MESSAGE_TYPES;
            $scope.itsMe = $scope.thread?.from?._id === userService.getUserId();

            // Thread actions
            $scope.onClick = async ($event, thread) => {
                try {
                    $rootScope.$broadcast('chat:open', $scope.itsMe ? thread.to : thread.from);
                    await $scope.onThreadClick({ thread });
                } catch (e) {
                    helper.error(e);
                }
            };

            $scope.onMarkAsReadClick = async ($event, thread) => {
                try {
                    $event.stopImmediatePropagation();
                    await chatService.ackAllUserMessages(thread.from._id);
                    await $scope.onThreadMarkAsReadClicked({ thread });
                } catch (e) {
                    helper.error(e);
                }
            };
        }
    };
});
