import angular from 'angular';
import {SHARE_ENTITY_TYPES, SHARE_TARGET_TYPES, USER_REF_TYPES} from '../../../shared/consts';
import sharedHelper from '../../../shared/sharedHelper';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('shareService', (analyticsService, helper, $rootScope, popupService,
                             userService, storage, mobileAppService) => {

    const refParameter = 'share';
    const userSelectionStorageKey = 'shareTargetSelections';

    // prettier-ignore
    /**
     * getUrl method gets hte following object: {
     *   url: string,
     *   title?: string,
     *   media?: string,
     *   text?: string,
     *   via?: string,
     *   fbAppId?: string
     * }
     */

    const SHARE_TARGETS = [
        {
            key: SHARE_TARGET_TYPES.WHATSAPP,
            name: 'Whatsapp',
            class: 'svgable',
            getUrl: (d) => `https://wa.me/?text=${encodeURIComponent(d.title)}%0D%0A${encodeURIComponent(d.url)}${d.text ? `%0D%0A%0D%0A${encodeURIComponent(d.text)}` : ''}`,
        },
        {
            key: SHARE_TARGET_TYPES.TELEGRAM,
            name: 'Telegram',
            class: 'svgable',
            getUrl: (d) => `https://telegram.me/share/url?url=${encodeURIComponent(d.url)}${d.text ? `&text=${encodeURIComponent(d.text)}` : ''}`,
        },
        {
            key: SHARE_TARGET_TYPES.LINK,
            name: 'Copy link',
            class: 'iconable fas fa-link',
            getUrl: (d) => {
                const url = new URL(d.url);
                const params = new URLSearchParams(url.search);
                url.search = params.toString();
                return url.toString();
            },
        },
        {
            key: SHARE_TARGET_TYPES.EMAIL,
            name: 'Email',
            class: 'iconable fa fa-envelope',
            getUrl: (d) => `mailto://?subject=${encodeURIComponent(d.title)}&body=${d.text ? encodeURIComponent(`${d.text}, `): ''}${encodeURIComponent(d.url)}`,
        },
        {
            key: SHARE_TARGET_TYPES.FACEBOOK,
            name: 'Facebook',
            class: 'svgable',
            getUrl: (d) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(d.url)}`,
        },
        {
            key: SHARE_TARGET_TYPES.TWITTER,
            name: 'Twitter',
            class: 'svgable',
            getUrl: (d) => `https://twitter.com/intent/tweet?url=${encodeURIComponent(d.url)}&text=${encodeURIComponent(d.title)}${d.via ? `&via=${d.via}` : ''}`,
        },
        {
            key: SHARE_TARGET_TYPES.MASTODON,
            name: 'Mastodon',
            class: 'svgable',
            getUrl: (d) => `https://toot.kytta.dev/?text=${encodeURIComponent(d.title)}%0D%0A${encodeURIComponent(d.url)}${d.text ? `%0D%0A%0D%0A${encodeURIComponent(d.text)}` : ''}${d.via ? `%0D%0A%0D%0A${d.via}` : ''}`,
        },
        {
            key: SHARE_TARGET_TYPES.MESSENGER,
            name: 'Messenger',
            class: 'svgable',
            getUrl: (d) => `https://www.facebook.com/dialog/send?app_id=${d.fbAppId}&link=${encodeURIComponent(d.url)}&redirect_uri=${encodeURIComponent(d.url)}`,
        },
        {
            key: SHARE_TARGET_TYPES.PINTEREST,
            name: 'Pinterest',
            class: 'svgable',
            getUrl: (d) => `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(d.url)}&description=${encodeURIComponent(d.title)}${d.media ? `&media=${d.media}` : ''}`,
        },
        {
            key: SHARE_TARGET_TYPES.POCKET,
            name: 'Pocket',
            class: 'svgable',
            getUrl: (d) => `https://getpocket.com/edit.php?url=${encodeURIComponent(d.url)}`,
        },
        {
            key: SHARE_TARGET_TYPES.REDDIT,
            name: 'Reddit',
            class: 'svgable',
            getUrl: (d) => `https://www.reddit.com/submit?title=${encodeURIComponent(d.title)}&url=${encodeURIComponent(d.url)}`,
        },
        {
            key: SHARE_TARGET_TYPES.VIBER,
            name: 'Viber',
            class: 'svgable',
            getUrl: (d) => `viber://forward?text=${encodeURIComponent(d.title)}%0D%0A${encodeURIComponent(d.url)}${d.text ? `%0D%0A%0D%0A${encodeURIComponent(d.text)}` : ''}`,
        },
        {
            key: SHARE_TARGET_TYPES.LINE,
            name: 'Line',
            getUrl: (d) => `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(d.url)}&text=${encodeURIComponent(d.title)}`,
        }
        /*,
        {
            key: SHARE_TARGET_TYPES.BEATSENSE,
            name: 'BeatSense',
            getUrl: (d) => `${helper.getCurrentBaseUrl()}/#/createFeedDrop?text=${encodeURIComponent(d.title)}&url=${encodeURIComponent(d.url)}`,
        }*/
    ];

    const getSongShareParams = (song) => {

        if(!song?.youtubeId || !song?.name) {
            throw new Error('Invalid song params');
        }

        const { youtubeId } = song;

        let url;
        if ($rootScope.isRoomPage) {
            const roomUrl = helper.getRoomUrl($rootScope.room);

            // Link with cache breaker
            url = `${roomUrl}/c/${new Date().getTime()}?ref=${refParameter}#/song/${youtubeId}/sticky`;
        } else {
            url = `${helper.getSongWikiUrl(song)}&ref=${refParameter}/#/song/${youtubeId}`;
        }

        return {url};
    };

    return {
        getShareTargets() {
            const userSelectionKeys = storage.getItem(userSelectionStorageKey) || [];

            const targets = SHARE_TARGETS.filter(item => {
                    return !userSelectionKeys.includes(item.key);
            });

            const userSelections = userSelectionKeys.filter(userSelectionKey => SHARE_TARGETS.find(item => item.key === userSelectionKey)).map(userSelectionKey => {
                return SHARE_TARGETS.find(item => item.key === userSelectionKey);
            });

            targets.unshift(...userSelections);

            return targets;
        },
        getShareURL({ entityType, entityData, target, title, text, ...args }) {

            let url;
            switch (entityType) {
                case SHARE_ENTITY_TYPES.SONG:
                    ({url} = getSongShareParams(entityData));
                    break;
                case SHARE_ENTITY_TYPES.ROOM:
                    url = userService.getAffiliateLink({ room: entityData.room });
                    break;
                case SHARE_ENTITY_TYPES.AMBASSADOR:
                    url = userService.getAffiliateLink({
                        room: entityData.room,
                        ref: USER_REF_TYPES.AMBASSADOR
                    });
                    break;
                case SHARE_ENTITY_TYPES.USER:
                    url = `${helper.getCurrentBaseUrl()}?ref=${refParameter}/#/user/${entityData.userName}`;
                    break;
                case SHARE_ENTITY_TYPES.FEED_DROP:
                    url = `${helper.getCurrentBaseUrl()}?ref=${refParameter}/#/feedDrop/${entityData._id}`;
                    break;
                default:
                    throw new Error('Unknown share entity type');
            }

            let targetUrl = target.getUrl({
                url,
                text,
                title,
                entityType,
                entityData,
                ...args
            });

            if ($rootScope.isMobileApp) {
                targetUrl = mobileAppService.makeUrlExternal(targetUrl);
            }

            return targetUrl;
        },
        updateUserSelection(shareTarget) {
            let currSelections = storage.getItem(userSelectionStorageKey) || [];
            currSelections.unshift(shareTarget.key);
            currSelections = sharedHelper.removeArrayDuplicates(currSelections);
            storage.setItem(userSelectionStorageKey, currSelections);
        }
    }
});