import angular from 'angular';
import template from './feedDropItem.html';
import './feedDropItem.less';

const app = angular.module(MyGlobal.page.ngAppName);

if (MyGlobal.project.isMobile) {
    import('./feedDropItem_mobile.less');
}

import '../feedDropItem/feedDropItemPoll/feedDropItemPoll';
import {FEED_DROP_ATTACHMENT_TYPES, USER_REPORT_TYPES, SHARE_ENTITY_TYPES} from '../../../../../shared/consts';

app.directive('myFeedDropItem', ($timeout, slidingWindowService, $rootScope, helper, songService) => {
    return {
        restrict: 'E',
        template,
        scope: {
            feedDrop: '=',
            readOnly: '=',
            hideActions: '=',
            hideFeedbackActions: '=',
            isReply: '=',
            onDeleted: '&',
            onHashtagSelected: '&?'
        },
        controller($scope, feedService, helper, userService, $rootScope,
                   userNameFilter, analyticsService, popupService) {

            $scope.FEED_DROP_ATTACHMENT_TYPES = FEED_DROP_ATTACHMENT_TYPES;
            $scope.userNameFilter = userNameFilter;
            $scope.helper = helper;

            const isMyDrop = $scope.feedDrop.user?._id === $rootScope.user?.id;

            const actionWrapper = ({ onClick, ...args })=> {

                const origOnClick = onClick;

                onClick = async ({$event, action}) => {
                    try {

                        $event.stopImmediatePropagation();

                        if (!action.allowAnonymous && !helper.isUserLoggedIn()) {
                            helper.gotoState('join');
                            return;
                        }

                        if ($scope.loading[action.name]) return;
                        if (action.confirmable && !confirm('Are you sure?')) return;

                        $scope.loading[action.name] = true;

                        const res = origOnClick({$event, action});

                        if (res instanceof Promise) {
                            return await res;
                        }

                        return res;
                    } catch(e) {
                        helper.error(e);
                    } finally {
                        delete $scope.loading[action.name];
                        try { $scope.$digest(); } catch(e) {}
                    }
                };

                return {
                    ...args,
                    onClick
                };
            };

            $scope.actions = [actionWrapper({
                name: 'delete',
                tooltipText: 'Delete drop',
                className: 'far fa-trash-alt',
                confirmable: true,
                isEnabled() {
                    return (
                            $scope.feedDrop.user
                            && isMyDrop
                        )
                        || $rootScope.isAdmin;
                },
                onClick: async ()=> {
                    await feedService.delete($scope.feedDrop);
                    $scope.onDeleted();
                }
            }), actionWrapper({
                name: 'unStick',
                tooltipText: `UnStick this drop (only admin can see this)`,
                className: 'fas fa-thumbtack',
                confirmable: true,
                isEnabled() {
                    return $scope.feedDrop.isSticky && $rootScope.isAdmin
                },
                onClick: async ()=> {
                    await feedService.unStick($scope.feedDrop._id);
                    $scope.feedDrop.isSticky = false;
                }
            }), actionWrapper({
                name: 'subscribe',
                tooltipText: `Turn on notifications for the drop`,
                className: 'far fa-bell',
                isEnabled() {
                    return !$scope.feedDrop.subscribed
                },
                onClick: async ()=> {
                    const { subscribed } = await feedService.subscribeToFeedDrop({
                        feedDropId: $scope.feedDrop._id
                    });

                    $scope.feedDrop.subscribed = subscribed;

                    $rootScope.showNotification('You\'ll get notified once someone replies');

                    analyticsService.trackFeedEvent({
                        eventAction: 'TurnedDropNotificationsOn'
                    });
                }
            }), actionWrapper({
                name: 'unsubscribe',
                tooltipText: `Notifications are on`,
                className: 'fas fa-bell',
                isEnabled() {
                    return $scope.feedDrop.subscribed;
                },
                onClick: async ()=> {
                    const { subscribed } = await feedService.subscribeToFeedDrop({
                        feedDropId: $scope.feedDrop._id
                    });

                    $scope.feedDrop.subscribed = subscribed;

                    analyticsService.trackFeedEvent({
                        eventAction: 'TurnedDropNotificationsOff'
                    });
                }
            }), actionWrapper({
                name: 'share',
                tooltipText: `Share`,
                className: 'fas fa-share-alt',
                allowAnonymous: true,
                isEnabled() {
                    return true;
                },
                onClick: async ()=> {
                    popupService.openSharePopup({
                        entityType: SHARE_ENTITY_TYPES.FEED_DROP,
                        entityData: $scope.feedDrop,
                        title: 'Check out this drop'
                    });
                }
            }), actionWrapper({
                name: 'unfollow',
                tooltipText: `Unfollow ${$scope.feedDrop.user?.userName}`,
                className: 'fas fa-microphone-alt-slash',
                confirmable: true,
                isEnabled() {
                    return $scope.feedDrop.follows
                        && $scope.feedDrop.user
                        && !isMyDrop;
                },
                onClick: async ()=> {
                    await userService.unFollow($scope.feedDrop.user._id);
                    delete $scope.feedDrop.follows;
                }
            }), actionWrapper({
                name: 'mute',
                tooltipText: `Mute ${$scope.feedDrop.user?.userName}`,
                className: 'fas fa-volume-mute',
                confirmable: true,
                isEnabled() {
                    return !$scope.feedDrop.follows
                        && $scope.feedDrop.user
                        && !isMyDrop;
                },
                onClick: async ()=> {
                    await feedService.mute($scope.feedDrop.user._id);
                    $scope.onDeleted({
                        options: {
                            fake: true
                        }
                    });
                }
            }), actionWrapper({
                name: 'report',
                tooltipText: `Report this drop`,
                className: 'far fa-flag',
                isEnabled() {
                    return !isMyDrop && $scope.feedDrop.user;
                },
                onClick: async ({$event})=> {
                    popupService.openUserReportPopup({
                        $event,
                        type: USER_REPORT_TYPES.FEED_DROP,
                        data: $scope.feedDrop
                    });
                }
            }), actionWrapper({
                name: 'addToPlayList',
                tooltipText: `Add to the playlist`,
                className: 'fas fa-headphones-alt',
                isEnabled() {
                    return $rootScope.isRoomPage &&
                        $scope?.feedDrop?.attachment?.type === FEED_DROP_ATTACHMENT_TYPES.SONG;
                },
                onClick: async ()=> {

                    const { song } = $scope?.feedDrop?.attachment?.content || {};

                    helper.gotoState('song-modal', {
                        id: song.youtubeId,
                        type: 'ytap',
                        name: songService.getSongName(song)
                    });
                }
            })];

            $scope.loading = {};

            $scope.onClick = () => {
                if ($scope.readOnly) return;

                helper.gotoState('feedDrop', {
                    id: $scope.feedDrop._id,
                    feedDrop: $scope.feedDrop,
                    ignoreStack: false
                });
            };

            $scope.toggleLike = async ($event)=> {
                try {
                    $event.stopImmediatePropagation();

                    if(!userService.isLoggedIn()){
                        return helper.gotoState( 'join');
                    }

                    let sign;
                    if (!$scope.feedDrop.liked) {
                        sign = 1;
                        $scope.feedDrop.likes = ($scope.feedDrop.likes || 0) + 1;
                        $scope.feedDrop.liked = true;
                    } else {
                        sign = -1;
                        $scope.feedDrop.likes = $scope.feedDrop.likes > 0 ? $scope.feedDrop.likes - 1 : 0;
                        $scope.feedDrop.liked = false;
                    }

                    $rootScope.$broadcast('feedDrop:liked:toggled', {
                        feedDrop: $scope.feedDrop,
                        sign
                    });

                    await feedService.toggleLike($scope.feedDrop);

                    if ($scope.feedDrop.liked) {
                        analyticsService.trackFeedEvent({
                            eventAction: 'like'
                        });
                    }
                } catch(e) {
                    if (e.status === 409) {
                        $rootScope.showNotification(e.message, { digest: true });
                    } else {
                        helper.error(e);
                    }
                }
            };

            $scope.onReDropClicked = ($event, feedDrop) => {
                $event.stopImmediatePropagation();
                popupService.openFeedReDropReplyPopup({
                    $event, feedDrop
                });
            };

            $scope.onReplyClicked = ($event, feedDrop) => {
                $event.stopImmediatePropagation();
                popupService.openFeedDropReplyPopup({
                    $event, feedDrop
                });
            };

            $scope.$on('document:click', () => {
                $scope.showShareComponent = false;
                try { $scope.$digest(); } catch(e) {}
            });

            $scope.$on(`feedDrop:${$scope.feedDrop._id}:subscribed`, () => {
                $scope.feedDrop.subscribed = true;
            });
        },
        link($scope, $element) {
            $timeout(() => {
                $element.find('.hashtag').click(($evt) => {
                    $evt.stopImmediatePropagation();

                    const { innerText: hashtag } = $evt.currentTarget;

                    if (typeof $scope.onHashtagSelected === 'function') {
                        $scope.onHashtagSelected({ hashtag });
                    } else {

                        const data = {
                            filters: {
                                hashtags: [hashtag]
                            }
                        };

                        if ($rootScope.isHomePage) {
                            $rootScope.$broadcast('feed:show', data);
                        } else {
                            slidingWindowService.open({
                                type: slidingWindowService.types.FEED,
                                data
                            });
                        }

                        helper.replaceHash('/');
                    }
                });
            });
        }
    };
});
