/**
 * Created by Shlomi on 19/09/2014.
 */

/**
 * TODO: convert this file to consts in shared/consts.js
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('config', function(){
        return {
            setData(data){
                this.data = data;
            },
            getSummonAlinaTTL() {
                return this?.data?.project?.summonAlina?.ttlInMinutes;
            },
            getStaticBaseUrl(){
                return this?.data?.project?.staticBaseUrl;
            },
            getLastFmConfig(){
                return this.data?.project?.lastfm;
            },
            getRoomStatRanges() {
                return this.data?.page?.roomSettings?.statsRanges;
            },
            getRanks() {
                return this.data?.project?.userRanks;
            },
            isAnalyticsActive() {
                return !this.data.page.disableGoogleAnalytics && !!this.data.project.googleAnalytics.active;
            },
            getAnalyticsTrackingId() {
                return this.data.project.googleAnalytics.trackingId;
            },
            getBeatsTimerReward() {
                return MyGlobal.project.beats.rewards.beatsTimer;
            },
            getMaxAllowedHoursDurationForSong() {
                return MyGlobal.page.roomSettings.maxAllowedHoursDurationForSong;
            },
            getGiphyApiKey() {
                return MyGlobal.project.giphy.apiKey;
            },
            getTagsSettings() {
                return MyGlobal.project.tags;
            },
            getMinScoresToStartEffect() {
                return MyGlobal.project.minScoresToStartEffect;
            },
            getCurrentSong() {
                return MyGlobal.page.song;
            },
            getMusicMatchSettings() {
                return MyGlobal.project.musicMatchSettings;
            },
            getRoomFeatures() {
                return MyGlobal.project.roomFeatures;
            },
            getRoomFeatureKeys() {
                return MyGlobal.project.roomFeatureKeys;
            },
            getRoomUser() {
                return MyGlobal.page.roomUser;
            },
            getRoomOwner() {
                return MyGlobal.page.roomOwner;
            },
            getAutoVoteSongsCount() {
                return MyGlobal.page.roomSettings.autoVote.songsCount;
            },
            getRecaptchaSiteKey() {
                return MyGlobal.project.recaptcha.siteKey;
            },
            getIsStreamingEnabled() {
                return MyGlobal.project.streaming.enabled;
            },
            getAndroidMobileAppSettings() {
                return MyGlobal.project.mobileApp.android;
            },
            preventAutoplayBlockPopup() {
                return MyGlobal.project.preventAutoplayBlockPopup;
            },
            getContributionEnabled() {
                return MyGlobal.project.contributionEnabled;
            },
            getContactUsEmail() {
                return MyGlobal.project.contactUsEmail;
            },
            getPlayerDomain() {
                return MyGlobal.project.playerDomain;
            }
        }

    });

}(angular));
