import angular from 'angular';
import './roomLevelBadge.less';
import template from './roomLevelBadge.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myRoomLevelBadge', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            room: '=',
            minLevel: '=',
            isNotFeature: '=',
            onClick: '&'
        },
        controller($scope, roomService) {

            $scope.minRoomLevel = roomService.getRoomLevel({ level: $scope.minLevel });
            const roomLevel = roomService.getRoomLevel($scope.room);

            $scope.isEnabled = roomLevel?.value >= $scope.minRoomLevel.value;
            $scope.tooltip = `${$scope.minRoomLevel.name}${$scope.isNotFeature ? '' : ' feature'}`;

            $scope.onClickInt = ($event) => {
                if ($scope.isEnabled) return;
                $scope.onClick({ $event, level: $scope.minLevel });
            }
        }
    };
});
