import '../../components/shared/loginOptions/loginOptions';

import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('LoginCtrl', function ($scope, $rootScope, $uibModalInstance, userService,
                                      $http, helper, pageLoadManager, $timeout) {

    // TODO: ui-router not allowed to move params without use the URL, this is workaround meantime.
    $scope.message = MyGlobal.temp.popupParams && MyGlobal.temp.popupParams.message ? MyGlobal.temp.popupParams.message : '';

    $scope.userService = userService;

    $scope.clearForm = function($event){
        if($event.keyCode === 13) return;
        $scope.error = '';
    };

    $scope.login = async ($event, params) => {
        try {
            // In case the page is invitation page and the user need to login - so pass it to login and automatically approve the invitation after the user will log in successfully on server side.
            if(MyGlobal.page.t && MyGlobal.page.h){
                params.t = MyGlobal.page.t;
                params.h = MyGlobal.page.h;
            }

            // Activate action may settled by pageLoadManager
            pageLoadManager.activateCurrAction();

            $scope.error = '';
            $scope.loading = true;

            const data = await userService.login(params);

            helper.postLogin({
                popupParams: MyGlobal.temp.popupParams,
                ...data
            });
        } catch(e) {
            $scope.error = e?.message || 'unknown error';
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.showJoinPopup = function(){
        $scope.close('join');
    };

    $scope.close = function(what){
        $uibModalInstance.close(what);
    };

    $scope.onJoinNowClicked = function(){
        $scope.showJoinPopup();
        helper.trackGaUIEvent('LoginModalJoinNowClicked');
    };

    helper.trackGaUIEvent('LoginModalOpened');

    $timeout(() => {
        $('#txtEmail').focus();
    });
});

