import angular from 'angular';
import template from './feedDropInput.html';
import './feedDropInput.less';

import '../feed.service';

import '../feedDropItem/feedDropItemGif/feedDropItemGif';
import '../feedDropItem/feedDropItemRoom/feedDropItemRoom';
import '../feedDropItem/feedDropItemSong/feedDropItemSong';
import '../feedDropItem/feedDropItemImage/feedDropItemImage';
import '../../shared/poll/pollBuilder/pollBuilder';

import {FEED_DROP_ATTACHMENT_TYPES, FEED_DROP_MAX_LENGTH} from '../../../../../shared/consts';
import sharedHelper from '../../../../../shared/sharedHelper';

const app = angular.module(MyGlobal.page.ngAppName);

if (MyGlobal.project.isMobile) {
    import('./feedDropInput_mobile.less');
}

app.directive('myFeedDropInput', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            originalFeedDropId: '=',
            parentFeedDropId: '=',
            room: '=',
            autoFocus: '=',
            isAdmin: '=',
            placeholder: '=?',
            type: '=',
            autoDrop: '=',
            extraData: '=?',
            content: '=?',
            onFeedDropCreated: '&'
        },
        controller($scope, feedService, $rootScope, helper, popupService, $timeout, analyticsService,
                   uploadService, userService, songService) {

            $scope.FEED_DROP_ATTACHMENT_TYPES = FEED_DROP_ATTACHMENT_TYPES;
            $scope.uploadService = uploadService;

            $scope.context = {
                val: $scope.type || (($scope.room?._id || $rootScope.isWikiPage) && FEED_DROP_ATTACHMENT_TYPES.SONG)
            };

            $scope.isReDrop = !!$scope.originalFeedDropId;
            $scope.isReply = !!$scope.parentFeedDropId;

            const originalContent = $scope.content;
            let evaluatedAttachment;

            let eventKey;
            switch(true) {
                case $scope.isReDrop:
                    eventKey = 'feedReDropInput';
                    break;
                case $scope.isReply:
                    eventKey = 'feedReplyInput';
                    break;
                default:
                    eventKey = 'feedDropInput';
            }

            $scope.$on(`${eventKey}:gifSelected`, ($evt, gif) => {
                $scope.showAttachment({
                    type: FEED_DROP_ATTACHMENT_TYPES.GIF,
                    content: gif
                });

                $timeout(()=>{
                    $scope.focusInput();
                });
            });

            $scope.openGifs = ($event) => {

                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join');
                    $event.preventDefault();
                    return;
                }

                if (!userService.canPerformPrivilegedFeatures()) {
                    helper.gotoState('contribution');
                    $event.preventDefault();
                    return;
                }

                popupService.openGifsPopup({
                    $event,
                    source: eventKey,
                    text: 'DROP IT!'
                });
            };

            const uploadAttachment = async (attachment, feedDrop)=> {

                if (!attachment?.content?.local?.file) {
                    throw new Error(`Invalid attachment`);
                }

                const { local } = attachment.content;
                delete attachment.content.local;

                const { url } = await uploadService.upload({
                    type: uploadService.FILE_UPLOAD_TYPES.FEED_DROP_ATTACHMENT,
                    file: local.file,
                    objId: feedDrop._id
                });

                return url;
            };

            const isUploadable = (attachment)=> {
                return [FEED_DROP_ATTACHMENT_TYPES.IMAGE].includes(attachment.type);
            }

            $scope.submitDrop = async () => {
                try {

                    if (!helper.isUserLoggedIn()) {
                        helper.gotoState('join');
                        return;
                    }

                    if (!userService.canPerformPrivilegedFeatures()) {
                        helper.gotoState('contribution');
                        return;
                    }

                    const isDisabled = $scope.submitDisabled();

                    if (isDisabled || $scope.loading) {

                        if (isDisabled) {
                            helper.focusContenteditableElement('#dropContent');
                        }

                        return;
                    }

                    let poll;
                    if ($scope.showPoll) {
                        if (!$scope.content) {
                            throw new Error('Poll must have a question');
                        }

                        if (!$scope.pollApi) {
                            throw new Error('Poll API not found');
                        }

                        poll = {
                            options: $scope.pollApi.getOptions()
                        }
                    }

                    let { attachment } = $scope;

                    let tmpAttachment;
                    if (attachment && isUploadable(attachment)) {
                        tmpAttachment = {
                            type: attachment.type
                        };
                    } else {
                        tmpAttachment = attachment
                    }

                    $scope.loading = true;

                    const payload = {
                        content: $scope.content || undefined,
                        originalFeedDropId: $scope.originalFeedDropId,
                        parentFeedDropId: $scope.parentFeedDropId,
                        roomId: $scope.room?._id,
                        isAdmin: $scope.isAdmin,
                        attachment: tmpAttachment,
                        status: attachment && isUploadable(attachment) ? 'pending' : undefined,
                        isSticky: $scope.isSticky,
                        poll
                    };

                    if ($scope.autoDrop && $scope.content && originalContent === $scope.content) {
                        payload.autoDrop = true;
                    }

                    const feedDrop = await feedService.createDrop(payload);

                    if (attachment && isUploadable(attachment)) {
                        const url = await uploadAttachment(attachment, feedDrop);

                        $scope.showAttachment({
                            ...attachment,
                            content: {
                                url
                            }
                        });

                        feedDrop.attachment = $scope.attachment;

                        await feedService.updateDrop(feedDrop._id, {
                            status: 'active',
                            attachment: feedDrop.attachment
                        });
                    }

                    $scope.onFeedDropCreated({ feedDrop });

                    delete $scope.content;
                    delete $scope.attachment;

                    if ($scope.showPoll) {
                        $scope.restorePlaceholder();
                        delete $scope.showPoll;
                    }

                    $rootScope.showNotification(helper.getRandomFromArray([
                        'Mic droped!',
                        'Drop sent'
                    ]));

                    analyticsService.trackFeedEvent({
                        eventAction: 'drop',
                        eventLabel:  $scope.room?._id ? 'room' : 'feed'
                    });

                    if (attachment?.type) {
                        analyticsService.trackFeedEvent({
                            eventAction: 'dropType',
                            eventLabel:  attachment.type.toLowerCase()
                        });
                    }

                    if (feedDrop.subscribedToParent && feedDrop.parentFeedDrop) {
                        $rootScope.$broadcast(`feedDrop:${feedDrop.parentFeedDrop}:subscribed`);
                    }

                } catch(e) {
                    $rootScope.showError(e);
                } finally {
                    $scope.loading = false;
                    $scope.removeAttachment();
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.onContextChanged = () => {
                switch ($scope.context?.val) {
                    case FEED_DROP_ATTACHMENT_TYPES.SONG:

                        let song;

                        if($scope.extraData?.song) {
                            song = $scope.extraData.song
                        } else if ($rootScope.isRoomPage) {
                            song = $rootScope.currSong?.song
                        } else if ($rootScope.isWikiPage) {
                            song = $rootScope.song;
                        }

                        $scope.showAttachment({
                            type: FEED_DROP_ATTACHMENT_TYPES.SONG,
                            content: {
                                song: {
                                    _id: song._id,
                                    name: songService.getSongName(song),
                                    youtubeId: song.youtubeId,
                                    ytSqThumbnail: song.ytSqThumbnail,
                                },
                                room: $rootScope.room && {
                                    _id: $rootScope.room?._id,
                                    name: $rootScope.room?.name,
                                    uniqueName: $rootScope.room?.uniqueName
                                }
                            }
                        });
                        break;
                    case FEED_DROP_ATTACHMENT_TYPES.ROOM:
                        $scope.showAttachment({
                            type: FEED_DROP_ATTACHMENT_TYPES.ROOM,
                            content: {
                                _id: $rootScope.room?._id,
                                name: $rootScope.room?.name,
                                user: $rootScope.room?.user,
                                uniqueName: $rootScope.room?.uniqueName,
                                type: $rootScope.room?.type,
                                theme: $rootScope.room?.theme,
                                description: $rootScope.room?.description,
                                musicTags: $rootScope.room?.musicTags,
                            }
                        });
                        break;
                    default:
                        $scope.removeAttachment();
                }

                $scope.focusInput();
            };

            $scope.showAttachment = ({ type, placeholder, content }) => {
                $scope.attachment = {
                    type,
                    content
                };

                if (placeholder) {
                    $scope.changePlaceholder(placeholder);
                }

                $scope.attachementBlocker = true;
            };

            $scope.changePlaceholder = (str) => {
                $scope.prevPlaceholder = $scope.placeholder;
                $scope.placeholder = str;
            }

            $scope.restorePlaceholder = ()=> {
                $scope.placeholder = $scope.prevPlaceholder;
            }

            $scope.removeAttachment = () => {
                delete $scope.attachment;
                delete $scope.attachementBlocker;
                evaluatedAttachment = false;
                $scope.context = {};
                $scope.restorePlaceholder();
            };

            $scope.onImageSelectionClick = () => {
                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join');
                    return;
                }

                if (!userService.canPerformPrivilegedFeatures()) {
                    helper.gotoState('contribution');
                    return;
                }
            };

            $scope.toggleEmojis = ($event)=> {
                $event.preventDefault();
                $event.stopImmediatePropagation();

                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join');
                    return;
                }

                if (!userService.canPerformPrivilegedFeatures()) {
                    helper.gotoState('contribution');
                    return;
                }


                $scope.initEmojis = true;
                $scope.showEmojis = !$scope.showEmojis;

                if ($scope.showEmojis) {
                    $scope.emojisElement = $($event.target);
                }
            };

            $scope.closeEmojis = ()=> {
                $scope.showEmojis = false;
                try { $scope.$digest(); } catch(e) {}
            };

            $scope.onEmojiSelected = (emoji)=> {
                $scope.content = `${ $scope.content ? `${$scope.content} ` : ''}${emoji.unicode}`;
                try { $scope.$digest(); } catch(e) {}
            };

            $scope.previewImage = async ()=> {
                try {
                    if ($scope.imageFileError && $scope.imageFileError.$error) {
                        switch($scope.imageFileError.$error) {
                            case 'maxSize':
                                throw new Error(`File size should be smaller than ${uploadService.maxSize.feedDrop}`);
                            case 'pattern':
                                throw new Error(`File type is not allowed.`);
                            default:
                                throw new Error(`Unmapped upload error: ${$scope.imageFileError?.$error || 'unknown'}`);
                        }
                    }

                    if(!$scope.imageFile) return;

                    $scope.attachementLoading = true;

                    const url = await uploadService.getFileBase64($scope.imageFile);

                    $scope.showAttachment({
                        type: FEED_DROP_ATTACHMENT_TYPES.IMAGE,
                        content: {
                            url,
                            title: 'Feed Drop Image',
                            local: {
                                file: $scope.imageFile
                            }
                        }
                    });

                } catch(e) {
                    $rootScope.showError(e);
                    delete $scope.imageFile;
                    delete $scope.imageFileError;
                } finally {
                    delete $scope.attachementLoading;
                    try { $scope.$digest(); } catch(e) {}
                }
            }

            $scope.getPollApi = (api) => {
                $scope.pollApi = api;
            };

            $scope.togglePoll = ($event) => {
                $event.preventDefault();
                $event.stopImmediatePropagation();

                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join');
                    return;
                }

                if (!userService.canPerformPrivilegedFeatures()) {
                    helper.gotoState('contribution');
                    return;
                }

                $scope.showPoll = !$scope.showPoll;

                if ($scope.showPoll) {
                    $scope.changePlaceholder('Ask a question...');
                } else {
                    $scope.restorePlaceholder();
                }
            };

            $scope.evaluateInputContent = async (text)=> {
                try {
                    if (!$scope.attachment || evaluatedAttachment) {
                        if (songService.isWikiURL(text) || sharedHelper.isYouTubeLink(text)) {
                            const links = helper.getLinksInText(text);

                            const wikiLink = links?.find(item => {
                                return songService.isWikiURL(item.href);
                            });

                            const youtubeLink = !wikiLink && links?.find(item => {
                                return  sharedHelper.isYouTubeLink(item.href);
                            });

                            const youtubeId = wikiLink ?
                                helper.getUrlParam('youtube', wikiLink.href) :
                                helper.getUrlParam('v', youtubeLink.href);

                            // Cache
                            if (evaluatedAttachment !== youtubeId) {

                                let song;

                                try {
                                    const {song: tmpSong} = await songService.getByYouTubeId({youtubeId}) || {};
                                    song = tmpSong;
                                } catch(e) {}

                                $scope.removeAttachment();

                                if (song) {
                                    $timeout(() => {
                                        $scope.showAttachment({
                                            type: FEED_DROP_ATTACHMENT_TYPES.SONG,
                                            content: {
                                                song: {
                                                    _id: song._id,
                                                    name: songService.getSongName(song),
                                                    youtubeId: song.youtubeId,
                                                    ytSqThumbnail: song.ytSqThumbnail,
                                                }
                                            }
                                        });
                                    });

                                    evaluatedAttachment = youtubeId;
                                } else {
                                    evaluatedAttachment = false;
                                }
                            }
                        } else {
                            evaluatedAttachment = false;
                        }
                    }
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.submitDisabled = () => {
                return ((!$scope.content || helper.isEmptyHTML($scope.content)) && !$scope.isReDrop && !$scope.attachment);
            };

            if( $scope.context.val ) {
                $timeout(() => {
                    $scope.onContextChanged();
                });
            }
        },
        link($scope, $element) {

            const dropContent = $($element).find('#dropContent');

            $scope.focusInput = () => {
                dropContent.focus();
            };

            dropContent.on('keydown paste', function(event) {
                const text = $(this).text();
                if(!$scope.isAdmin && text.length >= FEED_DROP_MAX_LENGTH && ![8, 17, 19].includes(event.keyCode)) {
                    $(this).text(text.substr(0, FEED_DROP_MAX_LENGTH));
                    event.preventDefault();
                }
            });

            dropContent.on('input', function() {
                const text = $(this).text();
                $scope.evaluateInputContent(text);
            });

            if ($scope.autoFocus) {
                $scope.focusInput();
            }
        }
    };
});
