
import './loginOptions.less';

import angular from 'angular';

import template from './loginOptions.html';
import {LOGIN_OPTIONS, LOGIN_OPTIONS_KEYS} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myLoginOptions', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            join: '=',
            onEmailSelected: '&',
            onError: '&'
        },
        controller($scope, helper, mobileAppService, userService) {

            $scope.loading = {};
            $scope.loginOptions = LOGIN_OPTIONS;

            if (!$scope.join) {
                $scope.loginOptions = $scope.loginOptions.filter(item => item.key !== LOGIN_OPTIONS_KEYS.EMAIL);
            }

            $scope.submit = async (key) => {

                if ($scope.loading[key]) return;

                try {

                    if (key === LOGIN_OPTIONS_KEYS.EMAIL) {
                        return $scope.onEmailSelected();
                    }

                    $scope.loading[key] = true;

                    if (mobileAppService.isMobileApp() && key === LOGIN_OPTIONS_KEYS.FACEBOOK) {
                        const accessToken = await mobileAppService.facebookLogin();
                        await userService.socialAccessTokenLogin(key, accessToken);
                    } else {
                        await userService.socialRedirectLogin(key);
                    }

                } catch(e) {
                    helper.error(e);
                } finally {
                    //delete $scope.loading[key];
                    try { $scope.$digest(); } catch(e) {}
                }
            };
        }
    };
});
