/**
 * Created by Shlomi on 12/09/2014.
 */

import XRegExp from 'xregexp';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import jwt_decode from 'jwt-decode';

import sharedHelper from '../../../shared/sharedHelper';

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.factory('helper', function($http, $log, $state, $timeout,
                                   modalService, $rootScope, $location, $q, storage,
                                   config, $injector, httpService, $interval, $window){
        return {
            _origTitle: null,
            userNameValidation: { //change here should also be implemened in userHelper.js
                minLength: 2,
                maxLength: 15,
                regex:    /^([\p{L}\p{N}]+([_\-\.]{1}[\p{L}\p{N}]+)*)$/,
                regexOpen:/[\p{L}\p{N}]+([_\-\.]{1}[\p{L}\p{N}]+)*/,
                messages: {
                    min: 'Username must be at least 2 characters long.',
                    max: 'Username can be maximum of 15 characters long.',
                    invalid: 'Please use letters or numbers. Underscore, and dot can be used inside the username',
                    password: 'Password has to be at least 5 characters long'
                }
            },
            passwordValidation: {
                minLength: 5,
                messages: {
                    min: 'Password has to be at least 5 characters long.',
                    incorrect: 'Invalid current password'
                }
            },
            emailValidation: {
                messages: {
                    invalid: 'Invalid email address'
                }
            },
            biographyValidation: {
                maxLength: 1000,
                messages: {
                    invalid: 'You are much of a talker, don\'t you? Try to make it shorter.'
                }
            },
            isValidPassword: function(password){
                return password && password.length >= this.passwordValidation.minLength;
            },
            isPasswordLongEnough: function(value){
                return value && value.length >= this.passwordValidation.minLength;
            },
            isUsernameLongEnough: function(value){
                return value && value.length >= this.userNameValidation.minLength;
            },
            isUsernameShortEnough: function(value){
                return value && value.length <= this.userNameValidation.maxLength;
            },
            isValidUsername: function(value){
                return new XRegExp(this.userNameValidation.regex.source).test(value);
            },
            cookiePrefix: MyGlobal.project.cookiePrefix,
            isUserLoggedIn: function(){
                return !!MyGlobal.user;
            },
            getCurrUserId: function(){
                return MyGlobal.user ? MyGlobal.user.id : undefined;
            },
            getCurrUserName: function(){
                return MyGlobal.user ? MyGlobal.user.userName : undefined;
            },
            isUndefined: function(value){
                return typeof value === 'undefined';
            },
            async reloadPageWait(ttl, ...params) {
                await this.wait(ttl);
                this.reloadPage(...params);
            },
            reloadPage(withoutHash, withoutQuery){

                const currUrl = window.location.href;
                let url;

                if (withoutQuery) {
                    url = currUrl.substr(0, currUrl.indexOf('?'));
                }

                if (withoutHash) {
                    url = currUrl.substr(0, currUrl.indexOf('#'));
                }

                if (url) {
                    window.location.href = url;
                } else {
                    window.location.reload();
                }
            },
            reloadPageWithError({ message, ttl = 3000 }) {
                $rootScope.showError(message, false, { ttl }).then(() => {
                    this.reloadPage();
                });
            },
            redirect: function(path){
                window.location.href = path;
            },
            openWindow(...args) {
                return $window.open(...args);
            },
            redirectToHome: function(reason, params){
                if(reason){
                    storage.setItem('redirectReason', $.extend({reason: reason}, params), this.secsToMs(10));
                }
                this.redirect('/');
            },
            redirectToWiki(song, { newPage } = {}) {
                const url = this.getSongWikiUrl(song);

                if (newPage) {
                    return this.openWindow(url, '_blank');
                }

                return this.redirect(url);
            },
            replaceHash: function(hash){

                if(hash instanceof Array){
                    var str = '';
                    hash.forEach(function(value){
                        str = str.concat('/'+value);
                    });
                    hash = str.substr(1);
                }

                $location.path(hash).replace();
            },
            isValidEmail: function(email){

                if(email instanceof Array){
                    for(var i=0;i<email.length;i++){
                        var res = this.isValidEmail(email[i]);
                        if(!res) return res;
                    }
                    return true;
                }
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            isValidBiography: function (value) {
                return !value || value.length <= this.biographyValidation.maxLength;
            },
            hoursToSeconds: function(hours){
                return hours*60*60;
            },
            hoursToMs: function(hours){
                if(!hours) return;
                return this.hoursToSeconds(hours)*1000;
            },
            hoursToMinutes(hours){
                return hours*60;
            },
            minutesToHours(mins) {
                return mins/60;
            },
            getCookie: function(key, options = {}){
                if (key && !options.skipPrefix) {
                    key = this.cookiePrefix + '.' + key;
                }

                var i, tmpKey, tmpVal, arrCookies = document.cookie.split(";");
                for (i = 0; i < arrCookies.length; i++) {

                    tmpKey = arrCookies[i].substr(0, arrCookies[i].indexOf("="));
                    tmpVal = arrCookies[i].substr(arrCookies[i].indexOf("=") + 1);

                    tmpKey = tmpKey.replace(/^\s+|\s+$/g, "");
                    if (tmpKey === key) {
                        var val = unescape(tmpVal);
                        if (options) {

                            if (options.jsonDecode) {
                                return JSON.parse(val);
                            }

                            if (options.boolVal || options === true || val === 'false' || val === 'true') {
                                if (val === 'false') {
                                    val = false;
                                }
                                return Boolean(val);
                            }
                        }
                        return val;
                    }
                }

                return null;
            },
            deleteCookie(key) {
                document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
            },
            getLogTimeStr: function(){
                const d = new Date();
                const month = d.getMonth()+1;
                const obj = {
                    day: d.getDate() < 10 ? '0'+d.getDate() : d.getDate(),
                    month: month < 10 ? '0'+month : month,
                    year: d.getFullYear(),
                    hours: d.getHours() < 10 ? '0'+d.getHours() : d.getHours(),
                    minutes: d.getMinutes() < 10 ? '0'+d.getMinutes() : d.getMinutes(),
                    seconds: d.getSeconds() < 10 ? '0'+d.getSeconds() : d.getSeconds()
                };

                //return '['+ obj.year+'-'+ obj.month+'-'+ obj.day+' ' +obj.hours+ ':' + obj.minutes + ':' + obj.seconds + ']';
                return `[${obj.year}-${obj.month}-${obj.day} ${obj.hours}:${obj.minutes}:${obj.seconds}]`;
            },
            log: function(msg){
                $log.log(msg);
            },
            setAlertConsole: function (state) {
                this.alertInConsole = state;
            },
            debug: function(msg, force){

                force = force || this.getUrlParam('debug') || MyGlobal.temp.debug;

                if((MyGlobal && MyGlobal.project && ['dev', 'test'].indexOf(MyGlobal.project.env) > -1) || force){
                    msg = this.getLogTimeStr() + ' ' + msg;
                    $log.debug(msg);
                }

                if(this.alertInConsole){
                    alert(msg);
                }
            },
            isDev: function(){
                return MyGlobal.project.env === 'dev';
            },
            isProd: function(){
                return MyGlobal.project.env === 'prod';
            },
            error: function(msg){
                $log.error(msg);
            },
            info: function(msg){
                $log.info(msg);
            },
            secondsToTime: function(secs){

                if(typeof secs === 'undefined') return '';

                secs = Math.round(secs);

                var hours = Math.floor(secs / (60 * 60));

                var divisor_for_minutes = secs % (60 * 60);
                var minutes = Math.floor(divisor_for_minutes / 60);

                var divisor_for_seconds = divisor_for_minutes % 60;
                var seconds = Math.ceil(divisor_for_seconds);

                return {
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds
                };
            },
            daysToMs: function(days){
                return days*24*60*60*1000;
            },
            minutesToMs: function(min){
                return min*60*1000;
            },
            minutesToSecs: function(mins){
                return mins*60;
            },
            secsToMs: function(secs){
                return secs*1000;
            },
            secsToMins: function(secs){
                return secs/60;
            },
            secsToHours: function(secs){
                return secs/60/60;
            },
            msToSeconds: function(ms, round){
                let res = ms/1000;

                if (round) {
                    if (round > 0) {
                        res = Math.ceil(res);
                    } else {
                        res = Math.floor(res);
                    }
                }

                return res;
            },
            msToMinutes: function(ms){
                return this.msToSeconds(ms)/60;
            },
            msToHours: function(ms){
                return this.msToMinutes(ms)/60;
            },
            msToDays(ms) {
                return this.msToHours(ms)/24;
            },
            addLeadingZero: function(num){
                if(num < 10){
                    return '0' + num;
                }
                return num;
            },
            getLoggedInSockets: function(sockets){
                var users = [], dic = {};
                if(sockets && sockets instanceof Array){
                    for(var i=0;i<sockets.length;i++){
                        if(sockets[i] && sockets[i].user && sockets[i].user.id && !dic[sockets[i].user.id]){
                            users.push(sockets[i]);
                            dic[sockets[i].user.id] = true;
                        }
                    }
                }
                return users;
            },
            getSpectatorsSockets: function(sockets){
                var arr = [];
                if(sockets && sockets instanceof Array){
                    for(var i=0;i<sockets.length;i++){
                        if(sockets[i] && !sockets[i].user){
                            arr.push(sockets[i]);
                        }
                    }
                }
                return arr;
            },
            onWindowResize: function() {
                var width = $(window).width();

                if (width >= 1200) {
                    $('body').addClass('lg').removeClass('md sm xs');
                } else if (width >= 980) {
                    $('body').addClass('md').removeClass('lg sm xs');
                } else if (width >= 767) {
                    $('body').addClass('sm').removeClass('md lg xs');
                } else {
                    $('body').addClass('xs').removeClass('md sm lg');
                }
            },
            checkAuthNew(error) {
                return this.checkAuth({ error: error.message, status: error.status });
            },
            checkAuth: function(response){
                if(response.status === 401){

                    if(!this.isUserLoggedIn()){
                        this.gotoState('login', {
                            message: 'You haven\'t been here in a while. Please log in to continue'
                        });
                    }else{
                        $rootScope.showError('Unauthorized operation');
                    }
                }
            },
            gotoState(where, params, options = {}){
                MyGlobal.temp.popupParams = params;
                options.location = 'replace';
                MyGlobal.temp.isHelperTransition = true;

                const go = () => {
                    $state.transitionTo(where, params, options);

                    if(options.prev){
                        $timeout(() => {
                            modalService.push(options.prev);
                        });
                    }
                };

                if (options.nextTick) {
                    $timeout(() => {
                        go();
                    });
                } else {
                    go();
                }
            },
            navigateOnModalClose: function(result){
                var ignoreStack = result && result.ignoreStack;

                var state;
                var params;
                if(!ignoreStack){
                    var transition = modalService.pop();

                    if(typeof transition === 'object'){
                        state = transition.name;
                        params = transition.params;
                    }else{
                        state = transition;
                    }
                }

                if(!state && result && !ignoreStack){

                    if(result.state){
                        state = result.state;
                        params = result.params;
                    }else if(typeof result === 'string'){
                        state = result;
                    }
                }

                this.gotoState(state || 'default', params);
            },
            isLink: function(link){
                return link && (link.indexOf('http://') > -1 || link.indexOf('https://') > -1 || link.indexOf('//') > -1);
            },
            isValidUrl: function(str){
                var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                return pattern.test(str);
            },
            getCurrentFullUrl({ withoutHash } = {}) {
                if (!withoutHash) {
                    return location.href;
                }

                const { pathname, search } = location;
                return `${this.getCurrentBaseUrl()}${pathname}${search}`;
            },
            getCurrentBaseUrl() {
                const { protocol, host } = location;
                return `${protocol}//${host}`;
            },
            getCurrentUrlHash: function() {
                return location.hash;
            },
            getUrlParamBoolean(...args) {
                  const value = this.getUrlParam(...args);
                  return value === '1' || value === 'true' || value === true;
            },
            getUrlParam(key, url) {
                const urlObj = new URL(url || location.href);
                const val = urlObj?.searchParams.get(key);
                return val === '' || val;
            },
            getUrlParams(){
                try{
                    let tmp = location.search.substr(1);
                    tmp = tmp.split('&');
                    if(tmp instanceof Array){
                        const obj = {};
                        for(let i=0;i<tmp.length;i++){
                            const param = tmp[i].split('=');
                            if(param instanceof Array && param.length === 2){
                                obj[param[0]] = decodeURIComponent(param[1]);
                            }
                        }
                        return obj;
                    }
                }catch(e){
                    this.log(e);
                }
            },
            // DEPRECATED!!! from now on, use analyticsService.trackUIEvent
            trackGaUIEvent: function(action, label, value, options){

                const analyticsService = $injector.get('analyticsService');
                analyticsService.trackEvent({
                    eventCategory: 'UI',
                    eventAction: action,
                    eventLabel: label,
                    eventValue: value,
                    ...options
                });
            },
            // DEPRECATED!!! from now on, use analyticsService.trackBeatsEvent
            trackBeatsEvent: function(action, label, value, options){
                const analyticsService = $injector.get('analyticsService');
                analyticsService.trackEvent({
                    eventCategory: 'Beats',
                    eventAction: action,
                    eventLabel: label,
                    eventValue: value,
                    ...options
                });
            },
            getRandomInt: function (min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            },
            getRandomFromArray: function (stringsArr){
                return stringsArr[this.getRandomInt(0, stringsArr.length-1)];
            },
            getRandomFloat: function(min, max){
                return Math.random() * (max - min) + min
            },
            scrollByArrows: function(options){

                options = options || {};

                var parent = $(options.parent);
                var child = options.child;
                var direction = options.direction;

                if(!parent || !child || !direction) {
                    this.debug('Missing scrollByArrows mandatory options');
                    return;
                }

                var getNextElement = function(){
                    var currFocusedElem =  parent.find(child+'[data-curr-elem]')[direction]();
                    // Return found element or first
                    return currFocusedElem.length ? currFocusedElem : parent.find(child).first();
                };

                var nextElem = getNextElement();

                if((nextElem.index() < 0 && direction === 'prev') || (nextElem.index() === parent.find(child).length && direction === 'next')) return;

                parent.find(child).removeAttr('data-curr-elem');
                parent.find(child).removeClass('hovered');

                nextElem.addClass('hovered');
                nextElem.attr('data-curr-elem', true);
                //nextElem.focus();

                parent.scrollTop(nextElem.index() * nextElem.outerHeight());

                return nextElem;
            },
            getUsersSummary: function({ summary }){
                return summary.f || summary;
            },
            isClientHaveHTML5ForYoutubePlayer: function(){
                var testEl = document.createElement( "video" );
                return typeof testEl.canPlayType === 'function';
            },
            isClientHaveProperFlashVersion: function(){
                return swfobject && swfobject.hasFlashPlayerVersion('10.1');
            },
            async isClientHaveInternetConnection(cb){
                cb($injector.get('mySocket').connected());
            },
            removePagePreloader: function(){
                const pagePreloader = $('#page-preloader');
                if(!pagePreloader.attr('data-sticky')){
                    pagePreloader.remove();
                }

                $('body').removeClass('noscroll');
            },
            onPageVisibilityChanged: function(cb){

                var _this = this;

                // If the page is hidden, pause the video;
                // if the page is shown, play the video
                var handleVisibilityChange = function() {
                    if (document[hidden]) {
                        cb(false);
                    } else {
                        cb(true);
                    }
                };

                // Set the name of the hidden property and the change event for visibility
                var hidden, visibilityChange;
                if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
                    hidden = "hidden";
                    visibilityChange = "visibilitychange";
                } else if (typeof document.mozHidden !== "undefined") {
                    hidden = "mozHidden";
                    visibilityChange = "mozvisibilitychange";
                } else if (typeof document.msHidden !== "undefined") {
                    hidden = "msHidden";
                    visibilityChange = "msvisibilitychange";
                } else if (typeof document.webkitHidden !== "undefined") {
                    hidden = "webkitHidden";
                    visibilityChange = "webkitvisibilitychange";
                }

                // Warn if the browser doesn't support addEventListener or the Page Visibility API
                if (typeof document.addEventListener === "undefined" ||
                    typeof document[hidden] === "undefined") {
                    _this.debug("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
                } else {
                    // Handle page visibility change
                    document.addEventListener(visibilityChange, handleVisibilityChange, false);
                }
            },
            getRoomUrl(room){
                return sharedHelper.getRoomUrl(room);
            },
            postLogin(options= {}) {

                const { popupParams, roomUser, isNewUser, invalidSocialEmail } = options;

                if(isNewUser){
                    storage.setItem('isFirstVisit', true, this.hoursToMs(12));
                }

                if (popupParams) {
                    if (typeof popupParams.postLogin === 'function'){
                        return popupParams.postLogin();
                    } else if (popupParams && popupParams.postLoginUrl) {
                        return this.redirect(popupParams.postLoginUrl);
                    }
                }

                if (roomUser && roomUser.room) {
                    return this.redirect(`/${this.getRoomUrl(roomUser.room)}`);
                }

                if (invalidSocialEmail) {
                    storage.setItem('invalidSocialEmail', true, this.secsToMs(10));
                }

                storage.removeItems([
                    'streamingConfig'
                ]);

                this.reloadPage(true);
            },
            getMySqlDate: function(date){
                return date.getFullYear() + '-' +
                    ('00' + (date.getMonth()+1)).slice(-2) + '-' +
                    ('00' + date.getDate()).slice(-2) + ' ' +
                    ('00' + date.getHours()).slice(-2) + ':' +
                    ('00' + date.getMinutes()).slice(-2) + ':' +
                    ('00' + date.getSeconds()).slice(-2);
            },
            timeToString: function(time, convertLongerThanHour, forceHours){

                var string = '';

                if(time.hours > 0 || forceHours){

                    if(time.hours > 0 && convertLongerThanHour){
                        return '> ' + time.hours + 'h';
                    }

                    string += this.addLeadingZero(time.hours) + ':';
                }

                if(time.minutes > 0){
                    string += this.addLeadingZero(time.minutes) + ':';
                }else{
                    string += '00:';
                }

                return string + this.addLeadingZero(time.seconds);
            },
            replaceLinksInText(text, { target } = {}){
                if(typeof text !== 'string') return;

                return linkifyHtml(text, {
                    defaultProtocol: 'https',
                    target: (href) => {
                        return target || (this.isProjectLink(href) ? '_self' : '_blank');
                    },
                    format: (value) => {
                        return value && value.replace('www.', '').replace(/https?:\/\//, '');
                    }
                });
            },
            getLinksInText(text) {
                return linkify.find(text);
            },
            getUserNamesInTextRegex: function(){
                return new XRegExp(/\B@/.source+'('+this.userNameValidation.regexOpen.source+')', 'gi');
            },
            replaceUserNamesInText(text, options = {}){
                if (!text) return;
                const regex = this.getUserNamesInTextRegex();
                if( text.search(regex) > -1 ){
                    return text.replace(regex, `<a href="#user/$1" class="${options.className}">$&</a>`);
                }
                return text;
            },
            getAllUserNamesInText: function(text, options = {}){
                var regex = this.getUserNamesInTextRegex();
                var results = text.match(regex);

                if(results && results.length && options.toLowerCase){
                    results = results.map(function(result){
                        return result.toLowerCase();
                    });
                }

                return results;
            },
            isInAppropriateMessage(message){
                if(!message) return;

                const arrBadWords = MyGlobal.project.chatInAppropriateWords;

                if(Array.isArray(arrBadWords)) {
                    for (const badWord of arrBadWords) {
                        const regex = new RegExp(`\\b${badWord}\\b`, 'i'); // 'i' makes it case-insensitive
                        if (regex.test(message)) {
                            return true;
                        }
                    }
                }

                return false;
            },
            doWeNeedToShowWaitingForSongsTimer: function(topSongs){
                if(!topSongs || !topSongs.length) return;

                var validSongsCounter = 0;
                for(var i=0;i<topSongs.length;i++){
                    var tmpSong = topSongs[i];

                    if(Math.round(this.secsToMins(tmpSong.duration)) <= 10 && tmpSong.likes >= 0){
                        validSongsCounter++;
                    }
                }

                return validSongsCounter >= 2;
            },
            getNewUserSongPlaylistOrderIndex: function(playlist, userId){
                if(!playlist || !userId) return;

                var i;
                var usersDict = {};

                for(i=0;i<playlist.length;i++){
                    var tmp = playlist[i];

                    // BeatSense song case
                    if(!tmp.user || !tmp.user._id) continue;

                    var tmpUserId = tmp.user._id;

                    if(usersDict[tmpUserId] && tmpUserId !== userId){
                        return i;
                    }else{

                        if(tmpUserId === userId){
                            // Cause current user already found, reset all array and consider the loop as initial one
                            usersDict =  {};
                        }

                        // Curr iterated user is not found in the helper dict, move on..
                        usersDict[tmpUserId] = true;
                    }
                }

                // Put the userSong at the last place
                return playlist.length;
            },
            fadeMenuOptions: function(holder){
                if(!holder) return;
                holder.fadeToggle(400);
            },
            objectToUrlQuery (params) {
                if(!params) return '';

                let str = '';

                for(let i in params){
                    if (!params.hasOwnProperty(i) || typeof params[i] === 'undefined') continue;
                    str += '&'+i+'='+params[i];
                }

                return str.substr(1);
            },
            getUrlSeparator: function(url){
                return url?.indexOf('?') === -1 ? '?' : '&';
            },
            async injectJsCodeToBody({ src, check, max }){
                if(!src) return;

                const script = $('<script>');
                // script.attr('async', true);

                $('body').append(script);
                script.attr('src', src);

                let checks = 1;
                max = max || 30;
                if (typeof check === 'function') {
                    return await new Promise((resolve, reject) => {
                        try {
                            $interval(() => {
                                try {
                                    if (check()) return resolve(true);
                                    if (checks++ >= max) return resolve(false);
                                } catch(e) {
                                    reject(e);
                                }
                            }, 100);
                        } catch(e) {
                            reject(e);
                        }
                    });
                }

                return true;
            },
            starWars(){
                // Starwars
                $('#campaignTiser').show();
                $('body').append("" +
                    "<img class='campaignImg' id='c3p3' src='/static/images/campaigns/starwars/c3p3.png'/>" +
                    "<img class='campaignImg' id='chewbacca' src='/static/images/campaigns/starwars/chewbacca.png'/>" +
                    "<img class='campaignImg' id='han-solo' src='/static/images/campaigns/starwars/han-solo.png'/>" +
                    "<img class='campaignImg' id='java-the-hutt' src='/static/images/campaigns/starwars/java-the-hutt.png'/>" +
                    "<img class='campaignImg' id='luke-skywalker' src='/static/images/campaigns/starwars/luke-skywalker.png'/>" +
                    "<img class='campaignImg' id='padme-amidala' src='/static/images/campaigns/starwars/padme-amidala.png'/>" +
                    "<img class='campaignImg' id='r2d2' src='/static/images/campaigns/starwars/r2d2.png'/>" +
                    "<img class='campaignImg' id='vader' src='/static/images/campaigns/starwars/vader.png'/>" +
                    "<img class='campaignImg' id='yoda' src='/static/images/campaigns/starwars/yoda.png'/>" +
                    "");

                $('#votes-holder .score-holder > .scores').hover(
                    function(){
                        $('#c3p3').fadeIn();
                    }, function() {
                        $('#c3p3').fadeOut();
                    }
                );
                $('#feedbackWidget').hover(
                    function(){
                        $('#java-the-hutt').fadeIn();
                    }, function() {
                        $('#java-the-hutt').fadeOut();
                    }
                );
                $('#header_leftContainer .fa-sign-out').hover(
                    function(){
                        $('#yoda').fadeIn();
                    }, function() {
                        $('#yoda').fadeOut();
                    }
                );
                $('#twitter').hover(
                    function(){
                        $('#r2d2').fadeIn();
                    }, function() {
                        $('#r2d2').fadeOut();
                    }
                );
                $('#fixed-online-users-list').hover(
                    function(){
                        $('#vader').fadeIn();
                    }, function() {
                        $('#vader').fadeOut();
                    }
                );
                $('#topBarChatOpener').hover(
                    function(){
                        $('#chewbacca').fadeIn();
                    }, function() {
                        $('#chewbacca').fadeOut();
                    }
                );
                $('.top-list-refresh-icon').hover(
                    function(){
                        $('#han-solo').fadeIn();
                    }, function() {
                        $('#han-solo').fadeOut();
                    }
                );
                $('#favoriteSongsMenu').hover(
                    function(){
                        $('#luke-skywalker').fadeIn();
                    }, function() {
                        $('#luke-skywalker').fadeOut();
                    }
                );
            },
            getTutorialSteps: function(options){
                options = options || {};

                // Set IntroJS for this page
                var steps = [];

                steps.push({
                    intro: '<h1>A minute of guidance</h1> Click the right-arrow key <i class="fas fa-long-arrow-alt-right"></i> to continue.'
                });

                if(!$rootScope.isStations){
                    steps.push({
                        element: '#playlist-holder',
                        intro: 'Meet the collaborative playlist, where everyone adds their music to.',
                        position: 'left'
                    });
                }

                steps.push({
                    element: '#btnAndLockWrapper',
                    intro: 'To add music, click that giant button.',
                    position: 'top'
                });

                if($rootScope.isStations){
                    steps.push({
                        element: '#onlineUsers_list',
                        intro: 'A list of online Beatsters jamming LIVE with you!',
                        position: 'top'
                    });
                }else{
                    steps.push({
                        element: '#onlineUsers_list',
                        intro: 'A list of online Beatsters discovering and sharing, LIVE with you!',
                        position: 'top'
                    });
                }

                steps.push({
                    element: '.invitePeople',
                    intro: 'Click here to invite your friends to join the party!',
                    position: 'top'
                });

                steps.push({
                    element: '#room-chat-holder',
                    intro: `The live chat. Where you can speak with whoever in the ${$rootScope.roomKindGlobally}.`,
                    position: 'right'
                });

                steps.push({
                    element: '#currSongThumbUp_forTutorial',
                    intro: 'Share your opinion! A score of -' + options.room.diffVotesToNextSong + ' will cause the song to be drafted out.',
                    position: 'top'
                });


                steps.push({
                    element: '#favoriteSongsMenu',
                    intro: 'Meet your BeatBox. Where you stash your favorite music and import YouTube playlists! Save a song to your BeatBox by clicking the heart beside it.',
                    position: 'left'
                });

                // steps.push({
                //     element: '#user-mood-holder',
                //     intro: 'Express your thoughts and individual feelings about what\'s playing.',
                //     position: 'left'
                // });

                    //steps.push({
                    //    element: '#user-global-score',
                    //    intro: `This is your total score, earned by participating in ${$rootScope.roomKindGlobally}. You can earn score by playing your own songs inside the ${$rootScope.roomKindGlobally}s and get positives votes by the online Beatsters listening to it live, together with you.`,
                    //    position: 'left'
                    //});
                // steps.push({
                //     element: '#roomListToggler',
                //     intro: `Click here to peek on the ${$rootScope.roomKindGlobally} list.`,
                //     position: 'left'
                // });

                steps.push({
                    element: '#createBeatRoomBtn',
                    intro: `Click here to create a new ${$rootScope.roomKindGlobally}`,
                    position: 'left'
                });

                steps.push({
                    element: '#feedbackWidgetOpener',
                    intro: 'Got feedback? Please shoot us a note anytime! <br/> We are a friendly bunch of people :)',
                    position: 'left'
                });

                if(!$rootScope.isStations){
                    steps.push({
                        element: '#top-songs-holder h2',
                        intro: `Top 10 songs that gained the highest score in this ${$rootScope.roomKindGlobally}. Someday, one of your songs will be listed here.`,
                        position: 'top'
                    });

                    steps.push({
                        element: '#top-users-holder h2',
                        intro: 'The leading Beatsters. You earn score by sharing music and earn up-votes. Are you feeling ambitious?',
                        position: 'top'
                    });
                }

                if($rootScope.isRoomModerator){
                    steps.push({
                        element: '#editRoomTrigger',
                        intro: `The ${$rootScope.roomKindGlobally}'s settings. Check it out later.`,
                        position: 'bottom'
                    });
                }

                steps.push({
                    element: '#introJStrigger',
                    intro: 'Trigger this tutorial again by clicking that thing.',
                    position: 'bottom'
                });

                return steps;
            },
            replaceEmoji: function (input) {

                if(typeof input !== 'string') return;
                //if( input.indexOf('-') > -1 ) return input;

                var replacements = [{
                    from: /:\+1:/.source,
                    to: ':plus1:'
                },{
                    from: /:y:/.source,
                    to: ':plus1:'
                },{
                    from: /:\)/.source,
                    to: ':simple_smile:'
                },{
                    from: /:smiley:/.source,
                    to: ':smile:'
                },{
                    from: /;\)/.source,
                    to: ':wink:'
                },{
                    from: /:\(/.source,
                    to: ':disappointed:'
                },{
                    from: /&lt;3/.source,
                    to: ':heart:'
                },{
                    from: /<3/.source,
                    to: ':heart:'
                },{
                    from: /:hankey:/.source,
                    to: ':shit:'
                }, {
                    from: /:zibi:/.source,
                    to: ':fu:'
                }];

                replacements.forEach(function (cell) {
                    input = input.replace(new RegExp(cell.from, 'g'), cell.to);
                });

                // Replace the CSS
                return input.replace( /:([a-z0-9-+_=]+):/g, function ( match, text ) {
                    if( text.indexOf('-') > -1 ) return text;
                    return '<i class="emoji emoji-' + text + '" title="' + text + '">' + text + '</i>'
                });
            },
            replaceNewLines: function(text){
                if(typeof text !== 'string') return;
                return text.replace(/\n/g, '<br />');
            },
            parseVersion: function (str) {

                if(!str) return;
                var info = str.split('.');
                if(!info) return;

                return {
                    major: typeof info[0] !== 'undefined' ? info[0] : undefined,
                    minor: typeof info[1] !== 'undefined' ? info[1] : undefined,
                    patch: typeof info[2] !== 'undefined' ? info[2] : undefined
                }
            },
            getProjectHost(options = {}) {
                return `${options.protocol ? location.protocol : ''}//${MyGlobal.project.host}`;
            },
            sendLocalSystemChatMessage: function (message, options = {}) {

                if(typeof message === 'string'){
                    message = { content: message };
                }

                message = {
                    ...message,
                    systemMessage: true,
                    localMessage: true,
                    addedAt: new Date(),
                    type: 'text',
                    ...options
                };

                $rootScope.$broadcast('chat:message', message);
            },
            clearSongInfoChatMessage: function(){
                $rootScope.$broadcast('chat:clearSongInfo');
            },
            nameToUrl: function (name) {
                return name && name
                        .replace(/\?|#|%|-|/g, '')
                        .replace(/\s\s+/g, ' ')
                        .replace(/\/|\\|\s|(\s+)?-(\s+)?/g, '-');
            },
            updateTitle: function (content){
                var titleElem = $('title');
                if(!this._origTitle){
                    this._origTitle = titleElem.text();
                }

                titleElem.text(content);
            },
            restoreTitle: function (){
                if(this._origTitle){
                    this.updateTitle(this._origTitle);
                }
                this._origTitle = null;
            },
            stripLinkProtocol: function(link){
                if(!link) return;
                var index = link.indexOf('http');
                if(index === -1) return link;

                // Remove `http` or `https` form the link
                var offset = ~link.indexOf('https') ? 2 : 1;
                return link && link.substr(index + 4 + offset);
            },
            cutStrToMaxLength: function(str, maxLength, options){
                if(!str || !maxLength) return str;

                options = options || {};

                var newStr = str && str.substr(0, maxLength);

                if(options.donotCutWords){
                    newStr = newStr.substr(0, Math.min(newStr.length, newStr.lastIndexOf(' ')));
                }

                if(newStr.length < str.length && options.suffix){
                    newStr += options.suffix;
                }

                return newStr;
            },
            getSongWikiUrl: function(song){
                if(!song || !song.youtubeId || !song.name) {
                    throw new Error('Invalid song params');
                }

                // Force wiki to be in B version
                const baseUrl = sharedHelper.getPlatformBaseUrl(false);
                return `${baseUrl}/wiki/${this.nameToUrl($injector.get('songService').getSongName(song))}?youtube=${song.youtubeId}`;
            },
            isMuted: function () {
                return storage.getItem('player.mute') || !!this.getUrlParam('mute');
            },
            getBrowserInfo: function() {
                const ua = navigator.userAgent;

                let M = ua.match(/(opera|chrome|safari|firefox|msie|trident|Edg(?=\/)|edg(?=\/))\/?\s*(\d+)/i) || [];
                let tem;
                if (/trident/i.test(M[1])) {
                    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                    return { name: 'IE ', version: (tem[1] || '') };
                }
                if (M[1] === 'Chrome' || M[1] === 'Edg' || M[1] === 'edg') {
                    tem = ua.match(/\b(OPR|Edg|edg)\/(\d+)/i);
                    if (tem != null) { return { name: tem[1].replace(/edg/i, 'Edge'), version: tem[2] }; }
                }
                M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
                if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
                return {
                    name: M[0],
                    version: M[1]
                };
            },
            isRTL: function( string ) {
                const ltrChars    = '0-9\A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF'+'\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF',
                    rtlChars    = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC',
                    rtlDirCheck = new RegExp('^[^'+ltrChars+']*['+rtlChars+']');

                return rtlDirCheck.test(string);
            },
            getRank: ( score = false ) => {
                let ranks = config.getRanks();

                if( score === false ) return ranks;
                if (score < 0 ) return 'Baby Beatster';

                for( let i in ranks ){
                    if (!ranks.hasOwnProperty(i)) continue;
                    if( score >= ranks[i] ){
                        return i;
                    }
                }

            },
            replaceWindowScroll(alternativeCallback){
                // left: 37, up: 38, right: 39, down: 40,
                // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
                const keys = {37: 1, 38: 1, 39: 1, 40: 1};

                const windowScroll = {
                    preventDefault(e){
                        e.preventDefault();

                        if(typeof alternativeCallback === 'function'){
                            alternativeCallback(e);
                        }
                    },
                    preventDefaultForScrollKeys(e){
                        if (keys[e.keyCode]) {
                            windowScroll.preventDefault(e);
                            return false;
                        }
                    },
                    wheelOpt: { passive: false },
                    wheelEvent: 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
                };

                this.windowScroll = windowScroll;

                // call this to Disable
                window.addEventListener('DOMMouseScroll', this.windowScroll.preventDefault, false); // older FF
                window.addEventListener(this.windowScroll.wheelEvent, this.windowScroll.preventDefault, this.windowScroll.wheelOpt); // modern desktop
                window.addEventListener('touchmove', this.windowScroll.preventDefault, this.windowScroll.wheelOpt); // mobile
                window.addEventListener('keydown', this.windowScroll.preventDefaultForScrollKeys, false);
            },
            restoreWindowScroll(){
                if(!this.windowScroll) return;

                window.removeEventListener('DOMMouseScroll', this.windowScroll.preventDefault, false);
                window.removeEventListener(this.windowScroll.wheelEvent, this.windowScroll.preventDefault, this.windowScroll.wheelOpt);
                window.removeEventListener('touchmove', this.windowScroll.preventDefault, this.windowScroll.wheelOpt);
                window.removeEventListener('keydown', this.windowScroll.preventDefaultForScrollKeys, false);
            },
            getUserName(user){
                return user?.userName || $rootScope.projectName;
            },
            removeItemFromArray(arr, resolver) {
                return sharedHelper.removeItemFromArray(arr, resolver);
            },
            async wait(ms) {
                if(ms <= 0) return;
                return new Promise((resolve) => {
                    $timeout(resolve, ms);
                });
            },
            isProjectLink(url) {
                return url?.includes(MyGlobal.project.host);
            },
            preventClick($event, { linksOnly } = {}) {
                if (!linksOnly || $event?.target?.tagName === 'A') {
                    $event.stopImmediatePropagation();
                }
            },
            getPositionNextToElementWithinScreen({ elemTop, elemLeft, elemHeight, holderHeight }) {
                const position = {
                    top: elemTop + elemHeight,
                    left: elemLeft
                };

                // Must be here
                const windowHeightBeforeShowHolder = $(window).outerHeight();
                const windowScrollTop = $(window).scrollTop();

                // Make sure the menu doenst goes out of the window
                const threshold = (holderHeight + (position.top - windowScrollTop));

                if(threshold > windowHeightBeforeShowHolder){
                    position.top = elemTop - holderHeight;
                }

                // Edge case
                if(position.top < 0){
                    position.top = elemTop + elemHeight;
                }

                return position;
            },
            isInt(n) {
                return n % 1 === 0;
            },
            focusContenteditableElement(selector) {
                const jqElem = $(selector);

                if (jqElem && jqElem.length) {
                    const elem = jqElem[0];

                    const s = window.getSelection();
                    const r = document.createRange();

                    r.setStart(elem, 0);
                    r.setEnd(elem, 0);
                    s.removeAllRanges();
                    s.addRange(r);
                }
            },
            getRandomString(length = 5) {
                let text = '';
                const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for(let i=0;i<length;i++){
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }

                return text;
            },
            async copyToClipboard({ text, label } = { label: 'General' }) {
                if ($rootScope.isMobileApp) {
                    return await $injector.get('mobileAppService').copyToClipboard({
                        text,
                        label
                    });
                } else {
                    return await navigator.clipboard.writeText(text);
                }
            },
            autoCompleteSearch(query, list) {
                if (!query) return list;
                const queryEscaped = sharedHelper.escapeRegex(query);
                const regexp = new RegExp(`^${queryEscaped}`, 'i');

                return list.filter(item => {
                    return item.name.match(regexp);
                });
            },
            removeDuplicatesFromArray(arr, { key } = { key: '_id' }) {
                if (!arr || !arr.length) return arr;
                return arr.filter((a, pos, arr) => {
                    return arr.findIndex(b => {
                        return b[key] === a[key];
                    }) === pos;
                });
            },
            isEmptyHTML(str) {
                return !str.replace(/&nbsp;/g, ' ').replace('<br>', '').trim();
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            getJwtData() {
                const token = this.getCookie('jwt', { skipPrefix: true });
                return (token && jwt_decode(token)) || {};
            },
            isAnotherPlatform(room) {
                return (room?.station && !$rootScope.isStations) || (!room?.station && $rootScope.isStations);
            }
        };
    });

}(angular));

