import angular from 'angular';

import './sharePopup.less';
import '../../../services/share.service';
import {SHARE_ENTITY_TYPES} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MySharePopupCtrl', ($scope, $uibModalInstance, popupParams, $timeout, helper) => {

    const { entityType, entityData, title, text } = popupParams;

    $scope.entityType = entityType;
    $scope.entityData = entityData;
    $scope.title = title;
    $scope.text = text;

    switch ($scope.entityType) {
        case SHARE_ENTITY_TYPES.FEED_DROP:
            $scope.entityTypeName = 'this drop';
            break;
        case SHARE_ENTITY_TYPES.SONG:
            $scope.entityTypeName = 'this song';
            break;
        case SHARE_ENTITY_TYPES.ROOM:
            $scope.entityTypeName = 'this room';
            break;
        case SHARE_ENTITY_TYPES.USER:
            $scope.entityTypeName = 'this Beatster';
            break;
    }

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    $timeout(() => {
        if (!entityType || !entityData) {
            $scope.close();
            helper.error(new Error('Invalid entity to share'));
        }
    });

});
