import './gifsPopup.less';
import './gifs.service';

import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyGifsPopupCtrl', ($scope, $uibModalInstance, $stateParams, popupParams, helper, gifsService, beatsService, analyticsService,
                                   $rootScope, $timeout) => {

    const listSelector = '#myGifsModal .listHolder';
    const { source, id: userId, text } = popupParams;
    const itemsPerPage = 20;

    let scrollLocked = false;
    let currPage = 1;
    let currSearch = '';
    let currPagination = null;
    let timer;

    $scope.beatsService = beatsService;

    $scope.text = text;

    const render = (data, pagination, options = {}) => {
        if (!options.append) {
            $scope.results = data;
        } else {
            $scope.results = ($scope.results || []).concat(data);
        }

        currPagination = pagination;

        updateScroller();
    };
    
    const getCurrentOffset = () => {
        return (currPage - 1) * itemsPerPage;
    };

    const loadTrending = async (options = {}) => {
        const { data, pagination } = await gifsService.trending({ limit: itemsPerPage, offset: getCurrentOffset() });
        render(data, pagination, options);
    };

    const loadSearchResults = async (options = {}) => {
        const { data, pagination } = await gifsService.search(currSearch, { limit: itemsPerPage, offset: getCurrentOffset() });
        render(data, pagination, options);
    }

    $scope.search = async (term) => {
        try {
            reset();
            currSearch = term;
            await loadSearchResults();
        } catch (e) {
            $scope.error = e?.message;
        }
    };

    const reset = () => {
        currPage = 1;
        currSearch = '';
        currPagination = null;
    };

    $scope.clear = async () => {
        try {
            reset();
            await loadTrending();
        } catch (e) {
            helper.error(e);
        }
    };

    const triggerGifSelected = ({ eventName, gif }) => {
        const { title, images } = gif;
        const { fixed_width: image} = images;
        const { url, width, height} = image;

        $rootScope.$broadcast(eventName, {
            title,
            url,
            width,
            height
        });
    };

    $scope.onGifSelected = (gif) => {

        if(!helper.isUserLoggedIn()){
            helper.gotoState('join', {
                message: 'Sign up to add gifs'
            });
            return;
        }

        analyticsService.trackUIEvent({
            eventAction: 'GifSelected',
            eventLabel: gif.id
        });

        switch(source) {
            case 'chat':
                triggerGifSelected({
                    gif,
                    eventName: 'chat:send:gif'
                });
                break;
            case 'privateChat':
                triggerGifSelected({
                    gif,
                    eventName: `chat:${userId}:send:gif`
                });
                break;
            default:
                triggerGifSelected({
                    gif,
                    eventName: `${source}:gifSelected`
                });
        }

        $scope.close();
    };

    $scope.onUserClicked = ($event) => {
        $event.stopImmediatePropagation();
    };

    $scope.$on('$destroy', () => {
        if (timer) {
            $timeout.cancel(timer);
            timer = null;
        }
    });

    const updateScroller = () => {
        $timeout(() => {
            $(listSelector).perfectScrollbar('update');
        });
    };

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    $timeout(async () => {
        try {
            await loadTrending();

            timer = $timeout(() => {
                $(listSelector).on('ps-y-reach-end', async () => {
                    try {
                        if(!$scope.results?.length || scrollLocked || !currPagination?.count) return;

                        scrollLocked = true;

                        $timeout(() => {
                            scrollLocked = false;
                        }, 700);

                        currPage++;

                        if (!currSearch) {
                            await loadTrending({ append: true });
                        } else {
                            await loadSearchResults({ append: true });
                        }
                    } catch (e) {
                        helper.error(e);
                    }
                });
            }, 100);
        } catch (e) {
            helper.error(e);
        }
    });

});
