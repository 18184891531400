import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);


app.service('chatService', (helper, mySocket, httpService, $rootScope, beatsService) => {

    const songColorCache = {};
    const messageSongColors = ['#65cec9',"#c95786"];
    const messageSongOpacity = [0.3, 0.6, 1];

    const chatPlaceholders = [
        'Share your thoughts ...',
        'Inspire us...',
        'Say something smart...',
        'Speak your mind...',
        'Share your wisdom...'
    ];

    /**
     * @param to, ObjectId, optional, in private message case you have to define the recipient
     * @param type, enum, 'text' or 'gif'
     * @param content - mixed, string for 'text' type, object for 'gif' type
     * @param userSong - ObjectId, optional, to mark the chat message with the song played when it sent.
     * @param replyTo - Object, optional, when user replied to another message
     * @returns {Promise<unknown>}
     */
    const sendMessage = async ({ to, type, content, userSong, replyTo }) => {

        if (to) {
            return mySocket.emitP('chat:message:private', { to, type, content, userSong, replyTo });
        }

        return mySocket.emitP('chat:message', { type, content, userSong, replyTo });
    };

    const normalizeTextMessage = (message) => {
        // Replace stuff
        if(!message || !message.content) {
            throw new Error(`Invalid message without content`);
        }

        if(helper.isRTL(message.content)){
            message.rtl = true;
        }

        let { content } = message;
        const origContent = content;

        if (typeof content === 'string') {

            content = helper.replaceUserNamesInText(content, {
                className: 'user-link-inline'
            });

            content = message.songInfo ? content : helper.replaceLinksInText(content);
            content = helper.replaceEmoji(content);

            if (message.systemMessage && !message.songInfo) {
                if (helper.isValidEmail(content)) {
                    content = content.replace(/@/, '');
                }
            }
        }

        return {
            ...message,
            content,
            origContent
        };
    };

    const getMessageColor = (userSongId, prevMessage) => {

        if(songColorCache && songColorCache[userSongId]){
            return songColorCache[userSongId];
        }

        const filteredOpacity = prevMessage?.color?.opacity ? messageSongOpacity.filter(item => {
            return item !== prevMessage.color.opacity;
        }) : messageSongOpacity;

        const obj = {
            hex: helper.getRandomFromArray(messageSongColors),
            opacity: helper.getRandomFromArray(filteredOpacity)
        };

        songColorCache[userSongId] = obj;
        return obj;
    };

    // Public
    return {
        messageTypes: {
            TEXT: 'text',
            GIF: 'gif'
        },
        getPlaceholder() {
            return helper.getRandomFromArray(chatPlaceholders);
        },
        async sendTextMessage({ message, ...args }) {
            return sendMessage({
                type: this.messageTypes.TEXT,
                content: message,
                ...args
            });
        },
        async sendGifMessage({ data, ...args }) {
            const res = sendMessage({
                type: this.messageTypes.GIF,
                content: data,
                ...args
            });
            return res;
        },
        async ackMessages({ ids, userId, all, updateUnreads } = {}) {
            const data = await mySocket.emitP('chat:messages:private:ack', { ids, userId, all });
            if (updateUnreads) {
                $rootScope.$broadcast('inbox:updateUnreads');
            }
            return data;
        },
        async ackMessage({ id, ...args }) {
            return await this.ackMessages({ ids: [id], ...args});
        },
        async ackAllUserMessages(userId) {
            return await this.ackMessages({ userId, updateUnreads: true });
        },
        async ackAllMessages() {
            return await this.ackMessages({ all: true, updateUnreads: true });
        },
        normalizeMessage(message) {

            switch(message?.type) {
                case this.messageTypes.TEXT:
                    return normalizeTextMessage(message);
            }

            return message;
        },
        // Overload for legacy purposes.
        enhanceChatMessage(chatMessage, options) {
            if (!chatMessage?.message) return;
            chatMessage.message = this.enhanceMessage(chatMessage.message, options);
            return chatMessage;
        },
        enhanceMessage(message, { prevMessage } = {}) {

            if(message.userSong?._id){
                message.color = getMessageColor(message.userSong._id, prevMessage);
            }

            message = this.normalizeMessage(message);

            return message;
        },
        async loadMessages({ userId }) {
            return httpService.get(`/chat/${userId}`);
        },
        async toggleBlock(userId) {
            return httpService.get(`/chat/toggleBlock/${userId}`);
        },
        reduceReplyToMessage(message) {
            return message && {
                id: message.id,
                user: message.user,
                type: message.type,
                content: message.content
            };
        },
        reduceReplyToPrivateMessage(chatMessage) {
            return chatMessage && {
                _id: chatMessage._id,
                from: chatMessage.from,
                message: {
                    type: chatMessage.message.type,
                    content: chatMessage.message.content
                }
            };
        },
        convertNewChatToOldChatMessage(chatMessage) {
            return chatMessage && chatMessage.message && {
                id: chatMessage._id,
                user: chatMessage.from,
                type: chatMessage.message.type,
                content: chatMessage.message.content
            }
        },
        convertOldChatToNewChatMessage(message) {
            return message && {
                _id: message.id,
                from: message.user,
                message: {
                    type: message.type,
                    content: message.content
                }
            }
        },
        async deleteChat(userId) {
            return await httpService.delete(`/chat/${userId}`);
        },
        async updateChatMessageEmoji({ id, index, emoji, direction }) {
            return await mySocket.emitP('chat:message:emojiUpdated', { id, index, emoji, direction });
        }
    }

});
