/**
 * Created by Shlomi on 09/09/2016.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myReadMoreElement', function(helper, $timeout) {
        return {
            restrict: 'A',
            scope: {
                myReadMoreElementWatchValue: '@'
            },
            link: function ($scope, $element, $attributes) {

                var _defaultCollapsedHeight = 134;
                var _readMoreText = 'read more...';
                var _readLessText = 'read less...';

                var _readMoreButtonClass = '.readMoreButton';

                var _jqElem = $($element);
                var _collapsedHeight = Number($attributes.myReadMoreElementHeight) || _defaultCollapsedHeight;

                var _getReadMoreButton = function () {
                    return $('<span class="readMoreButton">'+_readMoreText+'</span>')
                };

                var _dispose = function () {
                    _jqElem.removeClass('readMoreElement');
                    _jqElem.removeClass('readMoreOpened');
                    _jqElem.css({height: 'auto'});
                    _jqElem.find(_readMoreButtonClass).remove();
                    _jqElem.off('click', _readMoreButtonClass, _onReadMoreButtonClicked);
                };

                var _onReadMoreButtonClicked = function (evt) {

                    if(evt){
                        evt.stopImmediatePropagation();
                    }

                    if(!_jqElem.hasClass('readMoreOpened')){
                        _jqElem.addClass('readMoreOpened');
                        _jqElem.css({height: 'auto'});
                        _jqElem.find(_readMoreButtonClass).text(_readLessText);
                    }else{
                        _jqElem.removeClass('readMoreOpened');
                        _jqElem.height(_collapsedHeight);
                        _jqElem.find(_readMoreButtonClass).text(_readMoreText);
                    }
                };

                var _doWork = function () {
                    var elemHeight = _jqElem.outerHeight();

                    helper.debug('ReadMoreElement height: '+elemHeight+', collapsed height: '+_collapsedHeight);

                    if(elemHeight <=0 || elemHeight <= _collapsedHeight){
                        return _dispose();
                    }

                    if(_jqElem.hasClass('readMoreElement')) return;

                    _jqElem.height(_collapsedHeight);
                    _jqElem.addClass('readMoreElement');
                    _jqElem.append(_getReadMoreButton());
                };

                // Event delegation for all future buttons
                _jqElem.on('click', _readMoreButtonClass, _onReadMoreButtonClicked);

                if($scope.myReadMoreElementWatchValue){
                    $scope.$watch('myReadMoreElementWatchValue', function () {
                        _dispose();
                        _jqElem.on('click', _readMoreButtonClass, _onReadMoreButtonClicked);
                        _doWork();
                    });
                }

                $scope.$on('$destroy', function() {
                    helper.debug('Destroying ReadMoreElement');
                    _dispose();
                });

                $timeout(function () {
                    var elemHeight = _jqElem.outerHeight();
                    helper.debug('Elem height after first timeout: '+elemHeight);
                    $timeout(_doWork, elemHeight > 0 ? 0 : 500);
                });
            }
        };
    });
}(angular));
