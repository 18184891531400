import angular from 'angular';
import template from './feedDropOpener.html';
import './feedDropOpener.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myFeedDropOpener', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            type: '=?',
            content: '=?',
            extraData: '=?',
            gaSource: '@'
        },
        controller($scope, popupService, userService, analyticsService, helper) {
            $scope.onClick = ($event)=> {

                if(!userService.isLoggedIn()){
                    helper.gotoState('join');
                    return;
                }

                if (!userService.canPerformPrivilegedFeatures()) {
                    helper.gotoState('contribution');
                    return;
                }

                if( $scope.gaSource === 'wikiPage'){
                    analyticsService.trackWikiEvent({
                        eventAction: 'FeedDropPopupOpened'
                    });
                }

                popupService.openCreateFeedDropPopup({
                    $event,
                    type: $scope.type,
                    content: $scope.content,
                    extraData: $scope.extraData
                });
            };
        }
    };
});
