import angular from 'angular';
import template from './shareTargets.html';
import './shareTargets.less';
import {SHARE_TARGET_TYPES} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myShareTargets', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            entityType: '=',
            entityData: '=',
            title: '=',
            text: '=',
            maxDefaultVisible: '=?'
        },
        controller($scope, shareService, analyticsService, $timeout, helper) {
            const { entityType, entityData, title, text } = $scope;

            $scope.maxDefaultVisible = $scope.maxDefaultVisible || 5;
            $scope.shareTargets = shareService.getShareTargets();

            $scope.onClick = async ($event, shareTarget) => {
                $event.preventDefault();
                $event.stopImmediatePropagation();

                try {

                    const { url } = shareTarget;

                    if (shareTarget.key === SHARE_TARGET_TYPES.LINK) {
                        await helper.copyToClipboard({
                            text: decodeURIComponent(url),
                            label: 'Share link'
                        });
                        $scope.copied = true;
                        $timeout(()=>{
                            delete $scope.copied;
                        }, 1500);
                    } else {
                        window.open(url, "_blank", "noopener,noreferrer");
                    }

                    analyticsService.trackShareEvent({
                        eventAction: shareTarget.name,
                        eventLabel: {
                            entityType
                        }
                    });

                    shareService.updateUserSelection(shareTarget);
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.toggleHidden = ($event) => {
                $event.preventDefault();
                $scope.showHidden = !$scope.showHidden;
            }

            (async ()=> {
                try {
                    for (const shareTarget of $scope.shareTargets) {
                        shareTarget.url = shareService.getShareURL({
                            entityType,
                            entityData,
                            title,
                            text,
                            target: shareTarget,
                            fbAppId: MyGlobal.project.facebook.appId
                        });
                    }
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            })();
        }
    };
});
