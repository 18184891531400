((angular) => {
    const app = angular.module(MyGlobal.page.ngAppName);

    app.service('userJammingService', (helper, httpService, roomService, userService,
                                       $timeout, storage, $rootScope) => {

        const STORAGE_KEY = 'isAlreadyJammed';
        const STORAGE_TTL = helper.minutesToMs(10);

        let _timer;
        const timeout = helper.getUrlParam('userJammingTimer') || helper.minutesToMs(3);

        const update = async ({ buy } = {}) => {
            return httpService.post(`/user/jamming`, {
                roomId: roomService.getRoomId(),
                buy
            });
        };

        const getStorageKey = () => {
            return `${STORAGE_KEY}.${roomService.getRoomId()}`;
        };

        const updateBlocked = () => {
            return !!storage.getItem(getStorageKey());
        };

        return {
            shouldStart() {
                return false;
                // return userService.isUserLoggedIn() &&
                //     $rootScope.totalLoggedInUsers === 1 &&
                //     $rootScope.room.type === 'public' &&
                //     !roomService.isPaused();
            },
            start(){

                this.stop();

                helper.debug(`Starting userJammingService timer (${helper.msToMinutes(timeout)} mins)..`);

                _timer = $timeout(async () => {
                    try {
                        if (!userService.isFeatureEnabled(userService.features.ENABLE_PRIVACY) && !updateBlocked()) {
                            helper.debug('userJammingService timer ended, updating the server..');
                            storage.setItem(getStorageKey(), Date.now(), STORAGE_TTL);
                            await update();
                        }

                        $rootScope.$broadcast('userJammingTimer:ended');
                    } catch (e) {
                        helper.error(e);
                    }
                }, timeout);
            },
            stop() {
                if (_timer) {
                    helper.debug('Stopping userJammingService timer..');
                    $timeout.cancel(_timer);
                    _timer = null;
                }

                $rootScope.$broadcast('userJammingTimer:stopped');
            },
            async promote() {
                return update({ buy: true });
            },
            async load({ page } = { page: 1 }) {
                return httpService.get(`/user/jamming/${page}`);
            },
            async loadOne(exclude = []) {
                return httpService.post(`/user/jamming/single`, { exclude });
            },
            async exists(id) {
                return httpService.get(`/user/jamming/${id}/exists`);
            }
        }
    });
})(angular);
