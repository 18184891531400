/**
 * Created by Orne on 15/05/2020
 */

import template from '../../../templates/partials/nothingButEcho.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myNothingButEcho', function() {
        return {
            restrict: 'E',
            template,
            scope: {}
        };
    });
}(angular));
