import angular from 'angular';
import {PAYMENT_TYPES} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('paypalService', (beatsService, subscriptionService, contributionService) => {
    return {
        async getRedirectLink({ type, data }) {
            switch (type) {
                case PAYMENT_TYPES.BEATS_PACKAGE:
                    return await beatsService.generatePaymentToken(data.package);
                case PAYMENT_TYPES.SUBSCRIPTION:
                    const { subscription } = data;
                    return await subscriptionService.generatePaymentToken(subscription.id);
                case PAYMENT_TYPES.CONTRIBUTION:
                    return await contributionService.generatePaymentToken();
            }
        }
    }
});