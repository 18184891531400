/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('webNotificationService', function($q, helper, $rootScope, $timeout, userService, analyticsService){

        let enabled = false;

        var _permissions = {
            chatAll: true,
            chatMentioned: true,
            newUserEnterRoom: true,
            userAlert: true,
            privateChatMessage: true,
            privateChatNotification: true,
            myMessageReplied: true
        };

        var _windowIsFocus = false;

        $rootScope.$on('pageVisibilityChanged', function(evt, visible){
            _windowIsFocus = visible;
        });

        return {
            sections: {
                chatAll: 'chatAll',
                chatMentioned: 'chatMentioned',
                newUserEnterRoom: 'newUserEnterRoom',
                userAlert: 'userAlert',
                privateChatMessage: 'privateChatMessage',
                privateChatNotification: 'privateChatNotification',
                myMessageReplied: 'myMessageReplied'
            },
            async init() {

                if (typeof Notification !== 'function') {
                    return false;
                }

                if (!($rootScope.user && $rootScope.user.enableWebNotifications)) {
                    return false;
                }

                let permission = Notification.permission;

                if (Notification.permission !== 'granted') {
                    permission = await Promise.resolve(Notification.requestPermission());
                }

                enabled = permission === 'granted';
            },
            canUse(options = {}) {
                return !!enabled;
            },
            needToSend: function () {
                return !_windowIsFocus;
            },
            isAllowed: function (key) {
                return typeof MyGlobal.notificationPermissions[key] !== 'undefined' ? MyGlobal.notificationPermissions[key] : !!_permissions[key];
            },
            async notify(message, type, options = {}) {
                try {
                    if((!this.canUse() || !this.needToSend() || !message) && !options.force) {
                        return;
                    }

                    if (userService.isFeatureEnabled(userService.features.NOTIFICATION_HIDDEN_CONTENT)) {
                        message = 'Content is hidden';
                    }

                    options.icon = options.icon || helper.getProjectHost()+'/static/images/notifications/logo.png';

                    const not = new Notification($(`<div>${message}</div>`).text(), options);

                    if(!options.persistent){
                        $timeout(not.close.bind(not), options.duration || 9000);
                    }

                    not.onclick = function() {
                        try{
                            window.focus();
                            this.close();

                            analyticsService.trackActionEvent({
                                eventAction: 'BrowserNotificationClicked',
                                eventLabel: type
                            });
                        }catch(e){}
                    };

                    not.onshow = function() {
                        try{
                            analyticsService.trackActionEvent({
                                eventAction: 'BrowserNotificationShowed',
                                eventLabel: type
                            });
                        }catch(e){}
                    };

                    not.onerror = function(err) {
                        helper.error(err);
                    };

                    return not;
                } catch (e) {
                    if (e?.message === 'TypeError: Failed to construct \'Notification\': Illegal constructor. Use ServiceWorkerRegistration.showNotification() instead.') {
                        enabled = false;
                    } else {
                        helper.error(e);
                    }
                }
            },
            async systemNotification(message, type, options) {
                if(!message || !type) return;
                return this.notify(message, type, options);
            }
        };
    });

}(angular));
