import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

import './feedDropPopup.less';
import '../../feed/feedDropInput/feedDropInput';

if (MyGlobal.project.isMobile) {
    import('./feedDropPopup_mobile.less');
}

app.controller('MyFeedDropPopup', ($scope, $rootScope, popupParams, $uibModalInstance) => {

    const { type, content, autoDrop, extraData } = popupParams;
    $scope.type = type;
    $scope.content = content;
    $scope.autoDrop = autoDrop;
    $scope.extraData = extraData;

    $scope.onDropCreated = (feedDrop) => {
        $rootScope.$broadcast('feedDrop:created', feedDrop);
        $scope.close();
    }

    $scope.close = () => {
        $uibModalInstance.dismiss();
    };
});
