/**
 * Created by Shlomi on 29/09/2014.
 */

import template from '../../templates/partials/feedbackWidget.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myFeedbackWidget', function($timeout) {
        return {
            restrict: 'E',
            scope: {},
            template,
            controller: function($scope, helper, $rootScope, $http){

                $scope.showOpener = true;

                $scope.$on('feedback:show', function ($evt, options) {
                    $scope.showWidget(options);
                });

                $scope.sendFeedback = function(feedback = {}){

                    if(!feedback.content) return $scope.opened = false;

                    if(!$rootScope.user && !helper.isValidEmail(feedback.email)){
                        return $scope.error = 'Invalid email';
                    }

                    $scope.loading = true;

                    const data = {
                        ...feedback,
                        clientInfo: $rootScope.technicalData
                    };

                    $http.post('/contact', data).success(function(){
                        $scope.loading = false;
                        $scope.done = true;
                        $timeout(function(){
                            $scope.close();
                        }, 12000);
                    }).error(function(response){
                        $scope.loading = false;
                        $rootScope.showError(response.error);
                    });
                };

                $scope.close = function () {
                    $scope.opened = false;
                    $scope.showOpener = true;
                };
            },
            link($scope, $element) {
                $scope.showWidget = (options = {}) => {

                    options = options || {};

                    $scope.feedback = { content: options.text } || '';
                    $scope.done = false;
                    $scope.opened = true;
                    $scope.showOpener = !!options.showOnTop;
                    $scope.error = '';
                    $scope.loading = false;
                    $scope.showOnTop = options.showOnTop;

                    $timeout(()=>{
                        $element.find('#txtFeedback').focus();
                    });
                };
            }
        };
    });
}(angular));
