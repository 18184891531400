
import './cookiesApproval.less';

import angular from 'angular';

import template from './cookiesApproval.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myCookiesApproval', ($rootScope) => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope) {
            $scope.onAccept = () => {
                $rootScope.$broadcast('cookies:approved');
            }
        }
    };
});
