import angular from 'angular';
import '../inbox.service';
import './inboxOpener.less';
import template from './inboxOpener.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myInboxOpener', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, helper, inboxService, $rootScope) {

            $scope.unreads = 0;

            $scope.toggle = ()=> {
                $rootScope.$broadcast('inbox:toggle', {
                    unreads: $scope.unreads
                });
            };

            const updateUnreads = async () => {
                const { data, total } = await inboxService.getTotalUnread() || 0;
                $scope.unreads = total;
                $scope.unreadData = data;

                $rootScope.$broadcast('inbox:unreads', {
                    data, total
                });
            };

            $scope.$on('page:ready', async () => {
                try {
                    await updateUnreads();
                } catch (e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            });

            $scope.$on('inbox:unread:increase', async () => {
                try {
                    $scope.unreads++;
                } catch (e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            });

            $scope.$on('inbox:unread:decrease', (evt) => {
                $scope.unreads++;
            });

            $scope.$on('inbox:updateUnreads', async (evt) => {
                try {
                    await updateUnreads();
                } catch (e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            });
        }
    };
});
