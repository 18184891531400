import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('roomFeaturesService', (config, roomService) => {
    return {
        keys: config.getRoomFeatureKeys(),
        features: config.getRoomFeatures(),
        hasFeature({ key, room }) {
            return !!this.getFeature({ room, key });
        },
        getFeature({ room, key }) {
            room = room || roomService.getRoom();
            return room && room.features?.find(item => item.key === key);
        },
        getRoomLevelFeatures(level) {
            const features = this.features;
            return features && features.filter(item => !item.free && level >= item.minLevel);
        },
        getFeatureByKey(key) {
            return this.features?.find(item => item.key === key);
        },
        getFeatureDefaultValue(key) {
            const feature = this.getFeatureByKey(key);
            return feature?.options?.find(item => item.selected)?.value;
        },
        addFeature({ room, key, value }) {
            if (!room || !key || this.hasFeature({ key, room })) return;

            const obj = { key };

            if (!value) {
                value = this.getFeatureDefaultValue(key);
            }

            if (value) {
                obj.value = value;
            }

            room.features.push(obj);
        },
        updateFeature({ room, key, value }) {
            if (!room || !key) return;
            const feature = room.features.find(item => item.key === key);

            if (feature) {
                feature.value = value;
            }
        },
        removeFeature({ room, key }) {
            if (!room || !key) return;

            const index = room.features.findIndex(item => item.key === key);

            if (~index) {
                room.features.splice(index, 1);
            }
        }
    };
});
