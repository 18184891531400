/**
 * Created by Shlomi on 19/09/2014.
 */

import moment from 'moment';

import '../services/recaptcha';

import {
    CONTRIBUTION_SETTINGS,
    USERS_SORT_OPTIONS_KEYS,
    USER_SELF_STATUSES,
    USER_REF_TYPES
} from '../../../shared/consts';

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('userService', ($q, $http, helper, storage, $rootScope, config,
                                httpService, roomService, recaptchaService,
                                roomFeaturesService, mySocket) => {

        // Private variables
        const _deferred = $q.defer();
        let _user = MyGlobal.user;

        const selfStatuses = Object.values(USER_SELF_STATUSES);

        const getAutoVoteKey = ({ roomId }) => {
            return `autoVote-${roomId}`;
        };

        return {
            features: MyGlobal.project.userFeatures,
            promise: _deferred.promise,
            async init(){

                if(!this.isUserLoggedIn()) return $q.resolve();

                helper.debug('Loading current user data');

                const user = await httpService.get('/user/current');

                helper.debug('Current user data has been loaded');
                _user = user;

                if (user.selfStatus) {
                    user.selfStatus = this.getSelfStatus(user.selfStatus);
                }

                return user;
            },
            async update(params) {
                return await httpService.put('/user', params);
            },
            getMadeMyDay() {
                return _user && _user.madeMyDay;
            },
            setMadeMyDay(madeMyDay) {
                if(!_user) return;
                _user.madeMyDay = madeMyDay;
            },
            isUserLoggedIn: function(){
                return _user && _user.id;
            },
            // Alias
            isLoggedIn() {
                return this.isUserLoggedIn();
            },
            getUser: function(){
                return _user;
            },
            getUserId: function(){
                return _user && _user.id;
            },
            isImpersonated() {
                const { user } = helper.getJwtData();
                return user?.impersonated;
            },
            isAmbassador() {
                return _user?.ambassador;
            },
            getUserName: function(){
                return _user && _user.userName;
            },
            isAdmin: function () {
                return _user?.role === 'admin';
            },
            isUserAdmin: function () {
                return _user?.role === 'userAdmin';
            },
            isOnlyUserInRoom: function () {
                return $rootScope.loggedInSockets && $rootScope.loggedInSockets.length === 1;
            },
            isUserSawTutorial: function (){
                return _user?.isSawTutorialModal;
            },
            freeVideoThemeUsed(){
                return _user?.freeVideoThemeUsed;
            },
            hasLastFM() {
                return _user?.hasLastFM;
            },
            setFreeVideoThemeUsed(){
                if(_user){
                    _user.freeVideoThemeUsed = true;
                }
            },
            openProfile() {
                helper.gotoState('user-modal', { userName: _user.userName });
            },
            checkDailyBeats: function () {

                helper.debug('Loading current user data');

                var defer = $q.defer();
                $http.get('/user/dailyBeats').success(defer.resolve).error(defer.reject);
                return defer.promise;
            },
            isFirstVisit: function () {
                return storage.getItem('isFirstVisit');
            },
            setFlag: function (flag, value, setOnLocalUser){
                if(!this.isUserLoggedIn()) return $q.resolve();
                return $http.get('/user/setFlag?f='+flag+'&v='+value).then(function () {
                    if(_user && setOnLocalUser){
                        _user[flag] = value;
                    }
                });
            },
            async toggleUserBan({ userId }) {
                return httpService.post('/user/ban/toggle', { userId });
            },
            async addMySoundtrack(youtubeId) {
                const defer = $q.defer();
                $http.post('/user/soundtrack', { youtubeId }).success(defer.resolve).error(defer.reject);
                return defer.promise;
            },
            async getUserSoundtracks(userName){
                const url = userName ? `/user/${encodeURIComponent(userName)}/soundtracks` : '/user/soundtracks';
                return httpService.get(url);
            },
            async removeSoundtrack(soundtrack){
                return httpService.delete(`/user/soundtrack/${soundtrack._id}`);
            },
            async getFeaturedSongs(userName){
                return httpService.get(`/user/${encodeURIComponent(userName)}/featured`);
            },
            async summonAlina({ userName, roomId }){
                if(!userName || !roomId) throw new Error('userName or roomId are invalid');
                return httpService.get(`/user/${encodeURIComponent(userName)}/summonAlina/${roomId}`);
            },
            isFeatureEnabled(key) {
                return _user?.features?.includes(key);
            },
            addFeature(feature) {
                if (!feature) return;
                _user.features = (_user.features || []);
                _user.features.push(feature);
                $rootScope.$broadcast('user:features:changed');
            },
            async updateFeature(name, value) {
                if (!name || typeof value !== 'boolean') return;
                const features = await httpService.put('/user/feature', { name, value });
                _user.features = features;
                $rootScope.$broadcast('user:features:changed');
                return features;
            },
            setFeatures(features) {
                _user.features = features;
                $rootScope.$broadcast('user:features:changed');
            },
            getFeatures() {
                return _user.features;
            },
            async getUserProfile({ userName, roomId }) {

                let url = `/user/${encodeURIComponent(userName)}/profile`;

                if($rootScope.isRoomPage){
                    url += `?roomId=${roomId}`;
                }

                return httpService.get(url);
            },
            isCurrUser(id) {
                return _user && _user.id === id;
            },
            updateScores(scores) {
                _user.score = scores;
            },
            getGlobalScore() {
                return _user?.score;
            },
            async getUserAdditionalData({ userName }) {
                return httpService.get(`/user/${encodeURIComponent(userName)}/profile/additionalData`);
            },
            async becomeContributor() {
                return httpService.post(`/user/becomeContributor`);
            },
            updateMusicTags(tags) {
                _user.musicTags = tags;
            },
            startAutoVote() {
                storage.setForeverItem(getAutoVoteKey({ roomId: roomService.getRoomId() }), true);
            },
            getAutoVote() {
                return storage.getItem(getAutoVoteKey({ roomId: roomService.getRoomId() }));
            },
            stopAutoVote() {
                return storage.removeItem(getAutoVoteKey({ roomId: roomService.getRoomId() }));
            },
            async logout({ redirect } = {}) {
                await httpService.get('/auth/logout');

                storage.setForeverItem('isUserLoggedIn', false);

                storage.removeItems([
                    'isFirstVisit',
                    'showSimilarSongs',
                    'chats',
                    'musicMatches',
                    'streamingConfig'
                ]);

                helper.deleteCookie('jwt');

                if (redirect) {
                    helper.redirect(redirect);
                } else {
                    helper.reloadPage(true);
                }
            },
            async checkSystemAlerts() {
                return httpService.get('/user/systemAlerts');
            },
            hasSubscription(user) {
                user = user || _user;
                const endDate = user?.subscription?.end ? new Date(user.subscription.end) : undefined;
                return !!(endDate && endDate.getTime() - Date.now() > 0);
            },
            async getUsers({ page, query, sort }) {
                return await httpService.post(`/user/list`, {
                    page,
                    query,
                    sort
                });
            },
            async follow(userId) {
                return await httpService.post(`/user/follow/${userId}`);
            },
            async unFollow(userId) {
                return await httpService.post(`/user/unfollow/${userId}`);
            },
            getProperUserName(user) {
                return user._id === _user.id ? 'you' : user.userName;
            },
            async socialRedirectLogin(key) {

                const baseUrl = `/auth/social/${key}/redirectLogin?redirect=`;

                let redirectUrl;
                if (MyGlobal.temp.popupParams && MyGlobal.temp.popupParams.postLoginUrl) {
                    redirectUrl = MyGlobal.temp.popupParams.postLoginUrl;
                } else {
                    redirectUrl = location.pathname;
                }

                const urlParams = helper.getUrlParams();
                if (urlParams && Object.keys(urlParams).length) {
                    Object.keys(urlParams).forEach(function (key) {
                        redirectUrl += `&${key}=${urlParams[key]}`;
                    });
                }

                const finalUrl = baseUrl+encodeURIComponent(redirectUrl);
                helper.redirect(finalUrl);
            },
            async socialAccessTokenLogin(key, accessToken) {
                await httpService.get(`/auth/social/${key}/tokenLogin?access_token=${accessToken}`);
                helper.reloadPage();
            },
            async login(params) {
                return await httpService.post('/auth/login', params);
            },
            async register(params) {

                params.token = await recaptchaService.generateToken({
                    action: recaptchaService.ACTIONS.REGISTER
                });

                return await httpService.post('/auth/register', params);
            },
            async sendRegistrationCode({ email }) {

                const token = await recaptchaService.generateToken({
                    action: recaptchaService.ACTIONS.REGISTRATION_CODE
                });

                return await httpService.post('/auth/register/code', { email, token });
            },
            async checkIfExists(what, value) {
                return await httpService.post('/auth/isExist', {
                    what,
                    value
                });
            },
            canPerformPrivilegedFeatures(checkRoomFeature = true) {

                if (!config.getContributionEnabled()) {
                    return true;
                }

                if (!this.isUserLoggedIn()) {
                    throw new Error('User has to be logged in');
                }

                if (checkRoomFeature && roomFeaturesService.hasFeature({ key: 'noSubscriptionForRoomUsers' })) {
                    return true;
                }

                const user = this.getUser();

                return user.score < CONTRIBUTION_SETTINGS.MIN_SCORES ||
                    this.hasSubscription(user) ||
                    (user.lastContribution && moment().diff(user.lastContribution, 'days') <= CONTRIBUTION_SETTINGS.DURATION)
            },
            setUserLastContribution(date){
                if(!_user) return;
                _user.lastContribution = date;
            },
            async reportMissingImage({ _id, image }) {
                // if (storage.getItem(`userImageReported.${_id}`)) return;
                // await httpService.post('/user/rmi', { userId: _id, image });
                // storage.setItem(`userImageReported.${_id}`, true, moment.duration(3, 'hours').asMilliseconds());
            },
            openUserDetails(user) {
                helper.gotoState('user-modal', { userName: user.userName });
            },
            isMobileAutoPlayEnabled() {
                return !!(_user && _user.maply);
            },
            getAffiliateLink({ room, ref } = {}) {
                if(!this.isUserLoggedIn()) return;

                let baseLink = helper.getCurrentBaseUrl();

                const params = {
                    ref: ref || USER_REF_TYPES.BEATSTER,
                    u: MyGlobal.user.affiliateId
                };

                if(room?._id){
                    baseLink = helper.getRoomUrl(room);
                }

                return `${baseLink}?${helper.objectToUrlQuery(params)}`;
            },
            tosApproved(){
                return _user.tosApproved;
            },
            async approveTos() {
                return await httpService.post('/user/approveTos');
            },
            async reportUser({ _id, text }) {
                return await httpService.post(`/user/${_id}/report`, { text });
            },
            async updateMood({ mood }) {

                if (!$rootScope.isRoomPage) {
                    throw new Error('Currently moods working only within a room');
                }

                await mySocket.emitP('user:changeMood', {
                    id: mood._id
                });

                _user.mood = mood;
            },
            async changeSelfStatus(key) {

                await mySocket.emitP('user:selfStatus:change', {
                    key
                });

                _user.selfStatus = this.getSelfStatus(key);

                // Since backend broadcast to all but me.
                $rootScope.$broadcast('user:selfStatus:changed', {
                    userId: this.getUserId(),
                    key
                });
            },
            getSelfStatus(key) {
                return selfStatuses.find(item => item.key === key);
            },
            async isUserEligibleForStayingBonus() {
                const { eligible } = await httpService.get('/user/stayingBonus');
                return eligible;
            },
            async claimStayingBonus() {
                return await httpService.post('/user/stayingBonus');
            },
            async deleteAccount() {
                return await httpService.delete('/user');
            },
            async sendAccountDeletionFeedback(data) {
                return await httpService.post('/user/accountDeletionFeedback', data);
            },
            getCountryCode() {
                return _user?.countryCode;
            },
            getOrigCountryCode() {
                return _user?.origCountryCode;
            },
            async changeCountry(countryCode) {
                await httpService.post('/user/country', { countryCode });
                _user.countryCode = countryCode;
                $rootScope.$broadcast('user:countryChanged');
            },
            async getProfileWatches() {
                return await httpService.get('/user/profileWatches');
            },
            async getProfileWatchesCount() {
                return await httpService.get('/user/profileWatchesCount');
            },
            getUserSortStatsTitleAndField(sortKey) {
                let title;
                let field;

                switch(sortKey) {
                    case USERS_SORT_OPTIONS_KEYS.FOLLOWERS:
                    case USERS_SORT_OPTIONS_KEYS.ACTIVITY:
                        title = 'Fans';
                        field = 'followers';
                        break;
                    case USERS_SORT_OPTIONS_KEYS.SCORE:
                        title = 'Score';
                        field = 'score';
                        break;
                    case USERS_SORT_OPTIONS_KEYS.WIKI_CONTRIBUTIONS:
                        title = 'Contributions';
                        field = 'wikiContributions';
                        break;
                }

                return { title, field };
            },
            async impersonate({ userName }) {
                return await httpService.post('/admin/users/impersonate', { userName });
            },
            async getFavoriteRooms() {
                return await httpService.get('/user/rooms/favorites');
            },
            async getMyRooms() {
                return await httpService.get('/user/rooms/my');
            },
            async toggleAmbassador(state) {
                await httpService.post('/user/ambassador', { state });
                _user.ambassador = true;
            },
            async getAmbassadorData() {
                return await httpService.get('/user/ambassador');
            }
        };
    });

}(angular));
