const MIN_SCORES_TO_SKIP = 100;

const FILE_UPLOAD_TYPES = {
    USER_IMAGE: 1,
    FEED_DROP_ATTACHMENT: 2
};

const REGISTRATION_CODE_LENGTH = 6;
const REGISTRATION_CODE_TTL = 300; // In seconds

const CHAT_MESSAGE_TYPES = {
    TEXT: 'text',
    GIF: 'gif'
};

const DONATE_EMOJIS = [{
    name: 'Beers',
    key: ':beer:'
}, {
    name: 'Coffee',
    key: ':coffee:'
}, {
    name: 'Teas',
    key: ':tea:'
}, {
    name: 'Pizzas',
    key: ':pizza:',
    text: 'yammi'
}, {
    name: 'Kisses',
    key: ':kiss:',
    text: 'muua'
}, {
    name: 'Roses',
    key: ':rose:'
}, {
    name: 'Chicken Wings',
    key: ':poultry_leg:',
    text: 'yammi'
}];

const CONTRIBUTION_SETTINGS = {
    MIN_SCORES: 1000,
    DURATION: 30,
    PURCHASE_PRICE: 1.99,
    BEATS_PRICE: 20
};

const YOUTUBE_API_KEY_ERROR = 'We\'ve imported too many playlists today. Imports will be available again at midnight PT.';

const BEATSENSE_USER_IMAGE = '/static/images/logo-meta.png';
const EMPTY_USER_IMAGE = '/static/images/emptyUserImage.png';

const USER_REPORT_TYPES = {
    FEED_DROP: 'feedDrop',
    USER: 'user'
};

const FREE_MADE_MY_DAYS = 1;
const MADE_MY_DAY_PRICE_INCREMENT = 10;

const PLATFORM_TYPES = {
    BEATSENSE: 'beatsense',
    STATIONS: 'stations'
};

const ROOM_ENTITY_NAMES = {
    STATION: 'Station',
    BEATROOM: 'BeatRoom'
}

const PROJECT_NAME = {
    [`${PLATFORM_TYPES.BEATSENSE}`]: 'BeatSense',
    [`${PLATFORM_TYPES.STATIONS}`]: 'Stations'
};

const AMBASSADOR_PROGRAM_INFO = {
    REWARD_USERS: 100,
    REWARD_AMOUNT: 10
};

const IFRAME_CONNECTION_TYPES = {
    PARENT: 'parent',
    CHILD: 'child'
};

module.exports = {
    MIN_SCORES_TO_SKIP,
    FILE_UPLOAD_TYPES,
    REGISTRATION_CODE_LENGTH,
    REGISTRATION_CODE_TTL,
    CHAT_MESSAGE_TYPES,
    DONATE_EMOJIS,
    CONTRIBUTION_SETTINGS,
    YOUTUBE_API_KEY_ERROR,
    BEATSENSE_USER_IMAGE,
    EMPTY_USER_IMAGE,
    USER_REPORT_TYPES,
    MADE_MY_DAY_PRICE_INCREMENT,
    FREE_MADE_MY_DAYS,
    PLATFORM_TYPES,
    PROJECT_NAME,
    ROOM_ENTITY_NAMES,
    AMBASSADOR_PROGRAM_INFO,
    IFRAME_CONNECTION_TYPES
};