import angular from 'angular';
import './ambassadorProgramOpener.less';
import template from './ambassadorProgramOpener.html';

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('myAmbassadorProgramOpener', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, userService) {
            $scope.title = !userService.isAmbassador() ? 'Become an ambassador' : 'Watch your achievements';
        }
    };
});
