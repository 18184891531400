
import angular from 'angular';
import './roomLevels.less';
import template from './roomLevels.html';

import { ROOM_LEVELS } from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('myRoomLevels', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            currLevel: '=',
            selectedLevel: '=',
            onChange: '&'
        },
        controller($scope, popupService) {

            $scope.roomLevels = ROOM_LEVELS;

            if ($scope.currLevel) {
                $scope.roomLevels = $scope.roomLevels.filter(item => item.value >= $scope.currLevel);
            }

            $scope.onRoomLevelChanged = (roomLevel)=> {
                $scope.onChange({ roomLevel });
            };

            $scope.showLevelPopup = ($event, roomLevel) => {
                popupService.openRoomLevelPopup({ $event, level: roomLevel.value });
            };
        }
    };
});
