import angular from 'angular';
import template from './countryIcon.html';

import sharedHelper from '../../../../../shared/sharedHelper';

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('myCountryIcon', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            countryCode: '='
        },
        controller($scope) {
            if ($scope.countryCode) {
                $scope.country = {
                    code: $scope.countryCode,
                    name: sharedHelper.getCountryNameByCode($scope.countryCode)
                };
            }
        }
    };
});
