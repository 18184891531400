import '../../shared/subscribableCasesList/subscribableCasesList';

import './subscribeToUserBeatCasesPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MySubscribeToUserBeatCasesPopupCtrl', ($scope, $rootScope, $uibModalInstance,
                                                       $uibModalStack, $http, helper, $stateParams,
                                                       beatBoxService, $timeout, popupParams) => {

    const casesListSelector = '#subscribeToUserBeatCasesPopup .listHolder';

    const { user, song, beatCaseId } = popupParams;

    $scope.single = !!beatCaseId;
    $scope.user = user;
    $scope.song = song;

    $scope.ctaButtonText = $scope.single && helper.getRandomFromArray([
        'No comprende',
        'No thanks',
        'Nope',
        'Thanks, but... no',
        'Close'
    ]);

    $scope.$watch('cases', () => {
        updateScroller();
    });

    const updateScroller = () => {
        $timeout(() => {
            $(casesListSelector).perfectScrollbar('update');
        }, 500);
    };

    const load = async () => {
        if (beatCaseId) {
            const beatCase = await beatBoxService.getCaseById(beatCaseId);

            if (beatCase) {
                $scope.cases = [beatCase];
            }
        } else {
            $scope.cases = await beatBoxService.getSubscribableCases({ userName: $scope.user.userName });
        }
    };

    $scope.onSubscribed = () => {
        delete $scope.ctaButtonText;
    };

    $scope.onSubscribedClicked = (bCase) => {
        $uibModalStack.dismissAll('transition to beatbox');

        helper.gotoState('myBeatBox', {case: bCase._id},  {
            nextTick: true,
            prev: {
                name: 'follow-user',
                params: {
                    userName: $scope.user.userName
                }
            }
        });
    };

    $scope.close = () => {
        $uibModalInstance.close();
    };

    (async () => {
        try {
            $scope.loading = true;
            await load();
        } catch (e) {
            helper.error(e);
        }
        $scope.loading = false;
    })();
});

