import angular from 'angular';
import './pushNotification.service'

const app = angular.module(MyGlobal.page.ngAppName);

app.service('mobileAppService', ($timeout, helper, roomService, userService, pushNotificationService, config) => {

    const { backgroundPlay, payments } = config.getAndroidMobileAppSettings();

    const getMobileAppGateway = () => {
        return window['MyAndroidApp'] || (helper.getUrlParamBoolean('isMobileApp') ? {onAppLoaded(){}} : undefined);
    };

    const validateMobileAppExists = (methodName) => {

        if (!getMobileAppGateway()) {
            throw new Error(`You're trying to use mobile app feature and mobile app isn't defined`);
        }

        if (!getMobileAppGateway()?.[methodName]) {
            throw new Error(`Mobile app method [${methodName}] is not defined`);
        }
    };

    const waitForAnswer = async({ methodName, data }) => {

        validateMobileAppExists(methodName);

        return new Promise((resolve, reject) => {
            try {

                const callback = `mobileApp_${methodName}_callback`;

                // Wait for the host app's response
                window[callback] = (...args) => {
                    $timeout(() => {
                        delete window[callback];
                    });
                    resolve(...args);
                };

                // Call the host app

                const mobileApp = getMobileAppGateway();

                if (data) {
                    mobileApp[methodName](JSON.stringify(data));
                } else {
                    mobileApp[methodName]();
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    return {
        init() {
            window.mobileApp_onPushNotification = (...args) => this.onPushNotification(...args);
        },
        isMobileApp() {
            return !!getMobileAppGateway()
        },
        async sendAppReady(data) {
            return waitForAnswer({ methodName: 'onAppLoaded', data });
        },
        async facebookLogin() {

            const {state, accessToken, errorMessage} = await waitForAnswer({ methodName: 'onFacebookLogin' });

            switch (state) {
                case 'failed':
                    throw new Error(errorMessage || 'Facebook login failed');
                case 'cancelled':
                    throw new Error(`Facebook login cancelled`);
                case 'succeed':
                    // no-op, continue down..
                    break;
                default:
                    throw new Error(`Facebook login unknown state: ${state}`);
            }

            return accessToken;
        },
        onPushNotification(notification) {
            pushNotificationService.onNotification(notification);
        },
        isBackgroundPlayEnabled() {
            return !!backgroundPlay || !!userService.isMobileAutoPlayEnabled();
        },
        isPaymentEnabled() {
            return !!payments;
        },
        makeUrlExternal(url) {
            try {
                const urlObj = new URL(url);
                urlObj.searchParams.append('moieb', 'true');
                return urlObj.toString();
            } catch(e) {
                helper.debug(url);
                debugger;
            }
        },
        async copyToClipboard({ text, label }) {
            return await waitForAnswer({ methodName: 'copyToClipboard', data: {text, label} });
        }
    }
});
