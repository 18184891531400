
import angular from 'angular';
import './mobileFooter.less';
import template from './mobileFooter.html';

import '../homePage/homePage.service';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myMobileFooter', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, $rootScope, mobileLayerService, helper, homePageService, popupService) {

            $scope.inRoom = !!$rootScope.isRoomPage;
            $scope.inWiki = !!$rootScope.isWikiPage;
            $scope.homePageService = homePageService;

            $scope.getAddButtonClasses = () => {
                return {
                    createRoomBtn: homePageService.getMobileSubView() !== homePageService.SUB_VIEWS.FEED || $scope.inRoom,
                    dropBtn: homePageService.getMobileSubView() === homePageService.SUB_VIEWS.FEED || !homePageService.SUB_VIEWS && !$scope.inRoom,
                    songSearchDisplayed: mobileLayerService.isOpen(mobileLayerService.subViews.songSearch)
                };
            }

            $scope.$on('song:search:toggle', () => {
                $scope.onAddClicked();
            })

            $scope.onAddClicked = ($event) => {
                if(homePageService.getMobileSubView() === homePageService.SUB_VIEWS.FEED){
                    popupService.openCreateFeedDropPopup({
                        $event
                    });

                }
                else if ($scope.inRoom && !$rootScope.showRoomList) {
                    if (!mobileLayerService.isOpen(mobileLayerService.subViews.songSearch)) {
                        mobileLayerService.open(mobileLayerService.subViews.songSearch);
                    } else {
                        mobileLayerService.close();
                    }
                } else {
                    helper.gotoState('create-room');
                }
            };

            $scope.showHomeSubView = ($event, subView) => {
                $event.preventDefault();
                homePageService.setMobileSubView(subView, { resetStack: true });
            };

            $scope.$on('roomChat:unread', (evt, { state }) => {
                $scope.roomChatUnread = state;
            });
        }
    };
});
