import angular from 'angular';
import '../inbox.service';
import './inboxHolder.less';
import './inboxItem/inboxItem';
import template from './inboxHolder.html';

if (MyGlobal.project.isMobile) {
    import('./inboxHolder_mobile.less');
}

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myInboxHolder', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, helper, inboxService, $rootScope, chatService, $timeout) {

            let currPage = 1;

            $scope.show = false;

            const load = async ({ append } = {}) => {
                const { models, pagination } = await inboxService.getInbox(currPage);

                if (!append) {
                    $scope.threads = models;
                } else {
                    $scope.threads = ($scope.threads || []).concat(models);
                }

                $scope.pagination = pagination;

                if ($rootScope.isMobile && !$scope.threads?.length) {
                    $timeout(() => {
                        $scope.close();
                    }, 3000);
                }
            };

            $scope.markAllAsRead = async () => {
                try {
                    $scope.loading = true;
                    await chatService.ackAllMessages();
                    await load();
                } catch (e) {
                    helper.error(e);
                } finally {
                    $scope.loading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.showHistory = async () => {
                try {
                    currPage = 1;
                    $scope.loading = true;
                    await load();
                } catch (e) {
                    helper.error(e);
                } finally {
                    $scope.loading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.hasMoreToLoad = () => {
                return currPage < $scope.pagination?.totalPages;
            };

            $scope.loadMore = async () => {
                try {
                    if ($scope.hasMoreToLoad()) {
                        currPage++;
                        await load({ append: true });
                    }
                } catch (e) {
                    helper.error(e);
                }
            };

            $scope.onThreadMarkAsReadClicked = async (thread) => {
                try {
                    if (!thread.read) {
                        await load();
                        $rootScope.$broadcast('inbox:updateUnreads');
                    }
                } catch (e) {
                    helper.error(e);
                }
            };

            $scope.close = () => {
                $scope.show = false;
            };

            $scope.$on('userAlerts:toggled', (evt, { state }) => {
                if (state) {
                    $scope.close();
                }
            });

            $scope.$on('inbox:unreads', (evt, { total }) => {
                $scope.unreads = total;
            });

            $scope.onClick = ($event) => {
                $event.stopImmediatePropagation();
            };

            $scope.$on('inbox:toggle', async (evt, { state, unreads }) => {
                try {

                    $scope.show = state || !$scope.show;
                    $scope.unreads = unreads || $scope.unreads;

                    if ($scope.show) {
                        $scope.loading = true;
                        await load();
                    }
                } catch(e) {
                    helper.error(e);
                } finally {
                    delete $scope.loading;
                    try { $scope.$digest(); } catch(e) {}
                }
            });

            $scope.$on('mobileRightMenu:toggled', (evt, { state }) => {
                if (!state) {
                    $scope.close();
                }
            });

            $scope.$watch('show', (newVal) => {
                if (newVal) {
                    $rootScope.$broadcast('userAlerts:close');
                }
            });

        }
    };
});
