/**
 * Created by Shlomi on 16/11/2015.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myEmoji', function (helper, $timeout) {
        return {
            restrict: 'A',
            // scope: {
            //     myEmoji : '='
            // },
            link: function ( $scope, element, attrs ) {
                var doEmoji = function (value) {
                    element.html(helper.replaceEmoji(value));
                };

                if(attrs.myEmoji){
                    // Listen to changes
                    $scope.$watch(attrs.myEmoji, function(value) {
                        if(value) {
                            doEmoji(value);
                        }
                    });
                }

                if(attrs.myEmojiTriggerEvent){
                    $scope.$on(attrs.myEmojiTriggerEvent, function(){
                        doEmoji(element.text());
                    });
                }

                $timeout(function(){
                    doEmoji($scope[attrs.myEmoji]);
                });
            }
        }
    });
}(angular));