import angular from 'angular';
const app = angular.module(MyGlobal.page.ngAppName);

app.directive('contenteditable', ['$sce', function($sce) {
    return {
        restrict: 'A', // only activate on element attribute
        require: '?ngModel', // get a hold of NgModelController
        link: function(scope, element, attrs, ngModel) {
            if (!ngModel) return; // do nothing if no ng-model

            const { defValue } = attrs;

            // Specify how UI should be updated
            ngModel.$render = function() {
                element.html($sce.getTrustedHtml($sce.trustAsHtml(ngModel.$viewValue) || ''));
            };

            // Listen for change events to enable binding
            element.on('blur keyup change', function($evt) {
                $evt.stopImmediatePropagation();
                scope.$evalAsync(read);
            });

            function setEndOfContenteditable(contentEditableElement) {
                var range,selection;
                if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
                {
                    range = document.createRange();//Create a range (a range is a like the selection but invisible)
                    range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
                    range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
                    selection = window.getSelection();//get the selection object (allows you to change selection)
                    selection.removeAllRanges();//remove any selections already made
                    selection.addRange(range);//make the range you have just created the visible selection
                }
                else if(document.selection)//IE 8 and lower
                {
                    range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
                    range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
                    range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
                    range.select();//Select the range (make it the visible selection
                }
            }

            // Write data to the model
            function read() {
                var html = element.html();
                // When we clear the content editable the browser leaves a <br> behind
                // If strip-br attribute is provided then we strip this out
                if ( attrs.stripBr && html === '<br>' ) {
                    html = '';
                }
                ngModel.$setViewValue(html);
            }

            if (!element.html()) {
                element.text(defValue);
            }

            read(); // initialize
            setEndOfContenteditable(element[0]);
            element.focus();
        }
    };
}]);