import angular from "angular";

const app = angular.module(MyGlobal.page.ngAppName);
app.factory('YoutubePlayer', ($window, helper) => {
    let sdkInitialized = false;

    return class YoutubePlayer {

        static id = 0;
        static arrAfters = [];

        id = null;
        eventListeners = {};
        player = null;
        initialized = false;
        ready = false
        userClickedPlayOnMobile = false

        constructor() {
            this.id = ++YoutubePlayer.id;
            this.eventListeners = {};
        }

        #getPlayerState(string) {
            const statusCode = this.#invokePlayerMethod('getPlayerState', arguments);
            if(!string) return statusCode;
            return this.#getPlayerStateText(statusCode);
        }
        async init(options = {}) {

            await YoutubePlayer.initSDK();

            this.playerVars = {
                autoplay: 0,
                modestbranding: 1,
                fs: false,
                disablekb: 1,
                wmode: 'opaque',
                iv_load_policy: 3,
                cc_load_policy: 0,
                enablejsapi: 1,
                rel: 0,
                hd: 1,
                ...(options.playerVars ? options.playerVars : {})
            };

            this.initialized = true;

            if (options.videoId) {
                debugger;
            }

            return new Promise((resolve) => {
                this.player = new YT.Player(options.playerId, {
                    width: options.width || '550',//'460',
                    height: options.height || '301',//'390',
                    origin: location.href,
                    playerVars: this.playerVars,
                    // Donot register more events here, only via addEventListener
                    events: {
                        onReady: (evt) => {
                            this.ready = true;
                            resolve(evt);
                        }
                    }
                });

                // For Hotjar
                $(`#${options.playerId}`).attr('data-hj-allow-iframe', '');
            });
        }
        isReady() {
            return typeof this.player && this.ready && this.initialized;
        }
        getStates() {
            return YT.PlayerState;
        }
        isPlaying(){
            return this.#getPlayerState() === YT.PlayerState.PLAYING;
        }
        mute() {
            return this.#invokePlayerMethod('mute', arguments);
        }
        unMute() {
            return this.#invokePlayerMethod('unMute', arguments);
        }
        isMuted() {
            return this.#invokePlayerMethod('isMuted', arguments);
        }
        loadVideoById(youtubeId, startSeconds) {
            this.#invokePlayerMethod('loadVideoById', arguments);
        }
        cueVideoById() {
            return this.#invokePlayerMethod('cueVideoById', arguments);
        }
        seekTo() {
            return this.#invokePlayerMethod('seekTo', arguments);
        }
        getCurrentTime() {
            return this.#invokePlayerMethod('getCurrentTime', arguments);
        }
        getDuration() {
            return this.#invokePlayerMethod('getDuration', arguments);
        }
        playVideo() {
            return this.#invokePlayerMethod('playVideo', arguments);
        }
        stopVideo() {
            return this.#invokePlayerMethod('stopVideo', arguments);
        }
        getVolume() {
            return this.#invokePlayerMethod('getVolume', arguments);
        }
        getVideoData() {
            return this.#invokePlayerMethod('getVideoData', arguments);
        }
        pauseVideo () {
            return this.#invokePlayerMethod('pauseVideo', arguments);
        }
        setVolume(){
            return this.#invokePlayerMethod('setVolume', arguments);
        }
        setSize(){
            return this.#invokePlayerMethod('setSize', arguments);
        }

        // Events
        addEventListener(eventName, callback) {

            if (!this.isReady()) {
                throw new Error('Player is not ready yet');
            }

            const eventGlobalName = 'bsYoutubeCallback' + Math.random().toString(36).substr(2, 7);
            $window[eventGlobalName] = (...args) => {
                if (eventName === 'onStateChange') {
                    callback({
                        states: YT.PlayerState,
                        ...args[0]
                    });
                } else {
                    callback(...args);
                }
            };

            if(!this.eventListeners[eventName]){
                this.eventListeners[eventName] = [];
            }

            this.eventListeners[eventName].push({
                globalCallbackName: eventGlobalName,
                callback: callback
            });

            this.player.addEventListener(eventName, eventGlobalName);

        }
        removeEventListener(eventName, callback) {

            if(this.eventListeners[eventName]){

                let eventGlobalName;

                for(let i=0;i<this.eventListeners[eventName].length;i++){
                    if(!callback || this.eventListeners[eventName][i].callback === callback){
                        eventGlobalName = this.eventListeners[eventName][i].globalCallbackName;
                        this.eventListeners[eventName].splice(i, 1);
                    }
                }

                if(eventGlobalName){
                    this.player.removeEventListener(eventName, eventGlobalName);

                    if($window[eventGlobalName]){
                        $window[eventGlobalName] = function(){};
                    }
                }
            }
        }
        removeEventListeners() {
            if(this.eventListeners){
                for(var eventName in this.eventListeners){
                    if(this.eventListeners.hasOwnProperty(eventName)){
                        if(this.eventListeners[eventName] instanceof Array){
                            while(this.eventListeners[eventName].length){
                                var tmp = this.eventListeners[eventName].pop();
                                var eventGlobalName = tmp.globalCallbackName;

                                //helper.debug('Removing YT event handler for `'+eventName+'` ('+eventGlobalName+'), at player '+this.id);
                                this.player.removeEventListener(eventName, eventGlobalName);

                                if($window[eventGlobalName]){
                                    $window[eventGlobalName] = function(){};
                                }
                            }
                        }
                    }
                }
            }
        }
        // Private methods
        #getPlayerStateText(statusCode) {
            let statusStr;
            switch(statusCode){
                case YT.PlayerState.PLAYING:
                    statusStr = 'playing';
                    break;
                case YT.PlayerState.ENDED:
                    statusStr = 'ended';
                    break;
                case YT.PlayerState.PAUSED:
                    statusStr = 'paused';
                    break;
                case YT.PlayerState.BUFFERING:
                    statusStr = 'buffering';
                    break;
                case YT.PlayerState.CUED:
                    statusStr = 'cued';
                    break;
                case YT.PlayerState.UNSTARTED:
                    statusStr = 'unstarted';
                    break;
                default:
                    statusStr = 'unknown';

            }

            return statusStr;
        }
        #invokePlayerMethod(method, ...args){

            if (!this.isReady()) {
                throw new Error('Player is not ready yet');
            }

            try {
                return this.player[method].apply(this.player, ...args);
            } catch (e) {
                helper.error(e);
            }
        }
        dispose(){
            if (!this.player) return;
            this.removeEventListeners();
            this.player.destroy();
            this.player = null;
        }

        static async initSDK() {

            if (!sdkInitialized) {
                sdkInitialized = new Promise((resolve, reject) => {
                    if(typeof YT === 'undefined' || !YT.Player){
                        // Initiate youtube API
                        const tag = document.createElement('script');
                        tag.src = `https://www.youtube.com/iframe_api?ttt=${Date.now()}`;
                        tag.async = true;
                        const firstScriptTag = document.getElementsByTagName('script')[0];
                        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                        // Global waiting method..
                        $window.onYouTubeIframeAPIReady = function(){
                            resolve();
                        };

                    }else{
                        resolve();
                    }
                });
            }

            return sdkInitialized;
        }
    }
});