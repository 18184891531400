import angular from 'angular';
import './paymentPopup.less';
import {
    CONTRIBUTION_SETTINGS,
    PAYMENT_METHODS,
    PAYMENT_TYPES
} from '../../../../../shared/consts';
import './paypal.service';
import './googlePay.service';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyPaymentPopupCtrl', ($scope, $uibModalInstance, popupParams, $rootScope,
                                      paypalService, googlePayService, helper, $timeout,
                                      mobileAppService) => {
    const { type, data } = popupParams;
    $scope.PAYMENT_METHODS = PAYMENT_METHODS;
    $scope.loaders = {};

    switch(type) {
        case PAYMENT_TYPES.BEATS_PACKAGE:
            $scope.amount = data.package.price;
            $scope.description = `${data.package.name} package`;
            break;
        case PAYMENT_TYPES.SUBSCRIPTION:
            const { subscription } = data;
            $scope.amount = Math.round(subscription.amount * subscription.months);
            $scope.description = `${data.subscription.name} subscription`;
            break;
        case PAYMENT_TYPES.CONTRIBUTION:
            $scope.amount = CONTRIBUTION_SETTINGS.PURCHASE_PRICE;
            $scope.description = `${CONTRIBUTION_SETTINGS.PURCHASE_PRICE} contribution`;
            break;
    }

    $scope.redirectToWeb = ($event) => {
        $event.preventDefault();
        let url = helper.getCurrentFullUrl();

        if ($rootScope.isMobileApp) {
            url = mobileAppService.makeUrlExternal(helper.getCurrentFullUrl());
        }

        helper.redirect(url);
    };

    const initGooglePayButton = async ()=> {
        try {
            $scope.loaders.googlePay = true;
            const button = await googlePayService.getButton({
                paymentType: $scope.type,
                onClick: async () => {
                    try {
                        await $scope.pay(PAYMENT_METHODS.GOOGLE_PAY);
                    } catch(e) {
                        helper.error(e);
                    } finally {
                        try { $scope.$digest(); } catch(e) {}
                    }
                }
            });

            $('#paymentPopup #googlePay').append(button);

        } finally {
            delete $scope.loaders.googlePay;
        }
    };

    $scope.pay = async (paymentMethod)=> {
        try {
            if (paymentMethod === PAYMENT_METHODS.PAYPAL) {
                const res = await paypalService.getRedirectLink({
                    type,
                    data
                });

                $scope.loaders.redirecting = true;
                helper.redirect(res.paymentUrl);
            } else {
                // const token = await googlePayService.makePayment({
                //     price: '5',
                //     countryCode: 'IL'
                // });
                //
                // debugger;
            }
        } catch(e) {
            helper.error(e);
            delete $scope.loaders.redirecting;
            $scope.error = `We're sorry, something happen while trying to redirect you, please contact us at ilove@beatsense.com`;
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    if (!type) {
        $timeout(() => {
            helper.error(new Error('Invalid payment type'));
            $scope.close();
        });
    } else {
        (async ()=> {
            try {
                if ($rootScope.isMobileApp && !mobileAppService.isPaymentEnabled()) {
                    $scope.showMobileAppNotification = true;
                } else {
                    $scope.loaders.redirecting = true;
                    await $scope.pay(PAYMENT_METHODS.PAYPAL);
                }

                //await initGooglePayButton();
            } catch(e) {
                helper.error(e);
            }
        })();
    }
});
