import angular from 'angular';
import './userAlertsOpener.less';
import template from './userAlertsOpener.html';
import '../userAlert.service';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myUserAlertsOpener', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller: function($scope, $rootScope, helper, userAlertService, $interval, $timeout){

            let timer;
            const ttl = parseInt(helper.getUrlParam('userAlertInfoInterval')) || helper.minutesToMs(3);

            $scope.toggle = () => {
                $rootScope.$broadcast('userAlerts:toggle');
            };

            const broadcastUnreads = ()=> {
                $rootScope.$broadcast('userAlerts:unreads', {
                    total: $scope.unread
                });
            }

            const load = async () => {
                const { unread } = await userAlertService.getInfo();
                $scope.unread = unread || 0;
                broadcastUnreads();
            };

            $scope.$on('userAlert:added', async () => {
                $scope.unread = ($scope.unread || 0) + 1;
                broadcastUnreads();
            });

            $scope.$on('userAlerts:reset', async (evt, alert, options = {}) => {
                $scope.unread = 0;
                broadcastUnreads();
            });

            $scope.$on('userAlert:deleted', async (evt, alert, options = {}) => {
                if (!options.local) {
                    $scope.unread = $scope.unread > 0 ? $scope.unread - 1 : 0;
                    broadcastUnreads();
                }
            });

            $scope.$on('$destroy', function() {
                if (timer) {
                    $interval.cancel(timer);
                    timer = null;
                }
            });

            $timeout( async ()=> {
                try {
                    await load();

                    timer = $interval(async () => {
                        try {
                            await load();
                        } catch (e) {
                            helper.error(e);
                        }
                    }, ttl);
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            });
        }
    };
});
