const app = angular.module(MyGlobal.page.ngAppName);

app.controller('BeatsInsufficientModalCtrl', ($scope, helper, $uibModalInstance, beatsService,
                                              $stateParams, analyticsService) => {

    const { reason } = $stateParams;

    $scope.closeAndGoToBeatsPurchaseModal = function(){
        helper.trackBeatsEvent('PurchaseModalOpened', 'NotEnoughBeatsModal');
        $scope.close();
    };

    (async () => {
        try {
            await beatsService.refresh();
            $scope.balance = beatsService.getBalance();

            if (reason) {
                analyticsService.trackBeatsEvent({
                    eventAction: 'NotEnoughBeatsModalOpened',
                    eventLabel: reason
                });
            }
        } catch (e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    })();

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };
});


