/**
 * Created by Shlomi on 29/09/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myFooter', ['helper', function() {
        return {
            restrict: 'A',
            controller: function($scope, $rootScope, helper, storage){

                $rootScope.onFacebookPageClicked = function(){
                    if($scope.fbPageIndicator){
                        storage.setForeverItem('fbIndicatorClicked', $scope.fbPageIndicator.version);
                        $scope.trackGaUIEvent('FacebookPageButtonClicked');
                    }
                };
                $rootScope.onTwitterPageClicked = function(){
                    if($scope.fbPageIndicator){
                        storage.setForeverItem('twitterIndicatorClicked', $scope.fbPageIndicator.version);
                        $scope.trackGaUIEvent('TwitterPageButtonClicked');
                    }
                };

                $rootScope.showFbIndicator = function(){
                    return $scope.fbPageIndicator && $scope.fbPageIndicator.count &&
                        storage.getItem('fbIndicatorClicked') !== $scope.fbPageIndicator.version;
                }
            }
        };
    }]);
}(angular));
