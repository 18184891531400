import '../../../less/songModal.less';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('SongModalCtrl', function ($scope, $rootScope, $uibModalInstance, songData, helper, beatBoxService) {

        $scope.close = function(state){
            $uibModalInstance.close(state);
        };

        if(!songData.id){
            helper.log('Song video id does not provided');
            return $scope.close();
        }

        $scope.ytSong = songData;
        $scope.ytSongSettings = {
            autoPreview: songData.type === 'ytap'
        };

        $scope.addingSongDone = ()=> {
            $scope.close();
            beatBoxService.setSongAddedFlag(true);
        };

        $scope.onLoadingSongError = (error) => {
            $scope.loading = false;
            $scope.error = error.message;

            if (!$scope.ytSong?.name) {
                $scope.close();
            }
        };

        // Report out
        $rootScope.$broadcast('songModal:opened');
        helper.trackGaUIEvent('SongModalOpened');

        $scope.loading = true;
        $scope.onLoadingSongDone = (ytSong)=> {
            $scope.ytSong = ytSong;
            $scope.loading = false;
        }
    });

}(angular));

