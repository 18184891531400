import angular from 'angular';
import './pollBuilder.less';
import {POLL_MAX_OPTIONS} from '../../../../../../shared/consts';
import template from './pollBuilder.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myPollBuilder', () => {

    return {
        restrict: 'E',
        template,
        scope: {
            poll: '=',
            feedDropId: '=',
            exposeApi: '&'
        },
        controller($scope, $rootScope, $timeout, helper) {

            $scope.showAddButton = true;
            $scope.options = [{}, {}];

            $scope.addOption = ($event)=> {

                if ($event) {
                    helper.preventClick($event);
                }

                $scope.options.push({});

                if ($scope.options.length >= POLL_MAX_OPTIONS) {
                    $scope.showAddButton = false;
                }

                $timeout($scope.focusLastOption);
            };

            $scope.removeOption = (index) => {
                helper.removeItemFromArray($scope.options, (item, i)=> {
                    return index === i;
                });

                $scope.showAddButton = true;
                $timeout($scope.focusLastOption);
            };

            $scope.onOptionKeyDown = ($event, index) => {

                const id = `txtPollOption_${index}`;

                if ($event.key === 'Tab') {
                    // If it's the last option
                    const lastElementId = `txtPollOption_${$scope.options.length - 1}`;
                    if (id === lastElementId) {
                        $scope.addOption();
                    }
                }
            };

            $scope.exposeApi({
                api: {
                    getOptions() {
                        return ($scope.options || []).filter(item => !!item.value);
                    }
                }
            });

            $timeout(() => $scope.focusFirstOption());
        },
        link($scope, $element) {

            $scope.focusFirstOption = () => {
                $($element).find('.pollOption input').first().focus();
            };

            $scope.focusLastOption = () => {
                $($element).find('.pollOption input').last().focus();
            };
        }
    };
});
