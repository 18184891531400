
import './chatMessageRow.less';

import angular from 'angular';

import template from './chatMessageRow.html';
import {CHAT_MESSAGE_TYPES} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myChatMessageRow', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            chatMessage: '=',
            onReplyTo: '&',
            onReplyClicked: '&'
        },
        controller($scope, userService, helper) {
            $scope.CHAT_MESSAGE_TYPES = CHAT_MESSAGE_TYPES;
            $scope.message = $scope.chatMessage.message;

            if(helper.isRTL($scope.message.content)){
                $scope.isRTL = true;
            }

            $scope.isMyMessage = (chatMessage) => {
                return chatMessage.from._id === userService.getUserId();
            };
        }
    };
});
