import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

if (MyGlobal.project.isMobile) {
    import('./feed_mobile.less');
}

app.service('feedService', (helper, httpService, config) => {

    return {
        async loadFeed({ page, ...data }) {
            return await httpService.post(`/feed/drops/${page}`, data);
        },
        async getFeedDropReplies({ id, page }) {
            return await httpService.get(`/feed/drop/${id}/replies/${page}`);
        },
        async getFeedDrop(id) {
            return await httpService.get(`/feed/drop/${id}`);
        },
        async createDrop(data) {
            return await httpService.post('/feed/drop', data);
        },
        async updateDrop(id, data) {
            return await httpService.put(`/feed/drop/${id}`, data);
        },
        async delete(feedDrop) {
            if (!feedDrop?._id) return;
            return await httpService.delete(`/feed/drop/${feedDrop._id}`);
        },
        async toggleLike(feedDrop) {
            if (!feedDrop?._id) return;
            return await httpService.post(`/feed/drop/${feedDrop._id}/like`);
        },
        async hasNewFeedDrops(lastCheck) {
            return await httpService.post(`/feed/drops/hasNew`, { lastCheck });
        },
        async mute(userId) {
            return await httpService.post(`/feed/user/${userId}/mute`);
        },
        async unStick(id) {
            return await httpService.post(`/feed/drop/${id}/unStick`);
        },
        async reportFeedDrop({ feedDropId, text }) {
            return await httpService.post(`/feed/drop/${feedDropId}/report`, {
                text
            });
        },
        async subscribeToFeedDrop({ feedDropId }) {
            return await httpService.post(`/feed/drop/${feedDropId}/subscribe`);
        }
    };

});
