import './subscriptionPurchasePopup.less';

import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MySubscriptionPurchasePopupCtrl', ($scope, $stateParams, $uibModalInstance, analyticsService,
                                                   subscriptionService) => {

    const { id, res, amount } = $stateParams;

    $scope.succeed = !res;

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    (async ()=> {
        try {
            if ($scope.succeed) {

                const subscription = await subscriptionService.getSubscription(parseInt(id));

                analyticsService.trackBeatsEvent({
                    eventAction: 'SubscriptionPurchaseSucceed',
                    eventLabel: subscription.name,
                    eventValue: parseFloat(amount)
                });
            }
        } catch(e) {
            helper.error(e);
        }
    })();

});
