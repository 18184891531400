const angular = require('angular');

const app = angular.module(MyGlobal.page.ngAppName);

app.service('analyticsService', (config, $timeout, $injector, storage, $interval) => {

    const helper = $injector.get('helper');
    const gaEventThreshold = 1000;
    const queuedGAEventsStorageKey = 'gaevents';

    let gaTimer;
    let lastGaEvent;

    const startTimer = () => {
        if(gaTimer) return;

        gaTimer = $timeout(() => {

            gaTimer = null;

            const events = storage.getItem(queuedGAEventsStorageKey) || [];
            if(events && events.length) {
                processEvent(events.shift());

                if(events.length){
                    storage.setItem(queuedGAEventsStorageKey, events);
                    startTimer();
                }else{
                    storage.removeItem(queuedGAEventsStorageKey);
                    lastGaEvent = null;
                }
            }

        }, gaEventThreshold);
    };

    const processEvent = (eventObj) => {

        if(!eventObj) return;

        eventObj.options = eventObj.options || {};

        let { eventCategory, eventAction, eventLabel, eventValue, hitCallback } = eventObj;
        eventLabel = eventLabel === null ? undefined : eventLabel;
        eventValue = eventValue === null ? undefined : eventValue;

        eventLabel = typeof eventLabel === 'object' ? JSON.stringify(eventLabel) : eventLabel;

        const params = {};

        if (eventCategory) {
            params.event_category_legacy = eventCategory;
        }

        if (eventLabel) {
            params.event_label_legacy = eventLabel;
        }

        if (eventValue) {
            params.event_value_legacy = eventValue;
        }

        if (typeof hitCallback === 'function') {
            params.event_callback = hitCallback;
        }

        send({
            command: 'event',
            eventName: `${eventAction}`,
            params
        });

        lastGaEvent = Date.now();

    };

    const send = ({
        command,
        eventName,
        params
      }) => {
        if(!config.isAnalyticsActive()) return;
        gtag(command, eventName, params);
    };

    const trackGA = ({ eventCategory, eventAction, eventLabel, eventValue, hitCallback, ...more })=> {
        if(!config.isAnalyticsActive()) {
            if (typeof hitCallback === 'function') {
                hitCallback();
            }
            return;
        }

        if (eventValue) {
            eventValue = parseFloat(eventValue);

            if (isNaN(eventValue)) {
                throw new Error('eventValue has to be an integer');
            }
        }

        const eventObj = {
            eventCategory,
            eventAction,
            eventLabel,
            eventValue,
            hitCallback,
            ...more
        };

        if(!lastGaEvent || Date.now() - lastGaEvent >= gaEventThreshold || typeof hitCallback === 'function'){
            processEvent(eventObj);
        }else{
            //to avoid GA 1 event per second limit
            const events = storage.getItem(queuedGAEventsStorageKey) || [];
            events.push(eventObj);
            storage.setItem(queuedGAEventsStorageKey, events);
            startTimer();
        }
    };

    // ### Public
    return {
        init({ keepAlive, userId, ...rest }) {
            const res = config.isAnalyticsActive();
            if(!res) {
                return;
            }

            const trackingId = config.getAnalyticsTrackingId();

            const script = document.createElement('script');
            script.async = true;
            document.head.appendChild(script);
            script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

            window.dataLayer = window.dataLayer || [];

            const gtag = function () {
                dataLayer.push(arguments);
            };
            gtag('js', new Date());

            gtag('config', trackingId, {
                user_id: userId,
                'user_properties': {
                    ...rest
                }
            });

            window.gtag = gtag;

            startTimer();

            if (keepAlive) {

                helper.debug('Starting GA keep alive timer..');

                $interval(() => {
                    helper.debug('Keeping GA user keep alive..');
                    // Dont remove this event, it makes user alive in Google Analytics RealTime!!
                    // this.trackOperationEvent({
                    //     eventAction: 'KeepUserAlive'
                    // });
                }, helper.minutesToMs(5));

            }
        },
        getRoomUrl(options = {}){
            let str = location.pathname + location.search;

            if (options.hash) {
                str += location.hash;
            }

            return str;
        },
        trackEvent({ eventCategory, eventAction, eventLabel, eventValue, hitCallback, ...more }) {
            trackGA({ eventCategory, eventAction, eventLabel, eventValue, hitCallback, ...more });
        },
        async trackEventP(params = {}) {
            return new Promise((resolve) => {
                params.hitCallback = () => {
                    resolve();
                };

                // Fallback in case params.hitCallback hasn't called form the Analytics
                $timeout(() => {
                    params.hitCallback();
                }, params.ttl || 2000);

                this.trackEvent(params);
            });
        },
        trackPage({ page, title, hitCallback }) {
            if(!config.isAnalyticsActive()) {
                if (typeof hitCallback === 'function') {
                    hitCallback();
                }
                return;
            }

            return send({
                command: 'event',
                eventName: 'page_view',
                params: {
                    page_location: page,
                    page_title: title || $(document).find('title').text()
                }
            });
        },
        // Aliases
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackUserAlertEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'UserAlert'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackUserAlertEventP(params) {
            return this.trackEventP({
                ...params,
                eventCategory: 'UserAlert'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackActionEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Action'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackShareEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Share'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackSongSearchEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Search'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackWikiEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Wiki'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackWikiEventP(params) {
            return this.trackEventP({
                ...params,
                eventCategory: 'Wiki'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackBeatsEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Beats'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackUIEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'UI'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        async trackUIEventP(params) {
            return this.trackEventP({
                ...params,
                eventCategory: 'UI'
            })
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackOperationEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Operation'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackMusicMatchEvent(params) {
            this.trackEvent({
                ...params,
                eventCategory: 'MusicMatch'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackRoomModerationEvent(params) {
            this.trackEvent({
                ...params,
                eventCategory: 'RoomModeration'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        async trackRoomModerationEventP(params) {
            return this.trackEventP({
                ...params,
                eventCategory: 'RoomModeration'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackUpgradedRoomEvent(params) {
            this.trackEvent({
                ...params,
                eventCategory: 'UpgradedRoom'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackRoomSettingsEventP(params) {
            return this.trackEventP({
                ...params,
                eventCategory: 'RoomSettings'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackFeedEvent(params) {
            this.trackEvent({
                ...params,
                eventCategory: 'Feed'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackContributionEvent(params) {
            this.trackEvent({
                ...params,
                eventCategory: 'Contribution'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackContributionEventP(params) {
            return this.trackEventP({
                ...params,
                eventCategory: 'Contribution'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackStreamEvent(params) {
            this.trackEvent({
                ...params,
                eventCategory: 'Streams'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackRoomEvent(params) {
            this.trackEvent({
                ...params,
                eventCategory: 'Room'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        async trackRoomEventP(params) {
            return await this.trackEventP({
                ...params,
                eventCategory: 'Room'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        async trackRetentionEventP(params) {
            return await this.trackEventP({
                ...params,
                eventCategory: 'Retention'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackProfileEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Profile'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackBeatzoneEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Beatzone'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackBeatBoxEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'BeatBox'
            });
        },
        /**
         *
         * @param params
         * @param params.eventAction
         * @param params.eventLabel
         * @param params.eventValue
         */
        trackAmbassadorEvent(params) {
            return this.trackEvent({
                ...params,
                eventCategory: 'Ambassador'
            });
        }
    }
});
