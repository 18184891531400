import angular from 'angular';
import {EMPTY_USER_IMAGE} from '../../../shared/consts/general';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myUserImage', (helper, userService) => {
    return {
        restrict: 'A',
        scope: {
            user: '='
        },
        link: function($scope, $element){
            $element.attr('alt', 'User Image');

            $element.on('error', async () => {
                try {
                    $element.attr('src', EMPTY_USER_IMAGE);

                    const userId = $scope.user?._id || $scope.user?.id;
                    const userImage = $scope.user?.image;

                    if (!userId) {
                        $element.attr('data-user-image-wrong', 1);
                        throw new Error('myUserImage must provided with a user');
                    }

                    await userService.reportMissingImage({
                        _id: userId,
                        image: userImage
                    });
                } catch(e) {
                    helper.error(e);
                }
            });
        }
    };
});
