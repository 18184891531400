const ROOM_LEVEL_KEYS = {
    STANDARD: 1,
    PRO: 2,
    PRO_PLUS: 3,
    PREMIUM: 4
};

const ROOM_LEVELS = [
    {
        name: 'Standard',
        shortName: 'Standard room',
        value: ROOM_LEVEL_KEYS.STANDARD
    },
    {
        name: 'Pro',
        shortName: 'Pro',
        value: ROOM_LEVEL_KEYS.PRO,
        beatsFeature: 'ProRoom'
    },
    {
        name: 'Pro+',
        shortName: 'Pro+',
        value: ROOM_LEVEL_KEYS.PRO_PLUS,
        beatsFeature: 'ProRoomPlus'
    },
    {
        name: 'Premium',
        shortName: 'Premium',
        value: ROOM_LEVEL_KEYS.PREMIUM,
        beatsFeature: 'PremiumRoom'
    }
];

const ROOM_DEFAULT_ENTRANCE_FEE = 5;
const ROOM_ACCESS_REQUEST_MIN_BEATS = 100;

const ROOM_PAUSE_ICON_REASONS = {
    Toilet: 'Toilet'
};

const ROOM_SETTINGS = {
    blockOthersSongs: 'blockOthersSongs'
};

module.exports = {
    ROOM_LEVELS,
    ROOM_DEFAULT_ENTRANCE_FEE,
    ROOM_ACCESS_REQUEST_MIN_BEATS,
    ROOM_PAUSE_ICON_REASONS,
    ROOM_LEVEL_KEYS,
    ROOM_SETTINGS
};