
import './musicTagSearch.less';

import angular from 'angular';

import template from './musicTagSearch.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myMusicTagSearch', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            model: '=',
            disabled: '=?',
            placeholder: '=',
            autoFocus: '=',
            searchAll: '=',
            maxTags: '=',
            onAdding: '&?',
            onChanges: '&',
            onEnterPressed: '&'
        },
        controller($scope, helper, musicTagService, $timeout) {

            $scope.state = $scope.model || [];
            $scope.placeholder = $scope.placeholder || 'Search music genres. Press Enter to add';

            const maxTagsAchieved = ()=> {
                return $scope.state.length >= $scope.maxTags;
            }

            $scope.onAddingInt = () => {
                if (typeof $scope.onAdding === 'function') {
                    return $scope.onAdding();
                }

                if (!!$scope.maxTags && $scope.state?.length) {
                    return !maxTagsAchieved();
                }
            }

            $scope.onAdded = () => {
                $scope.onChanges({ state: $scope.state });
                //$scope.disabled = maxTagsAchieved();

                $scope.tagAdded = true;
                $timeout(() => {
                    delete $scope.tagAdded;
                });
            };

            $scope.onRemoved = () => {
                $scope.onChanges({ state: $scope.state });
            };

            $scope.onKeyUp = ($event) => {
                if ($event.keyCode === 13 && !$scope.tagAdded) {
                    $scope.onEnterPressed({ $event });
                }
            }

            $scope.loadMusicTags = async (query) => {
                try {
                    if(query.length < 2) {
                        return [];
                    }

                    $scope.loading = true;
                    const res = await musicTagService.searchItems(query, {
                        all: $scope.searchAll
                    });

                    return res?.sort((a, b) => {
                        return a.name.replace(/'/g, '').match(/(\w+)/g).length === 1 ? -1 : 1;
                    })

                } catch (e) {
                    helper.error(e);
                    throw e;
                } finally {
                    $scope.loading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            };
        },
        link($scope, $element) {
            if ($scope.autoFocus) {
                $($element).find('input').focus();
            }
        }
    };
});
