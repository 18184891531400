import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);


app.service('homePageService', ($rootScope) => {

    return {
        SUB_VIEWS: {
            FEED: 'FEED',
            ROOMS: 'ROOMS',
            USERS: 'USERS',
            FEED_DROP: 'FEED_DROP',
            USER_PROFILE: 'USER_PROFILE',
            SONG: 'SONG'
        },
        setMobileSubView(name, { resetStack } = {}){
            this.mobileSubView = name;
            $rootScope.$broadcast('homePage:mobile:changeSubView', { resetStack });
        },
        getMobileSubView() {
            return this.mobileSubView;
        }

    };

});
