import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);


app.service('inboxService', (httpService) => {
    return {
        async getTotalUnread() {
            const res = await httpService.get('/inbox/unreads');
            return { data: res, total: res?.length || 0 };
        },
        async getInbox(page) {
            return httpService.get(`/inbox/${page}`);
        }
    }
});
