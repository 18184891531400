
import angular from 'angular';
import './mobileLayer.less';
import template from './mobileLayer.html';

import './mobileLayer.service';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myMobileLayer', ($compile) => {
    return {
        restrict: 'E',
        template,
        scope: {
            subView: '='
        },
        controller($scope, $element) {
            const { subView } = $scope;
            const name = subView.replace(/([A-Z])/g, '-$1').toLowerCase().trim();
            const el = $compile(`<my-mobile-layer-${name}></my-mobile-layer-${name}>`)($scope);
            $element.find('.content').html(el);
        }
    };
});
