/**
 * Created by Shlomi on 26/10/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('MyBeatCtrl', function ($scope, $uibModalInstance, beatsService, helper) {

        beatsService.promise.then(function () {
            $scope.balance = beatsService.getBalance();
        });

        $scope.$on('userBeats:updated', (evt, data) => {
            $scope.balance = data;
        });

        $scope.close = ($event) => {
            if ($event) $event.stopImmediatePropagation();
            $uibModalInstance.dismiss();
        };
    });

}(angular));

