import angular from 'angular';
import '../../../services/upload';
import './beatzonePopup.less';
import {LOGIN_OPTIONS_KEYS} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyBeatzonePopupCtrl', ($scope, $rootScope, $uibModalInstance, helper, analyticsService,
                                       uploadService, $timeout, beatsService, storage, popupService, songService,
                                       userService, $state, lastfmService, roomService, userRoomsService) => {

    let myRoomsLoaded, favoritesRoomsLoaded;
    const origUser = userService.getUser();

    // Initialize state
    $scope.beatsService = beatsService;
    $scope.uploadService = uploadService;

    $scope.showReplacementInput = {};
    $scope.inputEdited = {};
    $scope.beatsBalance = beatsService.getBalance();
    $scope.bioMaxLength = helper.biographyValidation.maxLength;


    // Bind logout function
    $scope.logout = async ($event) => {
        try {
            $event.preventDefault();

            $scope.loadingLogout = true;
            await userService.logout();
        } catch(e) {
            $scope.error = 'Logout failed!';
            helper.error(e);
        } finally {
            $scope.loadingLogout = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.updateUser = (data)=> {
        $scope.user = {
            ...$scope.user || {},
            ...data
        };
    };

    $scope.copyUser = function(data){
        $scope.user = {
            id: data.id,
            image: data.image,
            email: data.email,
            userName: data.userName,
            biography: data.biography,
            countryCode: data.countryCode
        };

        $scope.hasLastFM = userService.hasLastFM();
        $scope.userBiography = data.biography;
        $scope.userMusicTags = (data.musicTags && data.musicTags.map(item => item.tag)) || [];
    };

    $scope.isUserConnectedViaFacebookFn = function(arrUser3rdPartyAccounts){
        if(!arrUser3rdPartyAccounts || !(arrUser3rdPartyAccounts instanceof Array)) return false;

        for(var i=0;i<arrUser3rdPartyAccounts.length;i++){
            if(arrUser3rdPartyAccounts[i].type === LOGIN_OPTIONS_KEYS.FACEBOOK){
                return true;
            }
        }

        return false;

    };

    $scope.togglePrivacy =  async (value)=> {
        try {
            if (value) {
                popupService.openTogglePrivacyPopup();
            } else {
                $scope.enablePrivacy = value;
                $scope.user.features = await userService.updateFeature(userService.features.ENABLE_PRIVACY, value);
                analyticsService.trackBeatzoneEvent({
                    eventAction: 'PrivacyMode',
                    eventLabel: 'off'
                });
            }
        } catch (e) {
            $rootScope.showError(e);
        }
    };

    $scope.onInputKeyup = function($evt, what, elemId){
        if($evt.keyCode === 13 && !$scope.isInvalid[what]){
            return $scope.saveInput(what, elemId);
        }

        $scope.inputEdited[what] = true;
        $scope.isInvalid[what] = null;
        $scope.error = '';
    };

    $scope.saveInput = (what, elemId)=> {
        $scope.toggleReplacementInputFn(elemId);
        $scope.inputEdited[what] = false;
        return $scope.updateDetails();
    };

    $scope.cancelInputEdit = (what, elemId) => {
        $scope.toggleReplacementInputFn(elemId);
        $scope.user[what] = origUser[what];
        $scope.inputEdited[what] = false;
    };

    $scope.updateDetails = async () => {
        try {
            $scope.notification = '';

            var params = {
                userName: $scope.user.userName,
                email: $scope.user.email,
                biography: $scope.user.biography
            };

            var userName = $scope.user.userName;

            if(!helper.isUsernameLongEnough(userName)){
                return $scope.error = helper.userNameValidation.messages.min;
            }

            if(!helper.isUsernameShortEnough(userName)){
                return $scope.error = helper.userNameValidation.messages.max
            }

            if(!helper.isValidUsername(userName)){
                return $scope.error = helper.userNameValidation.messages.invalid;
            }

            if(!helper.isValidEmail($scope.user.email)){
                return $scope.error = helper.emailValidation.messages.invalid;
            }

            if(!helper.isValidBiography($scope.user.biography)){
                return $scope.isInvalid.biography = helper.biographyValidation.messages.invalid;
            }

            if($scope.user.currPassword && $scope.user.newPassword){

                if(!helper.isPasswordLongEnough($scope.user.newPassword)){
                    return $scope.error = helper.passwordValidation.messages.min;
                }

                params['currPassword'] = $scope.user.currPassword;
                params['newPassword'] = $scope.user.newPassword;
            }

            var reloadAfterSave;
            if(origUser.userName !== params.userName){
                reloadAfterSave = true;
            }

            $scope.loadingSaveUser = true;
            const data = await userService.update(params)

            $scope.loadingSaveUser = false;

            if(reloadAfterSave) return helper.reloadPage();

            $rootScope.$broadcast('user:updated', data);

            $scope.error = '';
            $scope.showChangePassword = false;
            $scope.notification = 'Successfully updated!';
            $scope.inputEdited = {};
            $timeout(function(){
                $scope.notification = '';
            }, 8000);

        } catch(e) {
            $scope.error = e.message;
            helper.checkAuth(e);
            $scope.copyUser(origUser);
            helper.error(e)
        } finally {
            $scope.loadingSaveUser = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.uploadImage = async () => {
        try {

            if ($scope.userImageFileError) {
                switch($scope.userImageFileError?.$error) {
                    case 'maxSize':
                        throw new Error(`File size should be smaller than ${uploadService.maxSize.userImage}`);
                    default:
                        throw new Error(`Unmapped upload error: ${$scope.userImageFileError?.$error || 'unknown'}`);
                }
            }

            if(!$scope.userImageFile) return;

            $scope.error = '';
            $scope.loadingUserImage = true;

            const data = await uploadService.upload({
                type: uploadService.FILE_UPLOAD_TYPES.USER_IMAGE,
                file: $scope.userImageFile
            });

            $rootScope.$broadcast('user:updated', data);
            $scope.updateUser(data);

            helper.trackGaUIEvent('UserChangedImage', {src: 'computer'});
        } catch(e) {
            $rootScope.showError(e);
        } finally {
            $scope.loadingUserImage = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.$on('document:click', function(evt, jqEvent){
        if($(jqEvent.target).parents('.edit-user-modal-user-image-holder').length > 0) return;
        $scope.hidePhotoOptions();
        try { $scope.$digest(); } catch(e) {}
    });

    $scope.hidePhotoOptions = function($event){
        $scope.showPhotoOptions=false;
        if($event) $event.stopPropagation();
    };

    $scope.togglePhotoOptions = function(){
        $scope.showPhotoOptions=!$scope.showPhotoOptions;
    };

    $scope.toggleChangePassword = function(){
        $scope.showChangePassword=!$scope.showChangePassword;
        return false;
    };

    $scope.toggleReplacementInputFn = function(elemId, state){

        $scope.showReplacementInput[elemId] = !$scope.showReplacementInput[elemId];

        if($scope.showReplacementInput[elemId]){
            $timeout(function(){
                $('#'+elemId).focus();
            }, 100);
        }
    };

    $scope.showMyBeatRoomsFn = async() => {
        try {
            $scope.showMyBeatRooms = true;
            $scope.showMyFavoritesBeatRooms = false;

            $('#myBeatRoomsTab').addClass('active');
            $('#myFavoriteBeatRoomsTab').removeClass('active');

            await loadUserRooms();
            updateRoomListScrollers();
        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.showMyFavoriteBeatRoomsFn = async () => {
        try {
            $scope.showMyBeatRooms = false;
            $scope.showMyFavoritesBeatRooms = true;

            $('#myBeatRoomsTab').removeClass('active');
            $('#myFavoriteBeatRoomsTab').addClass('active');

            await loadUserFavoriteRooms();
            updateRoomListScrollers();
        } catch(e) {
            helper.error(e);
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    var updateRoomListScrollers = function(){
        $timeout(function(){
            $('#edit-user-details-modal .my-scrollable').perfectScrollbar('update');
        }, 100);
    };

    // Load user rooms
    const loadUserRooms = async ()=> {
        if (myRoomsLoaded) return;
        const rooms = await userService.getMyRooms();
        $scope.myRooms = rooms.reverse();
        myRoomsLoaded = true;
    };

    const loadUserFavoriteRooms = async ({ force } = {}) => {
        if (!force && favoritesRoomsLoaded) return;

        const rooms = await userService.getFavoriteRooms();
        $scope.myFavoriteRooms = rooms.reverse();
        favoritesRoomsLoaded = true;
    };

    const loadUserSoundtracks = async () => {
        try {
            $scope.soundtracksLoading = true;
            $scope.soundtracks = await userService.getUserSoundtracks();
            $scope.soundtracksLoading = false;
        } catch (e) {
            $scope.soundtracksLoading = false;
            helper.error(e);
        }
    };

    $scope.removeSoundTrack = async ($event, soundtrack, index) => {
        if($event){
            $event.preventDefault();
            $event.stopPropagation();
        }
        try {
            const res = await userService.removeSoundtrack(soundtrack);

            if(res){
                $scope.soundtracks.splice(index, 1);
                helper.trackGaUIEvent('RemovedFromSountrackOfMyLIFE');
            }
        } catch (e) {
            helper.error(e);
        }
    };

    $scope.removeRoomFromFavorites = async (room, $event) => {
        try {
            $event.preventDefault();

            const { added } = await userRoomsService.toggleFavorite({roomId: room._id});

            if(added){
                $scope.error = 'Weird error, the room supposed to be removed :(';
                return;
            }

            helper.removeItemFromArray($scope.myFavoriteRooms, item => item._id === room._id);
        } catch(e) {
            helper.error(e);
            $scope.error = e.message;
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.close = ($event) => {
        if ($event) $event.stopImmediatePropagation();
        $uibModalInstance.dismiss();
    };

    $scope.closeAndGoToBeatsExplanationModal = function(){
        helper.trackBeatsEvent('ExplanationModalOpened', 'BeatZone');
        $scope.close();
    };

    $scope.closeAndGoToBeatsPurchaseModal = function(){
        helper.trackBeatsEvent('PurchaseModalOpened', 'BeatZone');
        $scope.close();
    };

    $scope.saveBiography = function ($event) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if(!helper.isValidBiography($scope.user.biography)){
            return $scope.isInvalid.biography = helper.biographyValidation.messages.invalid;
        }

        delete $scope.isInvalid.biography;

        $scope.updateDetails();
        $scope.editBiography = false;

        $timeout(function () {
            $scope.userBiography = $scope.user.biography;
        });
    };

    $scope.openSongModal = ($event, song) => {
        $event.preventDefault();
        helper.gotoState('song-modal', {
                id: song.youtubeId,
                type: 'yt',
                name: songService.getSongName(song)
            },
            {
                prev: 'beatzone'
            });
    };

    $scope.startScrobbling = async () => {
        const url = lastfmService.getAuthUrl({ roomId: roomService.getRoomId() });
        helper.redirect(url);
    };

    $scope.stopScrobbling = async () => {
        try {
            await lastfmService.stopScrobbling();
            helper.reloadPage();
        } catch (e) {
            $rootScope.showError(e);
        }
    };

    $scope.deleteMyAccount = ($event) => {
        $event.preventDefault();
        popupService.openDeleteAccountPopup();
        $scope.close();
    };

    $scope.onHowPplSeeMyProfileClicked = () => {
        $scope.close();

        $timeout(() => {
            helper.gotoState('user-modal', {
                userName: $scope.user.userName
            });

            analyticsService.trackUIEvent({
                eventAction: 'ClickedOnHowDoPeopleSeeMyProfile'
            });
        });
    };

    $scope.changeUserCountry = ()=> {
        popupService.openChangeUserCountryPopup();
    };

    $scope.impersonate = async ({ userName }) => {
        try {
            await userService.impersonate({
                userName
            });

            helper.reloadPage();
        } catch(e) {
            $scope.impersonateError = e.message;
            await helper.wait(3000);
            $scope.impersonateError = '';
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.$on('user:features:changed', () => {
        $scope.user.features = userService.getFeatures();
        $scope.enablePrivacy = userService.isFeatureEnabled(userService.features.ENABLE_PRIVACY);
    })

    $scope.$on('user:countryChanged', () => {
        // Some shit bcs of angular weird behavior
        $scope.countryCodeLoading = true;
        $scope.user.countryCode = '';
        $timeout(() => {
            $scope.countryCodeLoading = false;
            $scope.user.countryCode = userService.getCountryCode();
        }, 500);
    });

    $scope.copyUser(origUser);
    $scope.isUserConnectedViaFacebook = $scope.isUserConnectedViaFacebookFn(MyGlobal.user3rdPartyAccounts);
    $scope.enablePrivacy = userService.isFeatureEnabled(userService.features.ENABLE_PRIVACY);

    $scope.isInvalid = {};

    (async () => {
        try {
            await Promise.all([
                loadUserFavoriteRooms(),
                loadUserSoundtracks()
            ]);

            $scope.showMyFavoritesBeatRooms = true;

        } catch (e) {
            helper.error(e);
        }
    })();
});