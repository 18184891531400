import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

import './feedDropReplyPopup.less';

if (MyGlobal.project.isMobile) {
    import('./feedDropReplyPopup_mobile.less');
}

app.controller('MyFeedDropReplyPopup', ($scope, $uibModalInstance, $timeout, $rootScope,
                                        analyticsService, helper, popupParams) => {

    const { feedDrop } = popupParams;
    $scope.feedDrop = feedDrop;

    $scope.onReplyDropCreated = (feedDrop) => {
        $rootScope.$broadcast('feedDrop:replied', {
            _id: feedDrop.parentFeedDrop
        });

        analyticsService.trackFeedEvent({
            eventAction: 'replied'
        });

        if (location.hash !== `#/feedDrop/${feedDrop.parentFeedDrop}`) {
            helper.gotoState('feedDrop', {
                id: feedDrop.parentFeedDrop
            }, { nextTick: true });
        }

        $scope.close();
    }

    $scope.close = () => {
        $uibModalInstance.dismiss();
    };

    $timeout(()=> {
        if (!$scope.feedDrop) {
            $scope.close();
        }
    });
});
