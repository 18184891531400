import angular from 'angular';

import {ROOM_LEVEL_KEYS, ROOM_LEVELS, PLATFORM_TYPES} from '../../../shared/consts';
import sharedHelper from "../../../shared/sharedHelper";

var app = angular.module(MyGlobal.page.ngAppName);

app.service('roomService', function($q, $http, helper, $rootScope, httpService,
                                    storage, mySocket){

    // Private variables
    const _deferred = $q.defer();
    var _room;

    return {
        promise: _deferred.promise,
        init: function(roomId){
            var defer = $q.defer();

            $http.get('/room/'+roomId).success(function (room) {
                _room = room;
                defer.resolve(room);
            }).error(defer.reject);

            return defer.promise;
        },
        getRoomKindGlobally() {
            return sharedHelper.getRoomKind({ station: $rootScope.isStations });
        },
        isRoomPage() {
            return $rootScope.isRoomPage;
        },
        getRoom: function(){
            return _room;
        },
        getRoomId(){
            return _room && _room._id;
        },
        isPaused() {
            return _room?.pause?.active;
        },
        isLimbo(){
            return _room.isLimbo;
        },
        setIsReady: function(){
            _deferred.resolve();
        },
        getTheme(){
            return _room?.theme;
        },
        gotoRoom(room) {
            helper.redirect(helper.getRoomUrl(room));
        },
        async saveTheme(room, theme) {

            if(!room || !theme || !room._id || !theme._id) return;

            return httpService.post('/room/theme', {
                room: room._id,
                theme: theme._id
            });
        },
        async setRoomSetting(key, value) {
            if(!key || typeof value === 'undefined') return;
            return await httpService.post('/room/setting', {
                key: key,
                value: value
            });
        },
        loadHistory: function () {

            var room = this.getRoom();
            if(!room) return $q.reject('Room is not defined');
            var roomId = room._id;

            var defer = $q.defer();
            $http.get('/room/'+roomId+'/songs/history').success(defer.resolve).error(defer.reject);
            return defer.promise;
        },
        async loadTopSongs () {

            const range = storage.getItem(this.getTopSongsStorageKey());

            let data;

            if(range && range.unit && range.amount) {
                // We flatting the data due to a security restriction in the backend to send nested objects
                data = {
                    rangeUnit: range.unit,
                    rangeAmount: range.amount
                };
            }

            return httpService.post(`/room/${this.getRoomId()}/songs/top`, data);
        },
        getTopUsersStorageKey() {
            return `topUsersRange.${_room?._id}`
        },
        getTopSongsStorageKey() {
            return `topSongsRange.${_room?._id}`
        },
        async loadTopUsers() {

            const range = storage.getItem(this.getTopUsersStorageKey());

            let data;

            if(range && range.unit && range.amount) {
                // We flatting the data due to a security restriction in the backend to send nested objects
                data = {
                    rangeUnit: range.unit,
                    rangeAmount: range.amount
                };
            }

            return httpService.post(`/room/${this.getRoomId()}/users/top`, data);
        },
        async loadCurrentUserRoomScore() {

            const range = storage.getItem(this.getTopUsersStorageKey());

            let data;

            if(range && range.unit && range.amount) {
                // We flatting the data due to a security restriction in the backend to send nested objects
                data = {
                    rangeUnit: range.unit,
                    rangeAmount: range.amount
                };
            }

            return httpService.post(`/user/room/${this.getRoomId()}/score`, data);
        },
        loadInitialChatMessages: function () {

            var room = this.getRoom();
            if(!room) return $q.reject('Room is not defined');
            var roomId = room._id;

            var defer = $q.defer();
            $http.get('/room/'+roomId+'/chat/initialMessages').success(defer.resolve).error(defer.reject);
            return defer.promise;
        },
        loadRoomStats: function () {

            var room = this.getRoom();
            if(!room) return $q.reject('Room is not defined');
            var roomId = room._id;

            var defer = $q.defer();
            $http.get('/room/'+roomId+'/stats').success(defer.resolve).error(defer.reject);
            return defer.promise;
        },
        reloadStats({ forceHistoryUpdate } = {}) {

            const room = this.getRoom();
            if(!room) {
                return $q.reject('Room is not defined');
            }

            const response = {};

            const promises = [
                this.loadRoomStats().then(function (data) {
                    response.roomStats = data;
                })
            ];

            if(!$rootScope.isMobile){
                promises.push(
                    this.loadHistory().then(function (data) {
                        response.history = data;
                    }),
                    this.loadTopUsers().then(function (data) {
                        response.topUsers = data;
                    }),
                    this.loadTopSongs().then(function (data) {
                        response.topSongs = data;
                    })
                );
            } else if (forceHistoryUpdate) {
                promises.push(
                    this.loadHistory().then(function (data) {
                        response.history = data;
                    })
                );
            }

            return $q.all(promises).then(function () {
                return response;
            });
        },
        async inviteUsers(room, users, role) {

            if(!room || !room._id){
                throw new Error('Invalid room');
            }

            if(!users || !users.length){
                throw new Error('Empty or invalid users');
            }

            if(!(users instanceof Array)){
                users = [users];
            }

            return httpService.post(`/room/${room._id}/invite`, {
                invitations: users,
                role
            });
        },
        async banUsers(room, users) {
            if(!room || !room._id){
                throw new Error('Invalid room');
            }

            if(!users || !users.length){
                throw new Error('Empty or invalid users');
            }

            if(!(users instanceof Array)){
                users = [users];
            }

            return httpService.post(`/room/${room._id}/ban`, {
                users
            });
        },
        getLimboRoomTexts: function () {
            return [
                `FEATURED ${$rootScope.roomKindGlobally.toUpperCase()}`
                // 'Limbo yourself into a place of random music, with random people, in a random dimension and...<br/> Just click the damn thing!',
                // 'Limbo is a speculative idea about the afterlife condition of those who die in original sin without being assigned to the Hell of the Damned',
                // 'When you feel like the gods of music are not on your side, join the land of limbo',
                // 'Limbo - a region on the border of hell or heaven'
            ];
        },
        loadLimboRooms: function () {
            var defer = $q.defer();
            $http.get('/room/limbos').success(defer.resolve).error(function (response) {
                defer.reject(response ? response.error : 'Unknown error when loading limbo rooms');
            });
            return defer.promise;

        },
        createRoomBySong: function(song){

            if(!song || !song.id) return $q.reject('Invalid song');

            var defer = $q.defer();

            $http.post('/room/speciality/welcome', {
                youtubeId: song.id
            }).success(defer.resolve).error(defer.reject);

            return defer.promise;
        },
        isPlaylistHasVariety(playlist) {
            if(!playlist || !playlist.length) {
                playlist = $rootScope.playlist;
            }

            if(!playlist || !playlist.length) return;

            var dict = {};

            for(var i=0;i<playlist.length;i++){

                var userSong = playlist[i];

                // Ignore running song or it's beatsense song
                if(userSong.status !== 'active' || !userSong.user) continue;

                if(Object.keys(dict).length > 1) {
                    break;
                }

                dict[userSong.user._id] = true;
            }

            return Object.keys(dict).length > 1;
        },
        async changeRoomType({ roomId, type }){
            if(!roomId || !type) return;
            return httpService.post(`/room/${roomId}/changeType`, { type });
        },
        async findTheBestRoom() {
            return httpService.get(`/room/best`);
        },
        async getSimilarRooms(roomId) {
            if(!this.isLimbo()){
                return httpService.get(`/room/${roomId}/similarRooms`);
            }
        },
        getCurrSong() {
            return $rootScope.currSong;
        },
        isUpgradedLevel(level) {
            return level >= ROOM_LEVEL_KEYS.PRO;
        },
        isUpgradedRoom(room) {
            return room && this.isUpgradedLevel(room.level);
        },
        isProRoom(room) {
            return room.level === ROOM_LEVEL_KEYS.PRO;
        },
        isProPlusRoom(room) {
            return room.level === ROOM_LEVEL_KEYS.PRO_PLUS;
        },
        isPremiumRoom(room) {
            return room.level === ROOM_LEVEL_KEYS.PREMIUM;
        },
        isHighestLevelRoom(room) {
            return room && room.level >= ROOM_LEVELS[ROOM_LEVELS.length - 1].value;
        },
        getRoomLevel(room) {

            if (typeof room === 'number') {
                room = { level: room };
            }

            return room && room.level && ROOM_LEVELS.find(item => item.value === room.level);
        },
        getRoomLevelName(room) {
            return this.getRoomLevel(room)?.name;
        },
        async checkIfExists({ roomId, what, value }) {
            if(what && value && value.length){

                let url = `/room/isExist?w=${what}&v=${value}`;

                if(roomId){
                    url += `&exclude=${roomId}`;
                }

                return httpService.get(url);
            }
        },
        validateRoom(room) {

            if(!room.name){
                const error = new Error(`Please enter ${sharedHelper.getRoomKind(room).toLowerCase()} name`);
                error.type = 'name';
                error.tab = 'general';
                throw error;
            }

            if(!room.suggestAllTags && (!room.musicTags || !room.musicTags.length)){
                const error = new Error('Please mention at least one genre');
                error.type = 'tags';
                error.tab = 'general';
                throw error;
            }

            if(helper.isInAppropriateMessage(room.name)){
                const error = new Error(`Inappropriate ${sharedHelper.getRoomKind(room)} name`);
                error.type = 'name';
                error.tab = 'general';
                throw error;
            }

            if(helper.isInAppropriateMessage(room.description)){
                const error = new Error(`Inappropriate ${sharedHelper.getRoomKind(room)} description`);
                error.type = 'description';
                error.tab = 'general';
                throw error;
            }

            if (room.entranceFee > 0 && !helper.isInt(room.entranceFee)) {
                const error = new Error('Entrance fee should be a round number.');
                error.type = 'entranceFee';
                error.tab = 'general';
                throw error;
            }
        },
        getPostSaveError(e, room) {
            const error = e;
            const roomKind = sharedHelper.getRoomKind(room);
            switch(e.message){
                case 'roomNameAlreadyInUse':
                    error.message = `This title is already taken by other ${roomKind}, you have to be unique ;)`;
                    error.type = 'name';
                    error.tab = 'general';
                    break;
                case 'roomNameIsTooLong':
                    error.message = `${roomKind} name is too long, max 70 allowed`;
                    error.type = 'name';
                    error.tab = 'general';
                    break;
                case 'invalidRoomId':
                    error.message = 'This is not a valid unique name. Use letters, numbers, hyphens, and underscores';
                    error.type = 'uniqueName';
                    error.tab = 'general';
                    break;
                case 'roomUniqueNameAlreadyInUse':
                    error.message = `${roomKind} address already in use, please choose another`;
                    error.type = 'uniqueName';
                    error.tab = 'general';
                    break;
            }
            return error;
        },
        async createRoom(params) {
            this.validateRoom(params);
            try {
                return await httpService.post('/room', params);
            } catch (e) {
                throw this.getPostSaveError(e, params);
            }
        },
        async updateRoom(params) {

            if (!params._id) {
                new Error('Invalid room id');
            }

            this.validateRoom(params);
            try {
                return await httpService.put(`/room/${params._id}`, params);
            } catch (e) {
                throw this.getPostSaveError(e, params);
            }
        },
        async getRoomUsers({ roomId }) {
            return httpService.get(`/room/${roomId}/users`);
        },
        async getRoomBannedUsers(roomId) {
            return httpService.get(`/room/${roomId}/bans`);
        },
        async deleteRoomUser(roomId, id) {
            return httpService.delete(`/room/${roomId}/user/${id}`);
        },
        async deleteRoomUserBan(roomId, id) {
            return httpService.delete(`/room/${roomId}/userBan/${id}`);
        },
        isPublic(room) {
            return room && room.type === 'public';
        },
        isPrivate(room) {
            return room && room.type === 'private';
        },
        isRoomModerator(roomUser) {
            return roomUser && ['owner', 'admin'].includes(roomUser.role);
        },
        getPlaylistUsers({ excludeIds } = {}) {
            if (!$rootScope.playlist?.length) return;

            const dict = {};
            const users = [];

            const playlist = $rootScope.playlist;

            for (const userSong of playlist) {

                if (!userSong.user) continue;

                const userId = userSong.user._id;
                const exclude = excludeIds?.length && excludeIds.find(item => item === userId);

                if (userSong.user && !dict[userId] && !exclude) {
                    dict[userId] = true;
                    users.push(userSong.user);
                }
            }

            return users;

        },
        async clearUserSongs({ userId, roomId }) {
            return await mySocket.emitP('song:remove:bulk', {
                userId,
                roomId
            });
        },
        async notifyPlayerError({ error, userSongId }) {
            if (!userSongId) return;
            return await mySocket.emitP('user:player:error', {
                userSongId,
                error
            });
        },
        async getRooms({ query, page, itemsPerPage, filters }) {
            return await httpService.post('/room/list', {
                q: query || undefined,
                p: page,
                ipp: itemsPerPage,
                f: filters
            });
        },
        async getRoomBroadcaster(){
            return await httpService.get(`/room/${_room._id}/broadcaster`);
        },
        async requestAccess({ roomId }) {
            return await httpService.post(`/room/${roomId}/requestAccess`);
        },
        async approveAccessRequest({ id }) {
            return await httpService.post(`/room/requestAccess/approve`, { id });
        },
        async removeRoomSong({ roomId, songId }) {
            return await httpService.delete(`/room/${roomId}/song/${songId}`);
        },
        async toggleSubscribe() {
            return await httpService.post(`/room/subscription/toggle`);
        },
        isSubscribed() {
            return !!_room.subscribed;
        },
        setRoomSubscription(state) {
            const old = _room.subscribed;
            _room.subscribed = state;

            if (_room.subscribed !== old) {
                $rootScope.$broadcast('room:subscription:changed', { state });
            }
        },
        async deleteRoom(roomId) {
            return await httpService.delete(`/room/${roomId}`);
        },
        getSiteBaseDomain() {
            const hostname = window.location.hostname;
            const parts = hostname.split('.');

            // Check if the domain has a subdomain
            if (parts.length >= 3) {
                if (parts[0] === 'www') {
                    return parts.slice(1).join('.');
                }
                return hostname;
            }

            return hostname;
        },
        gotoRightPlatform(room) {
            if (helper.isAnotherPlatform(room)) {
                const type = $rootScope.isStations ? PLATFORM_TYPES.BEATSENSE : PLATFORM_TYPES.STATIONS;

                helper.gotoState('redirectToPlatform', {
                    type,
                    room
                });
            } else {
                this.gotoRoom(room);
            }
        }
    };
});
