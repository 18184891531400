
import './spinner.less';

import angular from 'angular';

import template from './spinner.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('mySpinner', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            fontSize: '='
        },
        controller($scope) {}
    };
});
