/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('similarSongsService', function($q, $http, helper, $timeout){

        var _serverData;

        return {
            setServerData: function(serverData){
                _serverData = serverData;
            },
            getServerData: function(){
                return _serverData;
            },
            getSimilarSongsByYoutubeId: function(params){

                params = params || {};

                var defer = $q.defer();

                $timeout(function(){
                    if(!params.youtubeId) return defer.reject('youtubeId param is undefined');

                    var url = '/song/similar/'+params.youtubeId;

                    var urlParams = {
                        page: params.pageToken
                    };

                    var query = '';
                    for(var i in urlParams){
                        if(urlParams.hasOwnProperty(i) && urlParams[i]){
                            query += (query.length ? '&' : '')+i+'='+urlParams[i];
                        }
                    }

                    if(query.length){
                        url += '?'+query;
                    }

                    $http.get(url).success(defer.resolve).error(defer.reject);
                });
                return defer.promise;
            }
        };
    });

}(angular));
