
import angular from 'angular';
import './privateChats.less';
import '../chat/chat';
import template from './privateChats.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myPrivateChats', (storage, helper, $rootScope, userService) => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, webNotificationService) {

            const maxOpenChats = !$rootScope.isMobile ? 4 : 1;
            const autoOpenChatCache = {};
            const storageKey = 'chats';

            $scope.inboxNotificationCache = {};
            $scope.chats = [];

            const openChat = (user) => {

                if (!(user?._id && user?.userName)) {
                    throw new Error('Invalid user details');
                }

                const { _id: userId, userName, image } = user;

                const chat = $scope.chats.find(item => {
                    return item.id === userId;
                });

                if (!chat) {

                    const newChat = {
                        id: userId,
                        user: { _id: userId, userName, image }
                    };

                    if ($scope.chats.length + 1 > maxOpenChats) {
                        $scope.chats[ $scope.chats.length - 1] = newChat;
                    } else {
                        $scope.chats.push(newChat);
                    }

                    saveChats();
                } else if (chat.minimized) {
                    chat.minimized = false;
                    saveChats();
                }
            };

            const saveChats = () => {
                storage.setForeverItem(storageKey, $scope.chats);
            };

            $scope.closeChat = (userId) => {
                if ($scope?.chats?.length) {

                    helper.removeItemFromArray($scope.chats, item => {
                        return item.user._id === userId;
                    });

                    delete $scope.inboxNotificationCache[userId];

                    saveChats();
                }
            };

            $scope.toggleMinimizedChat = (userId, state) => {
                if ($scope?.chats) {
                    const chat = $scope.chats.find(item => {
                        return item.id === userId;
                    });

                    if (chat) {
                        chat.minimized = state;
                        saveChats();

                        if (state) {
                            delete $scope.inboxNotificationCache[userId];
                        }
                    }

                }
            };

            $scope.$on('chat:open', async (evt, user) => {
                try {
                    openChat(user);
                } catch (e) {
                    helper.error(e);
                }
            });

            $scope.$on('page:ready', async () => {
                try {
                    // Restore open chats
                    $scope.chats = storage.getItem('chats') || [];

                    const chatInfo = helper.getUrlParam('chat');
                    if (chatInfo) {
                        try {
                            const user = JSON.parse(atob(chatInfo));
                            openChat(user);
                        } catch(e) {
                            helper.error(e);
                        }
                    }
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            });

            $scope.$on('chat:message:private', (evt, chatMessage) => {
                try {
                    const fromId = chatMessage?.from?._id;
                    const chat = $scope.chats.find(item => item.id === fromId);

                    if ((!chat || chat.minimized) && !$scope.inboxNotificationCache[fromId]) {
                        $scope.inboxNotificationCache[fromId] = true
                        $rootScope.$broadcast('inbox:unread:increase');

                        const message = `${chatMessage.from.userName} sent you a private message`;

                        // Open chat once
                        if (!autoOpenChatCache[fromId]) {
                            openChat(chatMessage.from);
                            autoOpenChatCache[fromId] = true;
                        }


                        if (webNotificationService.isAllowed(webNotificationService.sections.privateChatMessage)) {
                            webNotificationService.systemNotification(message, webNotificationService.sections.privateChatMessage).catch(e => helper.error(e));
                        }
                    }

                } catch (e) {
                    helper.error(e);
                }
            });

            $scope.$on('inbox:unreads', (evt, { data }) => {
                $scope.inboxNotificationCache = {};
                if (data?.length) {
                    for (const piece of data) {
                        const { _id: id, total } = piece;
                        $scope.inboxNotificationCache[id] = total;
                    }
                }
            });

            $scope.$on('window:storage', (evt, event) => {
                const { key }  = event?.originalEvent || {};

                if (key === storageKey) {
                    $scope.chats = storage.getItem('chats') || [];
                }
            });
        }
    };
});
