import angular from 'angular';
import 'flag-icon-css/css/flag-icon.min.css';

import './userProfile.less';
import template from './userProfile.html';
import {SHARE_ENTITY_TYPES, USER_REPORT_TYPES} from '../../../../shared/consts';
import sharedHelper from '../../../../shared/sharedHelper';

if (MyGlobal.project.isMobile) {
    import('./userProfile_mobile.less');
}

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myUserProfile', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            userName: '=',
            onClose: '&'
        },
        controller($scope, $rootScope, $http, helper, popupService,
                   buddyService, roomService, userService, beatsService,
                   $q, $sce, analyticsService, songService, slidingWindowService) {

            $scope.isBanEnabled = $rootScope.isAdmin || $rootScope.isUserAdmin;
            $scope.SHARE_ENTITY_TYPES = SHARE_ENTITY_TYPES;

            $scope.feedFiltersVisibility = {
                group: false
            };

            if ($rootScope.isRoomPage) {
                $scope.currRoomName = $rootScope.room.name;
            }

            $scope.donateBeats = async ($event, options = {}) => {

                $event.preventDefault();

                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join', {
                        message: 'Sign up to donate'
                    });

                    return;
                }

                try {

                    if ($scope.currUserBeatsBalance < $scope.beatsDonationThreshold) {
                        return helper.gotoState('beatsPurchase');
                    }

                    const {asAdmin} = options;

                    $scope.donateLoading = true;

                    await beatsService.donate({
                        userIds: [$scope.user._id],
                        amount: $scope.donateAmount,
                        asAdmin,
                        limit: true
                    });
                    await beatsService.refresh();
                    updateDonationStuff();

                    helper.trackBeatsEvent('Donated', $rootScope.user.userName + '_to_' + $scope.user.userName);
                    $rootScope.showNotification(`Donated! ${$scope.donateAmount} Beats to your buddy ${$scope.user.userName}`);
                } catch (e) {
                    helper.error(e);
                } finally {
                    $scope.donateLoading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            const loadUserProfile = async () => {

                const user = await userService.getUserProfile({
                    userName: $scope.userName,
                    roomId: $rootScope.room?._id
                });

                if (!user) {
                    $scope.userNotFound = true;
                    return;
                }

                $scope.user = user;
                $scope.invitationSent = $rootScope.invitedUsersToRoom && $rootScope.invitedUsersToRoom[$scope.user._id];

                // If it's the same logged-in user, update his local details
                if (userService.isCurrUser($scope.user._id)) {
                    userService.updateScores($scope.user.score);
                }

                $scope.user.rank = helper.getRank($scope.user.score);
            };

            const loadUserAdditionalData = async () => {
                $scope.addData = await userService.getUserAdditionalData({userName: $scope.userName}) || {};
                $scope.userHasPublicCases = !$scope.itsMe && $scope.addData.userHasPublicCases;
            };

            $scope.onFeedLoaded = (totalItems) => {
                $scope.feedTotalItems = totalItems;
                $scope.feedLoading = false;
            };

            $scope.toggleFollow = async ($event)=> {
                $event.stopImmediatePropagation();
                $event.preventDefault();

                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join', {
                        message: 'Sign up to follow a user'
                    });

                    return;
                }

                try {
                    if (!$scope.addData.follows) {
                        $scope.addData.follows = true;
                        await userService.follow($scope.user._id);
                        $rootScope.showNotification(`You are now following ${$scope.user.userName}`);
                    } else {
                        $scope.addData.follows = false;
                        await userService.unFollow($scope.user._id);
                        $rootScope.showNotification(`You just unfollowed ${$scope.user.userName}`);
                    }

                    if ($scope.addData.follows) {
                        analyticsService.trackFeedEvent({
                            eventAction: 'follow'
                        });
                    }

                } catch(e) {
                    helper.error(e);
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.reportUser = async ()=> {
                try {
                    popupService.openUserReportPopup({
                        type: USER_REPORT_TYPES.USER,
                        data: $scope.user
                    })
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.getUserSocialLinks = function (data) {
                if (data && data.externalAccounts && data.externalAccounts.length) {

                    var links = {facebook: null, google: null, twitter: null};

                    for (var i = 0; i < data.externalAccounts.length; i++) {
                        switch (data.externalAccounts[i].type) {
                            case 'facebook':
                                links.facebook = '//facebook.com/' + data.externalAccounts[i].uniqueId;
                                break;
                            case 'google':
                                links.google = '//plus.google.com/' + data.externalAccounts[i].uniqueId;
                                break;
                            case 'twitter':
                                links.twitter = '//twitter.com/intent/user?user_id=' + data.externalAccounts[i].uniqueId;
                                break;
                        }
                    }

                    return links;
                }
            };

            $scope.isUserYourBody = function () {
                return $scope.user ? buddyService.isUserBuddy($scope.user.userName) : false;
            };

            $scope.toggleBuddy = async ($event) => {
                try {

                    $event.preventDefault();

                    if (!helper.isUserLoggedIn()) {
                        helper.gotoState('join', {
                            message: 'Sign up to add as a buddy'
                        });

                        return;
                    }

                    const buddy = buddyService.getBuddyByUser({ userName: $scope.user.userName });

                    if (!buddy) {
                        const buddy = await buddyService.create($scope.user._id);
                        $rootScope.showNotification(`You have added ${buddy.userName} to your buddy list`);

                        $scope.addData.follows = true;

                        analyticsService.trackActionEvent({
                            eventAction: 'buddied'
                        });

                    } else {
                        await buddyService.delete(buddy._id);
                        $rootScope.showNotification(`You removed ${buddy.userName} from your buddy list`);
                    }


                } catch(e) {
                    helper.error(e);
                }
            };

            $scope.inviteToRoom = function ($event) {

                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join', {
                        message: 'Sign up to invite'
                    });

                    return;
                }

                if ($rootScope.isUserInRoom($scope.user) || $scope.invitationSent) {
                    $scope.invitationSent = true; //so will show the sent tooltip (although nothing is sent)
                    return;
                }

                $scope.loadingInviteToRoom = true;
                roomService.inviteUsers($rootScope.room, $scope.user.userName, 'member').then(function () {
                    $scope.loadingInviteToRoom = false;
                    $rootScope.invitedUsersToRoom[$scope.user._id] = true;
                    $scope.invitationSent = true;
                    $rootScope.showNotification('An invitation sent to ' + $scope.user.userName);
                }).catch(function (err) {
                    $scope.loadingInviteToRoom = false;
                    helper.error(err);
                });

                $event.preventDefault();
                $event.stopImmediatePropagation();
            };

            $scope.getBanButtonText = function () {
                if (!$scope.user) return;
                if ($scope.user.status !== 'suspended') {
                    return $scope.user._id !== $rootScope.user.id ? 'Ban' : 'Ban (yourself?!)';
                }

                return 'Remove user ban';
            };

            $scope.toggleUserBan = function (user) {

                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join');

                    return;
                }

                if (!user || !user._id || !confirm('Are you sure?')) return;

                $scope.banLoading = true;
                userService.toggleUserBan({userId: user._id}).then(function () {
                    $scope.banLoading = false;
                    $scope.user.status = $scope.user.status === 'suspended' ? 'active' : 'suspended';
                }).catch(function (e) {
                    $scope.banLoading = false;
                    $scope.banError = e && e.error;
                });
            };

            $scope.removeSoundTrack = async ($event, soundtrack, index) => {
                if ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                }
                try {
                    const res = await userService.removeSoundtrack(soundtrack);

                    if (res) {
                        $scope.soundtracks.splice(index, 1);
                        helper.trackGaUIEvent('RemovedFromSountrackOfMyLIFE');
                    }
                } catch (e) {
                    helper.error(e);
                }
            };

            $scope.showUserCases = ($event) => {

                if (!helper.isUserLoggedIn()) {
                    helper.gotoState('join', {
                        message: 'Sign up to subscribe to people\'s music'
                    });

                    return;
                }

                popupService.openSubscribeToUserBeatCasesPopup({
                    user: $scope.user
                });

                $event.preventDefault();
                $event.stopImmediatePropagation();
            };

            $scope.onSongClicked = ($event, song) => {
                $event.preventDefault();

                if ($rootScope.isRoomPage) {
                    helper.gotoState( 'song-modal', {
                            id: song.youtubeId,
                            type: 'ytap',
                            name: songService.getSongName(song)
                        },
                        {
                            prev: {
                                name: 'user-modal',
                                params: {
                                    userName: $scope.user.userName
                                }
                            }
                        });
                } else {
                    helper.redirectToWiki(song);
                }
            };

            $scope.openChat = () => {

                analyticsService.trackUIEvent({
                    eventAction: 'ChatOpened',
                    eventLabel: 'userModal'
                });

                $rootScope.$broadcast('chat:open', $scope.user);
            };

            const loadUserSoundtracks = async () => {
                try {
                    $scope.soundtracks = await userService.getUserSoundtracks($scope.userName);
                    $scope.soundtracksWithoutUser = $scope.soundtracks.map(item => songService.toYouTubeSong(item.song));
                } catch (e) {
                    helper.error(e);
                }
            };

            // const loadFeaturedSongs = async () => {
            //     try {
            //         const {total, models} = await userService.getFeaturedSongs($scope.userName) || {};
            //
            //         $scope.totalFeaturedSongs = total;
            //         $scope.featuredSongs = models;
            //     } catch (e) {
            //         helper.error(e);
            //     }
            // };

            const getDonateTooltipHTML = () => {
                return $sce.trustAsHtml($scope.currUserBeatsBalance > $scope.beatsDonationThreshold ?
                    `Donate ${$scope.donateAmount}<i class="fas fa-bolt"></i> to ${$scope.user.userName}` :
                    `You should have more than ${$scope.beatsDonationThreshold}<i class="fas fa-bolt"></i> to donate. Click to purchase.`);
            }

            const updateDonationStuff = () => {
                $scope.currUserBeatsBalance = beatsService.getBalance();
                $scope.donateTooltipHTML = getDonateTooltipHTML();
            };

            const init = async () => {
                if ($scope.userName === MyGlobal.project.name) {
                    $scope.user = {
                        image: '/static/images/roomDescriptionLogo.png',
                        userName: MyGlobal.project.name,
                        fake: true
                    }
                } else if ($scope.userName) {

                    try {
                        await loadUserProfile();
                    } catch(e) {
                        if (e.status === 404) {
                            $scope.userNotFound = true;
                            return;
                        } else {
                            throw e;
                        }
                    }

                    await $q.all([
                        loadUserAdditionalData(),
                        loadUserSoundtracks(),
                        userService.isLoggedIn() ? beatsService.promise : Promise.resolve()
                    ]);

                    $scope.donateAmount = beatsService.getDonationAmount();
                    $scope.beatsDonationThreshold = beatsService.getMinBeatsForDonation();

                    updateDonationStuff();
                }

                $scope.itsMe = $scope.user && $scope.user._id === userService.getUserId();
            };

            $scope.toggleAdminData = ($event)=> {
                $event.preventDefault();
                $scope.showAdData =! $scope.showAdData;
            };

            $scope.editMyProfileClicked = () => {
                analyticsService.trackProfileEvent({
                    eventAction: 'ClickedOnEdit'
                });

                $scope.onClose();
            };

            $scope.onMusicTagClicked = ($event, musicTag) => {
                $event.preventDefault();

                if (!musicTag?.tag?.name) return;

                const hashtag = `#${sharedHelper.strToHashtag(musicTag.tag.name)}`;

                const data = {
                    filters: {
                        hashtags: [hashtag]
                    }
                };

                if ($rootScope.isHomePage) {
                    $rootScope.$broadcast('feed:show', data);
                } else {
                    slidingWindowService.open({
                        type: slidingWindowService.types.FEED,
                        data
                    });
                }
            }

            (async () => {
                try {
                    $scope.loading = true;
                    $scope.feedLoading = true;
                    await init();
                } catch (e) {
                    helper.error(e);
                } finally {
                    $scope.loading = false;
                    $scope.feedLoading = false;
                    try { $scope.$digest(); } catch(e) {}
                }

                $scope.$watch('userName', async (newVal, oldVal)=> {
                    try {
                        if (newVal === oldVal) return;
                        delete $scope.user;
                        delete $scope.addData;
                        $scope.loading = true;
                        $scope.feedLoading = true;
                        await init();
                    } catch (e) {
                        helper.error(e);
                    } finally {
                        $scope.loading = false;
                        $scope.feedLoading = false;
                        try { $scope.$digest(); } catch(e) {}
                    }
                });

            })();
        }
    };
});
