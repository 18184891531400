
import angular from 'angular';

import template from './userLink.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myUserLink', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            user: '=',
            newTab: '=',
            possessive: '=',
            onClick: '&'
        },
        controller($scope) {
            $scope.onClickInt = ($event) => {
                $scope.onClick({ $event });
            };
        }
    };
});
