import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);

app.service('httpService', ($http) => {

    const success = response => {
        return response?.data;
    };

    const error = response => {
        const { error, ...rest } = response?.data || { error: 'http unknown error' };

        const e = new Error(error);

        for(const [key, value] of Object.entries(rest)) {
            e[key] = value;
        }

        return e;

    };

    const base = (method, ...args) => {
        return new Promise((resolve, reject) => {
            $http[method](...args).then(response => {
                resolve(success(response));
            }, response => {
                reject(error(response));
            });
        });
    };

    return {
        post(...args){
            return base('post', ...args);
        },
        put(...args){
            return base('put', ...args);
        },
        get(...args){
            return base('get', ...args);
        },
        delete(...args){
            return base('delete', ...args);
        },
        keepAlive() {
            return this.get('/ka');
        }
    }
});
