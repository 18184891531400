import angular from 'angular';
import './createRoomPopup.less';
import '../../shared/chargeForRoomEntrance/chargeForRoomEntrance';
import '../../shared/roomLevels/roomLevels';
import {ROOM_LEVEL_KEYS} from '../../../../../shared/consts';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyCreateRoomPopupCtrl', function ($scope, $uibModalInstance, $http, analyticsService,
                                           helper, $rootScope, $state, $stateParams, roomService,
                                           musicTagService, beatsService, $timeout) {

    const { state, source, outlineSection, subTitle } = $stateParams;

    $scope.isAlreadyExist = {};
    $scope.isInvalid = {};
    $scope.errors = {};
    $scope.showTagsPreloader = {};
    $scope.beatsService = beatsService;
    $scope.ROOM_LEVEL_KEYS = ROOM_LEVEL_KEYS;
    $scope.baseDomain = roomService.getSiteBaseDomain();
    $scope.subTitle = subTitle;

    const station = $rootScope.isStations;

    $scope.room = {
        type: 'public',
        autoMusic: true,
        station,
        suggestJustFromRoom: false,
        level: ROOM_LEVEL_KEYS.STANDARD,
        features: []
    };

    // Init tags for tag plugin
    $scope.roomTags = [];

    $scope.outlineSection = outlineSection;

    $scope.userBalance = beatsService.getBalance();
    $scope.beatsService = beatsService;
    $scope.publicRoomFeaturePrice = beatsService.getFeaturePrice(beatsService.keys.createPublicRoom);
    $scope.privateRoomFeaturePrice = beatsService.getFeaturePrice(beatsService.keys.createPrivateRoom);

    $scope.calculateRoomOpeningPrice = () => {

        let price = 0;

        if ($scope.room.type !== 'public' || !$scope.room.station) {
            const key = $scope.room.type === 'public' ? beatsService.keys.createPublicRoom : beatsService.keys.createPrivateRoom;
            price += beatsService.getFeaturePrice(key);
        }

        if (roomService.isUpgradedRoom($scope.room)) {
            const roomLevel = roomService.getRoomLevel($scope.room);
            price += beatsService.getFeaturePrice(roomLevel.beatsFeature);
        }

        $scope.createRoomPrice = price;
    };

    $scope.close = function(){

        $uibModalInstance.dismiss();

        if ($scope.showThemeManagement) {
            $scope.showThemeManagement = false;
        } else {
            $uibModalInstance.dismiss();
        }
    };

    $scope.showThemeManagementFn = ($event) => {
        $event.preventDefault();
        $scope.showThemeManagement = true;
    };

    $scope.setSelectedTheme = (theme) => {
        if (theme) {
            $scope.room.theme = theme;
        }
        $scope.showThemeManagement = false;
    };

    $scope.onEverythingGoesToggled = () => {
        if ($scope.room.suggestAllTags) {
            $scope.roomTags.length = 0;
        }
    };

    $scope.createRoom = async (params) => {
        try {

            $scope.room = { ...params };

            $scope.errors = {};
            $scope.error = '';

            // Prepare the data to be as the server need it
            params.musicTags = $scope.roomTags && $scope.roomTags.map(function (tag) {
                return tag && tag._id;
            });

            if(params.theme && typeof params.theme === 'object'){
                params.theme = params.theme._id;
            }

            if (!beatsService.userCanSpend($scope.createRoomPrice)) {
                analyticsService.trackBeatsEvent({
                    eventAction: 'NotEnoughBeatsModalOpened',
                    eventLabel: 'createRoom'
                });

                return helper.gotoState('beatsInsufficient', null,{
                    prev: {
                        name: 'create-room'
                    }
                });
            }

            $scope.loading = true;
            const room = await roomService.createRoom(params);

            await analyticsService.trackUIEventP({
                eventAction: 'UserSuccessfullyCreateRoom'
            })

            if (roomService.isUpgradedRoom(room)) {
                await analyticsService.trackRoomModerationEventP({
                    eventAction: `${roomService.getRoomLevelName(room)}RoomCreated`,
                    eventLabel: {room: room.name}
                });
            }

            helper.redirect(`${helper.getRoomUrl(room)}/#fv`);
        } catch (e) {

            const { type, message } = e;

            if (type) {
                $scope.errors[type] = message;
            } else {
                $scope.error = message;
            }

            helper.checkAuthNew(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.checkIsExist = function(evt, what, value){

        value = (value || evt.currentTarget.value).trim();
        if(what && value && value.length){

            var url = '/room/isExist?w='+what+'&v='+value;

            if($scope.room._id){
                url += '&exclude='+$scope.room._id;
            }

            $http.get(url).success(function(data){
                $scope.isAlreadyExist[what] = data.exist || data.preserved;
                $scope.isInvalid[what] = data.invalid;
            }).error(function(response){
                $scope.error = response?.error || 'unknown error, pls send us a feedback';
            });
        }
    };

    $scope.showRoomThemeThumb = function () {
        return $scope.room.theme && $scope.room.theme.key !== 'default';
    };

    $scope.onBlockOthersSongsChanged = function (state) {
        $scope.room.blockOthersSongs = state;
    };

    $scope.loadMusicTags = function (query, type) {
        if(query.length < 2) return [];

        type = type || 'tags';
        $scope.showTagsPreloader[type] = true;
        return musicTagService.searchItems(query).then(function (data) {
            $scope.showTagsPreloader[type] = false;
            return data;
        }).catch(function (e) {
            $scope.showTagsPreloader[type] = false;
            helper.error(e);
        });
    };

    $scope.changeSelectedRoomType = (value) => {
        $scope.room.type = value;

        if (value === 'public') {
            delete $scope.room.entranceFee;
        }

        $scope.calculateRoomOpeningPrice();
    };

    $scope.onRoomLevelChanged = (roomLevel) => {

        // if (roomService.isUpgradedLevel(roomLevel.value)) {
        //     popupService.openRoomLevelPopup({ level: roomLevel.value });
        // }

        $scope.room.level = roomLevel.value;

        $scope.calculateRoomOpeningPrice();
    };

    // After all
    $scope.showThemeManagement = state === 'themes';
    $scope.openSource = source;
    helper.trackGaUIEvent('CreateRoomModalOpened');

    $scope.calculateRoomOpeningPrice();

    $timeout(() => {

        $('#txtRoomTitle').focus();

        if($scope.room?.theme?.settings?.playbackRate){
            const video = $('.themeImage #themeVideo').get(0);

            if(video){
                video.playbackRate = $scope.room.theme.settings.playbackRate;
            }
        }
    });
});
