/**
 * Created by Shlomi on 19/09/2014.
 */

(function(angular){
    var app = angular.module(MyGlobal.page.ngAppName);

    app.service('lastfmService', function(helper, config, httpService, userService){

        return {
            getAuthUrl(){
                const lastfmConfig = config.getLastFmConfig()

                if(!lastfmConfig) {
                    throw new Error('LastFM config is missing');
                }

                const { apiKey, authBaseUrl } = lastfmConfig;

                if(!apiKey || !authBaseUrl) {
                    throw new Error('LastFM config is invalid');
                }

                const state = {
                    returnUrl: helper.getCurrentFullUrl()
                };

                let cb = `${helper.getProjectHost({ protocol: true })}/lastfm/callback`;

                if (Object.keys(state).length) {
                    cb += `?state=${btoa(JSON.stringify(state))}`;
                }

                return `${authBaseUrl}?api_key=${apiKey}&cb=${cb}`;
            },
            async stopScrobbling(){
                return httpService.post('/lastfm/scrobbling/stop');
            },
            isValidSong(song) {
                return song?.metaData?.artist && song.metaData.name;
            },
            isScrobbleEnabled() {
                return userService.hasLastFM();
            },
            async sendNowPlaying({ songId }){
                try {
                    return await httpService.post('/lastfm/updateNowPlaying', { song: songId });
                } catch (e) {
                    if(e.code === 9) {
                        return helper.reloadPageWithError({
                            message: 'LastFM connection has revoked, refreshing..'
                        });
                    }

                    throw e;
                }
            },
            async scrobble({ songId, startedAt }){
                try {
                    return await httpService.post('/lastfm/scrobble', { song: songId, startedAt });
                } catch (e) {
                    if(e.code === 9) {
                        return helper.reloadPageWithError({
                            message: 'LastFM connection has revoked, refreshing..'
                        });
                    }

                    throw e;
                }
            }

        }
    });

}(angular));
