
import angular from 'angular';
import './chatInputSuggestedUsers.less';
import template from './chatInputSuggestedUsers.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myChatInputSuggestedUsers', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            onUserSelected: '&',
            onClose: '&',
            chatId: '=',
            filter: '='
        },
        controller($scope, $rootScope, userService) {

            const container = $('#chatInputSuggestedUsersHolder');
            $scope.currIndex = 0;

            $scope.onUserSelected = ($event, userName) => {
                if ($event){
                    $event.preventDefault();
                }

                const { chatId } = $scope;
                const key = chatId ? `chat:${chatId}:mentionUser` : 'chat:mentionUser';
                $rootScope.$broadcast(key, { userName, fromSuggestions: true });

                $scope.onClose();
            };

            $scope.$on('document:keydown', ($evt, $event) => {
                const { key } = $event;

                if (key === 'ArrowDown' && $scope.currIndex < $scope.users.length - 1) {
                    $scope.currIndex++;
                } else if(key === 'ArrowUp' && $scope.currIndex > 0) {
                    $scope.currIndex--;
                } else if (key === 'Enter') {
                    const { userName } = $scope.users[$scope.currIndex] || {};

                    if (userName) {
                        $scope.onUserSelected($event, userName);
                        $event.stopImmediatePropagation();
                    }
                }

                container.scrollToElementAnimated(container.find('.selected'));
            });

            $scope.users = $rootScope.onlineLoggedInSockets?.filter(item => item.user?.id !== userService.getUserId()).map(item => item.user);
        }
    };
});
