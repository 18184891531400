const PAYMENT_TYPES = {
    BEATS_PACKAGE: 'beatsPackage',
    SUBSCRIPTION: 'subscription',
    CONTRIBUTION: 'contribution'
};

const PAYMENT_METHODS = {
    PAYPAL: 'paypal',
    GOOGLE_PAY: 'googlePay'
};

module.exports = {PAYMENT_TYPES, PAYMENT_METHODS};