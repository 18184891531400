import angular from 'angular';
import './header.less';
import template from './header.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myHeader', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, $state, $http, $rootScope, roomService, themeService, beatsService, helper, userService) {

            // Safety check due to stupid Angular redundant usage in header (and the ability to make simple ng-if without ruin the whole scope!!!!!!!!!!!!!!!!!
            if($scope.headerInitialized) return;
            $scope.headerInitialized = true;
            $scope.helper = helper;
            $scope.unreadThings = {};
            $scope.totalUnread = 0;

            $scope.$on('theme:changed', () => {
                $scope.isVideoThemePaused = themeService.isCurrentThemeVideoPausedPermanent();
                $scope.isVideoTheme = themeService.isCurrentVideoTheme();
            });

            $scope.isVideoThemePaused = themeService.isCurrentThemeVideoPausedPermanent();
            $scope.isVideoTheme = themeService.isCurrentVideoTheme();

            $scope.toggleVideoThemeState = () => {
                $scope.isVideoThemePaused = !$scope.isVideoThemePaused;

                if($scope.isVideoThemePaused){
                    themeService.pauseVideo({ persist: true });
                }else{
                    themeService.resumeVideo({ persist: true });
                }
            }

            $scope.onHeaderLogoClicked = function(){
                if(!$scope.isRoomPage){
                    return helper.redirect('/');
                }

                //$scope.toggleRoomList();
            };

            $scope.onMyBeatsClicked = async () => {
                await beatsService.refresh();
            };

            $scope.onLogoClicked = ($event) => {
                $event.preventDefault();
                helper.redirectToHome();
            };

            $scope.onUserImageClicked = ($event)=> {
                $event.stopImmediatePropagation();
                if (!$rootScope.isMobile) {
                    helper.gotoState('beatzone');
                } else {
                    $scope.showMobileRightMenu = !$scope.showMobileRightMenu;
                    broadcastMobileRightMenuState();
                }
            };

            $scope.logout = async ($event) => {
                $event.preventDefault();
                await userService.logout();
            };

            const broadcastMobileRightMenuState = ()=> {
                $rootScope.$broadcast('mobileRightMenu:toggled', { state: $scope.showMobileRightMenu });
            };

            const updateTotalUnreads = ()=> {
                $scope.totalUnread = ($scope.unreadThings.inbox || 0) + ($scope.unreadThings.userAlerts || 0);
            }

            $scope.$on('inbox:unreads', (evt, { total }) => {
                $scope.unreadThings.inbox = total;
                updateTotalUnreads();
            });

            $scope.$on('userAlerts:unreads', (evt, { total }) => {
                $scope.unreadThings.userAlerts = total;
                updateTotalUnreads();
            });

            if($scope.isRoomPage){
                $scope.$on('player:mute:changed', function($event, params){
                    params = params || {};
                    if(typeof params.state === 'undefined') return;

                    $scope.playerMute = params.state;
                });

                $scope.togglePlayerMute = function(){
                    $rootScope.$broadcast('player:mute:toggle');
                };
            }
        }
    };
});
