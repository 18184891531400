import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('myImage', function() {
    return {
        restrict: 'A',
        link($scope, $element, $attr) {
            $element.on('error', () => {
                if ($attr.fallbackUrl) {
                    $($element).attr('src', $attr.fallbackUrl);
                } else {
                    $($element).hide();
                }
            });
        }
    };
});
