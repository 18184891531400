import angular from "angular";
import {IFRAME_CONNECTION_TYPES} from "../../../shared/consts/general";

const app = angular.module(MyGlobal.page.ngAppName);
app.factory('IFrameConnector', (helper) => {
    return class IFrameConnector {

        static #invokeCounter = 1;

        // Parent mode
        #iframe = null;
        #iframeHost = null;

        // Child mode
        #parent = null;
        #parentHost = null;

        #listeners = {};
        #onMessageListener;
        #id = null;
        #mode = null;
        #data = null;

        constructor({ id, mode, data }) {
            this.#id = id;
            this.#mode = mode;
            this.#data = data;
        }

        async init() {
            switch (this.#mode) {
                case IFRAME_CONNECTION_TYPES.PARENT:
                    return await this.#initParent();
                case IFRAME_CONNECTION_TYPES.CHILD:
                    return await this.#initChild();
                default:
                    throw new Error('Invalid mode');
            }
        }

        async #initParent() {
            return new Promise((res, rej) => {
                try {

                    const { width, height, url, parentElem, initialData } = this.#data || {};

                    const iframe = this.#iframe = document.createElement('iframe');
                    iframe.allowFullscreen = true;
                    iframe.style.border = 'none';
                    iframe.width = width;
                    iframe.height = height;
                    iframe.id = `bs-ic-${this.#id}`;

                    const iframeUrl = new URL(url);
                    iframeUrl.searchParams.append('parentHost', `${location.protocol}//${location.host}`);
                    this.#iframeHost = `${iframeUrl.protocol}//${iframeUrl.host}`;

                    this.#onMessageListener = async event => {
                        try {

                            let { data: payload, origin } = event;

                            try {
                                payload = JSON.parse(payload);
                            } catch(e) {
                                helper.error(e);
                            }

                            if (origin === this.#iframeHost && payload.id === this.#id) {

                                const { key } = payload;

                                switch(key) {
                                    case 'ready':
                                        this.emit('init', initialData);
                                        break;
                                    case 'initialized':
                                        res();
                                        break;
                                    default:
                                        await this.#executeListeners(payload);
                                }
                            }
                        } catch(e) {
                            helper.error(e);
                        }
                    };

                    window.addEventListener('message', this.#onMessageListener);
                    parentElem.append(iframe);
                    iframe.src = iframeUrl.toString();
                } catch(e) {
                    rej(e);
                }
            });
        }

        async #initChild() {
            return new Promise((res, rej) => {
                try {
                    this.#parent = window.parent;
                    this.#parentHost = this.#data.parentHost;

                    if (!this.#parentHost) {
                        throw new Error('You have to provide the parent hostname while init the iframe');
                    }

                    this.#onMessageListener = async event => {
                        try {

                            let { data: payload, origin } = event;

                            try {
                                payload = JSON.parse(payload);
                            } catch(e) {
                                helper.error(e);
                            }

                            if (origin === this.#parentHost && payload.id === this.#id) {
                                if (payload.key === 'init') {
                                    this.#data = payload.data;
                                    this.emit('initialized');
                                    res();
                                } else {
                                    await this.#executeListeners(payload);
                                }
                            }
                        } catch(e) {
                            helper.error(e);
                        }
                    };

                    window.addEventListener('message', this.#onMessageListener);
                    this.emit('ready');
                } catch(e) {
                    rej(e);
                }
            });
        }

        async #executeListeners({ key, data, callback }) {
            if (this.#listeners[key]?.length) {
                for (const func of this.#listeners[key]) {
                    let res = func(data);

                    if (callback) {
                        if (res instanceof Promise) {
                            res = await res;
                        }

                        this.emit(callback, res);
                    }
                }
            }
        }

        setSize(width, height) {
            if (this.#mode === IFRAME_CONNECTION_TYPES.PARENT && this.#iframe) {
                this.#iframe.width = width;
                this.#iframe.height = height;
            }
        }

        on(key, func) {
            this.#listeners[key] = this.#listeners[key] || [];
            this.#listeners[key].push(func);
        }

        off(key, func) {
            if (this.#listeners[key]) {
                helper.removeItemFromArray(item => item === func);
            }
        }

        emit(key, data, callback) {

            const payload = JSON.stringify({ id: this.#id, key, data, callback });

            switch (this.#mode) {
                case IFRAME_CONNECTION_TYPES.PARENT:
                    this.#iframe.contentWindow.postMessage(payload, this.#iframeHost);
                    break;
                case IFRAME_CONNECTION_TYPES.CHILD:
                    this.#parent.postMessage(payload, this.#parentHost);
                    break;
                default:
                    throw new Error('Invalid mode');
            }
        }

        async emitP(key, data) {
            return new Promise((resolve, reject) => {
                try {
                    const callback = `asyncCb.${IFrameConnector.#invokeCounter++}`;

                    this.on(callback, (...args) => {
                        resolve(...args);
                    });

                    this.emit(key, data, callback);
                } catch(e) {
                    reject(e);
                }
            });
        }

        dispose() {
            switch (this.#mode) {
                case IFRAME_CONNECTION_TYPES.PARENT:
                    this.#iframe.parentElement.removeChild(this.#iframe);
                    this.#iframe = null;
                    break;
                case IFRAME_CONNECTION_TYPES.CHILD:
                    helper.debugger('no-op');
                    break;
                default:
                    throw new Error('Invalid mode');
            }

            this.#listeners = null;
            window.removeEventListener('message', this.#onMessageListener);
        }
    }
});