import angular from 'angular';

import './roomTile.less';

import {ROOM_LEVEL_KEYS, ROOM_PAUSE_ICON_REASONS} from '../../../../../shared/consts';

import template from './roomTile.html';
import roomAccessRequestPopupCtrl from '../../popups/roomAccessRequestPopup/roomAccessRequestPopup.html';
import sharedHelper from "../../../../../shared/sharedHelper";

if (MyGlobal.project.isMobile) {
    import('./roomTile_mobile.less');
}

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('myRoomTile', function() {
    return {
        restrict: 'E',
        template,
        scope: {
            room: '=',
            isLimbo: '=',
            isMobile: '=',
            hideAddToFavoritesStar: '=',
            linkTarget: '='
        },
        controller($scope, $rootScope, helper, userService, userRoomsService, popupService, $sce) {

            $scope.usersEllipsis = $scope.room.users?.length >= 10;
            $scope.isLimboRoomPlaying = false;
            $scope.pauseReasonHTML = $scope.room.pause
                && $sce.trustAsHtml($scope.room.pause?.reason === ROOM_PAUSE_ICON_REASONS.Toilet ? `<i class="fas fa-toilet-paper"></i>` : `<span>${$scope.room.pause.reason}</span>`);

            $scope.isPremiumRoom = $scope.room.level === ROOM_LEVEL_KEYS.PREMIUM;

            $scope.onPublicRoomClicked = () => {
                if($scope.isLimbo){
                    helper.trackGaUIEvent('LimboRoomTileClicked');
                }else{
                    helper.trackGaUIEvent('PublicRoomTileClicked');
                }
            };

            $scope.onLockPrivateRoomClicked = () => {
                $rootScope.showNotification(`You don't have permission to join this private ${sharedHelper.getRoomKind($scope.room)} :(`);
                helper.trackGaUIEvent('PrivateRoomTileClicked');
            };

            $scope.toggleFavorite = async ($event)=> {
                $event.preventDefault();

                try {
                    if(!userService.isLoggedIn()){
                        return helper.gotoState( 'join', {
                            message: `Join first, to add ${sharedHelper.getRoomKind($scope.room)} to your favorites list`
                        });
                    }

                    $scope.isFavorite = !$scope.isFavorite;
                    const { added } = await userRoomsService.toggleFavorite({ roomId: $scope.room._id });
                    $scope.isFavorite = added;

                    if(added){
                        helper.trackGaUIEvent('AddRoomToFavoritesClicked', { src: 'roomTile' });
                    }

                } catch(e) {
                    helper.error(e);
                }
            };

            $scope.onRequestAccess = ($event) => {
                $event.stopImmediatePropagation();
                popupService.openPopup({
                    $event,
                    template: roomAccessRequestPopupCtrl,
                    controller: 'MyRoomAccessRequestPopupCtrl',
                    resolve: {
                        popupParams: {
                            room: $scope.room
                        }
                    }
                })
            }

            (async ()=> {
                try {
                    if (userService.isLoggedIn()) {
                        $scope.isFavorite = await userRoomsService.inUserFavorites($scope.room._id);
                        $scope.inUserPrivateRooms = await userRoomsService.inUserPrivateRooms($scope.room._id);
                    }
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            })();

        }
    };
});
