
import angular from 'angular';
import './beatsIcon.less';
import template from './beatsIcon.html';

const app = angular.module(MyGlobal.page.ngAppName);
app.directive('myBeatsIcon', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            amount: '=?',
            abbreviateNumber: '=',
            featureKey: '='
        },
        controller($scope, beatsService, userService) {
            if ($scope.featureKey && !$scope.amount) {
                beatsService.promise.then(() => {

                    const feature = beatsService.getFeatureByKey($scope.featureKey);

                    if (userService.hasSubscription() && feature?.freeOnSubscription) {
                        $scope.hide = true;
                    } else if (feature) {
                        $scope.amount = feature.cost;
                    }
                });
            }
        }
    };
});
