/**
 * Created by Shlomi on 10/09/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName, MyGlobal.page.ngAppDeps || []);

    app.factory('$exceptionHandler', ($log) => {
        return (e, cause) => {
            if (e.message === 'Cannot read property \'childNodes\' of undefined') {
                $('body').html(`
                <div style="padding: 10px">
                    <div style="font-weight: bold">Unexpected error:</div>
                    <div><p>you probably have an issue with one of your browser's extensions<br/>we found that <a style="font-weight: bold;color: blue;" href="https://chrome.google.com/webstore/detail/requestly-redirect-url-mo/mdnleldcmiljblolnjhpnblkcekpdkpa" target="_blank">RERQUESTLY</a> or a similar browser extension is the problematic one, please remove (not only disable) it and try again.</p></div>
                    <div><p>if you still can't access, feel free to contact us at <a style="font-weight: bold;color: blue;" href="mailto:ilove@beatsense.com">ilove@beatsense.com</a></p></div>
                </div>`);
            } else {
                $log.error(e);
            }
        };
    });

    app.config(function($locationProvider,
                        $httpProvider, $uibTooltipProvider, $uibModalProvider,
                        tagsInputConfigProvider, timeAgoSettings) {

        // To allow the server to know the NG requests are ajax
        $httpProvider.defaults.headers.common['X-Angular-Ajax'] = true;

        if (MyGlobal.page.room) {
            $httpProvider.defaults.headers.common['X-Room-Id'] = MyGlobal.page.room;
        }

        // Initialize get if not there
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }
        //disable IE ajax request caching
        $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';

        // Tag inputs
        tagsInputConfigProvider.setDefaults('tagsInput', {
            addOnBlur: true,
            addOnComma: true,
            addOnEnter: true
        });

        // Disable tooltips on mobile
        if(MyGlobal.project.isMobile){
            $uibTooltipProvider.options({
                trigger: 'dontTrigger' // default dummy trigger event to show tooltips
            });
        }

        $uibModalProvider.options = { ...$uibModalProvider.options, animation: false };

        timeAgoSettings.refreshMillis = 60000;

        for (const [key, value] of Object.entries(timeAgoSettings.strings?.en_US)) {
            if (value?.includes('about')) {
                timeAgoSettings.strings.en_US[key] = value.replace(/about ?/g, '');
            }
        }

        MyGlobal.temp.$ = $;

    }).run(function(storage){
        storage.setForeverItem('isUserLoggedIn', !!MyGlobal.user);
    });
}(angular));
