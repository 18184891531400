/**
 * Created by Shlomi
 */

import template from '../../templates/partials/topMenu.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myTopMenu', function() {
        return {
            restrict: 'E',
            template,
            scope: {},
            controller: function ($scope, helper, $rootScope) {
                
                $scope.toggleMenu = function ($event) {
                    if($event) $event.preventDefault();
                    $scope.showMenu = !$scope.showMenu;
                };

                $scope.onMenuItemClicked = function($event, itemName){
                    helper.trackGaUIEvent('menuItemClicked:'+itemName);

                    if($event && ['sense'].indexOf(itemName) > -1) {
                        $event.preventDefault();
                    }

                    switch(itemName){
                        case 'sense':
                            $rootScope.$broadcast('feedback:show', {
                                showOnTop: true
                            });
                            break;
                    }

                    $scope.showMenu = false;
                };

                $scope.$on('document:click', function($evt, $jqEvt){
                    $jqEvt.stopImmediatePropagation();
                    // Close menu on click anywhere on screen except the menu itself..
                    var elem = $jqEvt ? $($jqEvt.target) : undefined;
                    if(elem && (elem.attr('id') === 'topMenu' || !!elem.parents('#topMenu').length)) return;
                    $scope.showMenu = false;
                    try { $scope.$digest(); } catch(e) {}
                });
                
            }
        };
    });
}(angular));