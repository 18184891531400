const FEED_DROP_ATTACHMENT_TYPES = {
    GIF: 'GIF',
    SONG: 'SONG',
    ROOM: 'ROOM',
    IMAGE: 'IMAGE',
    POLL: 'POLL'
};
const FEED_DROP_MAX_LENGTH = 300;

const POLL_MAX_OPTIONS = 10;
const POLL_MIN_OPTIONS = 2;
const MAX_SEARCH_HASHTAGS = 1;

const FEED_GROUP_FILTER_KEYS = {
    TRENDING: 'trending',
    FOLLOWING: 'following',
    LATEST: 'latest'
};

module.exports = {
    FEED_DROP_ATTACHMENT_TYPES,
    FEED_DROP_MAX_LENGTH,
    POLL_MAX_OPTIONS,
    POLL_MIN_OPTIONS,
    MAX_SEARCH_HASHTAGS,
    FEED_GROUP_FILTER_KEYS
};