import template from '../../../templates/partials/searchBox.html';

(function (angular) {

    var app = angular.module(MyGlobal.page.ngAppName);

    app.directive('mySearchBox', function() {
        return {
            restrict: 'E',
            template,
            scope: {
                customClass: '=',
                autoFocus: '=',
                placeholder: '=',
                minLength: '=',
                disabled: '=',
                cancelOnEscape: '=',
                onSearch: '&?',
                onClear: '&',
                onUpdate: '&',
                onCancel: '&'
            },
            controller: function ($scope, helper, $timeout) {

                const ignoredKeys = [
                    'ArrowRight',
                    'ArrowLeft',
                    'Meta'
                ];

                let blocked = false;
                let triggerAfterBlock = false;

                $scope.clear = async ($event, { leaveOpen } = {}) => {
                    try {
                        if ($event) {
                            $event.stopImmediatePropagation();
                        }

                        if ($scope.disabled) return;

                        const wasEmpty = !$scope.searchValue;

                        $scope.searchValue = '';
                        $scope.onUpdate({ value: $scope.searchValue });

                        await $scope.onClear();

                        if (wasEmpty && !leaveOpen) {
                            $scope.onCancel();
                        }
                    } catch(e) {
                        helper.error(e);
                    } finally {
                        try { $scope.$digest(); } catch(e) {}
                    }
                };

                $scope.onKeyUp = async ($event) => {

                    $event.stopImmediatePropagation();

                    if (ignoredKeys.includes($event.key)) {
                        return;
                    }

                    if ($event.key === 'Escape' && $scope.cancelOnEscape) {
                        $scope.onCancel();
                        return;
                    }

                    if (typeof $scope.onSearch !== 'function') return;

                    if (!$scope.searchValue || !$scope.searchValue.length) {
                        return $scope.clear(null, { leaveOpen: true });
                    }

                    if ($scope.minLength && $scope.searchValue.length < $scope.minLength) return;

                    if(blocked) {
                        triggerAfterBlock = true;
                        return;
                    }

                    blocked = $timeout(async () => {

                        if (triggerAfterBlock) {
                            await search();
                        }

                        triggerAfterBlock = false;
                        blocked = false;
                    }, 1500);

                    return search();
                };

                $scope.onBlur = () => {
                      $scope.onUpdate({ value: $scope.searchValue });
                };

                const search = async () => {
                    try {
                        $scope.searching = true;
                        await $scope.onSearch({ query: $scope.searchValue });
                    } catch(e) {
                        helper.error(e);
                    } finally {
                        $scope.searching = false;
                        try { $scope.$digest(); } catch(e) {}
                    }
                };
            },
            link($scope, $element) {
                if ($scope.autoFocus) {
                    $element.find('input').focus();
                }
            }
        };
    });

}(angular));
