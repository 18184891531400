const {STREAMING_EVENTS} = require('./streaming');
const {
    ROOM_LEVEL_KEYS,
    ROOM_LEVELS,
    ROOM_DEFAULT_ENTRANCE_FEE,
    ROOM_ACCESS_REQUEST_MIN_BEATS,
    ROOM_PAUSE_ICON_REASONS,
    ROOM_SETTINGS
} = require('./room');
const {LOGIN_OPTIONS_KEYS, LOGIN_OPTIONS} = require('./login');
const {PAYMENT_TYPES, PAYMENT_METHODS} = require('./payments');
const {NOTIFICATION_CHANNELS, NOTIFICATION_TYPES} = require('./notifications');
const {SHARE_TARGET_TYPES, SHARE_ENTITY_TYPES, USER_REF_TYPES} = require('./share');
const {
    FEED_DROP_ATTACHMENT_TYPES,
    FEED_DROP_MAX_LENGTH,
    POLL_MAX_OPTIONS,
    POLL_MIN_OPTIONS,
    MAX_SEARCH_HASHTAGS,
    FEED_GROUP_FILTER_KEYS
} = require('./feed');

const {
    WIKI_CHANGE_ACTIONS,
    WIKI_SONG_VALIDATION
} = require('./wiki');

const {
    MIN_SCORES_TO_SKIP,
    FILE_UPLOAD_TYPES,
    REGISTRATION_CODE_LENGTH,
    REGISTRATION_CODE_TTL,
    CHAT_MESSAGE_TYPES,
    DONATE_EMOJIS,
    CONTRIBUTION_SETTINGS,
    YOUTUBE_API_KEY_ERROR,
    BEATSENSE_USER_IMAGE,
    USER_REPORT_TYPES,
    EMPTY_USER_IMAGE,
    PLATFORM_TYPES,
    AMBASSADOR_PROGRAM_INFO,
    IFRAME_CONNECTION_TYPES
} = require('./general');

const { CREATE_ROOM_WHEN_PLAY_MESSAGE } = require('./stations');

const USER_SELF_STATUSES = {
    BRB: {
        key: 'brb',
        text: 'BRB',
        icon: 'fas fa-clock'
    },
    EATING: {
        key: 'eating',
        text: 'Went eating',
        icon: 'fas fa-utensils'
    },
    MEETING: {
        key: 'meeting',
        text: 'In a meeting',
        icon: 'fas fa-user-tie'
    },
    SLEEPING: {
        key: 'sleeping',
        text: 'Asleep',
        icon: 'fas fa-bed'
    },
    AFK: {
        key: 'afk',
        text: 'AFK',
        icon: 'fas fa-tree'
    }
};

const USER_STAYING_BONUS_AMOUNT = 20;

const BEATS_TRANSACTION_TYPES = {
    CREDIT: 'credit',
    DEBIT: 'debit'
};

const USERS_SORT_OPTIONS_STORAGE_KEY = 'usersSortMethod';

const USERS_SORT_OPTIONS_KEYS = {
    FOLLOWERS: 'followers',
    ACTIVITY: 'activity',
    SCORE: 'score',
    WIKI_CONTRIBUTIONS: 'wikiContributions'
};

const USERS_SORT_OPTIONS = [{
    key: USERS_SORT_OPTIONS_KEYS.FOLLOWERS,
    name: 'Fans'
}, {
    key: USERS_SORT_OPTIONS_KEYS.ACTIVITY,
    name: 'Activity'
}, {
    key: USERS_SORT_OPTIONS_KEYS.SCORE,
    name: 'Score'
}, {
    key: USERS_SORT_OPTIONS_KEYS.WIKI_CONTRIBUTIONS,
    name: 'Wiki Contributions'
}];

const YOUTUBE_LINK_REGEX = /^.*(youtube.com|youtu.be|y2u.be)\/((v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

const USER_CONTRIBUTION_GAINS = {
    REFERRING: 10,
    BEATBOX_CASE_SUBSCRIBE: 5,
    MOBILE_APP_5_STARS_REVIEW: 50,
    WRITE_INTRODUCTION: 10,
    SOCIAL_NETWORK_PER_100_FOLLOWERS: 50,
    WIKI_LYRICS: 3,
    WRITE_TESTIMONIAL: 30
};

// Above this number, the user won't be rearward
const USER_CONTRIBUTION_GAINS_LIMIT = {
    WIKI_LYRICS: 100
};

const USE_INNER_PLAYER = true;

module.exports = {
    STREAMING_EVENTS,
    MIN_SCORES_TO_SKIP,
    FILE_UPLOAD_TYPES,
    REGISTRATION_CODE_LENGTH,
    REGISTRATION_CODE_TTL,
    ROOM_DEFAULT_ENTRANCE_FEE,
    ROOM_ACCESS_REQUEST_MIN_BEATS,
    ROOM_PAUSE_ICON_REASONS,
    CHAT_MESSAGE_TYPES,
    DONATE_EMOJIS,
    CONTRIBUTION_SETTINGS,
    YOUTUBE_API_KEY_ERROR,
    ROOM_LEVELS,
    ROOM_LEVEL_KEYS,
    LOGIN_OPTIONS_KEYS,
    LOGIN_OPTIONS,
    POLL_MAX_OPTIONS,
    POLL_MIN_OPTIONS,
    FEED_DROP_ATTACHMENT_TYPES,
    FEED_DROP_MAX_LENGTH,
    BEATSENSE_USER_IMAGE,
    EMPTY_USER_IMAGE,
    PAYMENT_TYPES,
    PAYMENT_METHODS,
    SHARE_TARGET_TYPES,
    SHARE_ENTITY_TYPES,
    USER_REF_TYPES,
    USER_REPORT_TYPES,
    NOTIFICATION_CHANNELS,
    NOTIFICATION_TYPES,
    WIKI_CHANGE_ACTIONS,
    WIKI_SONG_VALIDATION,
    USER_SELF_STATUSES,
    USER_STAYING_BONUS_AMOUNT,
    USERS_SORT_OPTIONS_STORAGE_KEY,
    USERS_SORT_OPTIONS_KEYS,
    USERS_SORT_OPTIONS,
    BEATS_TRANSACTION_TYPES,
    MAX_SEARCH_HASHTAGS,
    FEED_GROUP_FILTER_KEYS,
    YOUTUBE_LINK_REGEX,
    USER_CONTRIBUTION_GAINS,
    USER_CONTRIBUTION_GAINS_LIMIT,
    ROOM_SETTINGS,
    CREATE_ROOM_WHEN_PLAY_MESSAGE,
    PLATFORM_TYPES,
    AMBASSADOR_PROGRAM_INFO,
    IFRAME_CONNECTION_TYPES,
    USE_INNER_PLAYER
};
