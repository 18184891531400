import angular from 'angular';
import './mobileRightMenu.less';
import template from './mobileRightMenu.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myMobileRightMenu', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, $rootScope, helper, beatsService) {
            $scope.helper = helper;

            $scope.onMyBeatsClicked = async () => {
                await beatsService.refresh();
            };
        }
    };
});
