/**
 * Created by Shlomi on 29/09/2014.
 */

import template from '../../templates/partials/roomThemeManagement.html';

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);
    app.directive('myRoomThemeManagement', function() {
        return {
            restrict: 'E',
            template,
            scope: {
                room: '=',
                origRoom: '=',
                isNewRoom: '=',
                createRoomPrice: '=',
                openSource: '=',
                onThemeSelected: '&'
            },
            controller: function($scope, $rootScope, themeService, roomService,
                                 userService, beatsService, helper, $q, $timeout,
                                 analyticsService){

                let currPage = 1;

                $scope.filters = {videos: 1, images: 1};

                $scope.selectedTheme = $scope.room.theme;

                beatsService.promise.then(function () {
                    $scope.premiumThemeCost = beatsService.getFeaturePrice(beatsService.keys.accessPremiumThemes);
                    $scope.accessPremiumVideoThemeCost = beatsService.getFeaturePrice(beatsService.keys.accessPremiumVideoThemes);
                });

                // Scope methods
                $scope.previewTheme = async (theme) => {

                    if(!theme) return;

                    if($scope.selectedTheme){
                        await removeThemePreview();
                        $('.themesHolder div[data-theme-key]').removeClass('active');
                    }

                    const parent = $('.themesHolder');
                    $scope.selectedTheme = theme;

                    if(!$scope.isNewRoom){
                        $scope.loadingPreview = true;
                        await themeService.setBodyTheme(theme, {
                            preview: true
                        }).catch(e => {
                            helper.error(e);
                        });
                        $scope.loadingPreview = false;
                    }

                    parent.find('div[data-theme-key='+theme.key+']').addClass('active');
                };

                const removeThemePreview = async () => {
                    if(!$scope.selectedTheme || $scope.isNewRoom) return;
                    $scope.selectedTheme = null;
                };

                $scope.canBuy = function (theme) {
                    const feature = theme.type === 'image' ? beatsService.keys.accessPremiumThemes : beatsService.keys.accessPremiumVideoThemes;
                    return beatsService.userCanBuy(feature, $scope.isNewRoom && $scope.createRoomPrice);
                };

                $scope.userHasFreeVideoTheme = () => {
                    return !userService.freeVideoThemeUsed();
                };

                $scope.onThemeSelectedInt = async ($event, theme) => {
                    try {
                        if(!theme) return;

                        const freeVideoEntitled = theme.type === 'video' && !userService.freeVideoThemeUsed();
                        const needToBuy = themeService.userNeedToBuy($scope.selectedTheme, { avoidFreeVideoTheme: true });
                        const canBuy = $scope.canBuy(theme);

                        if(needToBuy && !canBuy && !freeVideoEntitled){

                            if($scope.isNewRoom) return;

                            helper.trackBeatsEvent('NotEnoughBeatsModalOpened', 'GetPremiumThemeModal');
                            helper.gotoState('beatsInsufficient', null, {
                                prev: {
                                    name: $scope.isNewRoom ? 'create-room' : 'edit-room',
                                    params: {
                                        tab: 'themes'
                                    }
                                }
                            });

                            return;
                        }

                        analyticsService.trackEvent({
                            eventCategory: 'Themes',
                            eventAction: 'ThemeSelected',
                            eventLabel: theme.name
                        });

                        $scope.saving = true;

                        if (needToBuy) {
                            await themeService.buyThemeForUser(theme);
                        }

                        if(freeVideoEntitled){
                            analyticsService.trackEvent({
                                eventCategory: 'Themes',
                                eventAction: 'FreeVideoThemeRedeemed',
                                eventLabel: theme.name
                            });
                            userService.setFreeVideoThemeUsed();
                        }

                        if(!$scope.isNewRoom) {
                            await roomService.saveTheme($scope.room, theme);
                            await removeThemePreview();
                        }

                        if(needToBuy){
                            helper.trackBeatsEvent('VideoThemePurchased', theme.name);
                            return beatsService.purchaseHasBeenMade();
                        }
                    } catch (e) {
                        $scope.error = e;
                        helper.error(e);
                    } finally {
                        $scope.saving = false;
                        $scope.close(theme);
                        try { $scope.$digest(); } catch(e) {}
                    }
                };

                $scope.isRoomTheme = function (theme) {
                    return theme && $scope.room && $scope.room.theme && theme._id === $scope.room.theme._id;
                };

                $scope.userNeedToBuy = function (theme) {
                    return themeService.userNeedToBuy(theme);
                };

                $scope.getThemeBeatsFeatureKey = (theme) => {
                    return theme.type === 'image' ? beatsService.keys.accessPremiumThemes : beatsService.keys.accessPremiumVideoThemes;
                };

                $scope.onSearchKeyDown = ($event) => {
                    if($event.key === 'Escape' && $scope.searchQuery) {
                        $event.stopImmediatePropagation();
                    }
                };

                $scope.resetSearch = async () => {
                    return $scope.load();
                };

                let lastAutoCaseSelectTimer;
                let searchPrevQuery;
                let searchLocked;
                let searchLockTimer;
                let searchRequested;

                const updateVideosPlaybackRate = () => {
                    if(!$scope.themes?.length) return;
                    for(const theme of $scope.themes){
                        if(theme.type === 'video' && theme?.settings?.playbackRate){
                            const video = $(`[data-theme-key=${theme.key}] .themeImage video`).get(0);

                            if(video){
                                video.playbackRate = theme.settings.playbackRate;
                            }
                        }
                    }
                };

                $scope.load = async (params = {}) => {
                    currPage = params.page = params.page || 1;

                    if(!params.search && $scope.searchQuery){
                        params.search = $scope.searchQuery;
                    }

                    const filters = {};

                    for(const [key, value] of Object.entries($scope.filters)){
                        if(value){
                            filters[key] = value;
                        }
                    }

                    const { result, pages } = await themeService.load({ ...params, filters });

                    // In case the pagination should stop
                    if(result === false) return;

                    $scope.totalPages = pages;
                    $scope.themes = result;

                    updateVideosPlaybackRate();
                };

                const search = async () => {

                    const search = $scope.searchQuery;

                    if(search){
                        analyticsService.trackEvent({
                            eventCategory: 'Themes',
                            eventAction: 'ThemesSearch',
                            eventLabel: search
                        });
                    }

                    // It's possible the search will be empty, if the user delete the input in the meantime
                    return $scope.load({ search });
                };

                $scope.onSearchKeyUp = async ($event) => {
                    try {
                        if($event && $event.key === 'Tab') return;

                        if(lastAutoCaseSelectTimer) {
                            $timeout.cancel(lastAutoCaseSelectTimer);
                            lastAutoCaseSelectTimer = null;
                        } //to avoid flickering

                        if(!$scope.searchQuery || $event.key === 'Escape') {
                            await $scope.resetSearch();
                            return;
                        }

                        if($scope.searchQuery.length < 2) return;
                        if(searchPrevQuery === $scope.searchQuery) return;
                        searchPrevQuery = $scope.searchQuery;

                        // Block hitting the server
                        if(!searchLocked){

                            if(searchLockTimer){
                                $timeout.cancel(searchLockTimer);
                                searchLockTimer = null;
                            }

                            searchRequested = false;
                            searchLocked = true;

                            await search();

                            searchLockTimer = $timeout(async () => {

                                if(searchRequested){
                                    await search();
                                    searchRequested = false;
                                }

                                searchLocked = false;
                            }, 1000);
                        }else{
                            searchRequested = true;
                        }

                    } catch (e) {
                        helper.error(e);
                    }
                };

                $scope.close = (theme) => {
                    $scope.onThemeSelected({ theme });
                };

                let blockScrollTimer;
                const blockScroll = () => {
                    blockScrollTimer = $timeout(() => {
                        blockScrollTimer = null;
                    }, 100);
                };

                helper.replaceWindowScroll(async (evt) => {
                    try {

                        if(blockScrollTimer) return;

                        if(evt.deltaY < 0){
                            currPage--;
                        }else{
                            currPage++;
                        }

                        if(currPage <= 0) {
                            currPage = 0;
                            return;
                        }

                        if($scope.totalPages && currPage > $scope.totalPages){
                            currPage = $scope.totalPages;
                            return;
                        }

                        blockScroll();

                        await $scope.load({
                            page: currPage,
                            append: true
                        });

                    } catch (e) {
                        helper.error(e);
                    }
                });

                $scope.$on('$destroy', () => {
                    helper.restoreWindowScroll();
                });

                $timeout(async () => {
                    try {

                        $scope.loading = true;

                        await Promise.all([
                            themeService.loadMy(),
                            $scope.load()
                        ]);

                        $('#txtSearchTheme').focus();

                    } catch (e) {

                        helper.error(e);
                    } finally {
                        $scope.loading = false;
                    }
                });
            }
        };
    });
}(angular));
