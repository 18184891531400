import angular from 'angular';
import './postFavoritePopup.less';

import '../../stationsHomePage/station.service';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyPostFavoritePopupCtrl', ($scope, $stateParams, $rootScope, $uibModalInstance, roomService,
                                           stationService, helper) => {

    $scope.room = roomService.getRoom();
    $scope.subscribed = roomService.isSubscribed();

    $scope.subscribe = async () => {
        try {
            if ($scope.subscribeLoading) return;
            $scope.subscribeLoading = true;
            const { state } = await roomService.toggleSubscribe();
            roomService.setRoomSubscription(state);
            $scope.subscribed = state;
            $rootScope.showNotification(`Subscribed!`);
            $scope.close();
        } catch(e) {
            helper.error(e);
        } finally {
            delete $scope.subscribeLoading;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    if ($scope.room.user?._id) {
        (async () => {
            try {
                $scope.loading = true;
                const { total } = await stationService.getUserTotalStations({
                    userId: $scope.room.user?._id,
                    exclude: $scope.room._id
                });

                $scope.totalOtherStations = total;
            } catch(e) {
                helper.error(e);
            } finally {
                delete $scope.loading;
                try { $scope.$digest(); } catch(e) {}
            }
        })();
    }

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };
});
