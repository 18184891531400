const NOTIFICATION_CHANNELS = {
    USER_ALERT: 'userAlert',
    PUSH_NOTIFICATION: 'pushNotification'
};

const NOTIFICATION_TYPES = {
    SIMPLE: {
        key: 'simple',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT
        ]
    },
    BUDDIED: {
        key: 'buddied',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    ONLINE_BUDDY: {
        key: 'onlineBuddy',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT
        ]
    },
    GOT_DONATION: {
        key: 'gotDonation',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    ROOM_INVITATION: {
        key: 'roomInvitation',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    ROOM_FAVORITED: {
        key: 'roomFavorited',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    ROOM_ADMIN_INVITATION: {
        key: 'roomAdminInvitation',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    USER_LEVELED_UP: {
        key: 'userLeveledUp',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT
        ]
    },
    NEW_MUSIC_MATCH: {
        key: 'newMusicMatch',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT
        ]
    },
    SYSTEM_ALERT: {
        key: 'systemAlert',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT
        ]
    },
    FEED_DROP_POSTED: {
        key: 'feedDropPosted',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    FEED_DROP_LIKED: {
        key: 'feedDropLiked',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    FEED_DROP_REDROPED: {
        key: 'feedDropReDroped',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    FEED_DROP_REPLIED: {
        key: 'feedDropReplied',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    FEED_DROP_USER_MENTIONED: {
        key: 'feedDropUserMentioned',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    TRACKED_USER_IS_ONLINE: {
        key: 'trackedUserIsOnline',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    USER_FOLLOWED: {
        key: 'userFollowed',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    USER_START_JAMMING: {
        key: 'userStartJamming',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    ADDED_YOUR_SONG_TO_BEATBOX: {
        key: 'addedYourSongToBeatbox',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    USER_START_BROADCASTING: {
        key: 'userStartBroadcasting',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    ROOM_ACCESS_REQUEST: {
        key: 'roomAccessRequest',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    FEED_DROP_POLL_VOTED: {
        key: 'feedDropPollVoted',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    ROOM_OPENED_BY_USER: {
        key: 'roomOpenedByUser',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    ROOM_JAMMING: {
        key: 'roomJamming',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    PROFILE_WATCH: {
        key: 'profileWatch',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    },
    AMBASSADOR_REFERRAL_JOINED: {
        key: 'ambassadorReferralJoined',
        channels: [
            NOTIFICATION_CHANNELS.USER_ALERT,
            NOTIFICATION_CHANNELS.PUSH_NOTIFICATION
        ]
    }
};

module.exports = {
    NOTIFICATION_CHANNELS,
    NOTIFICATION_TYPES
};