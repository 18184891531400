import angular from 'angular';

const app = angular.module(MyGlobal.page.ngAppName);


app.service('pollService', (httpService) => {
    return {
        async vote({ pollId, optionId, feedDropId }) {
            return await httpService.post(`/poll/vote`, {
                id: pollId,
                option: optionId,
                feedDrop: feedDropId
            });
        }
    }
});