/**
 * Created by Shlomi on 26/10/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('ForgotPasswordCtrl', function ($scope, $uibModalInstance, $state, $http, helper) {

        $scope.forgotPassword = function(email){

            if(!email){
                return $scope.error = 'Please enter your email or username';
            }

            $scope.notificaion = $scope.error = '';

            $scope.loading = true;
            $http.post('/auth/forgotPassword', {email: email}).success(function(){
                $scope.loading = false;
                $scope.notificaion = 'Your password successfully sent to your email!'
            }).error(function(response){
                $scope.loading = false;
                $scope.error = response && response.status === 404 ? 'Unfamiliar email \\ username' : response.error;
            });
            return false;
        };

        $scope.close = function(){
            $uibModalInstance.dismiss();
        };

        helper.trackGaUIEvent('ForgotPasswordModalOpened');
    });

}(angular));

